const apiURL = process.env.REACT_APP_API_GATEWAY;

export const fetchMessageHistory = async (userId, messageId, token) => {
  try {
    const response = await fetch(`${apiURL}/api/message/history/${userId}/${messageId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();

    const messagesArray = Array.isArray(data.messages) ? data.messages : [];

    return { ...data, messages: messagesArray };

  } catch (error) {
    console.error('Error fetching message history:', error);
    throw error;
  }
};

export const fetchConversations = async (userId, token) => {
  try {
    const response = await fetch(`${apiURL}/api/message/conversations/${userId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();

    // Ensure the conversations are returned as an array
    const conversationsArray = Array.isArray(data.conversations) 
      ? data.conversations 
      : [];
    return { ...data, conversations: { conversations: conversationsArray } };
  } catch (error) {
    console.error('Error fetching conversations:', error);
    throw error;
  }
};