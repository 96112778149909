// src/components/ui/card/card-header.js
import * as React from "react"
import { cn } from "@/lib/utils"

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1.5 p-6", className)}
    {...props}
  />
))
CardHeader.displayName = "CardHeader"

export { CardHeader }