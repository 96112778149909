import React, { useEffect } from 'react';
import { FaRegBell, FaRegEnvelope, FaRegCalendarAlt } from 'react-icons/fa';
import { IoCheckmarkDone } from 'react-icons/io5';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useWebSocket } from '../context/WebSocketContext';
import { useNotifications } from '../context/NotificationContext';

const NotificationsPage = () => {
  const { notifications, setNotifications } = useNotifications();
  const { newMessage } = useWebSocket();

  const getNotificationIcon = (type) => {
    switch(type) {
      case 'message':
        return <FaRegEnvelope className="w-5 h-5" />;
      case 'reservation':
        return <FaRegCalendarAlt className="w-5 h-5" />;
      default:
        return <FaRegBell className="w-5 h-5" />;
    }
  };

  const getTimeAgo = (date) => {
    date = new Date(date);
    if (isNaN(date)) return null;

    const now = new Date();
    const diffInHours = Math.floor((now - date) / (1000 * 60 * 60));
    return diffInHours < 24 ? `Il y a ${diffInHours}h` : format(date, 'd MMM', { locale: fr });
  };

  const markAllAsRead = () => {
    setNotifications(prev => prev.map(n => ({ ...n, est_lu: true })));
    fetch(`${process.env.REACT_APP_API_GATEWAY}/api/auth/notification/mark-all-as-read`, {
      method: 'GET',
      credentials: 'include'
    }).then(res => res.json()).catch(console.error);
  };

  const markAsRead = (id) => {

    fetch(`${process.env.REACT_APP_API_GATEWAY}/api/auth/notification/mark-as-read/${id}`, {
      method: 'GET',
      credentials: 'include'
    }).then(res => res.json()).catch(console.error);
    setNotifications(prev => prev.map(n => n.notification_id === id ? { ...n, est_lu: true } : n));
  };

  useEffect(() => {
    if (newMessage) {
      setNotifications(prev => [newMessage, ...prev]); // Add new WebSocket notification to the beginning
    }
  }, [newMessage]);

  if (notifications.length === 0) {
    return (
      <div className="min-h-[80vh] flex items-center justify-center bg-gray-50/50">
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} className="text-center">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-50 mb-4">
            <FaRegBell className="w-8 h-8 text-blue-500" />
          </div>
          <h2 className="text-xl font-medium text-gray-900">Pas de notifications</h2>
          <p className="text-gray-500 mt-2">Nous vous préviendrons dès qu'il y aura du nouveau</p>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50/50 pt-20">
      <div className="max-w-3xl mx-auto px-4">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-semibold text-gray-900">Notifications</h1>
          {notifications.some(n => !n.est_lu) && (
            <button onClick={markAllAsRead} className="inline-flex items-center gap-2 px-4 py-2 text-sm text-gray-600 hover:text-gray-900 transition-colors">
              <IoCheckmarkDone className="w-5 h-5" />
              <span>Tout marquer comme lu</span>
            </button>
          )}
        </div>
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} className="space-y-2">
          {notifications.map((notification) => (
            <Link
              key={notification.notification_id}
              to={notification.link}
              onClick={() => markAsRead(notification.notification_id)}
              className={`block w-full ${!notification.est_lu ? 'bg-blue-50/50' : 'bg-white'} 
                rounded-xl p-4 hover:shadow-lg transition-all duration-200 border border-gray-100 hover:border-gray-200`}
            >
              <div className="flex items-start gap-4">
                <div className={`flex-shrink-0 p-2 rounded-full ${!notification.est_lu ? 'bg-blue-100/50 text-blue-600' : 'bg-gray-100/50 text-gray-500'}`}>
                  {getNotificationIcon(notification.type)}
                </div>
                <div className="flex-grow min-w-0">
                  <p className={`text-sm ${!notification.est_lu ? 'text-gray-900 font-medium' : 'text-gray-600'}`}>
                    {notification.message}
                  </p>
                  <p className="text-xs text-gray-500 mt-1">
                    {notification.subtitle}
                  </p>
                </div>
                <div className="flex-shrink-0 text-xs text-gray-400">
                  {getTimeAgo(notification.cree_a)}
                </div>
              </div>
            </Link>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default NotificationsPage;