import React, { createContext, useContext, useState, useEffect } from 'react';

// Création du contexte
const FormContext = createContext();

// Composant Provider qui enveloppe votre application et fournit l'accès au contexte
export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    steps : 'welcome',
    typeEspace: '',
    latitude: '',
    longitude: '',
    adresse: '',
    ville: '',
    pays: '',
    guests: 4,
    spaces: 1,
    toilets: 1,
    desks: 1,
    equipements: [],
    photos: [],
    photoPrincipal: null,
    titre: '',
    strongPoints: [],
    description: '',
    modeReservation: '',
    prixByHour: 10,
    prixByDay: 100,
    prixByWeek: 600,
    prixByMonth: 2400,
    status: 'En cours',
  });


  

  const handleChange = (field, value) => {
    setFormData(prevData => ({ ...prevData, [field]: value }));
  };

  return (
    <FormContext.Provider value={{ formData, handleChange }}>
      {children}
    </FormContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte dans d'autres composants
export const useForm = () => useContext(FormContext);
