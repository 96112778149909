import React, { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import Search from './Search';
import { 
  FaArrowDown, FaCity, FaBriefcase, FaUsers, 
  FaHandshake, FaLock, FaCalendarAlt, FaMapMarkerAlt,
  FaChartLine, FaStar, FaHeart,
  FaCheck
} from 'react-icons/fa';

const StatCard = ({ number, text }) => (
  <motion.div 
    className="bg-gradient-to-br from-white to-gray-50 p-8 rounded-3xl shadow-xl
               transition-all duration-300 border border-gray-100"
  >
    <h3 className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-blue-600 
                   to-violet-600 bg-clip-text text-transparent mb-4">
      {number}
    </h3>
    <p className="text-gray-600 text-lg">
      {text}
    </p>
  </motion.div>
);

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div className="relative">
    <div className="relative p-8 bg-white rounded-3xl shadow-xl 
                    border border-gray-100 transition-all duration-300">
      <Icon className="h-12 w-12 text-blue-600 mb-6" />
      <h3 className="text-2xl font-bold text-gray-900 mb-4">
        {title}
      </h3>
      <p className="text-gray-600 text-lg">
        {description}
      </p>
    </div>
  </motion.div>
);

const Hero = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start start", "end start"]
  });

  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const scale = useTransform(scrollYProgress, [0, 0.5], [1, 0.8]);

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-gray-50 via-white to-gray-50 pt-6">
      {/* Fond animé avec motifs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-full h-full">
          <motion.div
            className="absolute top-0 left-1/4 w-96 h-96 bg-blue-100/50 
                       rounded-full mix-blend-multiply filter blur-3xl opacity-60"
            animate={{
              y: [0, 100, 0],
              x: [0, 50, 0],
              scale: [1, 1.2, 1]
            }}
            transition={{
              duration: 20,
              repeat: Infinity,
              repeatType: "reverse"
            }}
          />
          <motion.div
            className="absolute bottom-1/4 right-1/3 w-[600px] h-[600px] 
                       bg-violet-100/50 rounded-full mix-blend-multiply filter blur-3xl opacity-60"
            animate={{
              y: [0, -100, 0],
              x: [0, -50, 0],
              scale: [1, 1.3, 1]
            }}
            transition={{
              duration: 23,
              repeat: Infinity,
              repeatType: "reverse"
            }}
          />
          <motion.div
            className="absolute top-1/3 right-1/4 w-[500px] h-[500px] 
                       bg-indigo-100/50 rounded-full mix-blend-multiply filter blur-3xl opacity-60"
            animate={{
              y: [0, 80, 0],
              x: [0, -30, 0],
              scale: [1, 1.1, 1]
            }}
            transition={{
              duration: 25,
              repeat: Infinity,
              repeatType: "reverse"
            }}
          />
        </div>
      </div>

      {/* Contenu principal */}
      <div className="relative min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-20"
          >
            {/* En-tête */}
            <div className="space-y-8">
              <motion.h1 
                className="text-6xl sm:text-7xl md:text-8xl font-bold text-gray-900 tracking-tight"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.8 }}
              >
                <span className="block mb-4">Trouvez votre</span>
                <span className="bg-gradient-to-r from-blue-600 to-violet-600 
                               bg-clip-text text-transparent inline-block">
                  espace idéal
                </span>
              </motion.h1>

              <motion.p 
                className="text-2xl md:text-3xl text-gray-600 max-w-3xl mx-auto font-light"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                Des espaces de travail uniques pour créer, collaborer et réussir
              </motion.p>
            </div>

            {/* Barre de recherche */}
            <motion.div
              className="max-w-2xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
            >
              <Search onSearch={value => console.log('Search:', value)} />
            </motion.div>

            {/* Cartes d'avantages */}
            <motion.div
              className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto px-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
            >
              {[
                {
                  icon: FaCity,
                  title: "Emplacements Premium",
                  description: "Quartiers stratégiques dans vos villes"
                },
                {
                  icon: FaBriefcase,
                  title: "Bureaux Nouvelle Génération",
                  description: "Espaces modulables avec technologie intégrée"
                },
                {
                  icon: FaUsers,
                  title: "Communauté Dynamique",
                  description: "Networking et événements professionnels"
                }
              ].map((feature, index) => (
                <FeatureCard key={index} {...feature} />
              ))}
            </motion.div>
          </motion.div>
        </div>
      </div>

      {/* Section Statistiques */}
      <section className="py-24 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            <StatCard number="1000+" text="Mètres carrés disponibles" />
            <StatCard number="3" text="Villes en France" />
            <StatCard number="85%" text="Satisfaction client" />
            <StatCard number="24/7" text="Support client" />
          </div>
        </div>
      </section>

      {/* Section Avantages */}
      <section className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-20">
            <h2 className="text-5xl font-bold mb-6 bg-gradient-to-r from-blue-600 
                          to-violet-600 bg-clip-text text-transparent">
              Pourquoi nous choisir ?
            </h2>
            <p className="text-2xl text-gray-600 font-light">
              La simplicité au service de vos besoins
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: FaHandshake,
                title: "Réservation Simplifiée",
                description: "Processus de réservation transparent et rapide, sans engagement de longue durée"
              },
              {
                icon: FaLock,
                title: "Paiement Sécurisé",
                description: "Transactions sécurisées et système de paiement fiable"
              },
              {
                icon: FaCalendarAlt,
                title: "Flexibilité Totale",
                description: "Réservez à l'heure, à la journée ou au mois selon vos besoins"
              }
            ].map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;