import { useCallback } from 'react';
import { useNotifications } from '../context/NotificationContext';
const useMessageHandlers = ({
  messageInput,
  previewFiles,
  setMessageInput,
  setPreviewFiles,
  fileInputRef,
  userId,
  userName,
  selectedMessage,
  ws,
  dispatch, // Add dispatch to the dependencies
}) => {

  const { markPrivateMessagesAsRead } = useNotifications(); // Destructure markPrivateMessagesAsRead

  
  const handleSendMessage = useCallback(() => {
    if (!messageInput.trim() && previewFiles.length === 0) return;

    // Add is_lu property to the message
    markPrivateMessagesAsRead(selectedMessage.id); // Mark messages as read

    const newMessages = previewFiles.map(file => ({
      type: file.type.startsWith('image/') ? 'image' : 'file',
      content: file.content,
      senderId: userId,
      author: userName,
      receiverId: selectedMessage.id,
      typeMessage: file.type.startsWith('image/') ? 'image' : 'file',
      timestamp: new Date().toISOString() // Add timestamp
    }));

    if (messageInput.trim()) {
      newMessages.push({
        type: 'privateMessage',
        content: messageInput,
        senderId: userId,
        author: userName,
        receiverId: selectedMessage.id,
        typeMessage: 'privateMessage', // Set typeMessage to privateMessage
        timestamp: new Date().toISOString() // Add timestamp
      });
    }

    newMessages.forEach(newMessage => {
      console.log('Envoi du message via WebSocket:', newMessage);
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(JSON.stringify(newMessage)); // Use the new WebSocket connection
      } else {
        console.error('WebSocket is not open. Unable to send message.');
      }

      
    });

    setMessageInput('');
    setPreviewFiles([]);
    fileInputRef.current.value = null; // Reset file input
  }, [messageInput, previewFiles, setMessageInput, setPreviewFiles, fileInputRef, userId, userName, selectedMessage, ws, dispatch]);

  const handleFileChange = useCallback((event) => {
    const files = Array.from(event.target.files);
    const filePromises = files.map(file => {
      return new Promise((resolve) => {
        if (file.size > 10 * 1024 * 1024) {
          alert('Le fichier doit être inférieur à 10 Mo');
          resolve(null);
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          resolve({
            name: file.name,
            type: file.type,
            content: reader.result
          });
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(filePromises).then(results => {
      const validFiles = results.filter(file => file !== null);
      setPreviewFiles([...previewFiles, ...validFiles]);
    });

    // Reset file input to allow uploading the same file again
    event.target.value = null;
  }, [setPreviewFiles, previewFiles]);

  const handleRemoveFile = useCallback((index) => {
    setPreviewFiles(previewFiles.filter((_, i) => i !== index));
  }, [setPreviewFiles, previewFiles]);

  return {
    handleSendMessage,
    handleFileChange,
    handleRemoveFile
  };
};

export default useMessageHandlers;