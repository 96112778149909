import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'lucide-react';

function Footer() {
  const currentYear = new Date().getFullYear();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  
  return (
<footer className="relative z-10">
 <div className="h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent" />
 
 <div className="bg-white/80 backdrop-blur-sm">
   <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
     <div className="flex flex-col md:flex-row justify-between items-center gap-4">
       {/* Left side */}
       <div className="flex flex-col md:flex-row items-center gap-4 md:gap-6">
         <Link to="/" className="text-gray-400 hover:text-gray-600">
           <svg className="w-6 h-6 transition-transform hover:scale-110" viewBox="0 0 24 24" fill="none" stroke="currentColor">
             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
           </svg>
         </Link>
         <div className="hidden md:block h-4 w-px bg-gray-200" />
         <div className="flex gap-4">
           <Link to="/privacy" className="text-sm text-gray-500 hover:text-gray-800">Confidentialité</Link>
           <Link to="/terms" className="text-sm text-gray-500 hover:text-gray-800">CGU</Link>
            {isAuthenticated && (
              <Link to="/contact" className="text-sm text-gray-500 hover:text-gray-800">Contact</Link>
            )}
         </div>
       </div>

       {/* Right side */}
       <div className="flex flex-wrap items-center justify-center md:justify-end gap-2 text-sm text-gray-500">
         <span>© {new Date().getFullYear()} HomeOfficeShare</span>
         <div className="hidden md:block h-4 w-px bg-gray-200" />
         <span className="hidden md:inline">Created by</span>
         <a href="https://www.linkedin.com/company/gauss-co" title = "Gauss & Co."target="_blank" rel="noopener noreferrer"
            className="inline-flex items-center gap-1 font-medium text-gray-700 hover:text-blue-600 group">
           Gauss & Co
           <ExternalLink className="w-3 h-3 opacity-50 group-hover:opacity-100 transform group-hover:-translate-y-0.5 group-hover:translate-x-0.5 transition-all" />
         </a>
       </div>
     </div>
   </div>
 </div>

 <div className="absolute inset-0 -z-10 bg-gradient-to-b from-transparent to-gray-50/50 pointer-events-none" />
</footer>
  );
}

export default Footer;