import React, { useEffect, useRef } from 'react';
import { formatDate } from '@/utils/dateUtils';
import ImagePdf from '../assets/pdf.png';

const MessageGroup = ({ messages, showDate, userId, handleImageClick }) => {
  const lastMessageRef = useRef(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="mb-8 last:mb-0">
      {showDate && <div className="text-center mb-6"><span className="text-[13px] text-gray-500 bg-white px-4">{messages[0].date}</span></div>}
      {messages.map((message, index) => (
        <div 
          key={message._id} 
          ref={index === messages.length - 1 ? lastMessageRef : null}
          className={`mb-6 last:mb-0 ${message.system ? 'text-center' : ''}`}
        >
          {message.system ? (
            <span className="text-[13px] text-gray-500">{message.message}</span>
          ) : (
            <div className={`flex ${message.senderId === userId ? 'justify-end' : 'items-start gap-4'}`}>
              {message.senderId !== userId && (
                <div className="w-8 h-8 bg-gray-900 rounded-full flex items-center justify-center flex-shrink-0">
                  <span className="text-white text-sm uppercase">{message.author?.charAt(0)}</span>
                </div>
              )}
              <div className="max-w-[75%] relative">
                <div className="flex items-baseline gap-2 mb-1">
                  <span className="font-medium text-[14px]">{message.sender}</span>
                  <span className="text-gray-500 text-[13px]">{formatDate(message.timestamp)}</span>
                </div>
                <div className={`p-4 rounded-2xl text-[15px] leading-relaxed ${message.senderId === userId ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
                  {message.typeMessage === 'image' ? (
                    <img src={message.content} alt="Envoyé" className="max-w-xs h-auto max-h-64 cursor-pointer" onClick={() => handleImageClick(message.content)} />
                  ) : message.typeMessage === 'file' ? (
                    <a href={message.content} download>
                      <img src={ImagePdf} alt="PDF" className="w-16 h-16" />
                    </a>
                  ) : (
                    message.content
                  )}
                </div>
                {message.readBy && <div className="mt-1.5 text-[13px] text-gray-500">{message.readBy}</div>}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MessageGroup;