
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useForm } from '../context/FormHostContext';


function SaveQuitButton() {
  const navigate = useNavigate();
  const { userId } = useAuth(); // Obtention du user connecté
  const { formData } = useForm();
  const apiURL = process.env.REACT_APP_API_GATEWAY;
  
  // Fonction pour envoyer les fichiers au serveur
  const sendFiles = (formData) => {

  fetch(`${apiURL}/api/workspace/createWorkspace`, {
    method: 'POST',
    credentials: 'include', 
    body: formData,
  })
    .then(response => response.json())
    .then(imageData => {
      console.log('Images uploaded successfully:', imageData);
    })
    .catch(error => {
      console.error('Error uploading images:', error);
    });
  }

  // Save database 
  const createSpace = () => {
  // Enregistre les images dans le dossier images
  const formDataImage = new FormData();

  // Ajouter les donnée de formData
  formDataImage.append('hote_id', userId);
  formDataImage.append('status', "En cours");
  formDataImage.append('typeEspace', formData.typeEspace);
  formDataImage.append('latitude', formData.latitude);
  formDataImage.append('longitude', formData.longitude);
  formDataImage.append('adresse', formData.adresse);
  formDataImage.append('ville', formData.ville);
  formDataImage.append('pays', formData.pays);
  formDataImage.append('guests', formData.guests);
  formDataImage.append('spaces', formData.spaces);
  formDataImage.append('toilets', formData.toilets);
  formDataImage.append('desks', formData.desks);
  formDataImage.append('equipements', JSON.stringify(formData.equipements));
  formDataImage.append('titre', formData.titre);
  formDataImage.append('strongPoints', JSON.stringify(formData.strongPoints));
  formDataImage.append('description', formData.description);
  formDataImage.append('modeReservation', formData.modeReservation);
  formDataImage.append('prixByHour', formData.prixByHour);
  formDataImage.append('prixByDay', formData.prixByDay);
  formDataImage.append('prixByWeek', formData.prixByWeek);
  formDataImage.append('prixByMonth', formData.prixByMonth);

    // SI pas d'images
    if(formData.photos.length == 0){
      sendFiles(formDataImage)
    }else{
      formData.photos.forEach((blobUrl, index) => {
        // Récupérer le blob à partir de l'URL
        fetch(blobUrl)
        .then(res => res.blob())  // Convertir l'URL en Blob
        .then(blob => {
          // Créer un objet File à partir du Blob
  
          let name_file = formData.photoPrincipal === blobUrl ? `photoPrincipal.jpg` : `photo_${index}.jpg`;
  
          const file = new File([blob], name_file, { type: blob.type });
          // Ajouter le fichier à FormData sous le champ 'photos'
          formDataImage.append('photos', file);
          
          // Si vous avez ajouté tous les fichiers, vous pouvez faire la requête
          if (index === formData.photos.length - 1) {
            // Envoyer les fichiers au backend
            sendFiles(formDataImage);
          }
        })
        .catch(error => {
        console.error('Erreur lors de la conversion du Blob:', error);
        });
      });

    }


  };

  const handleSaveAndQuit = async () => {

    createSpace();


    navigate('/dashboard');
  };

  return (
    <button className="save-quit-button" onClick={handleSaveAndQuit}>
      Enregistrer et quitter
    </button>
  );
}

export default SaveQuitButton;