import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import notificationSound from '../assets/audio/notification.mp3';

export const WebSocketContext = createContext(null);

let socket = null; // Variable globale pour persister la connexion

export const WebSocketProvider = ({ children, onMessage }) => {
  const [messages, setMessages] = useState([]);
  const audioRef = useRef(new Audio(notificationSound));
  const wsProtocol = process.env.NODE_ENV === 'production' ? 'wss' : 'ws';

  useEffect(() => {
    if (!socket) {
      socket = new WebSocket(`${wsProtocol}://${process.env.REACT_APP_WS_GATEWAY}/api/message/`);
      // socket = new WebSocket(`ws://localhost:8081/api/message/`);

      socket.onopen = () => {
        console.log('WebSocket connecté');
      };

      const safeJSONParse = (data) => {
        try {
          return JSON.parse(data);
        } catch {
          return null;
        }
      };

      socket.onmessage = (event) => {
        const data = safeJSONParse(event.data);

        if (data) {
          // Jouer le son de notification
          audioRef.current.play().catch(err => {
            console.warn('Erreur lors de la lecture du son:', err);
          });

          if(data.type === 'privateMessage') {
            onMessage(data); // Handle private messages as notifications
          } else {
            setMessages((prev) => [data, ...prev]);
            if (onMessage) {
              onMessage(data);
            }
          }
        }
      };

      socket.onclose = () => {
        console.log('WebSocket déconnecté');
      };
    }

    return () => {
      if (socket) {
        socket.close();
        socket = null; // Réinitialise la variable globale
      }
    };
  }, [onMessage]);

  return (
    <WebSocketContext.Provider value={{ socket, messages, setMessages }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
