import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Filter from '../components/Filter';
import { MapPin, Star, Search, ArrowUpRight, Menu, X } from 'lucide-react';

// Composant du drawer mobile pour les filtres
const MobileFilterDrawer = ({ isOpen, onClose, children }) => (
 <AnimatePresence>
   {isOpen && (
     <>
       <motion.div
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         exit={{ opacity: 0 }}
         onClick={onClose}
         className="fixed inset-0 bg-black/60 backdrop-blur-sm z-40"
       />
       <motion.div
         initial={{ x: '100%' }}
         animate={{ x: 0 }}
         exit={{ x: '100%' }}
         transition={{ type: 'spring', damping: 30 }}
         className="fixed right-0 top-0 h-full w-[90%] max-w-md bg-white shadow-xl z-50 overflow-y-auto"
       >
         <div className="sticky top-0 flex items-center justify-between p-4 border-b bg-white/80 backdrop-blur-sm">
           <h3 className="text-lg font-semibold">Filtres</h3>
           <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full transition-colors">
             <X className="w-5 h-5" />
           </button>
         </div>
         <div className="p-4">{children}</div>
       </motion.div>
     </>
   )}
 </AnimatePresence>
);

// Composant card pour un espace
const SpaceCard = ({ space }) => {
 const getTypeLabel = (type) => ({
   day: 'jour',
   week: 'semaine',
   month: 'mois',
   hour: 'heure'
 }[type] || type);

 return (
   <motion.div
     layout
     initial={{ opacity: 0, y: 20 }}
     animate={{ opacity: 1, y: 0 }}
     exit={{ opacity: 0, y: 20 }}
     className="group bg-white rounded-2xl overflow-hidden shadow-sm hover:shadow-xl transition-all duration-300"
   >
     <div className="relative aspect-[4/3] overflow-hidden">
       <img
         src={`data:image/jpeg;base64,${space.photo_base64}`}
         alt={space.titre}
         className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
       />
       <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
     </div>
     
     <div className="p-6">
       {/* <div className="flex items-center gap-2 mb-3">
         <div className="flex items-center px-3 py-1 bg-gray-50 rounded-full text-sm font-medium text-gray-700">
           <Star className="w-4 h-4 text-yellow-400 mr-1" />
           4.9
         </div>
         <span className="text-sm text-gray-500">24 avis</span>
       </div> */}
       
       <h2 className="text-lg font-medium text-gray-900 mb-2 line-clamp-1 group-hover:text-blue-600 transition-colors">
         {space.titre}
       </h2>
       <p className="text-sm text-gray-500 mb-4 line-clamp-2">
         {space.description}
       </p>
       
       <div className="flex items-center justify-between">
         <div className="text-sm">
           <span className="font-semibold text-gray-900">{space.prix}€</span>
           <span className="text-gray-500">/{getTypeLabel(space.type)}</span>
         </div>
         <ArrowUpRight className="w-5 h-5 text-blue-600 opacity-0 group-hover:opacity-100 transition-all transform group-hover:translate-x-1" />
       </div>
     </div>
   </motion.div>
 );
};

// Composant principal
function CitySpaces() {
 const { city } = useParams();
 const [spaces, setSpaces] = useState([]);
 const [isLoading, setIsLoading] = useState(true);
 const [type, setType] = useState(0);
 const [isFilterOpen, setIsFilterOpen] = useState(false);
 const apiUrl = process.env.REACT_APP_API_GATEWAY;

 useEffect(() => {
   const fetchSpaces = async () => {
     if (!city) {
       setSpaces([]);
       setIsLoading(false);
       return;
     }

     try {
       const response = await fetch(`${apiUrl}/api/workspace/lists`, {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         credentials: 'include',
         body: JSON.stringify({ 
           search: city,
           type_tarif: 'day',
         })
       });
       
       const data = await response.json();
       setSpaces(data);
     } catch (error) {
       console.error('Erreur:', error);
       setSpaces([]);
     } finally {
       setIsLoading(false);
     }
   };

   fetchSpaces();
 }, [city, apiUrl]);

 const capitalizedCity = city.charAt(0).toUpperCase() + city.slice(1);

 if (isLoading) {
   return (
     <div className="min-h-screen flex items-center justify-center">
       <div className="w-10 h-10 border-3 border-blue-600/10 border-t-blue-600 rounded-full animate-spin" />
     </div>
   );
 }

 return (
   <div className="min-h-screen bg-gray-50">
     {/* Hero Section */}
     <div className="relative bg-gradient-to-b from-blue-50 to-white py-12 sm:py-16">
       <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8">
         <div className="flex flex-col gap-3">
           <div className="flex items-center gap-2 text-gray-500 text-sm">
             <MapPin className="w-4 h-4" />
             <span>HomeOfficeShare</span>
             <span>•</span>
             <span>{capitalizedCity}</span>
           </div>
           <h1 className="text-3xl sm:text-4xl md:text-5xl font-light text-gray-900">
             Espaces inspirants à{" "}
             <span className="font-medium bg-gradient-to-r from-blue-600 to-violet-600 bg-clip-text text-transparent">
               {capitalizedCity}
             </span>
           </h1>
         </div>
       </div>
     </div>

     {/* Main Content */}
     <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
       {/* Mobile Filter Button */}
       <div className="lg:hidden sticky top-4 z-30 mb-6">
         <button
           onClick={() => setIsFilterOpen(true)}
           className="w-full flex items-center justify-center gap-2 p-3 bg-white/80 backdrop-blur-sm 
                    border rounded-xl text-gray-700 hover:bg-white transition-all shadow-sm"
         >
           <Menu className="w-5 h-5" />
           <span>Filtres</span>
         </button>
       </div>

       <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
         {/* Desktop Filters */}
         <div className="hidden lg:block lg:col-span-3">
           <div className="sticky top-8">
             <Filter
               props={{
                 type,
                 setType,
                 city,
                 setValue: setSpaces,
                 disableFutureDates: true,
                 onApply: () => setIsFilterOpen(false), // Ajout de cette prop
               }}
             />
           </div>
         </div>

         {/* Mobile Filter Drawer */}
         <MobileFilterDrawer
           isOpen={isFilterOpen}
           onClose={() => setIsFilterOpen(false)}
         >
           <Filter
             props={{
               type,
               setType,
               city,
               setValue: setSpaces,
               disableFutureDates: true,
             }}
           />
         </MobileFilterDrawer>

         {/* Results */}
         <div className="lg:col-span-9">
           {spaces.length > 0 ? (
             <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
               {spaces.map((space) => (
                 <Link key={space.espace_id} to={`/espace/${space.espace_id}`}>
                   <SpaceCard space={space} />
                 </Link>
               ))}
             </div>
           ) : (
             <div className="flex flex-col items-center justify-center min-h-[400px] bg-white rounded-2xl p-8">
               <Search className="w-12 h-12 text-gray-300 mb-4" />
               <h3 className="text-xl font-medium text-gray-900 mb-2">
                 Aucun espace disponible
               </h3>
               <p className="text-gray-500 mb-6 text-center">
                 Nous n'avons pas encore d'espaces disponibles dans cette ville.
                 Explorez d'autres villes ou modifiez vos critères de recherche.
               </p>
               <Link
                 to="/"
                 className="inline-flex items-center justify-center px-6 py-3 bg-gradient-to-r 
                          from-blue-600 to-blue-700 text-white text-sm font-medium rounded-xl 
                          shadow-lg shadow-blue-500/25 hover:shadow-blue-500/40 hover:from-blue-700 
                          hover:to-blue-800 transition-all duration-300"
               >
                 Retour à l'accueil
               </Link>
             </div>
           )}
         </div>
       </div>
     </div>
   </div>
 );
}

export default CitySpaces;