import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  HomeIcon, 
  Wallet, 
  Users2, 
  ArrowRight,
  Sparkles
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';

const GlassCard = ({ children, className = "" }) => (
  <div className={`backdrop-blur-md bg-white/80 rounded-2xl border border-blue-100/60 shadow-xl ${className}`}>
    {children}
  </div>
);

const BenefitCard = ({ icon: Icon, title, description, index }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <GlassCard>
      <div
        className="relative p-8 h-full cursor-pointer group"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Background Gradient Animation */}
        <div className={`absolute inset-0 bg-gradient-to-br from-blue-50 to-blue-100/50 
                        rounded-2xl transition-opacity duration-500 
                        ${isHovered ? 'opacity-100' : 'opacity-0'}`} />
        
        {/* Content */}
        <div className="relative flex flex-col h-full">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <div className="relative">
                <div className="absolute inset-0 bg-blue-500/20 rounded-full blur-md transform group-hover:scale-110 transition-transform duration-300" />
                <div className="relative p-3 bg-gradient-to-br from-white/80 to-white/40 rounded-full">
                  <Icon className="w-6 h-6 text-blue-600" />
                </div>
              </div>
              <span className="text-sm font-medium text-blue-500/60">0{index + 1}</span>
            </div>
            <Sparkles className={`w-5 h-5 text-blue-500 transition-opacity duration-300 
                                ${isHovered ? 'opacity-100' : 'opacity-0'}`} />
          </div>
          
          <h3 className="text-xl font-semibold mb-3 text-gray-800 group-hover:text-blue-600 transition-colors duration-300">
            {title}
          </h3>
          <p className="text-gray-600 leading-relaxed">
            {description}
          </p>
          
          <div className={`mt-6 text-blue-600 font-medium flex items-center space-x-2 transition-opacity duration-300
                          ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
            <span>En savoir plus</span>
            <ArrowRight className="w-4 h-4" />
          </div>
        </div>
      </div>
    </GlassCard>
  );
};

const Intro = () => {
  const navigate = useNavigate();
  const { userName, loading } = useAuth();
  const [mounted, setMounted] = useState(false);
  const formData = JSON.parse(localStorage.getItem('announcementData'));

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleNext = () => {
    const steps = formData?.steps ?? 'welcome';
    navigate('/become-host/' + steps);
  };

  const benefits = [
    {
      icon: HomeIcon,
      title: "Flexibilité absolue",
      description: "Gérez votre espace selon vos conditions et votre rythme. Restez maître de votre environnement tout en maximisant son potentiel."
    },
    {
      icon: Wallet,
      title: "Revenus optimisés",
      description: "Développez une source de revenus fiable et régulière. Notre système de tarification intelligent vous aide à maximiser vos gains."
    },
    {
      icon: Users2,
      title: "Réseau d'excellence",
      description: "Rejoignez une communauté de professionnels triés sur le volet. Créez des connexions significatives et enrichissantes."
    }
  ];

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
        <div className="relative">
          <div className="w-12 h-12 rounded-full border-2 border-blue-500 border-t-transparent animate-spin" />
          <div className="absolute inset-0 w-12 h-12 rounded-full border-2 border-blue-200" />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50 px-4 py-12 md:py-20 relative overflow-hidden">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-1/4 -left-1/4 w-1/2 h-1/2 bg-blue-100/50 rounded-full blur-3xl animate-float" />
        <div className="absolute -bottom-1/4 -right-1/4 w-1/2 h-1/2 bg-blue-100/50 rounded-full blur-3xl animate-float-delayed" />
      </div>

      <div className="max-w-7xl mx-auto relative">
        {/* Welcome Section */}
        <div className={`text-center mb-16 transition-all duration-1000 transform 
                        ${mounted ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
          <div className="inline-block mb-6">
            <GlassCard className="px-6 py-2">
              <div className="flex items-center space-x-2 text-blue-600">
                <Sparkles className="w-4 h-4" />
                <span className="text-sm font-medium">Espace Hôte</span>
              </div>
            </GlassCard>
          </div>
          
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6 leading-tight">
            Ravi de vous revoir,{' '}
            <span className="text-blue-600">{userName}</span> !
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Prêt à transformer votre espace en un lieu d'inspiration pour les télétravailleurs ?
          </p>
        </div>

        {/* Benefits Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8 mb-16">
          {benefits.map((benefit, index) => (
            <div key={index} 
                 className={`transition-all duration-1000 delay-${index * 200} transform
                            ${mounted ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
              <BenefitCard {...benefit} index={index} />
            </div>
          ))}
        </div>

        {/* Action Button */}
        <div className={`flex justify-center transition-all duration-1000 delay-700 transform
                        ${mounted ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
          <button
            onClick={handleNext}
            className="relative group px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-500 
                     text-white rounded-full text-lg font-medium
                     hover:shadow-xl hover:shadow-blue-500/20 
                     transform transition-all duration-300 
                     hover:-translate-y-1 active:translate-y-0
                     focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:ring-offset-2"
          >
            <span className="flex items-center space-x-3">
              <span>Créer une annonce</span>
              <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform duration-300" />
            </span>
          </button>
        </div>
      </div>

      {/* Add keyframes for floating animation to your global CSS */}
      <style>{`
        @keyframes float {
          0% { transform: translate(0, 0) scale(1); }
          50% { transform: translate(2%, 2%) scale(1.05); }
          100% { transform: translate(0, 0) scale(1); }
        }
        
        @keyframes float-delayed {
          0% { transform: translate(0, 0) scale(1.05); }
          50% { transform: translate(-2%, -2%) scale(1); }
          100% { transform: translate(0, 0) scale(1.05); }
        }
        
        .animate-float {
          animation: float 20s ease-in-out infinite;
        }
        
        .animate-float-delayed {
          animation: float-delayed 20s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
}

export default Intro;