import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import {
  Calendar,
  MapPin,
  Clock,
  Users,
  Euro,
  Tag,
  ChevronLeft,
  Wifi,
  Coffee,
  Monitor,
  CircleParking,
  Phone,
  Mail,
  MessageSquare,
  Share2,
  Trash2,
  FileText,
  AlertCircle,
  CheckCircle2,
  Timer,
  Building2,
  Edit2Icon
} from 'lucide-react';

import { useAuth } from '../context/AuthContext';

// Composant pour les badges de statut
const StatusBadge = ({ status }) => {
  const getStatusConfig = (status) => {
    switch(status.toLowerCase()) {
      case 'confirmé':
        return {
          icon: CheckCircle2,
          colors: 'bg-gradient-to-r from-emerald-500 to-emerald-600 text-white',
          text: 'Confirmé'
        };
      case 'en attente':
        return {
          icon: Timer,
          colors: 'bg-gradient-to-r from-amber-500 to-amber-600 text-white',
          text: 'En attente'
        };
      case 'annulé':
        return {
          icon: AlertCircle,
          colors: 'bg-gradient-to-r from-rose-500 to-rose-600 text-white',
          text: 'Annulé'
        };
      default:
        return {
          icon: Building2,
          colors: 'bg-gradient-to-r from-gray-500 to-gray-600 text-white',
          text: status
        };
    }
  };

  const config = getStatusConfig(status);
  const Icon = config.icon;

  return (
    <div className={`inline-flex items-center gap-2 px-4 py-2 rounded-2xl font-medium ${config.colors} shadow-lg shadow-${status.toLowerCase() === 'confirmé' ? 'emerald' : status.toLowerCase() === 'en attente' ? 'amber' : 'rose'}-500/25`}>
      <Icon className="w-4 h-4" />
      <span>{config.text}</span>
    </div>
  );
};

// Composant pour les cartes d'information
const InfoCard = ({ title, children, className = "" }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className={`bg-white rounded-3xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300 ${className}`}
  >
    <h2 className="text-xl font-bold text-gray-900 mb-6 flex items-center gap-2">
      {title}
    </h2>
    {children}
  </motion.div>
);

const BookingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_GATEWAY;
  const { userId } = useAuth(); // Assuming useAuth provides userId
  const [isProcessing, setIsProcessing] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/reservation/guest/details/${id}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();

        if (data.success) {
          setBooking(data.data);
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError('Erreur lors de la récupération des détails de la réservation');
      } finally {
        setLoading(false);
      }
    };

    fetchBookingDetails();
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd MMMM yyyy', { locale: fr });
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR'
    }).format(price);
  };

  const showStyledAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
  };


  const handleValidation = async (status) => {
    setIsProcessing(true);
    try {
      const response = await fetch(`${apiUrl}/api/reservation/guest/details/${id}`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status })
      });
  
      const data = await response.json();
  
      if (data.success) {
        // Met à jour localement le statut
        setBooking(prev => ({ ...prev, statut: status === 1 ? 'valide' : 'refuse' }));
        // window.location.reload();

      } else {
        console.error('Erreur:', data.message);
      }
    } catch (error) {
      console.error('Erreur lors de la validation :', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCancelBooking = async () => {
    setIsProcessing(true);
    try {
      const response = await fetch(`${apiUrl}/api/reservation/guest/cancel/${id}`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: 0 })
      });
  
      const data = await response.json();
  
      if (data.success) {
        // Met à jour localement le statut
        setBooking(prev => ({ ...prev, statut: 'annulé' }));
        // window.location.reload();
      } else {
        console.error('Erreur:', data.message);
      }
    } catch (error) {
      console.error('Erreur lors de l\'annulation :', error);
    } finally {
      setIsProcessing(false);
    }
  };
  

  const getStatusColor = (status) => {
    switch(status.toLowerCase()) {
      case 'confirmé':
        return 'bg-emerald-50 text-emerald-700 ring-1 ring-emerald-600/10';
      case 'en attente':
        return 'bg-amber-50 text-amber-700 ring-1 ring-amber-600/10';
      case 'annulé':
        return 'bg-rose-50 text-rose-700 ring-1 ring-rose-600/10';
      default:
        return 'bg-gray-50 text-gray-700 ring-1 ring-gray-600/10';
    }
  };

  const handleContactHost = async () => {
    try {
      console.log(userId, booking.hote_id, booking.hote_nom);

      const response = await fetch(`${apiUrl}/api/message/conversations`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: userId,
          senderId: booking.hote_id,
          name: booking.hote_nom
        })
      });

      const data = await response.json();
      if (data.success) {
        console.log('Conversation saved successfully');

        // Redirect to messages page
        navigate('/messages');
      } else {
        console.error('Error saving conversation:', data.error);
      }
    } catch (error) {
      console.error('Error saving conversation:', error);
    }
  };

  const handleContactGuest = async () => {
    try {

      const response = await fetch(`${apiUrl}/api/message/conversations`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: userId,
          senderId: booking.utilisateur_id,
          name: booking.guest_nom
        })
      });

      const data = await response.json();
      if (data.success) {
        console.log('Conversation saved successfully');

        // Redirect to messages page
        navigate('/messages');
      } else {
        console.error('Error saving conversation:', data.error);
      }
    } catch (error) {
      console.error('Error saving conversation:', error);
    }
  };

  const handleEditBooking = () => {
    // On ne peut modifier que les réservations confirmées
    if (booking.statut !== 'valide') {
        alert('Seules les réservations confirmées peuvent être modifiées');
        return;
    }

    // Vérifier si la date de début n'est pas dépassée
    const today = new Date();
    const startDate = new Date(booking.date_debut);
    
    if (startDate < today) {
        showStyledAlert('Impossible de modifier une réservation dont la date est déjà passée');
        return;
    }

    // Redirection vers la page de modification avec les paramètres nécessaires
    navigate(`/booking/edit/${booking.reservation_id}`, {
        state: {
            bookingDetails: {
                reservationId: booking.reservation_id,
                spaceId: booking.espace_id,
                startDate: booking.date_debut,
                endDate: booking.date_fin,
                numberOfGuests: booking.nombre_guests,
                totalPrice: booking.prix_total,
                basePrice: booking.prix_base,
                serviceFee: booking.frais_service,
                taxFee: booking.frais_taxe,
                spaceTitle: booking.espace_titre,
                spaceImage: booking.photo_couverture,
                spaceAddress: booking.espace_adresse,
                maxGuests: booking.capacite_max
            }
        }
    });
};
  
  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <div className="w-16 h-16 relative">
            <div className="absolute inset-0 rounded-full border-4 border-blue-100"></div>
            <div className="absolute inset-0 rounded-full border-4 border-blue-600 border-t-transparent animate-spin"></div>
          </div>
          <p className="text-gray-600 font-medium">Chargement des détails...</p>
        </div>
      </div>
    );
  }

  if (error || !booking) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-md w-full bg-white rounded-3xl p-8 shadow-xl"
        >
          <div className="inline-flex items-center justify-center w-20 h-20 rounded-full bg-gradient-to-br from-red-500 to-red-600 mb-6">
            <AlertCircle className="w-10 h-10 text-white" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-3">
            Erreur
          </h2>
          <p className="text-gray-600 mb-8">
            {error || "Impossible de trouver cette réservation"}
          </p>
          <Link
            to="/bookings"
            className="inline-flex items-center justify-center w-full px-6 py-3 rounded-2xl bg-gradient-to-r from-blue-500 to-blue-600 text-white font-semibold hover:from-blue-600 hover:to-blue-700 transition-all duration-200 shadow-lg hover:shadow-xl shadow-blue-500/25"
          >
            Retour aux réservations
          </Link>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 pt-10 pb-12">
      {booking.is_host ? (
        booking.statut !== 'attente' ? (
          <div className="max-w-7xl mx-auto px-4">
            {/* En-tête */}
            <motion.div 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex items-center justify-between mb-8"
            >
              <button
                onClick={() => navigate(-1)}
                className="flex items-center gap-2 px-4 py-2 rounded-xl text-gray-600 hover:text-gray-900 hover:bg-white transition-all"
              >
                <ChevronLeft className="w-5 h-5" />
                <span>Retour</span>
              </button>
            </motion.div>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Colonne principale */}
              <div className="lg:col-span-2 space-y-8">
                {/* Image et informations principales */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="bg-white rounded-3xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <div className="relative aspect-video">
                    <img
                      src={`data:image/jpeg;base64,${booking.photo_couverture}`}
                      alt={booking.espace_titre}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute top-4 right-4">
                      <StatusBadge status={booking.statut === 'valide' ? 'Confirmé' : booking.statut === 'refuse' ? 'Refusé' : booking.statut === 'annule' ? 'Annulé' : 'En attente'} />
                    </div>
                  </div>
                  
                  <div className="p-8">
                    <div className="flex justify-between items-start gap-4 mb-8">
                      <h1 className="text-3xl font-bold text-gray-900">{booking.espace_titre}</h1>
                      <div className="flex items-center gap-1 text-2xl font-bold bg-gradient-to-r from-blue-600 to-blue-700 text-white px-4 py-2 rounded-xl shadow-lg shadow-blue-500/25">
                        <Euro className="w-6 h-6" />
                        {formatPrice(booking.prix_total)}
                      </div>
                    </div>

                    <div className="grid gap-6">
                      <div className="flex items-start gap-4 p-4 rounded-2xl bg-gray-50 hover:bg-gray-100 transition-colors">
                        <MapPin className="w-6 h-6 flex-shrink-0 mt-1 text-blue-600" />
                        <span className="text-gray-600 font-medium">{booking.espace_adresse}</span>
                      </div>
                      
                      <div className="flex items-start gap-4 p-4 rounded-2xl bg-gray-50 hover:bg-gray-100 transition-colors">
                        <Calendar className="w-6 h-6 flex-shrink-0 mt-1 text-blue-600" />
                        <div className="text-gray-600">
                          <div className="font-medium">Du {formatDate(booking.date_debut)}</div>
                          <div className="font-medium">au {formatDate(booking.date_fin)}</div>
                        </div>
                      </div>

                      <div className="flex items-center gap-4 p-4 rounded-2xl bg-gray-50 hover:bg-gray-100 transition-colors">
                        <Users className="w-6 h-6 flex-shrink-0 text-blue-600" />
                        <span className="text-gray-600 font-medium">{booking.nombre_guests} personnes</span>
                      </div>

                      <div className="flex items-center gap-4 p-4 rounded-2xl bg-gray-50 hover:bg-gray-100 transition-colors">
                        <Tag className="w-6 h-6 flex-shrink-0 text-blue-600" />
                        <span className="text-gray-600 font-medium">{booking.type_espace}</span>
                      </div>

                      <div className="flex items-center gap-4 p-4 rounded-2xl bg-gray-50 hover:bg-gray-100 transition-colors">
                        <Clock className="w-6 h-6 flex-shrink-0 text-blue-600" />
                        <span className="text-gray-600 font-medium">Réservé le {formatDate(booking.cree_a)}</span>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>

              {/* Colonne latérale */}
              <div className="space-y-8">
                {/* Carte de contact */}
                <InfoCard title="Contact du client">
                  <div className="space-y-6">
                    <div className="flex items-center gap-4">
                      <div className="w-14 h-14 rounded-2xl bg-gradient-to-br from-blue-500 to-blue-600 flex items-center justify-center shadow-lg shadow-blue-500/25">
                        <Users className="w-7 h-7 text-white" />
                      </div>
                      <div>
                        <p className="font-semibold text-gray-900 text-lg">{booking.guest_nom}</p>
                        <p className="text-gray-600">Client vérifié</p>
                      </div>
                    </div>
                    
                    <div className="flex flex-col gap-4 pt-6 border-t border-gray-100">
                      <button className="flex items-center gap-3 p-4 rounded-xl bg-gray-50 hover:bg-gray-100 text-gray-600 hover:text-gray-900 transition-colors">
                        <Mail className="w-5 h-5" />
                        <span className="font-medium">{booking.guest_email}</span>
                      </button>
                      <button
                        onClick={handleContactGuest}
                        className="w-full flex items-center justify-center gap-2 px-6 py-3 rounded-xl bg-gradient-to-r from-blue-500 to-blue-600 text-white font-semibold hover:from-blue-600 hover:to-blue-700 transition-all shadow-lg shadow-blue-500/25"
                      >
                        <MessageSquare className="w-5 h-5" />
                        <span>Contacter le client</span>
                      </button>
                    </div>
                  </div>
                </InfoCard>

                {/* Récapitulatif des paiements */}
                <InfoCard title="Récapitulatif des paiements">
                  <div className="space-y-4">
                    <div className="p-4 rounded-xl bg-gray-50 space-y-3">
                      <div className="flex justify-between items-center text-gray-600">
                        <span>Prix de base</span>
                        <span className="font-medium">{formatPrice(booking.prix_base)}</span>
                      </div>
                      <div className="flex justify-between items-center text-gray-600">
                        <span>Frais de service</span>
                        <span className="font-medium">{formatPrice(booking.frais_service)}</span>
                      </div>
                      <div className="flex justify-between items-center text-gray-600">
                        <span>Taxes</span>
                        <span className="font-medium">{formatPrice(booking.frais_taxe)}</span>
                      </div>
                    </div>

                    <div className="mt-4 p-4 rounded-xl bg-gradient-to-r from-gray-50 to-gray-100">
                      <div className="flex justify-between items-center">
                        <span className="font-semibold text-gray-900">Total</span>
                        <span className="text-lg font-bold text-gray-900">
                          {formatPrice(booking.prix_total)}
                        </span>
                      </div>
                    </div>

                    <div className={`mt-4 p-4 rounded-xl ${
                      booking.statut === 'valide' 
                        ? 'bg-gradient-to-r from-emerald-50 to-emerald-100'
                        : 'bg-gradient-to-r from-amber-50 to-amber-100'
                    }`}>
                      <div className="flex items-center gap-3">
                        {booking.statut === 'valide' ? (
                          <CheckCircle2 className="w-5 h-5 text-emerald-600" />
                        ) : (
                          <Timer className="w-5 h-5 text-amber-600" />
                        )}
                          {booking.statut === 'valide' ? (
                            <p className="font-medium text-sm text-emerald-600">
                              Paiement effectué le {formatDate(booking.cree_a)}
                            </p>
                          ) : booking.statut === 'en attente' ? (
                            <p className="font-medium text-sm text-amber-600">
                              En attente de paiement
                            </p>
                          ) : booking.statut === 'annule' ? (
                            <p className="font-medium text-sm text-rose-600">
                              Réservation annulée le {formatDate(booking.modifie_a || booking.cree_a)}
                            </p>
                          ) : (
                            <p className="font-medium text-sm text-gray-600">
                              Statut inconnu
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </InfoCard>
              </div>
            </div>
          </div>
        ) : (
          // Affichage pour demande de confirmation par l'hôte avec boutons Valider et Refuser
          <div className="max-w-2xl mx-auto">
            <div className="bg-white rounded-3xl overflow-hidden shadow-lg p-8">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-6 rounded-full bg-blue-50">
                <Calendar className="w-8 h-8 text-blue-600" />
              </div>
              
              <h1 className="text-2xl font-bold text-center text-gray-900 mb-2">
                Demande de réservation
              </h1>
              
              <p className="text-gray-600 text-center mb-8">
                Une personne souhaite réserver votre espace. Veuillez examiner les détails ci-dessous.
              </p>

              <div className="space-y-6 mb-8">
                <div className="p-4 rounded-2xl bg-gray-50">
                  <div className="flex items-center gap-3 mb-4">
                    <Users className="w-5 h-5 text-blue-600" />
                    <h3 className="font-medium text-gray-900">Client</h3>
                  </div>
                  <p className="text-gray-600">{booking.guest_nom}</p>
                  <p className="text-gray-600">{booking.guest_email}</p>
                </div>

                <div className="p-4 rounded-2xl bg-gray-50">
                  <div className="flex items-center gap-3 mb-4">
                    <Calendar className="w-5 h-5 text-blue-600" />
                    <h3 className="font-medium text-gray-900">Dates</h3>
                  </div>
                  <p className="text-gray-600">Du {formatDate(booking.date_debut)}</p>
                  <p className="text-gray-600">au {formatDate(booking.date_fin)}</p>
                </div>

                <div className="p-4 rounded-2xl bg-gray-50">
                  <div className="flex items-center gap-3 mb-4">
                    <Euro className="w-5 h-5 text-blue-600" />
                    <h3 className="font-medium text-gray-900">Prix</h3>
                  </div>
                  <p className="text-gray-600">Prix total : {formatPrice(booking.prix_total)}</p>
                  <p className="text-gray-600 text-sm">Inclut les frais de service et taxes</p>
                </div>
              </div>

              <div className="flex gap-4">
                <button
                  onClick={() => handleValidation(0)}
                  disabled={isProcessing}
                  className="flex-1 px-6 py-3 rounded-xl border border-red-200 text-red-600 font-medium hover:bg-red-50 transition-colors disabled:opacity-50"
                >
                  Refuser
                </button>
                <button
                  onClick={ () => handleValidation(1) }
                  disabled={isProcessing}
                  className="flex-1 px-6 py-3 rounded-xl bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors disabled:opacity-50"
                >
                  {isProcessing ? (
                    <span className="flex items-center justify-center gap-2">
                      <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                      Traitement...
                    </span>
                  ) : (
                    'Accepter'
                  )}
                </button>
              </div>
            </div>
          </div>
        )
      ) : (
        booking.statut !== 'attente' ? (
          <div className="max-w-7xl mx-auto px-4">
            {/* En-tête */}
            <motion.div 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex items-center justify-between mb-8"
            >
              <button
                onClick={() => navigate(-1)}
                className="flex items-center gap-2 px-4 py-2 rounded-xl text-gray-600 hover:text-gray-900 hover:bg-white transition-all"
              >
                <ChevronLeft className="w-5 h-5" />
                <span>Retour</span>
              </button>
            </motion.div>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Colonne principale */}
              <div className="lg:col-span-2 space-y-8">
                {/* Image et informations principales */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="bg-white rounded-3xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <div className="relative aspect-video">
                    <img
                      src={`data:image/jpeg;base64,${booking.photo_couverture}`}
                      alt={booking.espace_titre}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute top-4 right-4">
                    <StatusBadge status={booking.statut === 'valide' ? 'Confirmé' : booking.statut === 'refuse' ? 'Refusé' : booking.statut === 'annule' ? 'Annulé' : 'En attente'} />
                    </div>
                  </div>
                  
                  <div className="p-8">
                    <div className="flex justify-between items-start gap-4 mb-8">
                      <h1 className="text-3xl font-bold text-gray-900">{booking.espace_titre}</h1>
                      <div className="flex items-center gap-1 text-2xl font-bold bg-gradient-to-r from-blue-600 to-blue-700 text-white px-4 py-2 rounded-xl shadow-lg shadow-blue-500/25">
                        <Euro className="w-6 h-6" />
                        {formatPrice(booking.prix_total)}
                      </div>
                    </div>

                    <div className="grid gap-6">
                      <div className="flex items-start gap-4 p-4 rounded-2xl bg-gray-50 hover:bg-gray-100 transition-colors">
                        <MapPin className="w-6 h-6 flex-shrink-0 mt-1 text-blue-600" />
                        <span className="text-gray-600 font-medium">{booking.espace_adresse}</span>
                      </div>
                      
                      <div className="flex items-start gap-4 p-4 rounded-2xl bg-gray-50 hover:bg-gray-100 transition-colors">
                        <Calendar className="w-6 h-6 flex-shrink-0 mt-1 text-blue-600" />
                        <div className="text-gray-600">
                          <div className="font-medium">Du {formatDate(booking.date_debut)}</div>
                          <div className="font-medium">au {formatDate(booking.date_fin)}</div>
                        </div>
                      </div>

                      <div className="flex items-center gap-4 p-4 rounded-2xl bg-gray-50 hover:bg-gray-100 transition-colors">
                        <Users className="w-6 h-6 flex-shrink-0 text-blue-600" />
                        <span className="text-gray-600 font-medium">{booking.nombre_guests} personnes</span>
                      </div>

                      <div className="flex items-center gap-4 p-4 rounded-2xl bg-gray-50 hover:bg-gray-100 transition-colors">
                        <Tag className="w-6 h-6 flex-shrink-0 text-blue-600" />
                        <span className="text-gray-600 font-medium">{booking.type_espace}</span>
                      </div>

                      <div className="flex items-center gap-4 p-4 rounded-2xl bg-gray-50 hover:bg-gray-100 transition-colors">
                        <Clock className="w-6 h-6 flex-shrink-0 text-blue-600" />
                        <span className="text-gray-600 font-medium">Réservé le {formatDate(booking.cree_a)}</span>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>

              {/* Colonne latérale */}
              <div className="space-y-8">
                {/* Carte de contact */}
                <InfoCard title="Contact de l'hôte">
                  <div className="space-y-6">
                    <div className="flex items-center gap-4">
                      <div className="w-14 h-14 rounded-2xl bg-gradient-to-br from-blue-500 to-blue-600 flex items-center justify-center shadow-lg shadow-blue-500/25">
                        <Users className="w-7 h-7 text-white" />
                      </div>
                      <div>
                        <p className="font-semibold text-gray-900 text-lg">{booking.hote_nom}</p>
                        <p className="text-gray-600">Hôte vérifié</p>
                      </div>
                    </div>
                    
                    <div className="flex flex-col gap-4 pt-6 border-t border-gray-100">
                      <button className="flex items-center gap-3 p-4 rounded-xl bg-gray-50 hover:bg-gray-100 text-gray-600 hover:text-gray-900 transition-colors">
                        <Mail className="w-5 h-5" />
                        <span className="font-medium">{booking.hote_email}</span>
                      </button>
                      <button
                        onClick={handleContactHost}
                        className="w-full flex items-center justify-center gap-2 px-6 py-3 rounded-xl bg-gradient-to-r from-blue-500 to-blue-600 text-white font-semibold hover:from-blue-600 hover:to-blue-700 transition-all shadow-lg shadow-blue-500/25"
                      >
                        <MessageSquare className="w-5 h-5" />
                        <span>Contacter l'hôte</span>
                      </button>
                    </div>
                  </div>
                </InfoCard>

                {/* Récapitulatif des paiements */}
                <InfoCard title="Récapitulatif des paiements">
                  <div className="space-y-4">
                    <div className="p-4 rounded-xl bg-gray-50 space-y-3">
                      <div className="flex justify-between items-center text-gray-600">
                        <span>Prix de base</span>
                        <span className="font-medium">{formatPrice(booking.prix_base)}</span>
                      </div>
                      <div className="flex justify-between items-center text-gray-600">
                        <span>Frais de service</span>
                        <span className="font-medium">{formatPrice(booking.frais_service)}</span>
                      </div>
                      <div className="flex justify-between items-center text-gray-600">
                        <span>Taxes</span>
                        <span className="font-medium">{formatPrice(booking.frais_taxe)}</span>
                      </div>
                    </div>

                    <div className="mt-4 p-4 rounded-xl bg-gradient-to-r from-gray-50 to-gray-100">
                      <div className="flex justify-between items-center">
                        <span className="font-semibold text-gray-900">Total</span>
                        <span className="text-lg font-bold text-gray-900">
                          {formatPrice(booking.prix_total)}
                        </span>
                      </div>
                    </div>

                    <div className={`mt-4 p-4 rounded-xl ${
                      booking.statut === 'valide' 
                        ? 'bg-gradient-to-r from-emerald-50 to-emerald-100'
                        : booking.statut === 'En attente'
                        ? 'bg-gradient-to-r from-amber-50 to-amber-100'
                        : booking.statut === 'annule'
                        ? 'bg-gradient-to-r from-rose-50 to-rose-100'
                        : 'bg-gradient-to-r from-gray-50 to-gray-100'
                    }`}>
                      <div className="flex items-center gap-3">
                        {booking.statut === 'valide' ? (
                          <>
                            <CheckCircle2 className="w-5 h-5 text-emerald-600" />
                            <p className="font-medium text-sm text-emerald-600">
                              Paiement effectué le {formatDate(booking.cree_a)}
                            </p>
                          </>
                        ) : booking.statut === 'En attente' ? (
                          <>
                            <Timer className="w-5 h-5 text-amber-600" />
                            <p className="font-medium text-sm text-amber-600">
                              En attente de paiement
                            </p>
                          </>
                        ) : booking.statut === 'annule' ? (
                          <>
                            <AlertCircle className="w-5 h-5 text-rose-600" />
                            <p className="font-medium text-sm text-rose-600">
                              Réservation annulée le {formatDate(booking.modifie_a || booking.cree_a)}
                            </p>
                          </>
                        ) : (
                          <>
                            <AlertCircle className="w-5 h-5 text-gray-600" />
                            <p className="font-medium text-sm text-gray-600">
                              Statut inconnu
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                        {booking.statut === 'valide' ?
                          <>
                          <button onClick={handleEditBooking} className="w-full flex items-center justify-center gap-2 px-6 py-3 rounded-xl bg-gradient-to-r from-blue-500 to-blue-600 text-white font-semibold hover:from-blue-600 hover:to-blue-700 transition-all shadow-lg shadow-blue-500/25">
                            <Edit2Icon className="w-5 h-5" />
                            <span>Modifier la réservation</span>
                          </button>
                        </>
                        : 
                        <><span></span></>}
                  </div>
                </InfoCard>
              </div>
            </div>
          </div>
        ) : (
          <div className="max-w-2xl mx-auto">
            <div className="bg-white rounded-3xl overflow-hidden shadow-lg p-8">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-6 rounded-full bg-blue-50">
                <Calendar className="w-8 h-8 text-blue-600" />
              </div>
              
              <h1 className="text-2xl font-bold text-center text-gray-900 mb-2">
                Demande de réservation
              </h1>
              
              <p className="text-gray-600 text-center mb-8">
                Votre demande de réservation est en attente de validation par l'hôte.
              </p>

              <div className="space-y-6 mb-8">
                <div className="p-4 rounded-2xl bg-gray-50">
                  <div className="flex items-center gap-3 mb-4">
                    <Users className="w-5 h-5 text-blue-600" />
                    <h3 className="font-medium text-gray-900">Client</h3>
                  </div>
                  <p className="text-gray-600">{booking.guest_nom}</p>
                  <p className="text-gray-600">{booking.guest_email}</p>
                </div>

                <div className="p-4 rounded-2xl bg-gray-50">
                  <div className="flex items-center gap-3 mb-4">
                    <Calendar className="w-5 h-5 text-blue-600" />
                    <h3 className="font-medium text-gray-900">Dates</h3>
                  </div>
                  <p className="text-gray-600">Du {formatDate(booking.date_debut)}</p>
                  <p className="text-gray-600">au {formatDate(booking.date_fin)}</p>
                </div>

                <div className="p-4 rounded-2xl bg-gray-50">
                  <div className="flex items-center gap-3 mb-4">
                    <Euro className="w-5 h-5 text-blue-600" />
                    <h3 className="font-medium text-gray-900">Prix</h3>
                  </div>
                  <p className="text-gray-600">Prix total : {formatPrice(booking.prix_total)}</p>
                  <p className="text-gray-600 text-sm">Inclut les frais de service et taxes</p>
                </div>
              </div>

              <div className="flex gap-4">
                <button
                  onClick={() => navigate(-1)}
                  className="flex-1 px-6 py-3 rounded-xl bg-gray-50 text-gray-600 font-medium hover:bg-gray-100 transition-all"
                >
                  Retour
                </button>

                <button
                  onClick={() => handleCancelBooking()}
                  className="flex-1 px-6 py-3 rounded-xl bg-red-600 text-white font-medium hover:bg-red-700 transition-all"
                >
                  Annuler la réservation
                </button>
              
              </div>
            </div>
             {/* La modale, positionnée de manière absolue par rapport au conteneur relatif */}
            {booking && isEditModalOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50">
                <div className="w-full max-w-xl bg-white rounded-3xl shadow-xl p-6">
                  {/* Header */}
                  <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-bold text-gray-900">
                      Modifier la réservation
                    </h2>
                    <button
                      onClick={() => setIsEditModalOpen(false)}
                      className="text-gray-400 hover:text-gray-600 transition-colors"
                    >
                      <X className="w-6 h-6" />
                    </button>
                  </div>

                  {/* Contenu temporaire pour tester */}
                  <div className="mb-6">
                    <p className="mb-2">ID: {booking.reservation_id}</p>
                    <p className="mb-2">Du: {booking.date_debut}</p>
                    <p className="mb-2">Au: {booking.date_fin}</p>
                    <p className="mb-2">Nombre de guests: {booking.nombre_guests}</p>
                  </div>

                  {/* Footer */}
                  <div className="flex gap-4 justify-end">
                    <button
                      onClick={() => setIsEditModalOpen(false)}
                      className="px-6 py-3 rounded-xl border border-gray-200 text-gray-600 font-medium hover:bg-gray-100 transition-colors"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={() => {
                        console.log('Tentative de sauvegarde');
                        // handleSaveBooking sera implémenté plus tard
                      }}
                      className="px-6 py-3 rounded-xl bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors"
                    >
                      Sauvegarder
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      )}
        {showAlert && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-2xl p-6 max-w-sm w-full shadow-xl">
              <div className="flex items-center gap-4 mb-4">
                <AlertCircle className="w-6 h-6 text-red-500" />
                <p className="text-gray-700">{alertMessage}</p>
              </div>
              <button
                onClick={() => setShowAlert(false)}
                className="w-full mt-4 px-4 py-2 bg-gray-900 text-white rounded-xl hover:bg-gray-800"
              >
                Fermer
              </button>
            </div>
          </div>
        )}
    </div>
  );
};

export default BookingDetails;