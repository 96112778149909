import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  ArrowRight,
  HelpCircle,
  Zap,
  MessageSquare,
  Check,
  Clock,
  Settings,
  Calendar,
  ShieldCheck
} from 'lucide-react';
import { useForm } from '../../../context/FormHostContext';
import SaveQuitButton from '../../../components/SaveQuitButton';

const OptionCard = ({ icon: Icon, emoji, title, description, benefits, selected, onClick }) => (
  <button
    onClick={onClick}
    className={`relative w-full p-6 rounded-2xl text-left transition-all duration-300 
              ${selected 
                ? 'bg-blue-50 border-blue-200 shadow-lg shadow-blue-500/10' 
                : 'bg-white hover:bg-gray-50 border-gray-200'}
              border-2 group hover:-translate-y-1`}
  >
    {/* Selection Indicator */}
    <div className={`absolute top-4 right-4 w-6 h-6 rounded-full flex items-center justify-center
                   transition-all duration-300
                   ${selected 
                     ? 'bg-blue-500 scale-100' 
                     : 'bg-gray-100 scale-90 group-hover:scale-100'}`}>
      <Check className={`w-4 h-4 ${selected ? 'text-white' : 'text-gray-400'}`} />
    </div>

    {/* Header */}
    <div className="flex items-center gap-4 mb-4">
      <div className={`p-3 rounded-xl transition-colors duration-300
                    ${selected ? 'bg-blue-100' : 'bg-blue-50'}`}>
        <Icon className={`w-6 h-6 ${selected ? 'text-blue-600' : 'text-blue-500'}`} />
      </div>
      <div>
        <span className="text-2xl mb-2">{emoji}</span>
        <h3 className={`text-xl font-semibold transition-colors duration-300
                     ${selected ? 'text-blue-700' : 'text-gray-900'}`}>
          {title}
        </h3>
      </div>
    </div>

    {/* Description */}
    <p className="text-gray-600 mb-6 leading-relaxed">{description}</p>

    {/* Benefits */}
    <div className="space-y-3">
      {benefits.map((benefit, idx) => (
        <div key={idx} className="flex items-center gap-3">
          <div className={`p-1 rounded-full transition-colors duration-300
                        ${selected ? 'bg-blue-100' : 'bg-gray-100'}`}>
            <benefit.icon className={`w-4 h-4 ${selected ? 'text-blue-600' : 'text-gray-500'}`} />
          </div>
          <span className="text-sm text-gray-600">{benefit.text}</span>
        </div>
      ))}
    </div>
  </button>
);

const StepProgress = ({ currentStep, totalSteps }) => (
  <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                  bg-white rounded-full shadow-lg p-2 border border-gray-200">
    <div className="flex items-center gap-2 px-3 py-1">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`rounded-full transition-all duration-300 
                    ${index < currentStep ? 'w-8 h-2 bg-blue-500' : 'w-2 h-2 bg-gray-200'}`}
        />
      ))}
    </div>
  </div>
);

export default function StepThreeReservationMode() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const { handleChange } = useForm();

  const reservationOptions = [
    {
      id: 'AUTO',
      icon: Zap,
      emoji: '⚡',
      title: 'Réservation immédiate',
      description: 'Les professionnels peuvent réserver directement votre espace sans attendre de validation.',
      benefits: [
        { icon: Clock, text: 'Réservation instantanée 24/7' },
        { icon: Calendar, text: 'Calendrier automatiquement mis à jour' },
        { icon: ShieldCheck, text: 'Vérification automatique des utilisateurs' }
      ]
    },
    {
      id: 'MANUAL',
      icon: MessageSquare,
      emoji: '💬',
      title: 'Demande de réservation',
      description: 'Validez manuellement chaque réservation pour garder le contrôle sur vos invités.',
      benefits: [
        { icon: Settings, text: 'Contrôle total sur les réservations' },
        { icon: MessageSquare, text: 'Échangez avec les utilisateurs avant d\'accepter' },
        { icon: ShieldCheck, text: 'Vérifiez chaque profil manuellement' }
      ]
    }
  ];

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    handleChange('modeReservation', option);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
      {/* Header */}
      <header className="fixed top-0 inset-x-0 bg-white/80 backdrop-blur-sm border-b border-gray-200 z-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex items-center justify-between">
          <div className="flex items-center gap-8">
            <button onClick={() => navigate('/become-host/stepthreepublish')}
                    className="text-gray-400 hover:text-gray-600 transition-colors">
              <ArrowLeft className="w-6 h-6" />
            </button>
            <h1 className="text-xl font-semibold text-gray-900">Mode de réservation</h1>
          </div>
          <SaveQuitButton />
        </div>

        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-blue-50 rounded-lg">
              <Settings className="w-5 h-5 text-blue-500" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Préférences de réservation</p>
              <h2 className="font-medium text-gray-900">
                Choisissez comment vos invités peuvent réserver
              </h2>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 pt-40 pb-48">
        <div className="max-w-4xl mx-auto">
          {/* Options Grid */}
          <div className="grid md:grid-cols-2 gap-6">
            {reservationOptions.map(option => (
              <OptionCard
                key={option.id}
                {...option}
                selected={selectedOption === option.id}
                onClick={() => handleOptionSelect(option.id)}
              />
            ))}
          </div>
        </div>
      </main>

      <StepProgress currentStep={12} totalSteps={14} />

      {/* Footer */}
      <footer className="fixed bottom-0 inset-x-0 bg-white/80 backdrop-blur-sm border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center gap-3 text-gray-500">
            <HelpCircle className="w-5 h-5" />
            <span>Besoin d'aide ?</span>
          </div>

          <button
            onClick={() => navigate('/become-host/stepthreepricing')}
            disabled={!selectedOption}
            className={`group px-8 py-3 rounded-xl flex items-center gap-2 
                     transition-all duration-300 
                     ${selectedOption
                       ? 'bg-blue-600 text-white hover:bg-blue-500 hover:shadow-xl hover:shadow-blue-500/20 hover:-translate-y-0.5' 
                       : 'bg-gray-100 text-gray-400 cursor-not-allowed'}`}
          >
            <span>Continuer</span>
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </button>
        </div>
      </footer>
    </div>
  );
}