import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import { toast } from 'react-toastify';

// Ajout du style CSS pour les étoiles
const starStyle = {
  cursor: 'pointer',
  transition: 'color 200ms',
};

export default function Reviews() {
  const { id } = useParams();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_GATEWAY;

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/reservation/guest/details/${id}`,
          { credentials: 'include' }
        );
        if (!response.ok) throw new Error('Erreur lors du chargement');
        const data = await response.json();


        setBooking(data.data);
      } catch (error) {
        toast.error("Impossible de charger les détails de la réservation");
      } finally {
        setLoading(false);
      }
    };

    fetchBookingDetails();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (rating === 0) {
      toast.error("Veuillez attribuer une note");
      return;
    }

    if (comment.length < 10) {
      toast.error("Veuillez écrire un commentaire d'au moins 10 caractères");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/workspace/reviews`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          reservation_id: id,
          rating,
          comment
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erreur lors de l\'envoi');
      }
      
      toast.success("Merci pour votre avis !");
      setTimeout(() => {
        window.location.href = '/bookings';
      }, 2000);
    } catch (error) {
      toast.error(error.message || "Une erreur est survenue lors de l'envoi de votre avis");
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center min-h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
    </div>;
  }

  // Remplace le composant Rating par notre système d'étoiles personnalisé
  const StarRating = () => {
    const [hover, setHover] = useState(null);

    return (
      <div className="flex gap-2">
        {[...Array(5)].map((_, index) => {
          const ratingValue = index + 1;
          return (
            <FaStar
              key={index}
              className="text-2xl"
              style={starStyle}
              color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
              onClick={() => setRating(ratingValue)}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(null)}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">
          Partagez votre expérience
        </h1>
        
        {booking && (
          <div className="mb-6 p-4 bg-gray-50 rounded">
            <h2 className="font-semibold text-lg mb-2">{booking.titre}</h2>
            <p className="text-sm text-gray-600">
              Du {new Date(booking.date_debut).toLocaleDateString()} au {new Date(booking.date_fin).toLocaleDateString()}
            </p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Votre note
            </label>
            <StarRating />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Votre commentaire
            </label>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows={4}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md p-2"
              placeholder="Partagez votre expérience avec cet espace..."
            />
          </div>

          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Envoyer mon avis
          </button>
        </form>
      </div>
    </div>
  );
}
