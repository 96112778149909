import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "../styles/PriceRangeSlider.css";

const PriceRangeSlider = ({ min, max, value, onChange }) => {
  const [minVal, setMinVal] = useState(value.min);
  const [maxVal, setMaxVal] = useState(value.max);

  useEffect(() => {
    setMinVal(value.min);
    setMaxVal(value.max);
  }, [value]);

  const handleMinChange = (e) => {
    const newMin = Math.min(Number(e.target.value), maxVal - 1);
    setMinVal(newMin);
    onChange({ min: newMin, max: maxVal });
  };

  const handleMaxChange = (e) => {
    const newMax = Math.max(Number(e.target.value), minVal + 1);
    setMaxVal(newMax);
    onChange({ min: minVal, max: newMax });
  };

  // Calculer les pourcentages pour le gradient
  const minPos = ((minVal - min) / (max - min)) * 100;
  const maxPos = ((maxVal - min) / (max - min)) * 100;

  return (
    <div className="relative pt-5 pb-8 px-2">
      {/* Prix min/max affichés */}
      <div className="flex justify-between mb-4">
        <span className="text-sm text-gray-600">{minVal}€</span>
        <span className="text-sm text-gray-600">{maxVal}€</span>
      </div>

      {/* Conteneur des sliders */}
      <div className="relative h-1">
        {/* Track en arrière-plan */}
        <div className="absolute w-full h-1 bg-gray-200 rounded"></div>
        
        {/* Track actif */}
        <div 
          className="absolute h-1 bg-black rounded"
          style={{ 
            left: `${minPos}%`, 
            width: `${maxPos - minPos}%` 
          }}
        ></div>

        {/* Sliders */}
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          onChange={handleMinChange}
          className="range-slider absolute w-full pointer-events-none appearance-none bg-transparent"
          style={{ height: '20px', marginTop: '-10px' }}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          onChange={handleMaxChange}
          className="range-slider absolute w-full pointer-events-none appearance-none bg-transparent"
          style={{ height: '20px', marginTop: '-10px' }}
        />
      </div>

      {/* Styles pour les sliders */}
      <style jsx>{`
        .range-slider::-webkit-slider-thumb {
          pointer-events: auto;
          -webkit-appearance: none;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: white;
          border: 2px solid black;
          cursor: pointer;
          margin-top: -10px;
        }

        .range-slider::-moz-range-thumb {
          pointer-events: auto;
          appearance: none;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: white;
          border: 2px solid black;
          cursor: pointer;
        }

        .range-slider:focus {
          outline: none;
        }

        .range-slider::-webkit-slider-runnable-track {
          width: 100%;
          height: 1px;
          background: transparent;
          border: none;
        }

        .range-slider::-moz-range-track {
          width: 100%;
          height: 1px;
          background: transparent;
          border: none;
        }
      `}</style>
    </div>
  );
};

PriceRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PriceRangeSlider;
