import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState(''); // Nouveau state pour le nom de l'utilisateur
  const [userId, setUserId] = useState(''); // Nouveau state pour l'ID de l'utilisateur
  const [userPhoto, setUserPhoto] = useState(''); // Nouveau state pour la photo de profil
  const [userMode, setUserMode] = useState(localStorage.getItem('userMode') || 'guest'); // Nouveau state pour le mode utilisateur
  const [loading, setLoading] = useState(true);
  
  const API_URL = process.env.REACT_APP_API_GATEWAY;

  const refreshAuthStatus = () => {
    fetch(`${API_URL}/api/auth/isLoggedIn`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Cache-Control': 'no-store',
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setIsAuthenticated(data.isAuthenticated);
        if (data.isAuthenticated) {
          setUserName(data.userName); // Stocke le nom de l'utilisateur s'il est authentifié
          setUserId(data.userId); // Stocke l'ID de l'utilisateur s'il est authentifié
          setUserPhoto(data.imageProfile); // Stocke la photo de l'utilisateur (ajoutée via Google Auth)
        }
      })
      .catch((error) => {
        console.error("Auth check error:", error);
        setIsAuthenticated(false);
        setUserName('');
        setUserPhoto(''); // Réinitialise la photo de l'utilisateur en cas d'erreur
        setUserId(''); // Réinitialise l'ID de l'utilisateur en cas d'erreur
      })
      .finally(() => setLoading(false));
  };

  const logout = async () => {
    try {
      const response = await fetch(`${API_URL}/api/auth/logout`, {
        method: 'GET',
        credentials: 'include',
      });
      
      if (response.ok) {
        setIsAuthenticated(false);
        setUserName(''); // Efface le nom de l'utilisateur lors de la déconnexion
        setUserPhoto(''); // Efface la photo de l'utilisateur lors de la déconnexion
        setUserMode('guest'); // Réinitialise le mode utilisateur lors de la déconnexion
        localStorage.removeItem('userMode'); // Efface le mode utilisateur du localStorage
        window.location.href = '/';
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const setMode = (mode) => {
    setUserMode(mode);
    localStorage.setItem('userMode', mode); // Sauvegarde le mode dans localStorage
  };



  useEffect(() => {
    refreshAuthStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      setIsAuthenticated, 
      userName,
      userId, // Ajout de userId
      userPhoto, // Ajout de userPhoto
      userMode, // Ajout de userMode
      setMode, // Ajout de setMode pour changer le mode utilisateur
      loading, 
      refreshAuthStatus,
      logout 
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
