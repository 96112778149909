import React, { useState, useEffect } from 'react';
import { 
  ChevronLeft, 
  ChevronRight, 
  X, 
  Settings, 
  Settings2, 
  Percent,
  Users, 
  Clock, 
  Calendar as CalendarIcon,
  Euro,
  Ban
} from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Switch } from "@/components/ui/switch";
import { Slider } from "@/components/ui/slider";
import  { NumericInput} from "@/components/ui/numeric-input/numeric-input";

// Composant pour les créneaux horaires
const TimeSlotSelector = ({ selectedDates, onSave }) => {
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("18:00");
  const [intervals, setIntervals] = useState(60);

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <h3 className="text-sm font-medium text-gray-700">Horaires d'ouverture</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-xs text-gray-500 mb-1">Heure de début</label>
            <input
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              className="w-full p-2 border rounded-xl focus:ring-2 focus:ring-blue-500/20"
            />
          </div>
          <div>
            <label className="block text-xs text-gray-500 mb-1">Heure de fin</label>
            <input
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              className="w-full p-2 border rounded-xl focus:ring-2 focus:ring-blue-500/20"
            />
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <h3 className="text-sm font-medium text-gray-700">Durée des créneaux</h3>
        <div className="space-y-2">
          <Slider
            defaultValue={[intervals]}
            max={120}
            min={15}
            step={15}
            onValueChange={([value]) => setIntervals(value)}
          />
          <div className="text-xs text-gray-500 text-center">
            {intervals} minutes
          </div>
        </div>
      </div>
      
      <button
        onClick={() => onSave({ startTime, endTime, intervals })}
        className="w-full px-4 py-2 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-colors"
      >
        Appliquer aux jours sélectionnés
      </button>
    </div>
  );
};

// Composant pour les paramètres de capacité
const CapacitySettings = ({ selectedDates, onSave }) => {
  const [capacity, setCapacity] = useState(1);
  const [allowGroupBooking, setAllowGroupBooking] = useState(false);

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <h3 className="text-sm font-medium text-gray-700">Capacité maximale</h3>
        <input
          type="number"
          min="1"
          value={capacity}
          onChange={(e) => setCapacity(parseInt(e.target.value))}
          className="w-full p-2 border rounded-xl focus:ring-2 focus:ring-blue-500/20"
        />
      </div>

      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h3 className="text-sm font-medium text-gray-700">Autoriser les groupes</h3>
          <p className="text-xs text-gray-500">Permettre plusieurs réservations sur le même créneau</p>
        </div>
        <Switch
          checked={allowGroupBooking}
          onCheckedChange={setAllowGroupBooking}
        />
      </div>

      <button
        onClick={() => onSave({ capacity, allowGroupBooking })}
        className="w-full px-4 py-2 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-colors"
      >
        Appliquer
      </button>
    </div>
  );
};

// Composant pour les paramètres d'indisponibilité
const UnavailabilitySettings = ({ selectedDates, onSave }) => {
  const [reason, setReason] = useState("");
  const [isRecurring, setIsRecurring] = useState(false);

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <h3 className="text-sm font-medium text-gray-700">Raison de l'indisponibilité</h3>
        <textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Ex: Maintenance, Événement privé..."
          className="w-full p-2 border rounded-xl h-24 resize-none focus:ring-2 focus:ring-blue-500/20"
        />
      </div>

      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h3 className="text-sm font-medium text-gray-700">Répéter chaque semaine</h3>
          <p className="text-xs text-gray-500">Cette indisponibilité sera répétée</p>
        </div>
        <Switch
          checked={isRecurring}
          onCheckedChange={setIsRecurring}
        />
      </div>

      <button
        onClick={() => onSave({ reason, isRecurring })}
        className="w-full px-4 py-2 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-colors"
      >
        Marquer comme indisponible
      </button>
    </div>
  );
};

// Composant principal
const HostCalendar = () => {
  // États pour la gestion de base
  const [selectedListing, setSelectedListing] = React.useState(null);
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [selectedDates, setSelectedDates] = React.useState([]);
  const [showYearMonthSelector, setShowYearMonthSelector] = React.useState(false);
  const [isMobileView, setIsMobileView] = React.useState(false);

  // États pour les paramètres avancés
  const [customPrices, setCustomPrices] = React.useState({});
  const [priceInputs, setPriceInputs] = React.useState({
    horaire: '',
    journalier: '',
    hebdomadaire: '',
    mensuel: ''
  });
  const [reductions, setReductions] = React.useState({
    horaire: 0,
    journalier: 0,
    hebdomadaire: 10,
    mensuel: 20
  });
  const [disabledDates, setDisabledDates] = React.useState({});
  const [timeSlots, setTimeSlots] = React.useState({});
  const [capacitySettings, setCapacitySettings] = React.useState({});
  const [unavailabilitySettings, setUnavailabilitySettings] = React.useState({});

  const [defaultPrices] = React.useState({
    horaire: 20,
    journalier: 90,
    hebdomadaire: 500,
    mensuel: 1800
  });

  const weekDays = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  const months = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];

  const priceTypes = [
    { id: 'horaire', label: 'Heure', symbol: 'h' },
    { id: 'journalier', label: 'Jour', symbol: 'j' },
    { id: 'hebdomadaire', label: 'Semaine', symbol: 'sem' },
    { id: 'mensuel', label: 'Mois', symbol: 'mois' }
  ];
  
  const mockListings = [
    {
      id: 1,
      title: "Bureau Premium Vue Jardin",
      address: "123 Rue de la Paix",
      description: "Espace de travail lumineux avec vue sur jardin",
      capacity: 4,
      amenities: ["WiFi", "Climatisation", "Café", "Imprimante"],
      pricePerHour: 25,
      pricePerDay: 150,
      image: "/images/berlin1.webp",
    },
    {
      id: 2,
      title: "Espace Collaboratif Design",
      address: "45 Avenue des Arts",
      description: "Open space moderne idéal pour le coworking",
      capacity: 8,
      amenities: ["WiFi", "Salle de réunion", "Cuisine", "Parking"],
      pricePerHour: 35,
      pricePerDay: 200,
      image: "/images/berlin2.webp",
    },
    {
      id: 3,
      title: "Bureau Cosy Centre-Ville",
      address: "78 Boulevard Central",
      description: "Bureau privé au cœur de la ville",
      capacity: 2,
      amenities: ["WiFi", "Café", "Casier"],
      pricePerHour: 20,
      pricePerDay: 120,
      image: "/images/berlin3.webp",
    }
  ];

  // Effet pour gérer le responsive
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Effet pour réinitialiser les états lors du changement d'annonce
  useEffect(() => {
    if (selectedListing !== null) {
      setSelectedDates([]);
      setCustomPrices({});
      setDisabledDates({});
      setTimeSlots({});
      setCapacitySettings({});
      setUnavailabilitySettings({});
    }
  }, [selectedListing]);

  // Fonctions utilitaires pour le calendrier
  const formatDateKey = (day) => {
    return `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${day}`;
  };

  const handleMultiDaySelection = (day) => {
    if (!day || isPastDay(day) || isDateDisabled(day)) return;
    
    const dateKey = formatDateKey(day);
    if (selectedDates.includes(dateKey)) {
      setSelectedDates(selectedDates.filter(d => d !== dateKey));
    } else {
      setSelectedDates([...selectedDates, dateKey]);
    }
  };

  const toggleDateAvailability = (dateKey) => {
    setDisabledDates(prev => ({
      ...prev,
      [dateKey]: !prev[dateKey]
    }));
  };

  const isPastDay = (day) => {
    if (!day) return false;
    const checkDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return checkDate < today;
  };

  const isDateDisabled = (day) => {
    if (!day) return false;
    const dateKey = formatDateKey(day);
    return disabledDates[dateKey];
  };

  const getDaysInMonth = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    let firstDayIndex = firstDay.getDay() - 1;
    if (firstDayIndex === -1) firstDayIndex = 6;

    const days = Array(firstDayIndex).fill(null);
    for (let day = 1; day <= lastDay.getDate(); day++) {
      days.push(day);
    }
    return days;
  };

  const YearMonthSelector = () => {
    if (!showYearMonthSelector) return null;

    return (
      <div className="absolute top-full mt-1 left-0 bg-white rounded-lg shadow-lg border p-4 z-50">
        <div className="grid grid-cols-3 gap-2">
          {months.map((month, index) => (
            <button
              key={month}
              onClick={() => {
                const newDate = new Date(currentDate);
                newDate.setMonth(index);
                setCurrentDate(newDate);
                setShowYearMonthSelector(false);
              }}
              className={`
                p-2 rounded-lg text-sm
                ${currentDate.getMonth() === index 
                  ? 'bg-blue-100 text-blue-700' 
                  : 'hover:bg-gray-100'}
              `}
            >
              {month.slice(0, 3)}
            </button>
          ))}
        </div>
        <div className="mt-4 flex justify-between items-center">
          <button
            onClick={() => {
              const newDate = new Date(currentDate);
              newDate.setFullYear(newDate.getFullYear() - 1);
              setCurrentDate(newDate);
            }}
            className="text-sm text-gray-600 hover:text-gray-900 px-3"
          >
            {currentDate.getFullYear() - 1}
          </button>
          <span className="font-medium">{currentDate.getFullYear()}</span>
          <button
            onClick={() => {
              const newDate = new Date(currentDate);
              newDate.setFullYear(newDate.getFullYear() + 1);
              setCurrentDate(newDate);
            }}
            className="text-sm text-gray-600 hover:text-gray-900 px-3"
          >
            {currentDate.getFullYear() + 1}
          </button>
        </div>
      </div>
    );
  };

  // Composant pour un jour du calendrier
  const CalendarDay = ({ day, index }) => {
    if (!day) return <div className="aspect-square" />;

    const isPass = isPastDay(day);
    const isSelected = selectedDates.includes(formatDateKey(day));
    const hasCustomPrice = customPrices[formatDateKey(day)];
    const isDisabled = isDateDisabled(day);
    const hasTimeSlots = timeSlots[formatDateKey(day)];

    const [showCustomPrice, setShowCustomPrice] = useState(true);
    useEffect(() => {
      let timer;
      if (hasCustomPrice) {
        timer = setTimeout(() => {
          setShowCustomPrice(false);
        }, 5000); // Masquer les prix personnalisés après 5 secondes
      }
      return () => clearTimeout(timer);
    }, [hasCustomPrice]);

    const handleDateClick = (day) => {
      if (!isPass && !isDisabled) {
        handleMultiDaySelection(day);
      }
    };

    return (
      <div
        onClick={() => handleDateClick(day)}
        className={`
          relative rounded-xl transition-all duration-200 group
          ${!day ? '' : 'border'}
          ${isPass ? 'bg-gray-50/50 cursor-not-allowed' : ''}
          ${isDisabled ? 'bg-red-50/50 border-red-100' : ''}
          ${isSelected ? 'border-blue-500 bg-blue-50/50 shadow-sm' : 'border-gray-100'}
          ${hasCustomPrice && !isSelected && showCustomPrice ? 'border-green-200' : ''}
          ${day && !isPass && !isDisabled ? 'hover:border-blue-200 hover:shadow-md hover:scale-105' : ''}
          p-2 aspect-square cursor-pointer
        `}
      >
        <div className="h-full flex flex-col">
          <div className="flex justify-between items-start">
            <span className={`
              text-sm font-medium rounded-full w-6 h-6 flex items-center justify-center
              ${isSelected ? 'bg-blue-500 text-white' : ''}
              ${isPass || isDisabled ? 'text-gray-400' : 'text-gray-700'}
            `}>
              {day}
            </span>
          </div>
          
          {!isPass && !isDisabled && (
            <div className="mt-auto space-y-0.5">
              {hasTimeSlots && (
                <div className="flex items-center gap-1 text-[10px] text-blue-500">
                  <Clock className="w-3 h-3" />
                  <span>{timeSlots[formatDateKey(day)].intervals}min</span>
                </div>
              )}
              <div className={`text-[10px] ${showCustomPrice && hasCustomPrice ? 'text-green-600' : 'text-gray-500'}`}>
                {formatPrice(getPriceForDate(day, 'journalier'), 'journalier')}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Fonction pour formater les prix
  const formatPrice = (price, type) => {
    if (!price) return '-';
    const priceType = priceTypes.find(pt => pt.id === type);
    return `${price}€/${priceType.symbol}`;
  };

  // Fonction pour obtenir le prix d'une date
  const getPriceForDate = (day, type) => {
    if (!day) return null;
    const dateKey = formatDateKey(day);
    const basePrice = customPrices[dateKey]?.[type] || defaultPrices[type];
    const reduction = reductions[type];
    return reduction > 0 ? basePrice * (1 - reduction / 100) : basePrice;
  };

  // Fonction pour appliquer les prix
  const applyPriceToSelectedDates = () => {
    const updatedPrices = { ...customPrices };
    
    selectedDates.forEach(dateStr => {
      updatedPrices[dateStr] = {
        ...priceInputs,
        reductions: { ...reductions },
        isDateDisabled: disabledDates[dateStr] || false
      };
    });

    setCustomPrices(updatedPrices);
    setSelectedDates([]);
    setPriceInputs({
      horaire: '',
      journalier: '',
      hebdomadaire: '',
      mensuel: ''
    });
  };

  // Composant pour les paramètres (Tabs)
  const SettingsPanel = () => (
    <Card className="w-[400px] flex-shrink-0">
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          <span>Paramètres {selectedDates.length > 0 ? `(${selectedDates.length} jour${selectedDates.length > 1 ? 's' : ''})` : ''}</span>
          {selectedDates.length > 0 && (
            <button
              onClick={() => setSelectedDates([])}
              className="text-sm text-gray-500 hover:text-gray-700"
            >
              Désélectionner
            </button>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="prices" className="w-full">
          <TabsList className="grid grid-cols-4 gap-4 mb-6">
            <TabsTrigger value="prices">
              <Euro className="w-4 h-4" />
            </TabsTrigger>
            <TabsTrigger value="timeSlots">
              <Clock className="w-4 h-4" />
            </TabsTrigger>
            <TabsTrigger value="capacity">
              <Users className="w-4 h-4" />
            </TabsTrigger>
            <TabsTrigger value="unavailability">
              <Ban className="w-4 h-4" />
            </TabsTrigger>
          </TabsList>

          <TabsContent value="prices" className="mt-0">
            <div className="space-y-6">
              {priceTypes.map((type) => (
                <div key={type.id} className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Prix {type.label.toLowerCase()}
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="relative">
                      <NumericInput
                        value={priceInputs[type.id]}
                        onChange={(value) => setPriceInputs(prev => ({
                          ...prev,
                          [type.id]: value
                        }))}
                        placeholder={``}
                        className="w-full"
                        />
                        <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 text-sm">
                          €/{type.symbol}
                        </span>
                      </div>
                      <div className="relative">
                        <NumericInput
                          value={reductions[type.id]}
                          onChange={(value) => setReductions(prev => ({
                            ...prev,
                            [type.id]: value
                          }))}
                          placeholder="0"
                          className="w-full"
                        />
                        <Percent className="w-4 h-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                        <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 text-sm">
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="space-y-4">
                  <h3 className="text-sm font-medium text-gray-700">Disponibilité des jours</h3>
                  {selectedDates.map((dateStr) => (
                    <div key={dateStr} className="flex items-center justify-between">
                      <span>{dateStr}</span>
                      <Switch
                        checked={!disabledDates[dateStr]}
                        onCheckedChange={(value) => toggleDateAvailability(dateStr)}
                      />
                    </div>
                  ))}
                </div>
                <button
                  onClick={applyPriceToSelectedDates}
                  className="w-full px-4 py-2 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-colors"
                >
                  Appliquer
                </button>
              </div>
            </TabsContent>
  
            <TabsContent value="timeSlots">
              <TimeSlotSelector
                selectedDates={selectedDates}
                onSave={(settings) => {
                  selectedDates.forEach(date => {
                    setTimeSlots(prev => ({
                      ...prev,
                      [date]: settings
                    }));
                  });
                }}
              />
            </TabsContent>
  
            <TabsContent value="capacity">
              <CapacitySettings
                selectedDates={selectedDates}
                onSave={(settings) => {
                  selectedDates.forEach(date => {
                    setCapacitySettings(prev => ({
                      ...prev,
                      [date]: settings
                    }));
                  });
                }}
              />
            </TabsContent>
  
            <TabsContent value="unavailability">
              <UnavailabilitySettings
                selectedDates={selectedDates}
                onSave={(settings) => {
                  selectedDates.forEach(date => {
                    setUnavailabilitySettings(prev => ({
                      ...prev,
                      [date]: settings
                    }));
                    setDisabledDates(prev => ({
                      ...prev,
                      [date]: settings.disabledDates[date]
                    }));
                  });
                }}
              />
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    );
  
    // Le rendu principal
    return (
      <div className="w-full max-w-full mx-auto p-6 space-y-8 bg-gray-50 min-h-screen">
        {/* Section de sélection d'espace */}
        <Card className="bg-white shadow-sm hover:shadow-md transition-shadow">
          <CardHeader>
            <CardTitle>Sélectionnez votre espace</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {mockListings.map(listing => (
                <button
                  key={listing.id}
                  onClick={() => {
                    setSelectedListing(listing.id);
                    setSelectedDates([]);
                  }}
                  className={`
                    group p-6 rounded-xl text-left transition-all duration-200
                    ${selectedListing === listing.id 
                      ? 'bg-blue-50 border-2 border-blue-500 shadow-md' 
                      : 'bg-white border-2 border-gray-100 hover:border-blue-200 hover:shadow-md'
                    }
                  `}
                >
                  <div className="aspect-video rounded-lg overflow-hidden mb-4">
                    <img 
                      src={listing.image} 
                      alt={listing.title}
                      className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-200"
                    />
                  </div>
                  <div className="space-y-2">
                    <div className="font-medium text-gray-900 text-lg">{listing.title}</div>
                    <div className="text-sm text-gray-500">{listing.address}</div>
                    <div className="flex items-center gap-2 text-sm text-gray-600">
                      <Users className="w-4 h-4" />
                      <span>Jusqu'à {listing.capacity} personnes</span>
                    </div>
                    <div className="flex items-center gap-4 text-sm text-gray-700">
                      <div className="flex items-center gap-1">
                        <Clock className="w-4 h-4" />
                        <span>{listing.pricePerHour}€/h</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <CalendarIcon className="w-4 h-4" />
                        <span>{listing.pricePerDay}€/j</span>
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </CardContent>
        </Card>
  
        {selectedListing && (
          <div className="flex flex-col lg:flex-row gap-6">
            {/* Calendrier */}
            <Card className="flex-1">
              <CardHeader>
                <div className="flex items-center justify-between">
                  <div className="relative">
                    <button
                      onClick={() => setShowYearMonthSelector(!showYearMonthSelector)}
                      className="text-lg font-medium text-gray-900 hover:text-blue-600 transition-colors 
                               flex items-center gap-2"
                    >
                      <CalendarIcon className="w-5 h-5" />
                      {months[currentDate.getMonth()]} {currentDate.getFullYear()}
                    </button>
                    <YearMonthSelector />
                  </div>
                  <div className="flex gap-1">
                    <button 
                      onClick={() => {
                        const newDate = new Date(currentDate);
                        newDate.setMonth(newDate.getMonth() - 1);
                        setCurrentDate(newDate);
                      }}
                      className="p-2 rounded-lg hover:bg-gray-100 text-gray-600"
                    >
                      <ChevronLeft className="w-5 h-5" />
                    </button>
                    <button 
                      onClick={() => {
                        const newDate = new Date(currentDate);
                        newDate.setMonth(newDate.getMonth() + 1);
                        setCurrentDate(newDate);
                      }}
                      className="p-2 rounded-lg hover:bg-gray-100 text-gray-600"
                    >
                      <ChevronRight className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </CardHeader>
  
              <CardContent>
                <div className="grid grid-cols-7 gap-4 sm:grid-cols-7 md:grid-cols-7 lg:grid-cols-7">
                  {weekDays.map(day => (
                    <div key={day} className="text-center font-medium text-gray-400 text-sm py-2">
                      {day}
                    </div>
                  ))}
                  {getDaysInMonth().map((day, index) => (
                    <CalendarDay key={index} day={day} index={index} />
                  ))}
                </div>
              </CardContent>
            </Card>
  
            {/* Panel des paramètres */}
            <SettingsPanel />
          </div>
        )}
      </div>
    );
  };
  
  export default HostCalendar;