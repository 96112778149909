import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    Mail, Phone, Send, AlertCircle, User, Home,
    Calendar, CreditCard, HelpCircle, MessageCircle,
    Building, Clock, MapPin, DollarSign
} from 'lucide-react';

const ContactSupport = () => {
    const [formData, setFormData] = useState({
        userType: '',
        name: '',
        email: '',
        phone: '',
        category: '',
        subCategory: '',
        reservationId: '',
        spaceId: '',
        message: '',
    });

    const [step, setStep] = useState(1);
    const [sending, setSending] = useState(false);
    const [status, setStatus] = useState({ type: '', message: '' });

    // Catégories dynamiques basées sur le type d'utilisateur
    const categories = {
        guest: [
            { id: 'reservation', label: 'Problème de réservation', icon: Calendar },
            { id: 'payment', label: 'Question de paiement', icon: CreditCard },
            { id: 'space', label: 'Question sur un espace', icon: Building },
            { id: 'account', label: 'Gestion du compte', icon: User },
            {id : 'feedback', label: 'Feedback', icon: MessageCircle},
            { id: 'other', label: 'Autre demande', icon: HelpCircle },
        ],
        host: [
            { id: 'space_management', label: 'Gestion des espaces', icon: Building },
            { id: 'bookings', label: 'Gestion des réservations', icon: Calendar },
            { id: 'payments', label: 'Paiements et revenus', icon: DollarSign },
            { id: 'account', label: 'Gestion du compte', icon: User },
            {id : 'feedback', label: 'Feedback', icon: MessageCircle},
            { id: 'other', label: 'Autre demande', icon: HelpCircle },
        ],
    };

    // Sous-catégories basées sur la catégorie principale
    const subCategories = {
        reservation: [
            'Modification de réservation',
            'Annulation',
            'Accès à l\'espace',
            'Horaires',
        ],
        payment: [
            'Problème de paiement',
            'Remboursement',
            'Facture',
            'Mode de paiement',
        ],
        space_management: [
            'Ajout d\'espace',
            'Modification d\'espace',
            'Disponibilités',
            'Photos et médias',
        ],
        bookings: [
            'Acceptation de réservation',
            'Annulation',
            'Modification d\'horaires',
            'Communication avec le client',
        ],
        feedback: [
            'Proposition de nouvelles fonctionnalités',
            'Bug lors de l\'utilisation de l\'application'
        ]
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        // Reset dépendant fields
        if (name === 'userType') {
            setFormData(prev => ({
                ...prev,
                category: '',
                subCategory: '',
            }));
        }
        if (name === 'category') {
            setFormData(prev => ({
                ...prev,
                subCategory: '',
            }));
        }
    };

    const nextStep = () => {
        setStep(prev => prev + 1);
    };

    const prevStep = () => {
        setStep(prev => prev - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSending(true);
        setStatus({ type: '', message: '' });

        try {
            const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/api/auth/support/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                    type_utilisateur: formData.userType,
                    nom: formData.name,
                    email: formData.email,
                    telephone: formData.phone,
                    categorie: formData.category,
                    sous_categorie: formData.subCategory,
                    reservation_id: formData.reservationId,
                    espace_id: formData.spaceId,
                    message: formData.message
                })
            });

            const data = await response.json();

            if (response.ok) {
                setStatus({
                    type: 'success',
                    message: 'Votre message a été envoyé avec succès. Notre équipe vous répondra dans les plus brefs délais.'
                });
                // Réinitialisation du formulaire
                setFormData({
                    userType: '',
                    name: '',
                    email: '',
                    phone: '',
                    category: '',
                    subCategory: '',
                    reservationId: '',
                    spaceId: '',
                    message: '',
                });
                setStep(1);
            } else {
                throw new Error(data.message || 'Une erreur est survenue lors de l\'envoi du message');
            }
        } catch (error) {
            setStatus({
                type: 'error',
                message: error.message || 'Une erreur est survenue. Veuillez réessayer.'
            });
        } finally {
            setSending(false);
        }
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        className="space-y-6"
                    >
                        <h2 className="text-xl font-semibold text-gray-900">Qui êtes-vous ?</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <button
                                type="button"
                                onClick={() => {
                                    handleChange({ target: { name: 'userType', value: 'guest' } });
                                    nextStep();
                                }}
                                className={`
                  flex items-center gap-4 p-6 rounded-xl border-2 transition-all
                  ${formData.userType === 'guest'
                                        ? 'border-blue-500 bg-blue-50'
                                        : 'border-gray-200 hover:border-blue-200 hover:bg-blue-50/50'}
                `}
                            >
                                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                                    <User className="w-6 h-6 text-blue-600" />
                                </div>
                                <div className="text-left">
                                    <h3 className="font-medium text-gray-900">Invité</h3>
                                    <p className="text-sm text-gray-500">Je souhaite réserver un espace</p>
                                </div>
                            </button>

                            <button
                                type="button"
                                onClick={() => {
                                    handleChange({ target: { name: 'userType', value: 'host' } });
                                    nextStep();
                                }}
                                className={`
                  flex items-center gap-4 p-6 rounded-xl border-2 transition-all
                  ${formData.userType === 'host'
                                        ? 'border-blue-500 bg-blue-50'
                                        : 'border-gray-200 hover:border-blue-200 hover:bg-blue-50/50'}
                `}
                            >
                                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                                    <Home className="w-6 h-6 text-blue-600" />
                                </div>
                                <div className="text-left">
                                    <h3 className="font-medium text-gray-900">Hôte</h3>
                                    <p className="text-sm text-gray-500">Je propose des espaces</p>
                                </div>
                            </button>
                        </div>
                    </motion.div>
                );

            case 2:
                return (
                    <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        className="space-y-6"
                    >
                        <h2 className="text-xl font-semibold text-gray-900">Quel est le sujet de votre demande ?</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {categories[formData.userType].map(({ id, label, icon: Icon }) => (
                                <button
                                    key={id}
                                    type="button"
                                    onClick={() => {
                                        handleChange({ target: { name: 'category', value: id } });
                                        nextStep();
                                    }}
                                    className={`
                    flex items-center gap-4 p-6 rounded-xl border-2 transition-all
                    ${formData.category === id
                                            ? 'border-blue-500 bg-blue-50'
                                            : 'border-gray-200 hover:border-blue-200 hover:bg-blue-50/50'}
                  `}
                                >
                                    <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                                        <Icon className="w-6 h-6 text-blue-600" />
                                    </div>
                                    <div className="text-left">
                                        <h3 className="font-medium text-gray-900">{label}</h3>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </motion.div>
                );

            case 3:
                return (
                    <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        className="space-y-6"
                    >
                        <h2 className="text-xl font-semibold text-gray-900">Vos informations</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Nom complet
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                                    placeholder="John Doe"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                                    placeholder="john@example.com"
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Téléphone (optionnel)
                            </label>
                            <input
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                                placeholder="+33 6 12 34 56 78"
                            />
                        </div>

                        {subCategories[formData.category] && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Précisez votre demande
                                </label>
                                <select
                                    name="subCategory"
                                    value={formData.subCategory}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                                >
                                    <option value="">Sélectionnez une option</option>
                                    {subCategories[formData.category].map(sub => (
                                        <option key={sub} value={sub}>{sub}</option>
                                    ))}
                                </select>
                            </div>
                        )}

                        {['reservation', 'bookings'].includes(formData.category) && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Numéro de réservation (optionnel)
                                </label>
                                <input
                                    type="text"
                                    name="reservationId"
                                    value={formData.reservationId}
                                    onChange={handleChange}
                                    className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                                    placeholder="ex: RES-123456"
                                />
                            </div>
                        )}

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Votre message
                            </label>
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                rows={6}
                                className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all resize-none"
                                placeholder="Décrivez votre problème ou votre question en détail..."
                            />
                        </div>
                    </motion.div>
                );

            default:
                return null;
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
                <div className="bg-white rounded-2xl shadow-xl p-8">
                    {/* En-tête */}
                    <div className="text-center mb-8">
                        <h1 className="text-3xl font-bold text-gray-900">Comment pouvons-nous vous aider ?</h1>
                        <p className="mt-3 text-lg text-gray-600">
                            Notre équipe est là pour répondre à toutes vos questions
                        </p>
                    </div>

                    {/* Indicateur de progression */}
                    <div className="mb-8">
                        <div className="flex justify-between mb-2">
                            {[1, 2, 3].map((s) => (
                                <div
                                    key={s}
                                    className={`w-8 h-8 rounded-full flex items-center justify-center ${s === step
                                            ? 'bg-blue-600 text-white'
                                            : s < step
                                                ? 'bg-blue-100 text-blue-600'
                                                : 'bg-gray-100 text-gray-400'
                                        }`}
                                >
                                    {s}
                                </div>
                            ))}
                        </div>
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="h-1 w-full bg-gray-200 rounded">
                                    <div
                                        className="h-1 bg-blue-600 rounded transition-all duration-300"
                                        style={{ width: `${((step - 1) / 2) * 100}%` }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Formulaire par étapes */}
                    <form onSubmit={handleSubmit} className="mt-8">
                        <AnimatePresence mode="wait">
                            {renderStep()}
                        </AnimatePresence>

                        {/* Message de statut */}
                        {status.message && (
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                className={`mt-6 p-4 rounded-xl ${status.type === 'success'
                                        ? 'bg-green-50 text-green-800'
                                        : 'bg-red-50 text-red-800'
                                    } flex items-start gap-3`}
                            >
                                <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
                                <p>{status.message}</p>
                            </motion.div>
                        )}

                        {/* Navigation entre les étapes */}
                        <div className="flex justify-between mt-8 pt-6 border-t border-gray-200">
                            {step > 1 && (
                                <button
                                    type="button"
                                    onClick={prevStep}
                                    className="inline-flex items-center px-6 py-3 text-gray-700 bg-gray-100 
                             hover:bg-gray-200 rounded-xl transition-colors duration-200"
                                >
                                    Retour
                                </button>
                            )}
                            {step < 3 ? (
                                <button
                                    type="button"
                                    onClick={nextStep}
                                    className="ml-auto inline-flex items-center px-6 py-3 bg-blue-600 
                             text-white rounded-xl hover:bg-blue-700 transition-colors duration-200"
                                >
                                    Continuer
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    disabled={sending}
                                    className="ml-auto inline-flex items-center gap-2 px-6 py-3 bg-blue-600 
                             text-white rounded-xl hover:bg-blue-700 disabled:opacity-50 
                             disabled:cursor-not-allowed transition-all duration-200"
                                >
                                    {sending ? (
                                        <>
                                            <div className="w-5 h-5 border-2 border-white border-t-transparent 
                                      rounded-full animate-spin" />
                                            <span>Envoi en cours...</span>
                                        </>
                                    ) : (
                                        <>
                                            <Send className="w-5 h-5" />
                                            <span>Envoyer</span>
                                        </>
                                    )}
                                </button>
                            )}
                        </div>
                    </form>

                    {/* Informations de contact */}
                    <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="flex items-center gap-4 p-6 rounded-xl bg-gradient-to-br from-blue-50 to-blue-100">
                            <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center shadow-sm">
                                <Mail className="w-6 h-6 text-blue-600" />
                            </div>
                            <div>
                                <h3 className="text-lg font-medium text-gray-900">Email</h3>
                                <p className="text-gray-600">support@hos.com</p>
                            </div>
                        </div>

                        <div className="flex items-center gap-4 p-6 rounded-xl bg-gradient-to-br from-blue-50 to-blue-100">
                            <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center shadow-sm">
                                <Phone className="w-6 h-6 text-blue-600" />
                            </div>
                            <div>
                                <h3 className="text-lg font-medium text-gray-900">Téléphone</h3>
                                <p className="text-gray-600">+33 (0)6 26 38 74 34</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
);
};

export default ContactSupport;