export const initialState = {
  selectedMessage: null,
  messageInput: "",
  searchQuery: "",
  isMobileView: false,
  showReservationDetails: false,
  messages: {},
  previewFiles: [],
  selectedImage: null,
  conversations: []
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_MESSAGE':
      return { ...state, selectedMessage: action.payload };
    case 'SET_MESSAGE_INPUT':
      return { ...state, messageInput: action.payload };
    case 'SET_SEARCH_QUERY':
      return { ...state, searchQuery: action.payload };
    case 'SET_IS_MOBILE_VIEW':
      return { ...state, isMobileView: action.payload };
    case 'TOGGLE_SHOW_RESERVATION_DETAILS':
      return { ...state, showReservationDetails: !state.showReservationDetails };
    case 'SET_MESSAGES':
      return { 
        ...state, 
        messages: { 
          ...state.messages, 
          ...action.payload 
        } 
      };
    case 'ADD_MESSAGE':
      const { conversationId, message } = action.payload;
      return { 
        ...state, 
        messages: { 
          ...state.messages, 
          [conversationId]: [
            ...(state.messages[conversationId] || []),
            message
          ]
        } 
      };
    case 'SET_PREVIEW_FILES':
      return { ...state, previewFiles: action.payload };
    case 'REMOVE_PREVIEW_FILE':
      return { 
        ...state, 
        previewFiles: state.previewFiles.filter((_, i) => i !== action.payload) 
      };
    case 'SET_SELECTED_IMAGE':
      return { ...state, selectedImage: action.payload };
      case 'SET_CONVERSATIONS': {
        // Si le payload est un tableau, on initialise les conversations
        if (Array.isArray(action.payload)) {
          return { 
            ...state, 
            conversations: action.payload 
          };
        }

        // Mise à jour d'une seule conversation (sans redéclaration des variables)
        const updatedConversations = state.conversations.map((conversation) =>
          conversation.id === action.payload.conversationId
            ? {
                ...conversation,
                lastMessage: action.payload.message.content, // Mise à jour du dernier message
                time: action.payload.message.timestamp,              // Mise à jour du timestamp
              }
            : conversation
        );
      
        // Réordonner les conversations en fonction de "time"
        updatedConversations.sort((a, b) => new Date(b.time) - new Date(a.time));

      
        return {
          ...state,
          conversations: updatedConversations,
        };
      }
      
    case 'MARK_MESSAGES_AS_READ':
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload]: state.messages[action.payload]?.map(msg => ({ 
            ...msg, 
            is_lu: true 
          }))
        },
      };
    default:
      return state;
  }
};