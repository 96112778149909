import React, {useState, useEffect} from 'react';
import { 
  FaCheckCircle, FaLocationArrow, FaEnvelope, 
  FaPhoneAlt, FaLanguage, FaUserCircle, FaEdit,
  FaHome, FaStar, FaCalendarCheck, FaCog, FaBell,
  FaShieldAlt, FaHeart, FaMapMarkerAlt, FaRegCircle
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useAuth } from '../context/AuthContext';

const ProfileStat = ({ label, value, icon: Icon, color }) => (
  <div className={`bg-${color}-50 p-4 rounded-2xl flex items-start gap-4`}>
    <div className={`p-3 bg-${color}-100 rounded-xl`}>
      <Icon className={`w-6 h-6 text-${color}-600`} />
    </div>
    <div>
      <p className="text-sm text-gray-500">{label}</p>
      <p className="font-semibold text-lg">{value}</p>
    </div>
  </div>
);

const Profile = () => {
  const { userPhoto, userName } = useAuth();
  const [userData, setUserData] = useState('');
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState({
    prenom: '',
    nom: '',
    host_description: ''
  });
  const apiUrl = process.env.REACT_APP_API_GATEWAY;

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/auth/profile`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données utilisateur');
      }

      const data = await response.json();
      setUserData(data);
      console.log(data);
      setEditData({
        prenom: data.prenom || '',
        nom: data.nom || '',
        host_description: data.host_description || ''
      });
    } catch (error) {
      console.error('Erreur lors de la récupération des données utilisateur:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [apiUrl]);

  const handleUpdateProfile = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/auth/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(editData)
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour du profil');
      }

      const updatedData = await response.json();
      setUserData(updatedData);
      setIsEditing(false);
      // Rafraîchir les données après la mise à jour
      fetchUserData();
    } catch (error) {
      console.error('Erreur lors de la mise à jour:', error);
    }
  };

  const stats = [
    { label: 'Espaces gérés', value: userData.espaces_count || 0, icon: FaHome, color: 'blue' },
    { label: 'Note moyenne', value: userData.note_moyenne || 'Non disponible', icon: FaStar, color: 'yellow' },
    { label: 'Réservations', value: userData.reservations_count || 0, icon: FaCalendarCheck, color: 'green' },
    { label: 'Favoris', value: userData.favoris_count || 0, icon: FaHeart, color: 'pink' },
  ];  

  const verifications = [
    { 
      title: 'Identité vérifiée',
      description: 'Votre identité a été validée',
      status: 'active',
      icon: FaShieldAlt 
    },
    { 
      title: 'Email confirmé',
      description: userData.email,
      status: 'active',
      icon: FaEnvelope 
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50/50 pt-8 pb-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      
        {/* Profile Header */}
        <motion.div 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="relative bg-white rounded-3xl shadow-xl overflow-hidden mb-8"
        >
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 to-purple-600/20" />
          <div className="relative p-8 sm:p-12">
            <div className="flex flex-col sm:flex-row gap-8 items-center sm:items-start">
              <motion.div 
                whileHover={{ scale: 1.05 }}
                className="relative"
              >
                {userPhoto ? (
                  <img 
                    src={userPhoto} 
                    alt="Profile" 
                    className="w-40 h-40 rounded-2xl border-4 border-white shadow-xl object-cover"
                  />
                ) : (
                  <div className="w-40 h-40 rounded-2xl bg-gradient-to-br from-blue-500 to-purple-600 flex items-center justify-center">
                    <FaUserCircle size={80} className="text-white" />
                  </div>
                )}
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="absolute -bottom-3 -right-3 p-3 bg-blue-600 text-white rounded-xl shadow-lg"
                >
                  <FaEdit size={18} />
                </motion.button>
              </motion.div>

              <div className="flex-1 text-center sm:text-left">
              <h1 className="text-3xl font-bold mb-3">
                      {`${userData.prenom || ''} ${userData.nom || ''}`}
                    </h1>
                    <p className="text-gray-600 mb-6">
                      Membre depuis le {new Date(userData.cree_a).toLocaleDateString('fr-FR', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                      })}
                    </p>

                <div className="flex flex-wrap gap-3">
                  <span className="inline-flex items-center px-4 py-2 rounded-xl text-sm font-medium bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-lg">
                    <FaHome className="mr-2" />Hôte
                  </span>
                  {/* <span className="inline-flex items-center px-4 py-2 rounded-xl text-sm font-medium bg-gradient-to-r from-green-600 to-green-700 text-white shadow-lg">
                    <FaLanguage className="mr-2" /> 3 langues
                  </span> */}
                  {/* <span className="inline-flex items-center px-4 py-2 rounded-xl text-sm font-medium bg-gradient-to-r from-purple-600 to-purple-700 text-white shadow-lg">
                    <FaLocationArrow className="mr-2" /> {userData.ville}, {userData.pays}
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          {stats.map((stat, index) => (
            <motion.div
              key={stat.label}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <ProfileStat {...stat} />
            </motion.div>
          ))}
        </div>

        {/* Main Content Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column */}
          <div className="lg:col-span-2 space-y-8">
            {/* About Section */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white rounded-3xl shadow-xl p-8"
            >
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-2xl font-bold">À propos de moi</h2>
                <button 
                  onClick={() => setIsEditing(!isEditing)}
                  className="text-blue-600 hover:text-blue-700"
                >
                  <FaEdit size={20} />
                </button>
              </div>
              <div className="prose prose-lg prose-blue">
                {isEditing ? (
                  <div className="space-y-4">
                    <textarea
                      value={editData.host_description}
                      onChange={(e) => setEditData({...editData, host_description: e.target.value})}
                      className="w-full border rounded-lg px-3 py-2 h-32"
                      placeholder="Description..."
                    />
                    <div className="flex justify-end gap-3">
                      <button
                        onClick={() => setIsEditing(false)}
                        className="px-4 py-2 text-gray-600 hover:text-gray-800"
                      >
                        Annuler
                      </button>
                      <button
                        onClick={handleUpdateProfile}
                        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                      >
                        Sauvegarder
                      </button>
                    </div>
                  </div>
                ) : (
                  <p className="text-gray-600 leading-relaxed">
                    {userData.host_description || "Aucune description disponible"}
                  </p>
                )}
              </div>
            </motion.div>
          </div>

          {/* Right Column */}
          <div className="space-y-8">
            {/* Verifications Card */}
            <motion.div 
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              className="bg-white rounded-3xl shadow-xl p-8"
            >
              <h2 className="text-2xl font-bold mb-6">Vérifications</h2>
              <div className="space-y-6">
                {verifications.map(({ title, description, status, icon: Icon }) => (
                  <div key={title} className="flex items-start gap-4">
                    <div className="p-3 bg-green-50 rounded-xl">
                      <Icon className="w-6 h-6 text-green-600" />
                    </div>
                    <div>
                      <p className="font-medium">{title}</p>
                      <p className="text-sm text-gray-500">{description}</p>
                    </div>
                    {userData.status === 'active' ? (
                      <FaCheckCircle className="ml-auto text-green-500" />
                    ) : (
                      <FaRegCircle className="ml-auto text-gray-300" />
                    )}
                  </div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;