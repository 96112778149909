import React, { useState, useEffect } from 'react';
import { Filter as FilterIcon, X } from 'lucide-react';
import PriceRangeSlider from './PriceRangeSlider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const Filter = ({ props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [categoryVisibility, setCategoryVisibility] = useState({});
  const [priceRange, setPriceRange] = useState({ min: 10, max: 10000 });
  const [capacity, setCapacity] = useState(1);
  const [equipments, setEquipments] = useState([]);
  const [type_tarif, setType_tarif] = useState('day');
  const [type, setType] = useState([]);
  const [dateDebut, setDateDebut] = useState(null);
  const [dateFin, setDateFin] = useState(null);
  
  const apiUrl = process.env.REACT_APP_API_GATEWAY; 

  useEffect(() => {
    fetchEquipements();
    fetchType();
  }, []);

  const fetchEquipements = async () => {
    try {
      const response = await fetch(apiUrl + '/api/workspace/equipement');
      setEquipments(await response.json());
    } catch (error) {
      console.error('Error:', error);
      setEquipments([]);
    }
  };

  const fetchType = async () => {
    try {
      const response = await fetch(apiUrl + '/api/workspace/typeEspaces');

      const responseData = await response.json();

      setType(responseData.data.data);

    } catch (error) {
      console.error('Error:', error);
      setType([]);
    }
  };

  const handleFilteredSpaces = () => {
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      return `${year}-${month}-${day}`;
    };

    const bodyData = {
      search: props.city,
      type: props.type,
      price_min: priceRange.min,
      price_max: priceRange.max,
      guests: capacity,
      type_tarif,
      date_debut: dateDebut ? formatDate(dateDebut) : null,
      date_fin: dateFin ? formatDate(dateFin) : null,
    };


    fetch(apiUrl + '/api/workspace/lists', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      include: 'credentials',
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        props.setValue(data);
      })
      .catch((error) => {
        console.error('Erreur:', error);
      })
      .finally(() => {
      });
  };

  const FilterSection = React.memo(({ title, children }) => (
    <div style={{ marginTop: '100px' }} onClick={(e) => e.stopPropagation()}>
      <h3 className="text-sm font-medium text-gray-900 mb-4">{title}</h3>
      {children}
    </div>
  ));
  

  
    // Helper function to disable unavailable and past dates
    const isDateDisabled = (date) => {
      const today = new Date();
      // Disable past dates
      if (date < today.setHours(0,0,0,0)) {
        return true;
      }
      
    };

  return (
    <div className="w-full lg:w-80 bg-white rounded-lg">
      <div className="p-4">
        
        {/* Dates de réservation */}
        <FilterSection title="Dates de réservation">
          <div className="flex flex-col gap-4">
            <div className="relative">
              <DatePicker
                selected={dateDebut}
                onChange={(date) => setDateDebut(date)}
                filterDate={(date) => !isDateDisabled(date)}
                placeholderText="Date de début"
                className="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-700"
              />
            </div>
            <div className="relative">
              <DatePicker
                selected={dateFin}
                onChange={(date) => setDateFin(date)}
                selectsEnd
                startDate={dateDebut}
                endDate={dateFin}
                minDate={dateDebut}
                disabled={!dateDebut}
                filterDate={(date) => !isDateDisabled(date)}
                className="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholderText="Date de fin"
              />
            </div>
          </div>
        </FilterSection>

        {/* Type d'espace */}
        <FilterSection title="Type d'espace">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-2">
            <button
              onClick={() => props.setType(0)}
              className={`px-3 py-2 text-sm rounded-lg transition-colors duration-200 whitespace-nowrap ${
                props.type === 0
                  ? 'bg-gray-900 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              Tout
            </button>
            {type.map((item) => (
              <button
                key={item.espace_type_id}
                onClick={() => props.setType(item.espace_type_id)}
                className={`px-3 py-2 text-sm rounded-lg transition-colors duration-200 whitespace-nowrap ${
                  props.type === item.espace_type_id
                    ? 'bg-gray-900 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {item.nom}
              </button>
            ))}
          </div>
        </FilterSection>

        {/* Type de tarification */}
        <FilterSection title="Type de tarification">
          <div className="grid grid-cols-4 gap-2">
            {[
              { id: 'hour', label: 'Heure' },
              { id: 'day', label: 'Jour' },
              { id: 'week', label: 'Semaine' },
              { id: 'month', label: 'Mois' }
            ].map(({ id, label }) => (
              <button
                key={id}
                onClick={() => setType_tarif(id)}
                className={`px-3 py-2 text-sm rounded-lg transition-colors duration-200 ${
                  type_tarif === id
                    ? 'bg-gray-900 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {label}
              </button>
            ))}
          </div>
        </FilterSection>

        {/* Prix */}
        <div className="mt-6">
          <h3 className="text-sm font-medium text-gray-900 mb-4">Prix</h3>
          <div className="px-2 mb-2">
            <p className="text-sm text-gray-500 mb-6">Prix par jour, frais inclus</p>
            <PriceRangeSlider
              min={10}
              max={10000}
              value={priceRange}
              onChange={setPriceRange}
            />
          </div>
        </div>

        {/* Capacité */}
        <FilterSection title="Capacité">
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <span className="text-sm text-gray-700">Personnes</span>
            <div className="flex items-center gap-4">
              <button
                onClick={() => setCapacity(Math.max(1, capacity - 1))}
                className={`w-8 h-8 flex items-center justify-center rounded-full transition-colors duration-200 ${
                  capacity > 1
                    ? 'bg-white border border-gray-200 hover:border-gray-300 text-gray-700'
                    : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                }`}
                disabled={capacity <= 1}
              >
                -
              </button>
              <span className="text-sm font-medium w-4 text-center">{capacity}</span>
              <button
                onClick={() => setCapacity(Math.min(8, capacity + 1))}
                className={`w-8 h-8 flex items-center justify-center rounded-full transition-colors duration-200 ${
                  capacity < 8
                    ? 'bg-white border border-gray-200 hover:border-gray-300 text-gray-700'
                    : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                }`}
                disabled={capacity >= 8}
              >
                +
              </button>
            </div>
          </div>
        </FilterSection>

        {/* Équipements */}
        {/* <FilterSection title="Équipements">
          {Object.entries(equipments.reduce((acc, equipement) => {
            const type = equipement.type_equipement;
            if (!acc[type]) acc[type] = [];
            acc[type].push(equipement);
            return acc;
          }, {})).map(([category, items], index) => (
            <div key={index} className="mb-4">
              <button
                className="w-full flex items-center justify-between py-2 text-sm font-medium text-gray-700"
                onClick={() => setCategoryVisibility(prev => ({
                  ...prev,
                  [category]: !prev[category]
                }))}
              >
                {category}
                <span>{categoryVisibility[category] ? '−' : '+'}</span>
              </button>
              {categoryVisibility[category] && (
                <div className="grid grid-cols-1 gap-2 mt-2">
                  {items.map((equipment) => (
                    <button
                      key={equipment.equipement_id}
                      onClick={() => props.setEquipments(
                        props.equipments.includes(equipment.nom_equipement)
                          ? props.equipments.filter(e => e !== equipment.nom_equipement)
                          : [...props.equipments, equipment.nom_equipement]
                      )}
                      className={`flex items-center px-3 py-2 rounded-lg text-sm transition-colors duration-200 ${
                        props.equipments.includes(equipment.nom_equipement)
                          ? 'bg-gray-900 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      {equipment.icone_equipement} {equipment.nom_equipement}
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </FilterSection> */}



        {/* Actions */}
        <div className="flex items-center justify-between mt-8 pt-4 border-t border-gray-200">
          <button
            onClick={() => {
              setPriceRange({ min: 10, max: 1000 });
              setCapacity(1);
              setEquipments([]);
              setType(0);
              setType_tarif('day');
              setDateDebut(null);
              setDateFin(null);
            }}
            className="text-sm text-gray-500 hover:text-gray-700"
          >
            Réinitialiser
          </button>
          <button
            onClick={() => {
              handleFilteredSpaces;
              props.onApply?.();
            }}
            className="px-6 py-2 bg-gray-900 text-white text-sm font-medium rounded-lg hover:bg-gray-800 transition-colors duration-200"
          >
            Voir les résultats
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;