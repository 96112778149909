import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAuth } from '@/context/AuthContext';
import { 
  FaCalendarAlt, FaUsers, FaChartBar, FaStar, FaCalendarCheck,
  FaDollarSign, FaPercentage, FaMapMarkerAlt, FaChartLine, FaEuroSign,
  FaRegBell, FaRegQuestionCircle, FaBan, FaClock
} from 'react-icons/fa';
import { BsArrowUpShort, BsArrowDownShort} from 'react-icons/bs';
import { ArrowRight } from 'lucide-react';
import { 
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, 
  ResponsiveContainer, PieChart, Pie, Cell, Legend, BarChart, Bar
} from 'recharts';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
// import AdvancedAnalytics from '@/components/AdvancedAnalytics';

// Composant StatCard
const StatCard = ({ icon: Icon, title, value, trend, trendValue, small }) => (
  <motion.div
    whileHover={{ y: -2 }}
    className={`bg-white rounded-2xl ${small ? 'p-4' : 'p-6'} shadow-sm hover:shadow-md transition-all duration-200`}
  >
    <div className="flex justify-between items-start">
      <div className={`p-3 rounded-xl ${small ? 'bg-gray-50' : 'bg-blue-50'} ${small ? 'text-gray-600' : 'text-blue-600'}`}>
        <Icon className={`${small ? 'w-4 h-4' : 'w-5 h-5'}`} />
      </div>
      {trend && (
        <span className={`flex items-center text-sm font-medium ${
          trend === 'up' ? 'text-green-600' : 'text-red-600'
        }`}>
          {trend === 'up' ? <BsArrowUpShort className="w-4 h-4" /> : <BsArrowDownShort className="w-4 h-4" />}
          {trendValue}%
        </span>
      )}
    </div>
    <h3 className={`text-gray-500 ${small ? 'text-xs mt-3' : 'text-sm mt-4'} mb-1`}>{title}</h3>
    <p className={`${small ? 'text-lg' : 'text-2xl'} font-semibold text-gray-900`}>{value}</p>
  </motion.div>
);

// Composant BookingCard avec données dynamiques
const BookingCard = ({ reservation }) => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    className="bg-white p-4 rounded-xl shadow-sm hover:shadow-md transition-all duration-200"
  >
    <div className="flex items-start gap-4">
      <img 
        src={`data:image/jpeg;base64,${reservation.photo_couverture}`}
        alt={reservation.espace_titre} 
        className="w-16 h-16 rounded-lg object-cover"
      />
      <div className="flex-1 min-w-0">
        <div className="flex items-start justify-between">
          <div>
            <h3 className="font-medium text-gray-900 truncate">{reservation.espace_titre}</h3>
            <div className="flex items-center text-sm text-gray-500 mt-1">
              <FaMapMarkerAlt className="w-4 h-4 mr-1" />
              <p className="truncate">{reservation.espace_adresse}</p>
            </div>
          </div>
          <span className={`px-3 py-1 rounded-full text-xs font-medium ${
            reservation.statut === 'valide' 
              ? 'bg-green-50 text-green-700' 
              : reservation.statut === 'annule' 
                ? 'bg-red-50 text-red-700'
                : 'bg-yellow-50 text-yellow-700'
          }`}>
            {reservation.statut === 'valide' ? 'Confirmé' 
              : reservation.statut === 'annule' ? 'Annulé'
              : reservation.statut === 'refuse' ? 'Refusé'
              : 'En attente'}
          </span>
        </div>
        
        <div className="mt-3 flex flex-col gap-1">
          <div className="text-sm text-gray-600">
            <span className="font-medium">Début:</span> {' '}
            {format(new Date(reservation.date_debut), 'dd MMMM yyyy', { locale: fr })}
          </div>
          <div className="text-sm text-gray-600">
            <span className="font-medium">Fin:</span> {' '}
            {format(new Date(reservation.date_fin), 'dd MMMM yyyy', { locale: fr })}
          </div>
        </div>
        
        <div className="mt-3 text-xs text-gray-500">
          Réservé le {format(new Date(reservation.cree_a), 'dd/MM/yyyy à HH:mm')}
        </div>
      </div>
    </div>
  </motion.div>
);

// Nouveau composant pour le graphique de revenus
const RevenueChart = ({ data }) => {
  const [hoveredData, setHoveredData] = useState(null);

  return (
    <div className="h-[400px] w-full p-6 bg-white rounded-2xl shadow-sm">
      <h3 className="text-lg font-medium text-gray-900 mb-6">Évolution des revenus</h3>
      <ResponsiveContainer>
        <AreaChart data={data} onMouseMove={(e) => setHoveredData(e)}>
          <defs>
            <linearGradient id="revenueGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.3}/>
              <stop offset="95%" stopColor="#3B82F6" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
          <XAxis 
            dataKey="date" 
            tick={{ fill: '#6B7280', fontSize: 12 }}
            axisLine={{ stroke: '#E5E7EB' }}
          />
          <YAxis 
            tick={{ fill: '#6B7280', fontSize: 12 }}
            axisLine={{ stroke: '#E5E7EB' }}
            unit="€"
          />
          <Tooltip 
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return (
                  <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-100">
                    <p className="text-sm text-gray-600">{format(new Date(label), 'dd MMMM yyyy', { locale: fr })}</p>
                    <p className="text-lg font-semibold text-gray-900">{`${payload[0].value}€`}</p>
                  </div>
                );
              }
              return null;
            }}
          />
          <Area
            type="monotone"
            dataKey="revenue"
            stroke="#3B82F6"
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#revenueGradient)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

// Nouveau composant pour les statistiques de réservation
const BookingStats = ({ data }) => {
  const COLORS = ['#3B82F6', '#10B981', '#F59E0B'];

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div className="bg-white p-6 rounded-2xl shadow-sm">
        <h3 className="text-lg font-medium text-gray-900 mb-6">Répartition des réservations</h3>
        <div className="h-[300px]">
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={100}
                paddingAngle={5}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
                        <p className="text-sm text-gray-600">{payload[0].name}</p>
                        <p className="text-lg font-semibold text-gray-900">
                          {payload[0].value}%
                        </p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Legend
                verticalAlign="bottom"
                height={36}
                content={({ payload }) => (
                  <div className="flex justify-center gap-6">
                    {payload.map((entry, index) => (
                      <div key={`legend-${index}`} className="flex items-center">
                        <div
                          className="w-3 h-3 rounded-full mr-2"
                          style={{ backgroundColor: entry.color }}
                        />
                        <span className="text-sm text-gray-600">{entry.value}</span>
                      </div>
                    ))}
                  </div>
                )}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="bg-white p-6 rounded-2xl shadow-sm">
        <h3 className="text-lg font-medium text-gray-900 mb-6">Taux d'occupation</h3>
        <div className="h-[300px]">
          <ResponsiveContainer>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
              <XAxis dataKey="name" />
              <YAxis unit="%" />
              <Tooltip
                content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
                        <p className="text-sm text-gray-600">{label}</p>
                        <p className="text-lg font-semibold text-gray-900">
                          {`${payload[0].value}%`}
                        </p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Bar dataKey="value" fill="#3B82F6" radius={[4, 4, 0, 0]} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

// Constantes de période pour le filtrage
const PERIOD_FILTERS = {
  WEEK: '7days',
  MONTH: '30days',
  QUARTER: '90days',
  YEAR: '365days'
};

// Composant principal HostDashboard
const HostDashboard = () => {
  const [data, setData] = useState('');
  const [reservations, setReservations] = useState([]);
  const [occupancyData, setOccupancyData] = useState([]);
  const [periodFilter, setPeriodFilter] = useState(PERIOD_FILTERS.MONTH);
  const [activeTab, setActiveTab] = useState('all');
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_GATEWAY;
  const navigate = useNavigate();
  const { userName } = useAuth();

 // Données simulées pour les graphiques
 const revenueData = [
  { date: '2024-01-01', revenue: 1200 },
  { date: '2024-01-02', revenue: 1800 },
  { date: '2024-01-03', revenue: 1400 },
  { date: '2024-01-04', revenue: 2200 },
  { date: '2024-01-05', revenue: 1600 },
  { date: '2024-01-06', revenue: 2400 },
  { date: '2024-01-07', revenue: 2000 },
];

const bookingDistribution = [
  { name: 'En cours', value: 35 },
  { name: 'À venir', value: 45 },
  { name: 'Terminées', value: 20 },
];

 // Récupérer les données du tableau de bord
 useEffect(() => {
  const fetchDashboardData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${apiUrl}/api/auth/host/dashboard/stats`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données statistiques');
      }

      const res = await response.json();
      console.log('Données du tableau de bord : ', res);
      setData(res);

    } catch (error) {
      console.error('Erreur détaillée:', error);
      setError(`Erreur de chargement: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  fetchDashboardData();
}, [apiUrl]);

// Récupérer les réservations de l'hôte
useEffect(() => {
  const fetchReservations = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${apiUrl}/api/reservation/host/mybookings`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      if (data.success) {
        // S'assurer que data.data est un tableau
        setReservations(Array.isArray(data.data) ? data.data : []);
      } else {
        setError(data.message);
        setReservations([]); // Réinitialiser avec un tableau vide en cas d'erreur
      }
      
    } catch (err) {
      console.error('Erreur:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  fetchReservations();
}, [apiUrl]);

  // Filtrer les réservations selon l'onglet actif
  const filteredReservations = reservations.filter(reservation => {
    const now = new Date();
    const startDate = new Date(reservation.date_debut);
    const endDate = new Date(reservation.date_fin);

    switch (activeTab) {
      case 'upcoming':
        return startDate > now;
      case 'ongoing':
        return startDate <= now && endDate >= now;
      case 'past':
        return endDate < now;
      default:
        return true;
    }
  });

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50/50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600"></div>
      </div>
    );
  }

    const total_reservation = data.total_reservations || 0;
    const total_espaces = data.total_espaces || 0;
    const revenu_total = data.revenu_total || 0;
    const revenus_mensuels = data.revenus_mensuels || 0;
    const reservations_en_cours = data.reservations_en_cours || 0; 
    const taux_occupation = data.taux_occupation || 0;
    const taux_annulation = data.taux_annulation || 0;
    const taux_satisfaction = 0;
    const stats = {}
  return (
    <div className="min-h-screen bg-gray-50/50 pt-5 pb-5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-5">
          <div>
            <h1 className="text-2xl font-semibold text-gray-900">Tableau de bord</h1>
            <p className="text-gray-500">Bonjour {userName}, voici vos statistiques</p>
          </div>
          <div className="flex items-center gap-4">
            <button 
              onClick={() => navigate('/become-host')}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              + Créer une annonce
            </button>
            {/* <button className="p-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-all">
              <FaRegBell className="w-5 h-5 text-gray-600" />
            </button> */}
            {/* <button className="p-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-all">
              <FaRegQuestionCircle className="w-5 h-5 text-gray-600" />
            </button> */}
          </div>
        </div>

        {/* Statistiques principales */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <StatCard
          icon={FaDollarSign}  // Déjà correct pour les revenus
          title="Total des revenus"
          value={`${Math.round(revenu_total || 0 )} €`}
          trend={stats?.revenue_trend?.type}
          trendValue={stats?.revenue_trend?.value}
        />
        <StatCard
          icon={FaDollarSign}  // Déjà correct pour les revenus
          title="Revenus mensuels"
          value={`${Math.round(revenus_mensuels || 0 )} €`}
          trend={stats?.revenue_trend?.type}
          trendValue={stats?.revenue_trend?.value}
        />
        <StatCard
          icon={FaChartLine}  // Changé pour FaChartLine qui représente mieux le taux d'occupation
          title="Nombre de réservations totales"
          value={total_reservation || 0}
          trend={stats?.customers_trend?.type}
          trendValue={stats?.customers_trend?.value}
        />
        <StatCard
          icon={FaChartLine}  // Changé pour FaChartLine qui représente mieux le taux d'occupation
          title="Nombre d'espaces total"
          value={total_espaces || 0}
          trend={stats?.customers_trend?.type}
          trendValue={stats?.customers_trend?.value}
        />
        <StatCard
          icon={FaChartLine}  // Changé pour FaChartLine qui représente mieux le taux d'occupation
          title="Taux d'occupation"
          value={`${Math.round(taux_occupation || 0 )} %`}
          trend={stats?.customers_trend?.type}
          trendValue={stats?.customers_trend?.value}
        />
        <StatCard
          icon={FaCalendarCheck}  // Changé pour FaCalendarCheck pour les réservations
          title="Réservations actives"
          value={`${reservations_en_cours || 0}`}
          trend={stats?.rating_trend?.type}
          trendValue={stats?.rating_trend?.value}
        />

        <StatCard
          icon={FaBan}  // Changé pour FaBan pour les annulations
          title="Taux d'annulation"
          value={`${Math.round(taux_annulation || 0)} %`}
          trend={stats?.rating_trend?.type}
          trendValue={stats?.rating_trend?.value}
        />
        <StatCard
          icon={FaStar}  // Changé pour FaBan pour les annulations
          title="Taux moyen de satisfaction"
          value={`${Math.round(taux_satisfaction || 0)} %`}
          trend={stats?.rating_trend?.type}
          trendValue={stats?.rating_trend?.value}
        />

        </div>

      {/* Analyses avancées */}
      {/* <div className="mb-8">
        <AdvancedAnalytics data={data} />
      </div> */}

        {/* Section des réservations */}
        <div className="bg-white rounded-2xl shadow-sm p-6 mb-8 overflow-x-scroll">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
            <h2 className="text-lg font-medium text-gray-900">Réservations</h2>
            <div className="flex gap-2">
              <button
                onClick={() => setActiveTab('all')}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                  activeTab === 'all' 
                    ? 'bg-blue-600 text-white shadow-sm' 
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                Toutes
              </button>
              <button
                onClick={() => setActiveTab('upcoming')}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                  activeTab === 'upcoming' 
                    ? 'bg-blue-600 text-white shadow-sm' 
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                À venir
              </button>
              <button
                onClick={() => setActiveTab('ongoing')}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                  activeTab === 'ongoing' 
                    ? 'bg-blue-600 text-white shadow-sm' 
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                En cours
              </button>
              <button
                onClick={() => setActiveTab('past')}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                  activeTab === 'past' 
                    ? 'bg-blue-600 text-white shadow-sm' 
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                Passées
              </button>
            </div>
          </div>

          <div className="space-y-4">
            {filteredReservations.length === 0 ? (
              <div className="text-center py-8">
                <p className="text-gray-500">
                  Aucune réservation {
                    activeTab === 'upcoming' ? 'à venir' :
                    activeTab === 'ongoing' ? 'en cours' :
                    activeTab === 'past' ? 'passée' :
                    ''
                  }
                </p>
              </div>
            ) : (
              filteredReservations.map((reservation) => (
                <BookingCard 
                  key={reservation.reservation_id} 
                  reservation={reservation} 
                />
              ))
            )}
          </div>
        </div>
          <button
            onClick={() => navigate('/hosting/bookings')}
            className="flex items-center gap-2 px-4 py-2 text-dark 
                     rounded-lg transition-colors"
          >
            <span>Voir les réservations</span>
            <ArrowRight className="w-4 h-4" />
          </button>
      </div>
    </div>
  );
};

export default HostDashboard;