
import { useCallback } from 'react';

const useImageModal = (setSelectedImage) => {
  
  const handleImageClick = useCallback((image) => {
    setSelectedImage(image);
  }, [setSelectedImage]);

  const handleCloseImage = useCallback(() => {
    setSelectedImage(null);
  }, [setSelectedImage]);

  return {
    handleImageClick,
    handleCloseImage
  };
};

export default useImageModal;