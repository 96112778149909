import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Users,
  Home,
  Monitor,
  DoorClosed,
  HelpCircle,
  ArrowLeft,
  ArrowRight,
  Minus,
  Plus,
  Layout
} from 'lucide-react';
import { useForm } from '../../../context/FormHostContext';
import SaveQuitButton from '../../../components/SaveQuitButton';

const CapacityOption = ({ icon: Icon, label, value, onIncrement, onDecrement, description }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="bg-white rounded-2xl p-6 shadow-lg border border-gray-100
                transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-start justify-between mb-4">
        <div className="flex items-center gap-3">
          <div className={`p-2 rounded-xl transition-colors duration-300
                        ${isHovered ? 'bg-blue-500 text-white' : 'bg-blue-50 text-blue-500'}`}>
            <Icon className="w-6 h-6" />
          </div>
          <div>
            <h3 className="font-semibold text-gray-900">{label}</h3>
            <p className="text-sm text-gray-500 mt-1">{description}</p>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <button
          onClick={onDecrement}
          disabled={value <= 1}
          className={`w-12 h-12 rounded-full flex items-center justify-center transition-all duration-300
                    ${value <= 1 
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                      : 'bg-blue-50 text-blue-500 hover:bg-blue-100'}`}
        >
          <Minus className="w-5 h-5" />
        </button>

        <span className="text-2xl font-semibold text-gray-900 w-12 text-center">
          {value}
        </span>

        <button
          onClick={onIncrement}
          className="w-12 h-12 rounded-full bg-blue-50 text-blue-500 
                   flex items-center justify-center
                   hover:bg-blue-100 transition-all duration-300"
        >
          <Plus className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

const StepProgress = ({ currentStep, totalSteps }) => {
  return (
    <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                    bg-white rounded-full shadow-lg p-2 border border-gray-100">
      <div className="flex items-center gap-2 px-3 py-1">
        {Array.from({ length: totalSteps }).map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 rounded-full transition-all duration-300 
                      ${index < currentStep ? 'w-8 bg-blue-500' : 'bg-gray-200'}`}
          />
        ))}
      </div>
    </div>
  );
};

export default function StepOneCapacity() {
  const navigate = useNavigate();
  const [capacity, setCapacity] = useState({
    guests: 4,
    spaces: 1,
    desks: 1,
    toilets: 1,
  });
  const [isVisible, setIsVisible] = useState(false);
  const { handleChange } = useForm();

  useEffect(() => {
    setIsVisible(true);
    
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Vos modifications ne seront pas enregistrées.';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  const handleIncrement = (key) => {
    setCapacity(prev => {
      const newValue = prev[key] + 1;
      handleChange(key, newValue);
      return { ...prev, [key]: newValue };
    });
  };

  const handleDecrement = (key) => {
    if (capacity[key] > 1) {
      setCapacity(prev => {
        const newValue = prev[key] - 1;
        handleChange(key, newValue);
        return { ...prev, [key]: newValue };
      });
    }
  };

  const capacityOptions = [
    {
      icon: Users,
      label: "Capacité d'accueil",
      key: "guests",
      description: "Nombre maximum de personnes pouvant travailler simultanément"
    },
    {
      icon: Layout,
      label: "Espaces disponibles",
      key: "spaces",
      description: "Nombre de pièces ou zones de travail distinctes"
    },
    {
      icon: Monitor,
      label: "Bureaux",
      key: "desks",
      description: "Nombre de postes de travail équipés"
    },
    {
      icon: DoorClosed,
      label: "Toilettes",
      key: "toilets",
      description: "Nombre de toilettes accessibles"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-lg border-b border-gray-100 z-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <button className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors">
            <HelpCircle className="w-5 h-5" />
            <span>Des questions ?</span>
          </button>
          <SaveQuitButton />
        </div>
      </header>

      {/* Main Content */}
      <main className="pt-20 pb-24 max-w-4xl mx-auto px-4">
        <div className={`transition-all duration-700 ease-out
                      ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
          {/* Title Section */}
          <div className="text-center mb-12">
            <div className="inline-flex items-center gap-2 px-4 py-2 bg-blue-50 rounded-full text-blue-600 
                          text-sm font-medium mb-6">
              <Layout className="w-4 h-4" />
              <span>Informations essentielles</span>
            </div>
            
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Parlez-nous de votre espace
            </h1>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Configurez les détails essentiels de votre espace pour aider les télétravailleurs 
              à trouver l'environnement idéal.
            </p>
          </div>

          {/* Capacity Options Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
            {capacityOptions.map((option) => (
              <CapacityOption
                key={option.key}
                icon={option.icon}
                label={option.label}
                description={option.description}
                value={capacity[option.key]}
                onIncrement={() => handleIncrement(option.key)}
                onDecrement={() => handleDecrement(option.key)}
              />
            ))}
          </div>
        </div>
      </main>

      {/* Progress Steps */}
      <StepProgress currentStep={4} totalSteps={14} />

      {/* Footer */}
      <footer className="fixed bottom-0 left-0 right-0 bg-white/80 backdrop-blur-lg border-t border-gray-100">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <button
            onClick={() => navigate('/become-host/steponeemplacement')}
            className="flex items-center gap-2 px-6 py-3 rounded-full text-gray-700 
                     hover:bg-gray-100 transition-all duration-300"
          >
            <ArrowLeft className="w-5 h-5" />
            <span>Retour</span>
          </button>

          <button
            onClick={() => navigate('/become-host/steptwointro')}
            className="flex items-center gap-2 px-8 py-3 rounded-full text-white
                     bg-gradient-to-r from-blue-600 to-blue-500
                     hover:shadow-lg hover:shadow-blue-500/20 hover:-translate-y-0.5
                     transform transition-all duration-300"
          >
            <span>Continuer</span>
            <ArrowRight className="w-5 h-5" />
          </button>
        </div>
      </footer>
    </div>
  );
}