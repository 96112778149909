import React from 'react';
import { Link } from 'react-router-dom'; // Import du composant Link de React Router
import '../styles/Discover.css'; // Import du CSS

function Discover() {
  const offers = [
    { title: 'Espaces à Paris', image: '/images/paris.webp', path: '/espaces/paris' },
    { title: 'Bureaux à New York', image: '/images/ny.webp', path: '/espaces/new-york' },
    { title: 'Espaces créatifs à Berlin', image: '/images/berlin.webp', path: '/espaces/berlin' },
    { title: 'Bureaux en remote', image: '/images/remote.webp', path: '/espaces/remote' },
  ];

  return (
    <div>
      <section className="discover-page">
        <h1>Découvrez des espaces de travail uniques</h1>
        <div className="discover-grid">
          {offers.map((offer, index) => (
            <div key={index} className="discover-grid-item">
              <img src={offer.image} alt={offer.title} />
              <h3>{offer.title}</h3>
              {/* Lien pour rediriger vers la page des espaces correspondants */}
              <Link to={offer.path} className="discover-button">Voir les espaces</Link>
            </div>
          ))}
        </div>
      </section><br/><br/><br/><br/><br/><br/><br/><br/>
    </div>
  );
}

export default Discover;
