// src/components/Search.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaMapMarkerAlt } from 'react-icons/fa';
import '../styles/Search.css';

const apiUrl = process.env.REACT_APP_API_GATEWAY;

const Search = ({ onSearch }) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (value !== '') {
      fetch(apiUrl + '/api/workspace/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ search: value }),
      })
        .then((response) => response.json())
        .then((data) => {
          setSuggestions(data);
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des suggestions:', error);
        });
    } else {
      setSuggestions([]);
    }
  }, [value]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value) {
      onSearch(value);
      navigate(`/espaces/${value}`);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setValue(suggestion.ville);
    setSuggestions([]);
    navigate(`/espaces/${suggestion.ville}`);
  };

  return (
    <div className="search-container">
      <form onSubmit={handleSubmit} className="search-bar">
        <FaMapMarkerAlt className="icon location-icon" />
        <input
          type="text"
          placeholder="Rechercher une ville ou une région"
          className="search-input"
          value={value}
          onChange={handleChange}
        />
        <button type="submit" className="search-button">
          <FaSearch />
        </button>
      </form>
      {suggestions.length > 0 && (
        <ul className="suggestions">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              <FaMapMarkerAlt className="icon suggestion-icon" /> {suggestion.ville}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Search;