import React, { useState, useEffect } from 'react';
import { 
  Search, ChevronDown, Phone, Mail, MessageCircle, 
  Home, CreditCard, HelpCircle, Bell, Calendar,
  Settings, Users, Shield, Zap, ArrowRight, AlertCircle
} from 'lucide-react';

const Alert = ({ children, className = '' }) => (
  <div className={`rounded-lg p-4 flex items-start space-x-3 ${className}`}>
    <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
    <div className="flex-1">{children}</div>
  </div>
);

function Help() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [openIndex, setOpenIndex] = useState(null);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [searchFocus, setSearchFocus] = useState(false);

  const categories = [
    { id: 'all', name: 'Toutes les questions', icon: HelpCircle },
    { id: 'getting-started', name: 'Débuter', icon: Zap },
    { id: 'booking', name: 'Réservations', icon: Calendar },
    { id: 'payment', name: 'Paiement', icon: CreditCard },
    { id: 'host', name: 'Devenir hôte', icon: Home },
    { id: 'security', name: 'Sécurité', icon: Shield },
    { id: 'account', name: 'Compte', icon: Users }
  ];

  const faqs = [
    {
      question: 'Comment commencer à utiliser la plateforme ?',
      answer: 'Créez votre compte en quelques clics, complétez votre profil, et commencez à explorer les espaces disponibles. Notre guide interactif vous accompagnera dans vos premiers pas.',
      category: 'getting-started',
      popular: true
    },
    {
      question: 'Comment fonctionne le système de réservation ?',
      answer: 'Sélectionnez un espace, choisissez vos dates, et effectuez le paiement. La confirmation est instantanée et vous recevrez tous les détails par email.',
      category: 'booking',
      popular: true
    },
    {
      question: 'Quelles sont les options de paiement disponibles ?',
      answer: 'Nous acceptons les cartes de crédit principales (Visa, Mastercard, American Express), Apple Pay, Google Pay, et les virements SEPA pour les réservations professionnelles.',
      category: 'payment'
    },
    {
      question: 'Comment devenir un hôte vérifié ?',
      answer: 'Complétez votre profil hôte, ajoutez des photos de qualité de votre espace, et passez par notre processus de vérification. Notre équipe validera votre demande sous 48h.',
      category: 'host'
    },
    {
      question: 'Quelle est votre politique d\'annulation ?',
      answer: 'Notre politique d\'annulation flexible permet un remboursement complet jusqu\'à 48h avant la réservation. Des conditions spéciales s\'appliquent pour les réservations longue durée.',
      category: 'booking'
    },
    {
      question: 'Comment fonctionne la protection des paiements ?',
      answer: 'Tous les paiements sont sécurisés par cryptage SSL. Nous utilisons un système d\'entiercement pour protéger à la fois les hôtes et les locataires.',
      category: 'security',
      popular: true
    }
  ];

  useEffect(() => {
    const filtered = faqs.filter(faq => {
      const matchesSearch = faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          faq.answer.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory = selectedCategory === 'all' || faq.category === selectedCategory;
      return matchesSearch && matchesCategory;
    });
    setFilteredFaqs(filtered);
  }, [searchQuery, selectedCategory]);

  const popularQuestions = faqs.filter(faq => faq.popular);

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white pb-7">
      {/* Hero Section */}
      <div className="relative overflow-hidden pt-16 pb-32">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center">
            <h1 className="text-5xl font-bold tracking-tight text-gray-900 mb-8 leading-tight">
              Comment pouvons-nous<br />vous aider aujourd'hui ?
            </h1>
            
            {/* Search Bar */}
            <div className="max-w-2xl mx-auto relative group">
              <div className={`absolute inset-0 bg-blue-100/50 rounded-full transition-all duration-300 ${
                searchFocus ? 'opacity-100 scale-105' : 'opacity-0 scale-100'
              }`} />
              <div className="relative">
                <Search className={`absolute left-4 top-4 h-6 w-6 transition-colors duration-200 ${
                  searchFocus ? 'text-blue-600' : 'text-gray-400'
                }`} />
                <input
                  type="text"
                  placeholder="Rechercher une réponse..."
                  className="w-full pl-14 pr-4 py-4 rounded-full border-2 border-transparent bg-white shadow-lg focus:outline-none focus:border-blue-500 transition-all duration-200"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onFocus={() => setSearchFocus(true)}
                  onBlur={() => setSearchFocus(false)}
                />
              </div>
            </div>

            {/* Quick Stats */}
            <div className="mt-12 grid grid-cols-2 gap-8 sm:grid-cols-3 max-w-4xl mx-auto">
              <div className="bg-white/80 backdrop-blur-sm rounded-3xl p-6 shadow-lg transform hover:scale-105 transition-all duration-300">
                <div className="text-4xl font-bold text-blue-600 mb-2">24/7</div>
                <div className="text-gray-600">Support disponible</div>
              </div>
              <div className="bg-white/80 backdrop-blur-sm rounded-3xl p-6 shadow-lg transform hover:scale-105 transition-all duration-300">
                <div className="text-4xl font-bold text-blue-600 mb-2">&lt;1h</div>
                <div className="text-gray-600">Temps de réponse</div>
              </div>
              <div className="hidden sm:block bg-white/80 backdrop-blur-sm rounded-3xl p-6 shadow-lg transform hover:scale-105 transition-all duration-300">
                <div className="text-4xl font-bold text-blue-600 mb-2">98%</div>
                <div className="text-gray-600">Satisfaction client</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 -mt-24 relative z-20">
        {/* Categories Scrollable */}
        <div className="mb-12 overflow-x-auto pb-4 hide-scrollbar">
          <div className="flex space-x-4 min-w-min px-2">
            {categories.map((category) => {
              const Icon = category.icon;
              return (
                <button
                  key={category.id}
                  onClick={() => setSelectedCategory(category.id)}
                  className={`flex items-center px-6 py-4 rounded-full transition-all duration-300 whitespace-nowrap ${
                    selectedCategory === category.id
                      ? 'bg-blue-600 text-white shadow-lg scale-105'
                      : 'bg-white text-gray-600 hover:bg-gray-50 shadow-md hover:shadow-lg'
                  }`}
                >
                  <Icon className="w-5 h-5 mr-2" />
                  {category.name}
                </button>
              );
            })}
          </div>
        </div>

        {/* Content Grid */}
        <div className="grid lg:grid-cols-3 gap-8">
          {/* FAQ List */}
          <div className="lg:col-span-2 space-y-6">
            {filteredFaqs.length > 0 ? filteredFaqs.map((faq, index) => (
              <div
                key={index}
                className="bg-white rounded-3xl shadow-md hover:shadow-xl transition-all duration-300"
              >
                <button
                  className="w-full text-left px-8 py-6 flex justify-between items-center focus:outline-none"
                  onClick={() => setOpenIndex(openIndex === index ? null : index)}
                >
                  <h3 className="text-lg font-medium text-gray-900 pr-8">
                    {faq.question}
                  </h3>
                  <ChevronDown
                    className={`w-6 h-6 text-blue-600 transition-transform duration-300 flex-shrink-0 ${
                      openIndex === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                
                <div
                  className={`transition-all duration-300 ease-in-out ${
                    openIndex === index ? 'max-h-96' : 'max-h-0'
                  } overflow-hidden`}
                >
                  <p className="px-8 pb-6 text-gray-600 leading-relaxed">
                    {faq.answer}
                  </p>
                </div>
              </div>
            )) : (
              <Alert className="bg-blue-50/80 backdrop-blur-sm">
                Aucun résultat trouvé pour votre recherche. Essayez avec d'autres mots-clés ou parcourez nos catégories.
              </Alert>
            )}
          </div>

          {/* Sidebar */}
          <div className="space-y-8">
            {/* Popular Questions */}
            <div className="bg-gray-50 rounded-3xl p-6">
              <h2 className="text-lg font-semibold mb-4">Questions populaires</h2>
              <div className="space-y-3">
                {popularQuestions.map((q, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      setSelectedCategory(q.category);
                      setOpenIndex(filteredFaqs.findIndex(faq => faq.question === q.question));
                    }}
                    className="w-full text-left p-4 rounded-2xl bg-white shadow-sm hover:shadow-md transition-all duration-200 group"
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-gray-900">{q.question}</span>
                      <ArrowRight className="w-4 h-4 text-blue-600 opacity-0 group-hover:opacity-100 transition-all duration-200" />
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx global>{`
        .hide-scrollbar {
          scrollbar-width: none;
          -ms-overflow-style: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </div>
  );
}

export default Help;
