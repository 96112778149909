import { useEffect, useRef } from 'react';

const useWebSocket = ({ token, userId, setMessages, setConversations, selectedMessage, messagesEndRef }) => {
  const ws = useRef(null);
  const wsProtocol = process.env.NODE_ENV === 'production' ? 'wss' : 'ws';

  useEffect(() => {
    ws.current = new WebSocket(`${wsProtocol}://${process.env.REACT_APP_WS_GATEWAY}/api/message/?token=${token}`);

    ws.current.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);

      console.log('Message received:', message);
      
      if (message.type === 'privateMessage' || message.type === 'image' || message.type === 'file') {
        
        const conversationId = message.senderId === userId ? message.receiverId : message.senderId;

        // Add is_lu property to the message
        const updatedMessage = { ...message, is_lu: false };

        setMessages({ conversationId, message: updatedMessage });

        // Remove the dispatch call as it's undefined
        // dispatch({ type: 'MARK_MESSAGES_AS_READ', payload: conversationId });

        // If the incoming message is for the currently selected conversation, scroll to the message
        if (selectedMessage && (message.senderId === selectedMessage.id || message.receiverId === selectedMessage.id)) {
          setConversations({ conversationId, message: updatedMessage });
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    ws.current.onclose = () => {
      console.log('WebSocket disconnected');
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [token, userId, selectedMessage, messagesEndRef]);

  return ws;
};

export default useWebSocket;