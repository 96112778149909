import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  Heart, 
  MapPin, 
  Users, 
  Wifi,
  Coffee,
  Monitor,
  CircleParking,
  Search,
  AlertCircle,
  Euro
} from 'lucide-react';

const Favorites = () => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const apiUrl =  process.env.REACT_APP_API_GATEWAY || 'http://localhost:5000';
  const utilisateur_id = 1;

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        console.log('Fetching favorites...', { apiUrl, utilisateur_id }); // Debug log
  
        const response = await fetch(
          `${apiUrl}/api/workspace/favoris`,
          {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log('Favorites data:', data); // Debug log


        if (data.success) {
          setFavorites(data.data.data);
        } else {
          throw new Error(data.message || 'Erreur lors de la récupération des favoris');
        }
      } catch (error) {
        console.error('Error fetching favorites:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    if (utilisateur_id) {
      fetchFavorites();
    } else {
      setLoading(false);
      setError("Veuillez vous connecter pour voir vos favoris");
    }
  }, [apiUrl, utilisateur_id]);

  const handleRemoveFavorite = async (espace_id) => {
    try {
      const response = await fetch(`${apiUrl}/api/workspace/favoris/${espace_id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ espace_id })
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la suppression du favori');
      }

      const data = await response.json();
      if (data.success) {
        setFavorites(favorites.filter(fav => fav.espace_id !== espace_id));
      } else {
        throw new Error(data.message || 'Erreur lors de la suppression');
      }
    } catch (error) {
      console.error('Error removing favorite:', error);
      setError(error.message);
    }
  };

  const filteredFavorites = favorites.filter(favorite => 
    favorite.titre.toLowerCase().includes(searchTerm.toLowerCase()) ||
    favorite.adresse.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <div className="w-16 h-16 relative">
            <div className="absolute inset-0 rounded-full border-4 border-rose-100"></div>
            <div className="absolute inset-0 rounded-full border-4 border-rose-500 border-t-transparent animate-spin"></div>
          </div>
          <p className="text-gray-600 font-medium">Chargement de vos favoris...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-md w-full bg-white rounded-3xl p-8 shadow-lg text-center"
        >
          <div className="inline-flex items-center justify-center w-20 h-20 rounded-full bg-red-50 mb-6">
            <AlertCircle className="w-10 h-10 text-red-600" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-3">
            Une erreur est survenue
          </h2>
          <p className="text-gray-600 mb-8">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="inline-flex items-center justify-center w-full px-6 py-3 rounded-2xl bg-gradient-to-r from-rose-500 to-rose-600 text-white font-semibold hover:from-rose-600 hover:to-rose-700 transition-all duration-200"
          >
            Réessayer
          </button>
        </motion.div>
      </div>
    );
  }

  if (favorites.length === 0) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-md w-full bg-white rounded-3xl p-8 shadow-lg text-center"
        >
          <div className="inline-flex items-center justify-center w-20 h-20 rounded-full bg-rose-50 mb-6">
            <Heart className="w-10 h-10 text-rose-500" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-3">
            Aucun favori
          </h2>
          <p className="text-gray-600 mb-8">
            Vous n'avez pas encore ajouté d'espaces à vos favoris.
          </p>
          <Link
            to="/spaces"
            className="inline-flex items-center justify-center w-full px-6 py-3 rounded-2xl bg-gradient-to-r from-rose-500 to-rose-600 text-white font-semibold hover:from-rose-600 hover:to-rose-700 transition-all duration-200"
          >
            Découvrir des espaces
          </Link>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-16 pb-12">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col gap-8 mb-8">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-bold text-gray-900 mb-2">Mes favoris</h1>
              <p className="text-gray-600">{filteredFavorites.length} espaces enregistrés</p>
            </div>
          </div>

          <div className="relative">
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              placeholder="Rechercher par nom ou adresse..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-12 pr-4 py-3 rounded-2xl border border-gray-200 focus:border-rose-500 focus:ring-2 focus:ring-rose-500/20 outline-none transition-all"
            />
          </div>
        </div>

        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
        >
          {filteredFavorites.map((favorite) => (
            <motion.div
              key={favorite.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              whileHover={{ y: -4 }}
              className="bg-white rounded-3xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-200"
            >
              <Link to={`/espace/${favorite.espace_id}`}>
                <div className="relative aspect-[4/3]">
                  <img
                    src={`data:image/jpeg;base64,${favorite.photo_couverture}`}
                    alt={favorite.titre}
                    className="w-full h-full object-cover"
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleRemoveFavorite(favorite.espace_id);
                    }}
                    className="absolute top-4 right-4 p-2 rounded-full bg-white/90 hover:bg-rose-50 text-rose-500 transition-colors backdrop-blur-sm"
                  >
                    <Heart className="w-5 h-5 fill-current" />
                  </button>
                </div>

                <div className="p-6">
                  <div className="flex justify-between items-start gap-4 mb-4">
                    <h3 className="text-xl font-bold text-gray-900 line-clamp-2">
                      {favorite.titre}
                    </h3>
                    <div className="flex items-center gap-1 text-lg font-bold text-gray-900">
                      <Euro className="w-5 h-5" />
                      {favorite.prix}/j
                    </div>
                  </div>

                  <div className="space-y-3 text-gray-600">
                    <div className="flex items-start gap-3">
                      <MapPin className="w-5 h-5 flex-shrink-0 mt-0.5" />
                      <span className="line-clamp-2">{favorite.adresse}</span>
                    </div>

                    <div className="flex items-center gap-3">
                      <Users className="w-5 h-5 flex-shrink-0" />
                      <span>Jusqu'à {favorite.capacite} personnes</span>
                    </div>
                  </div>

                  <div className="mt-6 pt-6 border-t border-gray-100">
                    <div className="flex gap-2">
                      {favorite.amenities?.wifi && (
                        <div className="w-8 h-8 rounded-lg bg-gray-50 flex items-center justify-center text-gray-600">
                          <Wifi className="w-4 h-4" />
                        </div>
                      )}
                      {favorite.amenities?.coffee && (
                        <div className="w-8 h-8 rounded-lg bg-gray-50 flex items-center justify-center text-gray-600">
                          <Coffee className="w-4 h-4" />
                        </div>
                      )}
                      {favorite.amenities?.monitors && (
                        <div className="w-8 h-8 rounded-lg bg-gray-50 flex items-center justify-center text-gray-600">
                          <Monitor className="w-4 h-4" />
                        </div>
                      )}
                      {favorite.amenities?.parking && (
                        <div className="w-8 h-8 rounded-lg bg-gray-50 flex items-center justify-center text-gray-600">
                          <CircleParking className="w-4 h-4" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Favorites;