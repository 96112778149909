import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { 
  Search, Calendar, Layout, MapPin, 
  Sliders, ChevronDown, X, ArrowRight,
  Users, Euro, Wifi, Coffee, Monitor,
  Printer, Clock, Sun
} from 'lucide-react';
import {motion, AnimatePresence} from 'framer-motion';

export default function SearchBar({
  searchValue,
  setSearchValue,
  suggestions,
  dateDebut,
  setDateDebut,
  dateFin,
  setDateFin,
  handleSearch,
  setShowFilters,
  showFilters,
  hasActiveFilters,
  setSuggestions,
  type,
  type_tarif,
  capacity,
  equipments
}) {
  const [isFocused, setIsFocused] = useState(false);

  const handleSuggestionClick = (suggestion) => {
    setSearchValue(suggestion.ville);
    setSuggestions([]);
    handleSearch();
  };

  
  // Helper function to disable unavailable and past dates
  const isDateDisabled = (date) => {
    const today = new Date();
    // Disable past dates
    if (date < today.setHours(0,0,0,0)) {
      return true;
    }
    
  };


  return (
    <div className="flex flex-col sm:flex-row gap-3">
      {/* Sélection des dates */}
      <div className="flex gap-3 flex-1">
        <div className="relative flex-1">
          <DatePicker
            selected={dateDebut}
            onChange={(date) => setDateDebut(date)}
            placeholderText="Date de début"
            filterDate={(date) => !isDateDisabled(date)}
            className="w-full h-14 pl-12 pr-4 rounded-xl border-2 border-gray-200 focus:border-black focus:ring-0 text-gray-900 bg-white transition-all"
          />
          <Calendar
            size={20}
            className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400"
          />
        </div>
        <div className="relative flex-1">
          <DatePicker
            selected={dateFin}
            onChange={(date) => setDateFin(date)}
            selectsEnd
            startDate={dateDebut}
            endDate={dateFin}
            minDate={dateDebut}
            disabled={!dateDebut}
            filterDate={(date) => !isDateDisabled(date)}
            className="w-full h-14 pl-12 pr-4 rounded-xl border-2 border-gray-200 focus:border-black focus:ring-0 text-gray-900 bg-white transition-all"
            placeholderText="Date de fin"
          />
          <Calendar
            size={20}
            className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400"
          />
        </div>
      </div>

      {/* Champ de recherche de ville */}
      <div className="relative flex-1">
        <input
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setTimeout(() => setIsFocused(false), 200)}
          placeholder="Rechercher une ville..."
          className="w-full h-14 pl-12 pr-4 rounded-xl border-2 border-gray-200 focus:border-black focus:ring-0 text-gray-900 bg-white transition-all"
        />
        <MapPin
          size={20}
          className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400"
        />

         {/* Suggestions */}
         <AnimatePresence>
            {isFocused && suggestions.length > 0 && (
              <motion.div
                initial={{ opacity: 0, y: 8 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 8 }}
                className="absolute left-0  right-0 mt-2 bg-white rounded-xl shadow-lg
                  border border-gray-200 overflow-hidden z-50"
              >
                {suggestions.map((suggestion, index) => (
                  <button
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="flex items-center gap-3 w-full px-4 py-3 text-left hover:bg-gray-50 
                      transition-colors group"
                  >
                    <MapPin size={18} className="text-gray-400" />
                    <span className="text-gray-900">{suggestion.ville}</span>
                    <ArrowRight 
                      size={18} 
                      className="ml-auto text-gray-400 opacity-0 group-hover:opacity-100 
                        transition-opacity"
                    />
                  </button>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
      </div>

      {/* Boutons d'action */}
      <div className="flex gap-2 mt-3 sm:mt-0">
        <button
          type="button"
          onClick={() => setShowFilters(!showFilters)}
          className="flex items-center gap-2 px-4 h-14 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-xl transition-colors relative"
        >
          <Sliders size={20} />
          <span className="hidden sm:block">Filtres</span>
          {hasActiveFilters() && (
            <span className="absolute -top-1 -right-1 w-5 h-5 bg-black text-white text-xs flex items-center justify-center rounded-full">
              {(type !== 0 ? 1 : 0) +
                (capacity !== 1 ? 1 : 0) +
                (equipments.length ? 1 : 0) +
                (type_tarif !== "day" ? 1 : 0)}
            </span>
          )}
        </button>
        <button
          onClick={handleSearch}
          className="px-8 h-14 bg-black text-white rounded-xl hover:bg-gray-900 transition-colors font-medium"
        >
          Rechercher
        </button>
      </div>
    </div>
  );
}