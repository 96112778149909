import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Rocket, 
  Sparkles,
  PartyPopper,
  Star,
  TrendingUp,
  Users,
  Trophy,
  Heart
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';

const GradientBadge = ({ icon: Icon, text }) => (
  <div className="relative group">
    <div className="absolute inset-0 bg-gradient-to-r from-blue-600/20 to-purple-600/20 rounded-full blur-xl 
                  opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
    <div className="relative flex items-center gap-2 px-4 py-2 bg-white/90 rounded-full shadow-lg">
      <Icon className="w-4 h-4 text-blue-600" />
      <span className="text-sm font-medium bg-gradient-to-r from-blue-600 to-purple-600 
                     text-transparent bg-clip-text">{text}</span>
    </div>
  </div>
);

const FloatingParticle = () => {
  const colors = ['bg-blue-500', 'bg-purple-500', 'bg-pink-500', 'bg-yellow-500'];
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  
  return (
    <div 
      className={`absolute ${randomColor} opacity-20 rounded-full animate-float-particle`}
      style={{
        width: `${20 + Math.random() * 30}px`,
        height: `${20 + Math.random() * 30}px`,
        left: `${Math.random() * 100}vw`,
        top: `${Math.random() * 100}vh`,
        animationDelay: `${Math.random() * 5}s`,
        animationDuration: `${15 + Math.random() * 15}s`
      }}
    />
  );
};

const AnimatedStat = ({ value, label, delay }) => (
  <div className="relative overflow-hidden">
    <div className="flex flex-col items-center p-6 bg-white/80 backdrop-blur-sm rounded-2xl
                  transform hover:scale-105 transition-all duration-500
                  border border-white/50 shadow-xl hover:shadow-2xl">
      <div className={`text-3xl font-bold text-transparent bg-clip-text 
                     bg-gradient-to-r from-blue-600 to-purple-600
                     animate-fade-in-up`}
           style={{ animationDelay: `${delay}ms` }}>
        {value}
      </div>
      <div className={`mt-2 text-sm text-gray-600 animate-fade-in-up`}
           style={{ animationDelay: `${delay + 200}ms` }}>
        {label}
      </div>
    </div>
  </div>
);

export default function FinalStepCongrats() {
  const navigate = useNavigate();
  const { userName } = useAuth();
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen overflow-hidden bg-gradient-to-br from-gray-900 to-blue-900 
                  text-white relative flex items-center justify-center px-4">
      {/* Animated Background */}
      <div className="absolute inset-0 overflow-hidden">
        {Array.from({ length: 30 }).map((_, i) => (
          <FloatingParticle key={i} />
        ))}
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900/80 to-transparent" />
      </div>

      {/* Success Glow Effect */}
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
                    w-[800px] h-[800px] bg-blue-500/10 rounded-full blur-3xl pulse-slow" />

      {/* Main Content Container */}
      <div className="relative max-w-6xl w-full mx-auto">
        {/* Top Floating Badge */}
        <div className="absolute -top-12 left-1/2 -translate-x-1/2 animate-float-slow">
          <GradientBadge icon={PartyPopper} text="Bienvenue à bord !" />
        </div>

        {/* Central Content */}
        <div className="text-center mb-16">
          <div className="inline-block mb-8">
            <div className="relative">
              <h1 className="text-6xl font-bold leading-tight">
                <span className="bg-gradient-to-r from-blue-400 to-purple-400 
                              text-transparent bg-clip-text">
                  Félicitations {userName} !
                </span>
              </h1>
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r 
                           from-blue-500 to-purple-500 opacity-20 blur-2xl -z-10" />
            </div>
          </div>

          <p className="text-xl text-blue-100 max-w-2xl mx-auto mb-12 leading-relaxed">
            Votre espace est maintenant en ligne sur HomeOfficeShare. 
            Commencez dès maintenant à accueillir des professionnels inspirants !
          </p>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-16">
          <AnimatedStat value="150+" label="Réservations mensuelles potentielles" delay={0} />
          <AnimatedStat value="85%" label="Taux d'occupation moyen" delay={200} />
          <AnimatedStat value="4.8/5" label="Note moyenne des espaces" delay={400} />
        </div>

        {/* CEO Message */}
        <div className="relative max-w-2xl mx-auto">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-600/20 to-purple-600/20 
                       blur-xl transform -rotate-1" />
          <div className="relative bg-white/10 backdrop-blur-md rounded-2xl p-8 
                       border border-white/20">
            <div className="flex items-start gap-6">
              <div className="p-3 bg-white/10 rounded-full">
                <Heart className="w-6 h-6" />
              </div>
              <div>
                <p className="text-lg text-blue-100 leading-relaxed mb-6">
                  "Nous sommes ravis de vous accueillir parmi nos hôtes. 
                  Ensemble, nous allons redéfinir l'avenir du travail flexible !"
                </p>
                <div className="flex items-center gap-4">
                  <div>
                    <div className="font-medium text-white">Carl Gauss</div>
                    <div className="text-sm text-blue-200">Co-Fondateur & CEO</div>
                  </div>
                  <div className="ml-auto flex gap-1">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="w-4 h-4 text-yellow-400 fill-yellow-400" />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CTA Button */}
        <div className="text-center mt-16">
          <button
            onClick={() => navigate('/hosting')}
            className="group relative inline-flex items-center gap-3 px-8 py-4 
                     bg-gradient-to-r from-blue-600 to-blue-500
                     rounded-full text-lg font-medium 
                     transform transition-all duration-300
                     hover:scale-105 hover:shadow-2xl hover:shadow-blue-500/20"
          >
            <div className="absolute inset-0 rounded-full bg-gradient-to-r 
                         from-blue-600/50 to-purple-600/50 blur-xl
                         opacity-0 group-hover:opacity-100 transition-opacity" />
            <Rocket className="w-5 h-5" />
            <span className="relative">Explorer mon espace</span>
            <span className="absolute right-0 w-12 h-full bg-gradient-to-r 
                          from-transparent to-white/10 
                          transform translate-x-12 skew-x-[-20deg]
                          group-hover:translate-x-[-12rem] transition-transform duration-1000" />
          </button>
        </div>
      </div>

      {/* Confetti Effect */}
      {showConfetti && (
        <div className="fixed inset-0 pointer-events-none">
          {Array.from({ length: 100 }).map((_, i) => (
            <div
              key={i}
              className="absolute animate-confetti"
              style={{
                left: `${Math.random() * 100}vw`,
                top: `-20px`,
                backgroundColor: ['#60A5FA', '#34D399', '#F472B6', '#FBBF24'][Math.floor(Math.random() * 4)],
                width: `${Math.random() * 10 + 5}px`,
                height: `${Math.random() * 10 + 5}px`,
                animationDelay: `${Math.random() * 3}s`,
                animationDuration: `${Math.random() * 2 + 2}s`
              }}
            />
          ))}
        </div>
      )}

      <style>{`
        @keyframes float-particle {
          0%, 100% { transform: translate(0, 0) rotate(0deg); }
          25% { transform: translate(20px, -20px) rotate(90deg); }
          50% { transform: translate(-20px, -40px) rotate(180deg); }
          75% { transform: translate(-40px, -20px) rotate(270deg); }
        }

        @keyframes confetti {
          0% { transform: translateY(0) rotateZ(0deg); opacity: 1; }
          100% { transform: translateY(100vh) rotateZ(720deg); opacity: 0; }
        }

        .animate-confetti {
          animation: confetti 3s ease-in-out forwards;
        }

        .pulse-slow {
          animation: pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }

        .animate-float-slow {
          animation: float 3s ease-in-out infinite;
        }

        @keyframes float {
          0%, 100% { transform: translateY(0) translateX(-50%); }
          50% { transform: translateY(-10px) translateX(-50%); }
        }

        @keyframes fade-in-up {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fade-in-up {
          animation: fade-in-up 0.6s ease-out forwards;
        }
      `}</style>
    </div>
  );
}