import React, { useEffect, useRef, useReducer } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, ArrowLeft, Plus, Send, X, Image, Paperclip, MessageSquare } from 'lucide-react';
import ImagePdf from '../../assets/pdf.png';
import { useAuth } from '@/context/AuthContext';
import useWebSocket from '@/hooks/useWebSocket';
import useMessageHandlers from '@/hooks/useMessageHandlers';
import useImageModal from '@/hooks/useImageModal';
import ConversationItem from '@/components/ConversationItem';
import MessageGroup from '@/components/MessageGroup';
import { fetchConversations, fetchMessageHistory } from '@/services/messageService';
import { reducer, initialState } from '@/reducers/messagesReducer';
import { useNotifications } from '../../context/NotificationContext';

const Messages = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { 
    selectedMessage, 
    messageInput, 
    searchQuery, 
    isMobileView, 
    messages, 
    previewFiles, 
    selectedImage, 
    conversations 
  } = state;
  
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const { userId, token, userName } = useAuth();

  const ws = useWebSocket({
    token,
    userId,
    setMessages: ({ conversationId, message }) => 
      dispatch({ type: 'ADD_MESSAGE', payload: { conversationId, message } }),
    conversations,
    setConversations: (newConversations) => 
      dispatch({ type: 'SET_CONVERSATIONS', payload: newConversations }),
    selectedMessage,
    messagesEndRef
  });

  const {
    handleSendMessage,
    handleFileChange,
  } = useMessageHandlers({
    messageInput,
    previewFiles,
    setMessageInput: (value) => dispatch({ type: 'SET_MESSAGE_INPUT', payload: value }),
    setPreviewFiles: (files) => dispatch({ type: 'SET_PREVIEW_FILES', payload: files }),
    fileInputRef,
    userId,
    userName,
    selectedMessage,
    ws,
    dispatch
  });

  const { handleImageClick, handleCloseImage } = useImageModal((image) => 
    dispatch({ type: 'SET_SELECTED_IMAGE', payload: image }));
  const { markPrivateMessagesAsRead } = useNotifications();

  // Effet pour la réactivité mobile
  useEffect(() => {
    const handleResize = () => {
      dispatch({ 
        type: 'SET_IS_MOBILE_VIEW', 
        payload: window.innerWidth < 768 
      });
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Scroll automatique
  useEffect(() => {
    if (messagesEndRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            entry.target.scrollIntoView({ behavior: 'smooth' });
          }
        },
        { threshold: 0.1 }
      );
      observer.observe(messagesEndRef.current);
      return () => observer.disconnect();
    }
  }, [messages, selectedMessage]);

  // Chargement des conversations
  useEffect(() => {
    const getConversations = async () => {
      try {
        const data = await fetchConversations(userId, token);
        if (data.success) {
          dispatch({ 
            type: 'SET_CONVERSATIONS', 
            payload: data.conversations.conversations 
          });
        }
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    };

    if (userId) getConversations();
  }, [userId, token]);

  // Chargement de l'historique des messages
  useEffect(() => {
    const getMessageHistory = async () => {
      try {
        const data = await fetchMessageHistory(userId, selectedMessage.id, token);
        dispatch({ 
          type: 'SET_MESSAGES', 
          payload: {
            ...messages,
            [selectedMessage.id]: data.messages
          } 
        });
      } catch (error) {
        console.error('Error fetching message history:', error);
      }
    };

    if (selectedMessage) getMessageHistory();
  }, [selectedMessage, userId, token]);

  const handleSelectConversation = (conversation) => {
    dispatch({ type: 'SET_SELECTED_MESSAGE', payload: conversation });
    dispatch({ type: 'MARK_MESSAGES_AS_READ', payload: conversation.id });
    markPrivateMessagesAsRead(conversation.id);
  };

  const filteredConversations = Array.isArray(conversations) ? 
    Object.values(conversations)
      .filter(conversation => 
        conversation.name?.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) 
    : [];

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Liste des conversations */}
      <AnimatePresence mode="wait">
        <motion.div
          initial={isMobileView ? { x: -300 } : false}
          animate={{ x: 0 }}
          exit={isMobileView ? { x: -300 } : false}
          className={`
            ${isMobileView && selectedMessage ? 'hidden' : 'flex flex-col'}
            w-full md:w-[380px] bg-white border-r border-gray-200 shadow-sm
          `}
        >
          <div className="p-4 border-b border-gray-200">
            <h1 className="text-xl font-semibold text-gray-900 mb-4">Messages</h1>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="text"
                placeholder="Rechercher une conversation..."
                value={searchQuery}
                onChange={(e) => dispatch({ 
                  type: 'SET_SEARCH_QUERY', 
                  payload: e.target.value 
                })}
                className="w-full pl-10 pr-4 py-2.5 bg-gray-50 rounded-xl border border-gray-200 
                         focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                         transition-all duration-200"
              />
            </div>
          </div>

          <div className="flex-1 overflow-y-auto">
            <AnimatePresence>
              {filteredConversations.map((conversation, index) => (
                <motion.div
                  key={conversation.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.05 }}
                >
                  <ConversationItem
                    conversation={conversation}
                    isSelected={selectedMessage?.id === conversation.id}
                    onClick={() => handleSelectConversation(conversation)}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </motion.div>
      </AnimatePresence>

      {/* Zone de conversation */}
      <AnimatePresence mode="wait">
        <motion.div
          initial={isMobileView ? { x: 300 } : false}
          animate={{ x: 0 }}
          exit={isMobileView ? { x: 300 } : false}
          className={`
            ${isMobileView && !selectedMessage ? 'hidden' : 'flex flex-col'}
            flex-1 h-screen bg-white
          `}
        >
          {selectedMessage ? (
            <>
              {/* Header */}
              <div className="px-4 py-3 border-b border-gray-200 flex items-center gap-3 bg-white">
                {isMobileView && (
                  <button
                    onClick={() => dispatch({ type: 'SET_SELECTED_MESSAGE', payload: null })}
                    className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                  >
                    <ArrowLeft className="w-5 h-5 text-gray-600" />
                  </button>
                )}
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 bg-gradient-to-br from-blue-500 to-blue-600 
                               rounded-full flex items-center justify-center shadow-sm">
                    <span className="text-white text-sm font-medium">
                      {selectedMessage.name?.charAt(0).toUpperCase()}
                    </span>
                  </div>
                  <div>
                    <h2 className="font-medium text-gray-900">
                      {selectedMessage.name}
                    </h2>
                    <p className="text-sm text-gray-500">
                      {selectedMessage.lastSeen ? 'En ligne' : 'Hors ligne'}
                    </p>
                  </div>
                </div>
              </div>

              {/* Messages */}
              <div className="flex-1 overflow-y-auto bg-gray-50">
                <div className="p-4">
                  {(messages[selectedMessage.id] || [])
                    .reduce((groups, message) => {
                      const lastGroup = groups[groups.length - 1];
                      if (!lastGroup || lastGroup[0].date !== message.date) {
                        groups.push([message]);
                      } else {
                        lastGroup.push(message);
                      }
                      return groups;
                    }, [])
                    .map((group, index) => (
                      <MessageGroup 
                        key={group[0]._id} 
                        messages={group} 
                        showDate={index === 0 || 
                          group[0].date !== (messages[selectedMessage.id] || [])[index - 1]?.date}
                        userId={userId}
                        handleImageClick={handleImageClick}
                      />
                    ))}
                  <div ref={messagesEndRef} />
                </div>
              </div>

              {/* Input zone */}
              <div className="border-t border-gray-200 bg-white p-4">
                {previewFiles.length > 0 && (
                  <div className="flex gap-2 mb-4 overflow-x-auto pb-2">
                    {previewFiles.map((file, index) => (
                      <div key={index} className="relative group">
                        <div className="w-20 h-20 rounded-lg overflow-hidden bg-gray-100 flex items-center justify-center">
                          {file.type.startsWith('image/') ? (
                            <img 
                              src={file.content} 
                              alt="Preview" 
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <img 
                              src={ImagePdf} 
                              alt="PDF" 
                              className="w-12 h-12"
                            />
                          )}
                        </div>
                        <button
                          onClick={() => dispatch({ 
                            type: 'REMOVE_PREVIEW_FILE', 
                            payload: index 
                          })}
                          className="absolute -top-2 -right-2 w-6 h-6 bg-red-500 text-white 
                                   rounded-full flex items-center justify-center shadow-sm
                                   opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                <div className="flex items-center gap-2">
                  <button
                    onClick={() => fileInputRef.current.click()}
                    className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 
                             rounded-full transition-colors"
                  >
                    <Plus className="w-6 h-6" />
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={handleFileChange}
                    multiple
                  />
                  
                  <div className="flex-1 flex items-center bg-gray-50 rounded-xl px-4 py-2">
                    <input
                      type="text"
                      value={messageInput}
                      onChange={(e) => dispatch({ 
                        type: 'SET_MESSAGE_INPUT', 
                        payload: e.target.value 
                      })}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey && 
                           (messageInput.trim() || previewFiles.length > 0)) {
                          e.preventDefault();
                          handleSendMessage();
                        }
                      }}
                      placeholder="Votre message..."
                      className="flex-1 bg-transparent text-gray-900 placeholder-gray-500 
                               focus:outline-none"
                    />
                    {(messageInput.trim() || previewFiles.length > 0) && (
                      <button
                        onClick={handleSendMessage}
                        className="ml-2 p-2 text-blue-600 hover:text-blue-700 
                                 rounded-full transition-colors"
                      >
                        <Send className="w-5 h-5" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="flex-1 flex items-center justify-center bg-gray-50">
              <div className="text-center">
                <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center 
                              justify-center mx-auto mb-4">
                  <MessageSquare className="w-8 h-8 text-blue-600" />
                </div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  Vos messages
                </h3>
                <p className="text-gray-500">
                  Sélectionnez une conversation pour commencer
                </p>
              </div>
            </div>
          )}
        </motion.div>
      </AnimatePresence>

      {/* Modal image */}
      <AnimatePresence>
        {selectedImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-4"
            onClick={handleCloseImage}
          >
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
              className="relative max-w-4xl w-full"
              onClick={e => e.stopPropagation()}
            >
              <img
                src={selectedImage}
                alt="Image agrandie"
                className="w-full h-auto rounded-lg shadow-xl"
              />
              <button
                onClick={handleCloseImage}
                className="absolute -top-4 -right-4 w-8 h-8 bg-white rounded-full 
                         flex items-center justify-center shadow-lg
                         hover:bg-gray-100 transition-colors"
              >
                <X className="w-5 h-5 text-gray-900" />
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Style personnalisé pour la barre de défilement
const scrollbarStyle = `
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

// Ajouter le style à la page
const style = document.createElement('style');
style.textContent = scrollbarStyle;
document.head.appendChild(style);

export default Messages;