import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  ArrowRight,
  HelpCircle,
  Type,
  AlertCircle,
  Star,
  Sparkles
} from 'lucide-react';
import { useForm } from '../../../context/FormHostContext';
import SaveQuitButton from '../../../components/SaveQuitButton';

const ExampleCard = ({ title, isActive, onClick }) => (
  <button
    onClick={onClick}
    className={`p-4 rounded-xl text-left transition-all duration-300 flex flex-col gap-2
                border ${isActive 
                  ? 'bg-blue-50 border-blue-200' 
                  : 'bg-white hover:bg-gray-50 border-gray-200'}`}
  >
    <div className="flex items-center gap-2">
      <Star className={`w-4 h-4 ${isActive ? 'text-blue-500' : 'text-gray-400'}`} />
      <span className="text-sm text-gray-500">Exemple</span>
    </div>
    <p className="text-base font-medium text-gray-900">{title}</p>
  </button>
);

const StepProgress = ({ currentStep, totalSteps }) => (
  <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                  bg-white rounded-full shadow-lg p-2 border border-gray-200">
    <div className="flex items-center gap-2 px-3 py-1">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`rounded-full transition-all duration-300 
                    ${index < currentStep ? 'w-8 h-2 bg-blue-500' : 'w-2 h-2 bg-gray-200'}`}
        />
      ))}
    </div>
  </div>
);

export default function StepTwoTitle() {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const maxLength = 50;
  const { handleChange } = useForm();

  const handleTitleChange = (value) => {
    setTitle(value);
    handleChange('titre', value);
  };

  const examples = [
    "Espace lumineux avec bureau et WiFi à Paris",
    "Bureau design et calme au cœur de la ville",
    "Espace de travail moderne tout équipé",
    "Studio professionnel avec vue panoramique"
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <header className="fixed top-0 inset-x-0 bg-white border-b border-gray-200 z-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex items-center justify-between">
          <div className="flex items-center gap-8">
            <button onClick={() => navigate('/become-host/stepTwoPhotos')}
                    className="text-gray-400 hover:text-gray-600 transition-colors">
              <ArrowLeft className="w-6 h-6" />
            </button>
            <h1 className="text-xl font-semibold text-gray-900">Titre de l'annonce</h1>
          </div>
          <SaveQuitButton />
        </div>

        <div className="max-w-7xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center gap-3 text-sm text-gray-500">
            <Type className="w-4 h-4" />
            <span>{title.length}/{maxLength} caractères</span>
          </div>
          <div className="h-1 flex-1 mx-6 bg-gray-100 rounded-full overflow-hidden">
            <div
              className="h-full bg-blue-500 rounded-full transition-all duration-300"
              style={{ width: `${Math.min((title.length / 10) * 100, 100)}%` }}
            />
          </div>
          <span className="text-sm text-gray-500">
            {title.length === 0 ? "Ajoutez un titre attractif" : "Titre en cours de création"}
          </span>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 pt-32 pb-24">
        {title.length === 0 ? (
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              Donnez un titre à votre espace
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Choisissez un titre court et percutant qui met en valeur les points forts de votre espace.
            </p>
          </div>
        ) : null}

        {/* Title Input and Examples */}
        <div className="grid md:grid-cols-2 gap-8">
          {/* Title Input */}
          <div>
            <div className="relative mb-6">
              <textarea
                value={title}
                onChange={(e) => handleTitleChange(e.target.value)}
                placeholder="Ex: Espace lumineux avec bureau et WiFi à Paris..."
                maxLength={maxLength}
                rows={3}
                className="w-full px-4 py-3 text-lg rounded-xl border-2 border-gray-200
                         focus:border-blue-500 focus:ring-4 focus:ring-blue-500/10
                         transition-all duration-300 resize-none"
              />
              <div className="mt-2 flex items-center justify-between text-sm">
                <div className="flex items-center gap-2 text-gray-500">
                  <AlertCircle className="w-4 h-4" />
                  <span>Maximum {maxLength} caractères</span>
                </div>
                <div className="text-blue-500">
                  {title.length}/{maxLength}
                </div>
              </div>
            </div>
          </div>

          {/* Examples */}
          <div>
            <div className="flex items-center gap-2 mb-4">
              <Sparkles className="w-5 h-5 text-blue-500" />
              <h3 className="font-medium text-gray-900">Exemples de titres efficaces</h3>
            </div>
            <div className="grid gap-3">
              {examples.map((example, index) => (
                <ExampleCard
                  key={index}
                  title={example}
                  isActive={title === example}
                  onClick={() => handleTitleChange(example)}
                />
              ))}
            </div>
          </div>
        </div>
      </main>

      <StepProgress currentStep={8} totalSteps={14} />

      {/* Footer */}
      <footer className="fixed bottom-0 inset-x-0 bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center gap-3 text-gray-500">
            <HelpCircle className="w-5 h-5" />
            <span>Des questions ?</span>
          </div>

          <button
            onClick={() => navigate('/become-host/steptwodescription')}
            disabled={!title.trim()}
            className={`px-8 py-3 rounded-xl flex items-center gap-2 transition-all duration-300
                     ${title.trim() 
                       ? 'bg-blue-600 text-white hover:bg-blue-500 hover:shadow-lg hover:shadow-blue-500/20' 
                       : 'bg-gray-100 text-gray-400 cursor-not-allowed'}`}
          >
            <span>Continuer</span>
            <ArrowRight className="w-5 h-5" />
          </button>
        </div>
      </footer>
    </div>
  );
}