import React, { useState, useEffect } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { motion, AnimatePresence } from 'framer-motion';
import 'react-datepicker/dist/react-datepicker.css';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { format, isWithinInterval, startOfDay, endOfDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import {
  FaBook,  FaCog,  FaStar,  FaRegCalendar,  FaClock,  FaUsers,  FaWifi,  FaPrint,  FaCoffee,  FaParking,  FaUniversalAccess,  FaSnowflake,  FaPhone,  FaShower,  FaRegHeart,  FaHeart,  FaTimes,  FaChevronLeft,  FaChevronRight,  FaMapMarkerAlt,  FaShare,  FaShield, FaShieldAlt,
  FaCouch, FaPlus, FaLaptop, FaVideo, FaCheck, FaInfoCircle
} from 'react-icons/fa';
import { X } from 'lucide-react';
import { useAuth } from '@/context/AuthContext';

// Composant Gallery
const Gallery = ({ photos }) => {
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  // Place the main image (is_main = true) in the first position
  const sortedPhotos = [...photos].sort((a, b) => b.is_main - a.is_main);

  return (
    <>
      {/* Grid Gallery */}
      <div className="grid grid-cols-4 gap-2 h-[70vh] md:gap-4 overflow-hidden">
        {/* Image principale */}
        <div 
          className="col-span-4 md:col-span-2 relative rounded-t-xl md:rounded-l-xl overflow-hidden cursor-pointer" 
          onClick={() => setShowModal(true)}
        >
          <img
            src={`data:image/jpeg;base64,${sortedPhotos[0]?.photo_base64}`}
            alt="Main"
            className="w-full h-full object-cover hover:scale-110 transition-transform duration-300"
          />
          <div className="absolute bottom-4 left-4 bg-white px-4 py-2 rounded-full text-sm font-medium shadow-lg md:hidden">
            Voir toutes les photos
          </div>
        </div>

        {/* Grid d'images secondaires */}
        <div className="hidden md:grid grid-cols-2 col-span-2 gap-4">
          {sortedPhotos.slice(1, 5).map((photo, index) => (
            <div 
              key={index} 
              className={`relative overflow-hidden cursor-pointer ${
                index === 3 ? 'rounded-tr-xl' : ''
              }`}
              onClick={() => {
                setActiveIndex(index + 1);
                setShowModal(true);
              }}
            >
              <img
                src={`data:image/jpeg;base64,${photo.photo_base64}`}
                alt={`Gallery ${index + 1}`}
                className="w-full h-full object-cover hover:scale-110 transition-transform duration-300"
              />
              {index === 3 && sortedPhotos.length > 5 && (
                <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                  <span className="text-white text-xl font-medium">
                    +{sortedPhotos.length - 5} photos
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Mobile Gallery Indicator */}
        <div className="absolute bottom-4 right-4 flex space-x-2 md:hidden">
          {sortedPhotos.slice(0, 5).map((_, index) => (
            <div
              key={index}
              className={`w-2 h-2 rounded-full ${
                activeIndex === index ? 'bg-white' : 'bg-white/50'
              }`}
            />
          ))}
        </div>
      </div>

      {/* Gallery Modal */}
      <AnimatePresence>
        {showModal && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 bg-black bg-opacity-90 p-4 md:p-8"
          >
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-white text-4xl z-50 hover:opacity-75 transition-opacity"
            >
              <FaTimes />
            </button>

            <div className="h-full flex items-center justify-center">
              <button
                onClick={() => setActiveIndex((prev) => (prev === 0 ? sortedPhotos.length - 1 : prev - 1))}
                className="absolute left-4 text-white p-4 rounded-full bg-black/50 hover:bg-black/75 transition-colors"
              >
                <FaChevronLeft className="text-xl" />
              </button>

              <motion.img
                key={activeIndex}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                src={`data:image/jpeg;base64,${sortedPhotos[activeIndex]?.photo_base64}`}
                alt={`Gallery ${activeIndex + 1}`}
                className="max-h-full max-w-full object-contain"
              />

              <button
                onClick={() => setActiveIndex((prev) => (prev === sortedPhotos.length - 1 ? 0 : prev + 1))}
                className="absolute right-4 text-white p-4 rounded-full bg-black/50 hover:bg-black/75 transition-colors"
              >
                <FaChevronRight className="text-xl" />
              </button>

              {/* Thumbnails */}
              <div className="absolute bottom-4 left-0 right-0">
                <div className="flex justify-center space-x-2 overflow-x-auto py-2 px-4">
                  {sortedPhotos.map((photo, index) => (
                    <motion.div
                      key={index}
                      whileHover={{ scale: 1.05 }}
                      onClick={() => setActiveIndex(index)}
                      className={`w-20 h-20 flex-shrink-0 cursor-pointer rounded-lg overflow-hidden ${
                        activeIndex === index ? 'ring-2 ring-white' : 'opacity-50 hover:opacity-100'
                      }`}
                    >
                      <img
                        src={`data:image/jpeg;base64,${photo.photo_base64}`}
                        alt={`Thumbnail ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

// Composant BookingCard
const BookingCard = ({ space, className, unavailableDates }) => {
  const [bookingType, setBookingType] = useState('day'); // 'day' ou 'hour'
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState('09:00');
  const [endTime, setEndTime] = useState('18:00');
  const [guests, setGuests] = useState(1);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const {isAuthenticated } = useAuth();
  const navigate = useNavigate();
  
  const calculatePrice = () => {
    if (!startDate) return { daily: 0, service: 0, tax: 0, total: 0 };

    let subtotal = 0;
    if (bookingType === 'day') {
      const days = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
      const dailyRate = space.tarifications?.find(t => t.type === 'day')?.prix || 0;
      subtotal = dailyRate * days;
    } else {
      const hourlyRate = space.tarifications?.find(t => t.type === 'hour')?.prix || 0;
      const hours = parseInt(endTime) - parseInt(startTime);
      subtotal = hourlyRate * hours;
    }

    const serviceFee = subtotal * 0.15;
    const tax = subtotal * 0.1;
    
    return {
      daily: subtotal,
      service: serviceFee,
      tax: tax,
      total: subtotal + serviceFee + tax
    };
  };

  const prices = calculatePrice();


  const handleReservationJourney = async () => {

    if (!isAuthenticated) {
      setShowLoginPrompt(true);
    } else {

      setIsAlertOpen(true)
  
      const params = {
        espace_id: space.espace_id,
        date_debut: format(startDate, 'yyyy-MM-dd'),
        date_fin: format(endDate, 'yyyy-MM-dd'),
        heure_debut: startTime,
        heure_fin: endTime,
        guests: guests,
        type_tarif: bookingType,
        description: "Réservation de l'espace : " + space.titre,
      }
    
      fetch(`${process.env.REACT_APP_API_GATEWAY}/api/reservation/payment`, {
  
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include', 
        body: JSON.stringify(params)
  
  
      }).then((response) => response.json())
      .then((data) => {
        console.log(data);
        if(data.success === false) {
          setIsAlertOpen(false);
          alert('Veuillez remplir tous les champs requis');
          return;
        }
  
        window.location.href = data.url;
      })
      .catch((error) => {
        console.error('Erreur lors de la création de la session de paiement :', error);
      });
    }


  };

  const handleClosePrompt = () => {
    setShowLoginPrompt(false);
  };

  const handleRedirectToLogin = () => {
    navigate('/login', { state: { redirectTo: '/become-host/step1' } });
  };

  // Helper function to disable unavailable and past dates
  const isDateDisabled = (date) => {
    const today = new Date();
    // Disable past dates
    if (date < today.setHours(0,0,0,0)) {
      return true;
    }
    // Disable unavailable dates using isWithinInterval with startOfDay and endOfDay
    return unavailableDates.some(({ date_debut, date_fin }) => {
      const start = startOfDay(new Date(date_debut));
      const end = endOfDay(new Date(date_fin));
      return isWithinInterval(date, { start, end });
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`bg-white rounded-2xl shadow-xl p-6 ${className}`}
    >
      {/* Type de réservation */}
      <div className="flex p-1 bg-gray-100 rounded-xl mb-6">
        <button
          onClick={() => setBookingType("day")}
          className={`flex-1 py-3 px-4 rounded-lg font-medium transition-all ${
            bookingType === "day"
              ? "bg-white text-blue-600 shadow-sm"
              : "text-gray-600 hover:text-gray-900"
          }`}
        >
          <FaRegCalendar className="inline-block mr-2" />
          Par jour
        </button>
        <button
          onClick={() => setBookingType("hour")}
          className={`flex-1 py-3 px-4 rounded-lg font-medium transition-all ${
            bookingType === "hour"
              ? "bg-white text-blue-600 shadow-sm"
              : "text-gray-600 hover:text-gray-900"
          }`}
        >
          <FaClock className="inline-block mr-2" />
          Par heure
        </button>
      </div>

      {/* Prix */}
      <div className="mb-6">
        <div className="text-3xl font-bold text-gray-900">
          {bookingType === "day"
            ? `${
                space.tarifications?.find((t) => t.type === "day")?.prix || 0
              }€`
            : `${
                space.tarifications?.find((t) => t.type === "hour")?.prix || 0
              }€`}
          <span className="text-lg font-normal text-gray-500">
            {bookingType === "day" ? " / jour" : " / heure"}
          </span>
        </div>
      </div>

      {/* Sélection des dates */}
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            {bookingType === "day" ? "Dates de réservation" : "Date"}
          </label>
          <div
            className={bookingType === "day" ? "grid grid-cols-2 gap-2" : ""}
          >
            <DatePicker
              selected={startDate}
              onChange={setStartDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={new Date()}
              filterDate={(date) => !isDateDisabled(date)}
              className="w-full p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholderText={
                bookingType === "day"
                  ? "Date de début"
                  : "Sélectionner une date"
              }
              dateFormat="yyyy-MM-dd"
              locale={fr}
            />
            {bookingType === "day" && (
              <DatePicker
                selected={endDate}
                onChange={setEndDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                disabled={!startDate}
                filterDate={(date) => !isDateDisabled(date)}
                className="w-full p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholderText="Date de fin"
                dateFormat="yyyy-MM-dd"
                locale={fr}
              />
            )}
          </div>
        </div>

        {/* Sélection des horaires */}
        {bookingType === "hour" && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Horaires
            </label>
            <div className="grid grid-cols-2 gap-2">
              <select
                className="w-full p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              >
                {Array.from({ length: 24 }, (_, i) => (
                  <option key={i} value={`${String(i).padStart(2, "0")}:00`}>
                    {`${String(i).padStart(2, "0")}:00`}
                  </option>
                ))}
              </select>
              <select
                className="w-full p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              >
                {Array.from({ length: 24 }, (_, i) => (
                  <option key={i} value={`${String(i).padStart(2, "0")}:00`}>
                    {`${String(i).padStart(2, "0")}:00`}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        {/* Sélection du nombre de guests */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Nombre de guests
          </label>
          <select
            className="w-full p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={guests}
            onChange={(e) => setGuests(parseInt(e.target.value))}
          >
            {Array.from({ length: space.capacite_max }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1} {i + 1 === 1 ? "guest" : "guests"}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Détail des prix */}
      {prices.total > 0 && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          className="mt-6 border-t border-gray-200 pt-4 space-y-3"
        >
          <div className="flex items-center justify-between text-sm">
            <span className="text-gray-600">Prix de base</span>
            <span className="font-medium">{prices.daily.toFixed(2)}€</span>
          </div>
          <div className="flex items-center justify-between text-sm">
            <span className="text-gray-600">Frais de service</span>
            <span className="font-medium">{prices.service.toFixed(2)}€</span>
          </div>
          <div className="flex items-center justify-between text-sm">
            <span className="text-gray-600">Taxes</span>
            <span className="font-medium">{prices.tax.toFixed(2)}€</span>
          </div>
          <div className="pt-3 border-t border-gray-200">
            <div className="flex items-center justify-between">
              <span className="text-base font-semibold">Total</span>
              <span className="text-lg font-bold text-blue-600">
                {prices.total.toFixed(2)}€
              </span>
            </div>
          </div>
        </motion.div>
      )}

      {/* Bouton de réservation */}
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="w-full mt-6 bg-blue-600 text-white py-4 px-6 rounded-xl font-semibold 
                  hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 
                  focus:ring-blue-500 focus:ring-offset-2"
        onClick={handleReservationJourney}
      >
        Réserver maintenant
      </motion.button>

      {showLoginPrompt && (
        <div
          className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 
                 flex items-center justify-center p-4"
        >
          <div className="bg-white rounded-2xl p-8 max-w-sm w-full relative shadow-xl">
            <button
              onClick={handleClosePrompt}
              className="absolute top-4 right-4 p-2 text-gray-500 hover:bg-gray-100 
                   rounded-full transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
            <h2 className="text-2xl font-bold mb-4">Connexion requise</h2>
            <p className="text-gray-600 mb-6">
              Veuillez vous connecter pour commencer votre aventure d'hôte.
            </p>
            <div className="flex flex-col-reverse sm:flex-row gap-4 justify-end">
              <button
                onClick={handleClosePrompt}
                className="px-6 py-3 font-semibold text-gray-700 border-2 border-gray-300 
              hover:border-gray-400 hover:bg-gray-50 hover:scale-105
              rounded-xl transition-all duration-300"
              >
                Annuler
              </button>
              <button
                onClick={handleRedirectToLogin}
                className="px-6 py-3 font-semibold text-white rounded-xl
              bg-gradient-to-r from-blue-500 to-purple-600 
              hover:from-blue-600 hover:to-purple-700
              shadow-lg hover:shadow-purple-500/25 
              hover:scale-110 transition-all duration-300"
              >
                Se connecter
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Infos additionnelles */}
      <div className="mt-4 p-4 bg-gray-50 rounded-xl">
        <div className="space-y-2">
          <div className="flex items-center text-sm text-gray-600">
            <FaClock className="mr-2 text-gray-400" />
            <p>Réponse rapide - En moyenne sous 1h</p>
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <FaShieldAlt className="mr-2 text-gray-400" />
            <p>Paiement sécurisé via notre plateforme</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const LoadingSkeleton = () => {
  return (
    <div className="animate-pulse">
      {/* Gallery Skeleton */}
      <div className="aspect-[16/9] bg-gray-200 rounded-2xl mb-8" />

      {/* Content Skeleton */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2">
          {/* Title and Location */}
          <div className="mb-6">
            <div className="h-8 bg-gray-200 rounded-lg mb-4 w-3/4" />
            <div className="h-4 bg-gray-200 rounded w-1/2" />
          </div>

          {/* Tabs Skeleton */}
          <div className="flex space-x-4 mb-6">
            {[1, 2, 3, 4].map((i) => (
              <div key={i} className="h-10 w-24 bg-gray-200 rounded-lg" />
            ))}
          </div>

          {/* Content Area */}
          <div className="bg-gray-200 rounded-2xl p-6 h-96" />
        </div>

        {/* Booking Card Skeleton */}
        <div className="lg:col-span-1">
          <div className="bg-gray-200 rounded-2xl p-6 h-[32rem]">
            <div className="space-y-4">
              <div className="h-8 bg-gray-300 rounded w-1/2" />
              <div className="h-12 bg-gray-300 rounded" />
              <div className="h-12 bg-gray-300 rounded" />
              <div className="h-12 bg-gray-300 rounded" />
              <div className="h-12 bg-gray-300 rounded-lg mt-8" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Ajouter cette fonction helper au début du composant SpaceDetails
const renderStarRating = (rating) => {
  const stars = [];
  const roundedRating = Math.round(rating);

  for (let i = 0; i < 5; i++) {
    stars.push(
      <motion.span
        key={i}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: i * 0.1 }}
        className={`text-xl ${i < roundedRating ? 'text-yellow-400' : 'text-gray-300'}`}
      >
        ★
      </motion.span>
    );
  }

  return (
    <div className="flex items-center">
      {stars}
      <span className="ml-2 text-sm text-gray-600">
        {rating?.toFixed(1) || '0.0'}
      </span>
    </div>
  );
};

// Composant DescriptionTab
const DescriptionTab = ({ space }) => {
  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-2xl font-semibold mb-4">À propos de cet espace</h2>
        <p className="text-gray-600 leading-relaxed">{space.description}</p>
      </div>
      
      <div className="border-t pt-6">
        <h3 className="text-xl font-semibold mb-4">Votre hôte</h3>
        <div className="flex items-start space-x-4">
          <img
            src={space.image_profil || '/api/placeholder/50/50'}
            alt={space.nom}
            className="w-16 h-16 rounded-full object-cover"
          />
          <div>
            <h4 className="font-semibold">{space.prenom}</h4>
            <p className="text-gray-500 text-sm mb-2">
              Membre depuis {new Date(space.date_debut_hote).toLocaleDateString()}
            </p>
            <p className="text-gray-600">{space.description_hote}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const AmenitiesTab = ({ equipements }) => {
  const categoryMeta = {
    basic_equipment: {
      icon: FaWifi,
      label: "Équipements essentiels",
      color: "blue"
    },
    safety_equipment: {
      icon: FaShieldAlt,
      label: "Sécurité",
      color: "green"
    },
    comfort_equipment: {
      icon: FaCouch,
      label: "Confort",
      color: "purple"
    },
    productivity_equipment: {
      icon: FaLaptop,
      label: "Productivité",
      color: "indigo"
    },
    specialized_equipment: {
      icon: FaCog,
      label: "Équipements spécialisés",
      color: "orange"
    },
    video_conferencing_equipment: {
      icon: FaVideo,
      label: "Visioconférence",
      color: "pink"
    },
    additional_equipment: {
      icon: FaPlus,
      label: "Extras",
      color: "teal"
    }
  };

  const colorClasses = {
    blue: "bg-blue-50 text-blue-600 border-blue-200",
    green: "bg-green-50 text-green-600 border-green-200",
    purple: "bg-purple-50 text-purple-600 border-purple-200",
    indigo: "bg-indigo-50 text-indigo-600 border-indigo-200",
    orange: "bg-orange-50 text-orange-600 border-orange-200",
    pink: "bg-pink-50 text-pink-600 border-pink-200",
    teal: "bg-teal-50 text-teal-600 border-teal-200"
  };

    // Dans le cas où aucun équipement n'est listé
    if (!equipements) {
      return (
        <div className="p-6 bg-gray-50 rounded-2xl text-center">
          <FaCog className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <p className="text-gray-600">Aucun équipement n'est listé pour cet espace.</p>
        </div>
      );
    }

  return (
    
    <div className="space-y-8">
      <div className="flex items-center gap-4 mb-8">
        <h2 className="text-2xl font-bold text-gray-900">Équipements disponibles</h2>
        <div className="flex items-center gap-2 text-sm py-1 px-3 bg-green-50 text-green-700 rounded-full">
          <FaCheck className="text-green-500" />
          <span className="font-medium">Vérifiés</span>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {Object.entries(equipements || {}).map(([category, items]) => {
          const itemsArray = Array.isArray(items) ? items : [];
          const meta = categoryMeta[category] || {};
          const Icon = meta.icon || FaCog;
          const colorClass = colorClasses[meta.color] || colorClasses.blue;

          if (itemsArray.length === 0) return null;

          return (
            <motion.div
              key={category}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="relative group"
            >
              <div className={`absolute inset-0 rounded-2xl transition-transform duration-300 ${colorClass.replace('text-', 'bg-').replace('600', '100')} opacity-0 group-hover:opacity-100`} />
              
              <div className="relative p-6 bg-white rounded-2xl border border-gray-100 hover:border-transparent transition-colors duration-300 shadow-sm hover:shadow-md">
                <div className="flex items-center gap-4 mb-6">
                  <div className={`p-3 rounded-xl ${colorClass}`}>
                    <Icon className="w-6 h-6" />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900">
                    {meta.label || category}
                  </h3>
                </div>

                <div className="space-y-3">
                  {itemsArray.map((item, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="group/item flex items-center gap-3 p-2 rounded-lg hover:bg-gray-50 transition-colors"
                    >
                      <motion.div
                        initial={{ scale: 0.8 }}
                        animate={{ scale: 1 }}
                        transition={{ delay: index * 0.1 }}
                        className={`w-2 h-2 rounded-full ${colorClass.replace('bg-', 'bg-').replace('50', '400')}`}
                      />
                      <span className="text-gray-600 group-hover/item:text-gray-900 transition-colors">
                        {item}
                      </span>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>

      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="p-6 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-2xl mt-8"
      >
        <div className="flex items-center gap-4">
          <div className="p-3 bg-white rounded-xl shadow-sm">
            <FaInfoCircle className="w-6 h-6 text-blue-600" />
          </div>
          <div>
            <p className="text-sm text-gray-600">
              Tous nos espaces sont équipés avec du matériel professionnel régulièrement entretenu et désinfecté.
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

// Composant ReviewsTab
const ReviewsTab = ({ reviews = [] }) => {
  const averageRating = reviews.reduce((acc, rev) => acc + rev.note, 0) / reviews.length || 0;

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-semibold">Avis des guests ({reviews.length})</h2>
        <div className="flex items-center gap-2">
          <div className="text-yellow-400 text-2xl">★</div>
          <span className="font-semibold">{averageRating.toFixed(1)}</span>
        </div>
      </div>

      <div className="space-y-6">
        {reviews.map((review, idx) => (
          <div key={idx} className="border-b last:border-0 pb-6">
            <div className="flex items-start space-x-4">
              <div className="bg-blue-100 rounded-full w-12 h-12 flex items-center justify-center text-blue-600 font-semibold">
                {review.utilisateur_name.charAt(0)}
              </div>
              <div className="flex-1">
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="font-semibold">{review.utilisateur_name}</h4>
                    <p className="text-gray-500 text-sm">
                      {new Date(review.date_creation).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="flex items-center">
                    {renderStarRating(review.note)}
                  </div>
                </div>
                <p className="mt-2 text-gray-600">{review.commentaire}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Composant LocationTab
const LocationTab = ({ space }) => {
  return (
    <div>
      <h2 className="text-2xl font-semibold mb-6">Localisation</h2>
      <div className="rounded-xl overflow-hidden shadow-lg h-96 mb-4">
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API}>
          <GoogleMap
            mapContainerStyle={{ height: "100%", width: "100%" }}
            center={{
              lat: parseFloat(space.latitude),
              lng: parseFloat(space.longitude)
            }}
            zoom={15}
          >
            <Marker
              position={{
                lat: parseFloat(space.latitude),
                lng: parseFloat(space.longitude)
              }}
            />
          </GoogleMap>
        </LoadScript>
      </div>
      <div className="bg-gray-50 p-4 rounded-xl">
        <p className="flex items-center text-gray-700">
          <FaMapMarkerAlt className="text-blue-500 mr-2" />
          {space.ville}, {space.pays}
        </p>
      </div>
    </div>
  );
};

const SpaceDetails = () => {
  const { id } = useParams();
  const [space, setSpace] = useState(null);
  const [activeTab, setActiveTab] = useState('description');
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [unavailableDates, setUnavailableDates] = useState([]);

  useEffect(() => {
    const fetchSpaceDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/api/workspace/details/${id}`);
        const data = await response.json();
        setSpace(data[0]);
        if (data[0].equipements) {
          setSelectedAmenities(data[0].equipements);
        }
        setUnavailableDates(data[0].unavailableDates || []);
      } catch (error) {
        console.error('Error fetching space details:', error);
      }
    };

    if (id) fetchSpaceDetails();
  }, [id]);

  const tabs = [
    { id: 'description', label: 'Description', icon: FaBook },
    { id: 'amenities', label: 'Équipements', icon: FaCog },
    { id: 'reviews', label: 'Avis', icon: FaStar },
    { id: 'location', label: 'Localisation', icon: FaMapMarkerAlt }
  ];

  if (!space) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="w-full max-w-7xl mx-auto px-4">
          <LoadingSkeleton />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Galerie */}
      <div className="relative">
        <Gallery photos={space.photos} />
      </div>

      {/* Contenu principal */}
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Colonne principale */}
          <div className="lg:col-span-2 space-y-8">
            {/* En-tête */}
            <div>
              <h1 className="text-3xl font-bold text-gray-900 mb-2">{space.titre}</h1>
              <div className="flex items-center flex-wrap gap-4 text-gray-600">
                <span className="flex items-center">
                  <FaMapMarkerAlt className="mr-1" />
                  {space.ville}
                </span>
                <span>•</span>
                <div className="flex items-center">
                  {renderStarRating(space.avis?.reduce((acc, rev) => acc + rev.note, 0) / space.avis?.length || 0)}
                  <span className="ml-2">({space.avis?.length || 0} avis)</span>
                </div>
              </div>
            </div>

            {/* Navigation des onglets */}
            <nav className="flex space-x-1 bg-gray-100 p-1 rounded-xl overflow-x-scroll">
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`flex-1 flex items-center justify-center gap-2 py-3 px-4 rounded-lg font-medium transition-all ${
                    activeTab === tab.id
                      ? 'bg-white text-blue-600 shadow-sm'
                      : 'text-gray-600 hover:text-gray-900'
                  }`}
                >
                  <tab.icon className="text-sm" />
                  {tab.label}
                </button>
              ))}
            </nav>

            {/* Contenu des onglets */}
            <div className="bg-white rounded-2xl shadow-sm p-6">
              {/* Rendu conditionnel du contenu des onglets */}
              {activeTab === 'description' && <DescriptionTab space={space} />}
              {activeTab === 'amenities' && <AmenitiesTab equipements={space.equipements} />}
              {activeTab === 'reviews' && <ReviewsTab reviews={space.avis} />}
              {activeTab === 'location' && <LocationTab space={space} />}
            </div>
          </div>

          {/* Carte de réservation */}
          <div className="lg:col-span-1">
            <BookingCard 
              space={space} 
              className="sticky top-8"
              unavailableDates={unavailableDates}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpaceDetails;