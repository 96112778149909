
import React from 'react';

const ConversationItem = ({ conversation, isSelected, onClick }) => (
  <div 
    onClick={onClick}
    className={`px-6 py-4 cursor-pointer hover:bg-gray-50 ${isSelected ? 'bg-gray-50' : ''} border-b border-gray-200`}>
    <div className="flex gap-4">
      <div className="w-10 h-10 bg-gray-900 rounded-full flex items-center justify-center flex-shrink-0">
        <span className="text-white text-sm uppercase">{conversation.name?.charAt(0)}</span>
      </div>
      
      <div className="flex-1">
        <div className="flex justify-between items-start mb-1">
          <div>
            <h3 className="text-[15px] font-semibold text-gray-900">{conversation.name}</h3>
            {conversation.hasTranslation && <div className="text-xs text-gray-500 flex items-center gap-1.5">🌐 Traduction activée</div>}
          </div>
        </div>
        <p className="text-[15px] text-gray-600">
          {conversation.lastMessage.startsWith('data:') ? 'Fichier envoyé' : conversation.lastMessage}
        </p>
      </div>
    </div>
  </div>
);

export default ConversationItem;