import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { motion, AnimatePresence, useMotionValue, useTransform, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer'; // Ajout de useInView
import { useNavigate } from 'react-router-dom';
import { format, addDays, set } from 'date-fns';
import { fr } from 'date-fns/locale';
import { 
  FaSearch, FaHome, FaMapMarkerAlt, FaHeart, FaRegHeart,
  FaStar, FaRegCalendarAlt, FaUsers, FaWifi, FaCoffee, FaParking, FaPrint, FaAccessibleIcon,
  FaRegClock, FaFilter, FaChevronDown, FaList, FaThLarge, FaCompass, FaBookmark, FaCreditCard,
  FaBell, FaHistory, FaArrowRight, FaMoon, FaSun,
  FaCalendarWeek,FaArrowUp, FaArrowDown, FaShieldAlt, FaPlus, FaCog,
  FaVideo, FaCheck, FaBuilding, FaHandshake, FaLaptopHouse, FaUmbrella,
  FaWarehouse, FaDoorClosed, FaUserFriends, FaSeedling, FaYinYang
} from 'react-icons/fa';
import { 
  Search, Calendar, Layout, MapPin, 
  Sliders, ChevronDown, X, ArrowRight,
  Users, Euro, Wifi, Coffee, Monitor,
  Printer, Clock, Sun
} from 'lucide-react';
import "react-datepicker/dist/react-datepicker.css";

import HostDashboard from './Host/HostDashboard';
import SearchBar from '../components/SearchConnected';

const ViewToggle = ({ viewMode, setViewMode }) => {
  return (
    <div className="flex items-center gap-2 bg-white rounded-xl p-1 shadow-sm">
      <motion.button
        whileTap={{ scale: 0.95 }}
        onClick={() => setViewMode('grid')}
        className={`p-2 rounded-lg transition-colors ${
          viewMode === 'grid' 
            ? 'bg-blue-50 text-blue-600' 
            : 'text-gray-400 hover:text-gray-600'
        }`}
      >
        <FaThLarge className="w-5 h-5" />
      </motion.button>
      
    </div>
  );
};

const MapView = ({ spaces, showMap }) => {
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const center = { lat: 48.8566, lng: 2.3522 }; // Paris par défaut
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 500); // Adjust the timeout duration as needed
    return () => clearTimeout(timer);
  }, [spaces]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="w-full h-[calc(100vh-200px)] rounded-2xl overflow-hidden shadow-lg relative"
    >
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          zoom={13}
          center={center}
        >
          {spaces.map(space => (
            <Marker
              key={space.espace_id}
              position={{
                lat: parseFloat(space.latitude) || center.lat,
                lng: parseFloat(space.longitude) || center.lng
              }}
              onClick={() => setSelectedSpace(space)}
            />
          ))}

          {selectedSpace && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedSpace.latitude),
                lng: parseFloat(selectedSpace.longitude)
              }}
              onCloseClick={() => setSelectedSpace(null)}
            >
              <div className="p-2">
                <div className="relative h-32 mb-2">
                  <img
                    src={selectedSpace.photos?.[0]?.photo_base64 
                      ? `data:image/jpeg;base64,${selectedSpace.photos[0].photo_base64}`
                      : '/api/placeholder/400/300'}
                    alt={selectedSpace.titre}
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
                <h3 className="font-medium text-gray-900">{selectedSpace.titre}</h3>
                <p className="text-sm text-gray-500 mb-2">{selectedSpace.ville}</p>
                <div className="flex items-center justify-between">
                  <span className="text-blue-600 font-medium">
                    {selectedSpace.prix || 0}€/jour
                  </span>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="px-3 py-1 bg-blue-600 text-white text-sm rounded-lg"
                    onClick={() => navigate(`/espace/${selectedSpace.espace_id}`)}
                  >
                    Voir détails
                  </motion.button>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>

      {/* Liste des espaces sur le côté */}
      <div className="absolute top-4 right-4 w-80 max-h-[calc(100%-2rem)] overflow-y-auto bg-white rounded-xl shadow-lg">
        {spaces.map(space => (
          <motion.div
            key={space.espace_id}
            whileHover={{ scale: 1.02 }}
            className={`p-4 cursor-pointer border-b last:border-b-0 transition-colors ${
              selectedSpace?.espace_id === space.espace_id ? 'bg-blue-50' : 'hover:bg-gray-50'
            }`}
            onClick={() => {
              setSelectedSpace(space);
            }}
          >
            <div className="flex gap-3">
              <img
                src={space.photos?.[0]?.photo_base64 
                  ? `data:image/jpeg;base64,${space.photos[0].photo_base64}`
                  :  `data:image/jpeg;base64,${space.photo_base64}`}
                alt={space.titre}
                className="w-20 h-20 object-cover rounded-lg"
              />
              <div>
                <h3 className="font-medium text-gray-900 line-clamp-1">{space.titre}</h3>
                <p className="text-sm text-gray-500">{space.ville}</p>
                <p className="text-blue-600 font-medium mt-1">{space.prix || 0}€/jour</p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

// Composant MapToggle
const MapToggle = ({ showMap, setShowMap }) => {
  return (
    <motion.button
      whileTap={{ scale: 0.95 }}
      onClick={() => setShowMap(!showMap)}
      className={`flex items-center gap-2 px-4 py-2 rounded-xl ${
        showMap 
          ? 'bg-blue-50 text-blue-600 shadow-lg' 
          : 'bg-white text-gray-600 hover:text-gray-900 hover:bg-gray-50'
      }`}
    >
      <FaMapMarkerAlt className="w-5 h-5" />
      <span>{showMap ? 'Fermer la carte' : 'Voir la carte'}</span>
    </motion.button>
  );
};
// Composant de carte interactive avec vue 3D
const Interactive3DCard = ({ children }) => {
  const cardRef = useRef(null);
  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);

  const handleMouseMove = (e) => {
    if (!cardRef.current) return;
    const card = cardRef.current;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    const rotateXVal = ((y - centerY) / centerY) * 10;
    const rotateYVal = ((x - centerX) / centerX) * 10;
    setRotateX(-rotateXVal);
    setRotateY(rotateYVal);
  };

  return (
    <motion.div
      ref={cardRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={() => {
        setRotateX(0);
        setRotateY(0);
      }}
      style={{
        perspective: '1000px',
        transformStyle: 'preserve-3d'
      }}
      animate={{
        rotateX,
        rotateY
      }}
      className="relative cursor-pointer"
    >
      {children}
    </motion.div>
  );
};

const PriceRangeSlider = ({ min, max, value, onChange }) => {
  const [minVal, setMinVal] = useState(value.min);
  const [maxVal, setMaxVal] = useState(value.max);

  useEffect(() => {
    setMinVal(value.min);
    setMaxVal(value.max);
  }, [value]);

  const handleMinChange = (e) => {
    const newMin = Math.min(Number(e.target.value), maxVal - 1);
    setMinVal(newMin);
    onChange({ min: newMin, max: maxVal });
  };

  const handleMaxChange = (e) => {
    const newMax = Math.max(Number(e.target.value), minVal + 1);
    setMaxVal(newMax);
    onChange({ min: minVal, max: newMax });
  };

  // Calculer les pourcentages pour le gradient
  const minPos = ((minVal - min) / (max - min)) * 100;
  const maxPos = ((maxVal - min) / (max - min)) * 100;

  return (
    <div className="relative pt-5 pb-8 px-2">
      {/* Prix min/max affichés */}
      <div className="flex justify-between mb-4">
        <span className="text-sm text-gray-600">{minVal}€</span>
        <span className="text-sm text-gray-600">{maxVal}€</span>
      </div>

      {/* Conteneur des sliders */}
      <div className="relative h-1">
        {/* Track en arrière-plan */}
        <div className="absolute w-full h-1 bg-gray-200 rounded"></div>
        
        {/* Track actif */}
        <div 
          className="absolute h-1 bg-black rounded"
          style={{ 
            left: `${minPos}%`, 
            width: `${maxPos - minPos}%` 
          }}
        ></div>

        {/* Sliders */}
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          onChange={handleMinChange}
          className="range-slider absolute w-full pointer-events-none appearance-none bg-transparent"
          style={{ height: '20px', marginTop: '-10px' }}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          onChange={handleMaxChange}
          className="range-slider absolute w-full pointer-events-none appearance-none bg-transparent"
          style={{ height: '20px', marginTop: '-10px' }}
        />
      </div>

      {/* Styles pour les sliders */}
      <style jsx>{`
        .range-slider::-webkit-slider-thumb {
          pointer-events: auto;
          -webkit-appearance: none;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: white;
          border: 2px solid black;
          cursor: pointer;
          margin-top: -10px;
        }

        .range-slider::-moz-range-thumb {
          pointer-events: auto;
          appearance: none;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: white;
          border: 2px solid black;
          cursor: pointer;
        }

        .range-slider:focus {
          outline: none;
        }

        .range-slider::-webkit-slider-runnable-track {
          width: 100%;
          height: 1px;
          background: transparent;
          border: none;
        }

        .range-slider::-moz-range-track {
          width: 100%;
          height: 1px;
          background: transparent;
          border: none;
        }
      `}</style>
    </div>
  );
};

// Composant de recherche avancée avec suggestions
const AdvancedSearch = ({ spaces, setSpaces }) => {
  const apiUrl = process.env.REACT_APP_API_GATEWAY;

  // États de recherche
  const [searchValue, setSearchValue] = useState('');
  const [dateDebut, setDateDebut] = useState();
  const [dateFin, setDateFin] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  // États des filtres
  const [type, setType] = useState(0);
  const [priceRange, setPriceRange] = useState({ min: 10, max: 10000 });
  const [capacity, setCapacity] = useState(1);
  const [equipments, setEquipments] = useState([]);
  const [type_tarif, setType_tarif] = useState('day');

  const [equipmentsList, setEquipmentsList] = useState([]);
  const [typesList, setTypesList] = useState([]);

  // Récupération des données initiales (équipements et types)
  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        // Récupération des équipements
        const equipResponse = await fetch(`${apiUrl}/api/workspace/equipement`);
        const equipData = await equipResponse.json();
        setEquipmentsList(equipData);

        // Récupération des types d'espaces
        const typesResponse = await fetch(`${apiUrl}/api/workspace/typeEspaces`);
        const typesData = await typesResponse.json();

        setTypesList(typesData.data.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

    fetchFilterData();
  }, [apiUrl]);

  // Gestion de la recherche avec suggestions
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!searchValue.trim()) {
        setSuggestions([]);
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/api/workspace/search`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ search: searchValue.trim() })
        });
        
        if (!response.ok) throw new Error('Erreur réseau');
        const data = await response.json();
        setSuggestions(data);
      } catch (error) {
        console.error('Erreur:', error);
        setSuggestions([]);
      }
    };

    const timeoutId = setTimeout(fetchSuggestions, 300);
    return () => clearTimeout(timeoutId);
  }, [searchValue, apiUrl]);

  // Gestion des filtres
  const handleSearch = async () => {
    const searchData = {
      search: searchValue,
      type: type,
      price_min: priceRange.min,
      price_max: priceRange.max,
      guests: capacity,
      equipments: equipments,
      type_tarif: type_tarif,
      date_debut: dateDebut ? format(dateDebut, 'yyyy-MM-dd') : null,
      date_fin: dateFin ? format(dateFin, 'yyyy-MM-dd') : null
    };

    try {
      const response = await fetch(`${apiUrl}/api/workspace/lists`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(searchData)
      });


      if (!response.ok) throw new Error('Erreur réseau');
      const data = await response.json();
      const formattedSpaces = data.map(space => {
        return {
          ...space,
          photos: [space.photo_base64],
          id: space.espace_id, // S'assurer que l'ID est correctement mappé
          prix: space.prix || 0,
          rating: space.avis?.reduce((acc, rev) => acc + rev.note, 0) / (space.avis?.length || 1) || 0
        };
      });
  
      setSpaces(formattedSpaces);

      setShowFilters(false);
    } catch (error) {
      console.error('Erreur:', error);
    }
  };


  const clearFilters = () => {
    setType(0);
    setPriceRange({ min: 10, max: 10000 });
    setCapacity(1);
    setEquipments([]);
    setType_tarif('day');
  };

  const hasActiveFilters = () => {
    return type !== 0 || 
           capacity !== 1 || 
           equipments.length > 0 || 
           type_tarif !== 'day' ||
           priceRange.min !== 10 ||
           priceRange.max !== 10000;
  };

  return (
    <div className="w-full">
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-6">
        <div className="relative">
        <SearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
          dateDebut={dateDebut}
          setDateDebut={setDateDebut}
          dateFin={dateFin}
          setDateFin={setDateFin}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          handleSearch={handleSearch}
          hasActiveFilters={hasActiveFilters}
          type={type}
          type_tarif={type_tarif}
          capacity={capacity}
          equipments={equipments}
          />

         

            {/* Modal des filtres */}
            <AnimatePresence>
            {showFilters && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50"
                onClick={() => setShowFilters(false)}
              >
                <motion.div
                  initial={{ y: '100%' }}
                  animate={{ y: 0 }}
                  exit={{ y: '100%' }}
                  onClick={e => e.stopPropagation()}
                  className="absolute bottom-0 left-0 right-0 max-h-[90vh] overflow-auto 
                    bg-white rounded-t-2xl sm:rounded-2xl sm:bottom-auto sm:top-24 
                    sm:left-1/2 sm:-translate-x-1/2 sm:max-w-2xl"
                >
                  {/* Header */}
                  <div className="sticky top-0 bg-white px-6 py-4 border-b flex items-center justify-between">
                    <h2 className="text-lg font-semibold">Filtres</h2>
                    <button
                      onClick={() => setShowFilters(false)}
                      className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                    >
                      <X size={20} />
                    </button>
                  </div>

                  {/* Contenu des filtres */}
                  <div className="p-6 space-y-8">
                    {/* Type d'espace */}
                    <div className="space-y-4">
                      <h3 className="text-sm font-medium flex items-center gap-2">
                        <Layout size={18} />
                        Type d'espace
                      </h3>
                      <div className="grid grid-cols-2 gap-2">
                        <button
                          onClick={() => setType(0)}
                          className={`px-4 py-3 rounded-xl text-sm font-medium transition-all ${
                            type === 0
                              ? 'bg-black text-white'
                              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                          }`}
                        >
                          Tous
                        </button>
                        {typesList.map(t => (
                          <button
                            key={t.espace_type_id}
                            onClick={() => setType(t.espace_type_id)}
                            className={`px-4 py-3 rounded-xl text-sm font-medium transition-all ${
                              type === t.espace_type_id
                                ? 'bg-black text-white'
                                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                            }`}
                          >
                            {t.nom}
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Type de tarification */}
                    <div className="space-y-4">
                      <h3 className="text-sm font-medium flex items-center gap-2">
                        <Euro size={18} />
                        Type de tarification
                      </h3>
                      <div className="grid grid-cols-4 gap-2">
                        {['hour', 'day', 'week', 'month'].map(t => (
                          <button
                            key={t}
                            onClick={() => setType_tarif(t)}
                            className={`px-4 py-3 rounded-xl text-sm font-medium transition-all ${
                              type_tarif === t
                                ? 'bg-black text-white'
                                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                            }`}
                          >
                            {t === 'hour' ? 'Heure' :
                             t === 'day' ? 'Jour' :
                             t === 'week' ? 'Semaine' : 'Mois'}
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Capacité */}
                    <div className="space-y-4">
                      <h3 className="text-sm font-medium flex items-center gap-2">
                        <Users size={18} />
                        Capacité
                      </h3>
                      <div className="flex items-center justify-between p-4 bg-gray-100 rounded-xl">
                        <span className="text-sm text-gray-600">Personnes</span>
                        <div className="flex items-center gap-4">
                          <button
                            onClick={() => setCapacity(Math.max(1, capacity - 1))}
                            className={`w-10 h-10 flex items-center justify-center rounded-full 
                              transition-all ${
                              capacity > 1
                                ? 'bg-white text-gray-700 shadow-sm hover:shadow'
                                : 'bg-gray-200 text-gray-400'
                            }`}
                            disabled={capacity <= 1}
                          >
                            -
                          </button>
                          <span className="text-lg font-medium w-6 text-center">
                            {capacity}
                          </span>
                          <button
                            onClick={() => setCapacity(Math.min(8, capacity + 1))}
                            className={`w-10 h-10 flex items-center justify-center rounded-full 
                              transition-all ${
                              capacity < 8
                                ? 'bg-white text-gray-700 shadow-sm hover:shadow'
                                : 'bg-gray-200 text-gray-400'
                            }`}
                            disabled={capacity >= 8}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Équipements */}
                    {/* <div className="space-y-4">
                      <h3 className="text-sm font-medium">Équipements</h3>
                      <div className="grid grid-cols-2 gap-2">
                        {equipmentsList.map(equip => (
                          <button
                            key={equip.equipement_id}
                            onClick={() => {
                              setEquipments(prev => 
                                prev.includes(equip.nom_equipement)
                                  ? prev.filter(e => e !== equip.nom_equipement)
                                  : [...prev, equip.nom_equipement]
                                );
                              }}
                                className={`flex items-center gap-2 px-4 py-3 rounded-xl text-sm 
                                  font-medium transition-all ${
                                  equipments.includes(equip.nom_equipement)
                                    ? 'bg-black text-white'
                                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                }`}
                              >
                                <span className="text-lg">{equip.icone_equipement}</span>
                                <span>{equip.nom_equipement}</span>
                              </button>
                            ))}
                          </div>
                        </div> */}
    
                        {/* Prix */}
                        <div className="space-y-4">
                          <h3 className="text-sm font-medium flex items-center gap-2">
                            <Euro size={18} />
                            Fourchette de prix
                          </h3>
                          <div className="px-2">
                            <div className="mb-2">
                              <p className="text-sm text-gray-500">Prix {type_tarif === 'hour' ? 'par heure' : 
                                type_tarif === 'day' ? 'par jour' : 
                                type_tarif === 'week' ? 'par semaine' : 'par mois'}, frais inclus</p>
                            </div>
                            <PriceRangeSlider
                              min={10}
                              max={10000}
                              value={priceRange}
                              onChange={setPriceRange}
                            />
                            <div className="flex justify-between mt-2">
                              <span className="text-sm text-gray-600">{priceRange.min}€</span>
                              <span className="text-sm text-gray-600">{priceRange.max}€</span>
                            </div>
                          </div>
                        </div>
                      </div>
    
                      {/* Footer avec actions */}
                      <div className="sticky bottom-0 bg-white px-6 py-4 border-t">
                        <div className="flex items-center justify-between gap-4">
                          <button
                            onClick={clearFilters}
                            className={`text-sm ${hasActiveFilters() 
                              ? 'text-gray-700 hover:text-gray-900' 
                              : 'text-gray-400 cursor-not-allowed'}`}
                            disabled={!hasActiveFilters()}
                          >
                            Réinitialiser
                          </button>
                          <button
                            onClick={() => {
                              handleSearch();
                              setShowFilters(false);
                            }}
                            className="flex-1 max-w-xs px-8 py-3 bg-black text-white rounded-xl 
                              hover:bg-gray-900 transition-colors font-medium"
                          >
                            Voir les résultats
                          </button>
                        </div>
                      </div>
                    </motion.div>
                  </motion.div>
                )}
            </AnimatePresence>
            </div>
          </div>
        </div>
      );
};

// Composant de carte de workspace réinventé
const WorkspaceCard = ({ space, favorites, setFavorites, viewMode = 'grid' }) => {
  const [isHovered, setIsHovered] = useState(false);
  const apiUrl = process.env.REACT_APP_API_GATEWAY;
  const [isLoading, setIsLoading] = useState(false);
  const espace_id = space.id;
  const utilisateur_id = 1;
  const navigate = useNavigate();


  
  // Calcul de la note moyenne
  const averageRating = space.avis 
  ? space.avis.reduce((acc, rev) => acc + rev.note, 0) / space.avis.length 
  : 0;

  // Animation de progression pour montrer la disponibilité
  const availability = useMotionValue(0);
  const availabilityProgress = useTransform(availability, [0, 100], [0, 1]);

  useEffect(() => {
    const timer = setTimeout(() => {
      availability.set(75);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleFavoriteClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    if (!utilisateur_id) {
      navigate('/login');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(`${apiUrl}/api/workspace/favoris/${espace_id}`,
        {
          method: favorites.includes(espace_id) ? 'DELETE' : 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({ 
            utilisateur_id,
            espace_id 
          })
        }
      );

      const data = await response.json();
      
      if (data.success) {
        setFavorites(prevFavorites => 
          favorites.includes(espace_id) 
            ? prevFavorites.filter(id => id !== espace_id) 
            : [...prevFavorites, espace_id]
        );
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Erreur gestion favori:', error);
      // Vous pouvez ajouter un toast ici pour l'erreur
    } finally {
      setIsLoading(false);
    }
  };

  // Ensure the main image is placed first
  const sortedPhotos = space.photos?.sort((a, b) => b.is_main - a.is_main) || [];

  // Composant GridView
  const GridView = () => (
    <Interactive3DCard>
      <motion.div
        className="bg-white rounded-2xl overflow-hidden"
        whileHover={{ y: -8 }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => navigate(`/espace/${space.id}`)}
      >
        <div className="relative">
          {/* Image principale avec effet parallaxe */}
          <motion.div
            className="relative h-56 overflow-hidden"
            animate={{ scale: isHovered ? 1.05 : 1 }}
            transition={{ duration: 0.3 }}
          >
            {sortedPhotos.length > 0 ? (
              <img
                src={`data:image/jpeg;base64,${sortedPhotos[0].photo_base64 || space.photo_base64 }`}
                alt={space.titre}
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                <span className="text-gray-400">Aucune photo disponible</span>
              </div>
            )}
            <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-transparent to-transparent" />
          </motion.div>

          {/* Bouton favoris */}
          <motion.button
            className="absolute top-4 right-4 p-2 bg-white/90 backdrop-blur-sm rounded-full"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleFavoriteClick}
          >
            <AnimatePresence mode="wait">
              {favorites.includes(espace_id) ? (
                <motion.div
                  key="filled"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  <FaHeart className="text-red-500" />
                </motion.div>
              ) : (
                <motion.div
                  key="outline"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  <FaRegHeart className="text-gray-600" />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.button>

          {/* Barre de progression de disponibilité */}
          <motion.div
            className="absolute bottom-0 left-0 right-0 h-1 bg-blue-600/20"
            style={{ scaleX: availabilityProgress }}
            transition={{ duration: 1 }}
          />
        </div>

        <div className="p-6">
          {/* En-tête avec notation */}
          <div className="flex justify-between items-start mb-4">
            <div>
            <h3 className="text-lg font-semibold text-gray-900 truncate line-clamp-1 mb-1" title={space.titre}>{space.titre}</h3>
              <p className="text-gray-500 text-sm mt-1">{space.ville}</p>
              <p className="text-gray-600 text-sm mb-4 overflow-hidden h-[40px] text-ellipsis line-clamp-2">{space.description}</p>
            </div>
            <div className="flex items-center gap-1 bg-yellow-50 px-2 py-1 rounded-lg">
              <FaStar className="text-yellow-400" />
              <span className="font-medium text-yellow-700">{averageRating.toFixed(1)}</span>
            </div>
          </div>

          {/* Caractéristiques avec icônes animées */}
          <div className="grid grid-cols-3 gap-3 mb-6">
            {space.equipements && (() => {
              const allEquipments = Object.values(space.equipements).flat();
              const totalEquipments = allEquipments.length;
              const firstThree = allEquipments.slice(0, 3);

              return (
                <>
                  {firstThree.map((equip, index) => (
                    <motion.div
                      key={index}
                      className="flex items-center gap-2 text-sm text-gray-600"
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                    >
                      <FaCheck className="text-gray-400" />
                      <span className="truncate" title={equip}>{equip}</span>
                    </motion.div>
                  ))}
                  {totalEquipments > 3 && (
                    <motion.div
                      className="flex items-center gap-2 text-sm text-blue-600 font-medium"
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.3 }}
                    >
                      <span>+{totalEquipments - 3} autres</span>
                    </motion.div>
                  )}
                </>
              );
            })()}
          </div>

          <motion.div
            className='flex items-center justify-between'
          >
          {/* Prix et bouton de réservation */}
            <div>
              <span className="text-2xl font-semibold text-gray-900">
              {space.prix || 0}€
              </span>
              <span className="text-gray-500 text-sm">
                {space.type === 'hour' ? '/heure' :
                 space.type === 'day' ? '/jour' :
                 space.type === 'week' ? '/semaine' : '/mois'}
              </span>
            </div>
            {/* <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-6 py-2.5 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-colors"
              onClick={() => navigate(`/espace/${space.id}`)}
            >
              Réserver
            </motion.button> */}
          </motion.div>

          {/* Indicateur de tendance */}
          {/* {isHovered && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="absolute bottom-4 left-4 right-4 p-3 bg-black/80 backdrop-blur-sm text-white rounded-xl"
            >
              <div className="flex items-center gap-2 text-sm">
                <FaRegClock />
                <span>Très demandé - Réservé 12 fois cette semaine</span>
              </div>
            </motion.div>
          )} */}
        </div>
      </motion.div>
    </Interactive3DCard>
  );

  return <GridView />;
};

const Dashboard = () => {
  const { userMode, userName } = useAuth();
  const [showModePopup, setShowModePopup] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [activeSort, setActiveSort] = useState('recommended');
  const [showMap, setShowMap] = useState(false);
  const [spaces, setSpaces] = useState([]);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [favorites, setFavorites] = useState([]);
  // État des animations
  const [pageLoaded, setPageLoaded] = useState(false);
  const containerRef = useRef(null);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Vérifie si une préférence est déjà sauvegardée
    const savedTheme = localStorage.getItem('theme');
    // Vérifie la préférence système
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    return savedTheme === 'dark' || (!savedTheme && prefersDark);
  });

  // Effet pour appliquer le thème
  useEffect(() => {
    // Ajoute ou enlève la classe dark au document
    document.documentElement.classList.toggle('dark', isDarkMode);
    // Sauvegarde la préférence
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  useEffect(() => {
    const fetchSpacesAndFavorites = async () => {
      try {
        setIsLoading(true);
        const url = `${process.env.REACT_APP_API_GATEWAY}/api/workspace/workspace`;
        const favoritesUrl = `${process.env.REACT_APP_API_GATEWAY}/api/workspace/favoris`;

        const [spacesResponse, favoritesResponse] = await Promise.all([
          fetch(url, { credentials: 'include' }),
          fetch(favoritesUrl, { credentials: 'include'})
          ]);
          

        if (!spacesResponse.ok || !favoritesResponse.ok) {
          const errorData = await spacesResponse.json().catch(() => ({}));
          throw new Error(errorData.error || 'Erreur lors du chargement des espaces');
        }

        const [spacesData, favoritesData] = await Promise.all([
          spacesResponse.json(),
          favoritesResponse.json()
        ]);


        // Valider que nous avons reçu des données valides
        if (!Array.isArray(spacesData.data) || !Array.isArray(favoritesData.data.data)) {
          throw new Error('Format de données invalide');
        }


        const favoriteIds = favoritesData?.data?.data.map(fav => fav.espace_id);



        // Transformer les données et logger les IDs
        const formattedSpaces = spacesData?.data.map(space => {
          return {
            ...space,
            id: space.espace_id, // S'assurer que l'ID est correctement mappé
            prix: space.prix || 0,
            rating: space.avis?.reduce((acc, rev) => acc + rev.note, 0) / (space.avis?.length || 1) || 0,
            is_favoris: favoriteIds.includes(space.espace_id)
          };
        });


        setSpaces(formattedSpaces);
        setFavorites(favoriteIds);
        setError(null);
      } catch (err) {
        console.error('Error fetching spaces:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSpacesAndFavorites();
  }, []);

  // Options de tri
  const sortOptions = [
    { id: 'recommended', label: 'Recommandés', icon: FaCompass },
    { id: 'price_asc', label: 'Prix croissant', icon: FaArrowUp },
    { id: 'price_desc', label: 'Prix décroissant', icon: FaArrowDown },
    { id: 'rating', label: 'Mieux notés', icon: FaStar }
  ];

   // Fonction de tri des espaces
   const sortSpaces = (spacesToSort) => {
    switch (activeSort) {
      case 'price_asc':
        return [...spacesToSort].sort((a, b) => a.prix - b.prix);
      case 'price_desc':
        return [...spacesToSort].sort((a, b) => b.prix - a.prix);
      case 'rating':
        return [...spacesToSort].sort((a, b) => {
          const aRating = a.avis?.reduce((acc, rev) => acc + rev.note, 0) / (a.avis?.length || 1);
          const bRating = b.avis?.reduce((acc, rev) => acc + rev.note, 0) / (b.avis?.length || 1);
          return bRating - aRating;
        });
      default:
        return spacesToSort;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setPageLoaded(true), 500);
    return () => clearTimeout(timer);
  }, []);

    // Configuration des animations
    const containerVariants = {
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: {
          staggerChildren: 0.1
        }
      }
    };

    const itemVariants = {
      hidden: { y: 20, opacity: 0 },
      visible: {
        y: 0,
        opacity: 1
      }
    };
  // Animation des cartes au scroll
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView) {
      controls.start((i) => ({
        opacity: 1,
        y: 0,
        transition: { delay: i * 0.1 }
      }));
    }
  }, [controls, inView]);

  // Gestionnaire de défilement fluide
  const handleScroll = () => {
    if (!containerRef.current) return;
    const scrolled = containerRef.current.scrollTop;
    if (scrolled > 100) {
      // Animations basées sur le défilement
    }
  };

    const QuickStats = ({ spaces }) => {
      // Calcul du prix moyen
      const averagePrice = spaces.length 
        ? Math.round(spaces.reduce((acc, space) => acc + (space.prix || 0), 0) / spaces.length) 
        : 0;
      
      // Calcul des espaces récemment ajoutés (dans les 30 derniers jours)
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      const newSpaces = spaces.filter(space => new Date(space.created_at)).length;
    
      const stats = [
        { 
          label: 'Espaces disponibles', 
          value: spaces.length, 
          icon: FaHome 
        },
        { 
          label: 'Prix moyen', 
          value: `${averagePrice}€/jour`, 
          icon: FaCreditCard 
        },
        { 
          label: 'Espaces actifs', 
          value: spaces.filter(space => space.mode === 'actif').length, 
          icon: FaHistory 
        },
        { 
          label: 'Nouveaux espaces', 
          value: newSpaces, 
          icon: FaBell 
        }
      ];
    
      return (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
          {stats.map(({ label, value, icon: Icon }) => (
            <motion.div
              key={label}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white p-4 rounded-2xl shadow-sm"
            >
              <div className="flex items-center gap-4">
                <div className="p-3 bg-blue-50 rounded-xl">
                  <Icon className="w-5 h-5 text-blue-600" />
                </div>
                <div>
                  <p className="text-sm text-gray-500">{label}</p>
                  <p className="text-xl font-semibold text-gray-900">{value}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      );
    };

  // Vue principale
  return (
    <motion.div 
      className="min-h-screen bg-gray-50/50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      ref={containerRef}
      onScroll={handleScroll}
    >
      <AnimatePresence>
        {showModePopup && (
          <ModeSelectionPopup onSelect={handleModeSelection} userName={userName} />
        )}
      </AnimatePresence>

      <div>
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          {userMode === 'guest' ? (
            <>
              {/* En-tête avec animation de texte */}
              {/* <motion.div 
                className="mb-8"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <h1 className="text-4xl font-bold text-gray-900 mb-2">
                  <motion.span
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    Trouvez votre
                  </motion.span>{' '}
                  <motion.span
                    className="text-blue-600"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.4 }}
                  >
                    espace idéal
                  </motion.span>
                </h1>
                <motion.p 
                  className="text-gray-500 text-lg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.6 }}
                >
                  Des espaces uniques pour travailler, créer et collaborer
                </motion.p>
              </motion.div> */}

              {/* <QuickStats spaces={spaces}/> */}
              
              {/* Barre de recherche et filtres */}
              <AdvancedSearch spaces={spaces} setSpaces={setSpaces} />

              {/* Barre d'outils supérieure */}
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4 overflow-x-scroll">
                  {sortOptions.map(({ id, label, icon: Icon }) => (
                    <motion.button
                      key={id}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setActiveSort(id)}
                      className={`flex items-center gap-2 px-4 py-2 rounded-xl transition-colors ${
                        activeSort === id 
                          ? 'bg-blue-600 text-white' 
                          : 'bg-white text-gray-600 hover:bg-gray-50'
                      }`}
                    >
                      
                      <span>{label}</span>
                    </motion.button>
                  ))}
                </div>

                <div className="flex items-center gap-4">
                  <ViewToggle viewMode={viewMode} setViewMode={setViewMode} />
                  <MapToggle showMap={showMap} setShowMap={setShowMap} />
                </div>
              </div>

              {showMap ? (
                <MapView spaces={spaces} showMap={showMap} />
              ) : (
                // Grille des espaces
                <motion.div
                  className={`grid grid-cols-1 ${
                    viewMode === 'grid' 
                      ? 'sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3' 
                      : ''
                  } gap-6`}
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                >
                  {sortSpaces(spaces).map((space, index) => (
                    <motion.div
                      key={space.espace_id}
                      variants={itemVariants}
                      layout
                    >
                      <WorkspaceCard 
                        space={space}
                        favorites={favorites} 
                        setFavorites={setFavorites}
                        viewMode={viewMode}
                        isDarkMode={isDarkMode}
                      />
                    </motion.div>
                  ))}
                </motion.div>
              )}


            </>
          ) : (
            <HostDashboard />
          )}
        </div>
      </div>

      {/* <ThemeToggle /> */}
    </motion.div>
  );
};

export default Dashboard;
