import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Clock, Mail } from 'lucide-react';

const SectionTitle = ({ children, id }) => (
  <h2 
    id={id} 
    className="text-xl font-semibold text-gray-900 mt-8 mb-4 scroll-mt-20"
  >
    {children}
  </h2>
);

const TableOfContents = ({ sections }) => (
  <div className="hidden lg:block sticky top-8 bg-white rounded-xl p-6 shadow-sm border border-gray-100">
    <h3 className="font-medium text-gray-900 mb-3">Sommaire</h3>
    <nav className="space-y-1">
      {sections.map((section, index) => (
        <a
          key={index}
          href={`#${section.id}`}
          className="block text-sm text-gray-600 hover:text-blue-600 py-1 transition-colors duration-200"
        >
          {section.title}
        </a>
      ))}
    </nav>
  </div>
);

const PrivacyPolicy = () => {
  const sections = [
    { id: 'informations', title: 'Informations Collectées' },
    { id: 'utilisation', title: 'Utilisation des Données' },
    { id: 'partage', title: 'Partage des Données' },
    { id: 'securite', title: 'Sécurité' },
    { id: 'droits', title: 'Vos Droits' },
    { id: 'contact', title: 'Contact' }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50/50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* En-tête */}
        <div className="mb-8">
          <Link 
            to="/" 
            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 mb-6 group"
          >
            <ArrowLeft className="w-4 h-4 mr-2 transform group-hover:-translate-x-1 transition-transform duration-200" />
            Retour à l'accueil
          </Link>

          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <h1 className="text-3xl font-bold text-gray-900">
              Politique de Confidentialité
            </h1>
            <div className="flex items-center text-sm text-gray-500 bg-white px-4 py-2 rounded-lg shadow-sm border border-gray-100">
              <Clock className="w-4 h-4 mr-2 text-blue-500" />
              Mise à jour le 12/11/2024
            </div>
          </div>
        </div>

        {/* Contenu principal avec navigation latérale */}
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Contenu principal */}
          <div className="lg:col-span-3">
            <div className="bg-white rounded-xl shadow-sm border border-gray-100">
              {/* Introduction */}
              <div className="p-8 border-b border-gray-100 bg-gradient-to-br from-blue-50 to-white">
                <p className="text-gray-600 leading-relaxed">
                  Cette Politique de Confidentialité explique comment HomeOfficeShare collecte, utilise et protège les informations personnelles que vous nous fournissez en utilisant notre plateforme. En utilisant HomeOfficeShare, vous acceptez les termes de cette Politique de Confidentialité.
                </p>
              </div>

              <div className="p-8">
                <SectionTitle id="informations">1. Informations Collectées</SectionTitle>
                <p className="text-gray-600 leading-relaxed">
                  Nous collectons des informations personnelles lorsque vous créez un compte, effectuez une réservation ou devenez hôte sur HomeOfficeShare. Cela inclut vos nom, adresse e-mail, numéro de téléphone, informations de paiement et autres informations nécessaires pour fournir nos services.
                </p>

                <SectionTitle id="utilisation">2. Utilisation des Données</SectionTitle>
                <p className="text-gray-600 leading-relaxed mb-4">
                  Nous utilisons vos données personnelles pour :
                </p>
                <ul className="space-y-2 text-gray-600 list-none">
                  <li className="flex items-center">
                    <div className="w-2 h-2 rounded-full bg-blue-500 mr-3"></div>
                    Fournir, améliorer et personnaliser nos services
                  </li>
                  <li className="flex items-center">
                    <div className="w-2 h-2 rounded-full bg-blue-500 mr-3"></div>
                    Gérer votre compte et vos réservations
                  </li>
                  <li className="flex items-center">
                    <div className="w-2 h-2 rounded-full bg-blue-500 mr-3"></div>
                    Vous envoyer des notifications et des messages relatifs à votre compte
                  </li>
                </ul>

                <SectionTitle id="partage">3. Partage des Données</SectionTitle>
                <p className="text-gray-600 leading-relaxed">
                  HomeOfficeShare ne partage pas vos informations personnelles avec des tiers, sauf si cela est nécessaire pour fournir nos services, respecter une obligation légale ou protéger nos droits.
                </p>

                <SectionTitle id="securite">4. Sécurité</SectionTitle>
                <p className="text-gray-600 leading-relaxed">
                  Nous mettons en œuvre des mesures de sécurité pour protéger vos données personnelles contre tout accès non autorisé, altération, divulgation ou destruction. Cependant, la sécurité des informations transmises sur Internet ne peut être garantie à 100%.
                </p>

                <SectionTitle id="droits">5. Vos Droits</SectionTitle>
                <p className="text-gray-600 leading-relaxed">
                  Conformément au RGPD, vous disposez de droits d'accès, de rectification, de suppression et de portabilité de vos données personnelles. Vous pouvez également demander la limitation ou l'opposition au traitement de vos données.
                </p>

                <SectionTitle id="contact">6. Contact</SectionTitle>
                <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-6 mt-4">
                  <div className="flex items-start space-x-4">
                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <Mail className="w-6 h-6 text-blue-600" />
                    </div>
                    <div>
                      <h4 className="text-lg font-medium text-gray-900">Une question ?</h4>
                      <p className="mt-2 text-gray-600">
                        Pour toute question concernant cette politique, écrivez-nous à{' '}
                        <a 
                          href="mailto:support@homeofficeshare.com"
                          className="text-blue-600 hover:text-blue-700 transition-colors duration-200"
                        >
                          support@homeofficeshare.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Navigation latérale */}
          <aside>
            <TableOfContents sections={sections} />
          </aside>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;