import React from 'react';
import Hero from '../components/Hero';
import OfferGrid from '../components/OfferGrid';
// import SpaceDetails from './SpaceDetails';
import Testimonials from '../components/Testimonials';
import '../styles/Home.css';


function Home() {
  return (
    <div>
      <Hero />
      {/* <OfferGrid /> */}
      {/* <Testimonials /> */}
    </div>
  );
}

export default Home;
