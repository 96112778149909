import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Camera,
  Image as ImageIcon,
  MoreVertical,
  Star,
  ArrowLeft,
  ArrowRight,
  Trash2,
  MoveLeft,
  MoveRight,
  X,
  Upload,
  HelpCircle
} from 'lucide-react';
import { useForm } from '../../../context/FormHostContext';
import SaveQuitButton from '../../../components/SaveQuitButton';

const PhotoCard = ({ photo, index, isCover, onAction, totalPhotos }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className={`group relative rounded-2xl overflow-hidden transition-transform duration-300 hover:-translate-y-1
                ${isCover ? 'col-span-2 row-span-2' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setShowMenu(false);
      }}
    >
      {/* Photo */}
      <img 
        src={photo} 
        alt={`Space ${index + 1}`}
        className="w-full h-full object-cover aspect-square"
      />

      {/* Overlay on hover */}
      <div className={`absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent
                    transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
        {/* Photo number */}
        <div className="absolute top-3 left-3 px-3 py-1.5 bg-black/50 rounded-full
                      text-white text-sm backdrop-blur-sm">
          Photo {index + 1}
        </div>

        {/* Action buttons */}
        <div className="absolute top-3 right-3 flex items-center gap-2">
          <button
            onClick={() => setShowMenu(!showMenu)}
            className="p-2 rounded-full bg-black/50 text-white backdrop-blur-sm
                     hover:bg-black/70 transition-colors"
          >
            <MoreVertical className="w-4 h-4" />
          </button>
        </div>

        {/* Dropdown Menu */}
        {showMenu && (
          <div className="absolute top-14 right-3 bg-white rounded-xl shadow-xl py-1 min-w-[200px]
                         border border-gray-100">
            <button
              onClick={() => onAction(index, "cover")}
              className="w-full px-4 py-2.5 flex items-center gap-3 text-left hover:bg-gray-50 text-sm"
            >
              <Star className="w-4 h-4" />
              <span>Définir comme couverture</span>
            </button>
            <button
              onClick={() => onAction(index, "move-left")}
              disabled={index === 0}
              className="w-full px-4 py-2.5 flex items-center gap-3 text-left hover:bg-gray-50 text-sm
                       disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <MoveLeft className="w-4 h-4" />
              <span>Déplacer à gauche</span>
            </button>
            <button
              onClick={() => onAction(index, "move-right")}
              disabled={index === totalPhotos - 1}
              className="w-full px-4 py-2.5 flex items-center gap-3 text-left hover:bg-gray-50 text-sm
                       disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <MoveRight className="w-4 h-4" />
              <span>Déplacer à droite</span>
            </button>
            <button
              onClick={() => onAction(index, "delete")}
              className="w-full px-4 py-2.5 flex items-center gap-3 text-left hover:bg-red-50 text-red-500 text-sm"
            >
              <Trash2 className="w-4 h-4" />
              <span>Supprimer</span>
            </button>
          </div>
        )}

        {/* Cover badge */}
        {isCover && (
          <div className="absolute bottom-3 left-3 px-3 py-1.5 bg-blue-500 rounded-full
                         text-white text-sm font-medium flex items-center gap-2">
            <Star className="w-4 h-4" />
            <span>Photo principale</span>
          </div>
        )}
      </div>
    </div>
  );
};

const UploadZone = ({ onUpload, photosCount }) => {
  const dragRef = React.useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = [...e.dataTransfer.files];
    if (files && files.length > 0) {
      onUpload({ target: { files } });
    }
  };

  return (
    <div
      ref={dragRef}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      className={`relative col-span-2 row-span-2 rounded-2xl transition-all duration-300
                ${isDragging 
                  ? 'bg-blue-50 border-blue-500' 
                  : 'bg-gray-50 hover:bg-gray-100 border-gray-200'}
                border-2 border-dashed flex flex-col items-center justify-center p-8 cursor-pointer`}
    >
      <input
        type="file"
        id="photo-upload"
        className="hidden"
        multiple
        accept="image/*"
        onChange={onUpload}
      />
      <label htmlFor="photo-upload" className="cursor-pointer text-center">
        <div className="mb-4">
          <div className="w-16 h-16 rounded-full bg-blue-50 mx-auto flex items-center justify-center">
            <Camera className="w-8 h-8 text-blue-500" />
          </div>
        </div>
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          Ajoutez vos photos
        </h3>
        <p className="text-gray-500 mb-4">
          {photosCount > 0 
            ? `${photosCount} photo${photosCount > 1 ? 's' : ''} sélectionnée${photosCount > 1 ? 's' : ''}`
            : 'Glissez et déposez ou cliquez pour sélectionner'}
        </p>
        <button className="px-6 py-2 bg-blue-600 text-white rounded-lg 
                         hover:bg-blue-500 transition-colors">
          Parcourir
        </button>
      </label>
    </div>
  );
};

const StepProgress = ({ currentStep, totalSteps }) => (
  <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                  bg-white/90 backdrop-blur-sm rounded-full shadow-lg p-2 border border-blue-50">
    <div className="flex items-center gap-2 px-3 py-1">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`rounded-full transition-all duration-300 
                    ${index < currentStep 
                      ? 'w-8 h-2 bg-blue-500' 
                      : 'w-2 h-2 bg-gray-200'}`}
        />
      ))}
    </div>
  </div>
);

export default function StepTwoPhotos() {
  const navigate = useNavigate();
  const [photos, setPhotos] = useState([]);
  const { handleChange } = useForm();

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const fileURLs = files.map((file) => URL.createObjectURL(file));
    setPhotos((prev) => [...prev, ...fileURLs]);
    handleChange('photos', [...photos, ...fileURLs]);
    handleChange('photoPrincipal', photos.length === 0 ? fileURLs[0] : photos[0]);
  };

  const handlePhotoAction = (index, action) => {
    let updatedPhotos = [...photos];

    switch (action) {
      case "delete":
        updatedPhotos = updatedPhotos.filter((_, i) => i !== index);
        break;
      case "move-left":
        if (index > 0) {
          [updatedPhotos[index - 1], updatedPhotos[index]] = 
          [updatedPhotos[index], updatedPhotos[index - 1]];
        }
        break;
      case "move-right":
        if (index < photos.length - 1) {
          [updatedPhotos[index + 1], updatedPhotos[index]] = 
          [updatedPhotos[index], updatedPhotos[index + 1]];
        }
        break;
      case "cover":
        const [selectedPhoto] = updatedPhotos.splice(index, 1);
        updatedPhotos = [selectedPhoto, ...updatedPhotos];
        handleChange('photoPrincipal', selectedPhoto);
        break;
    }

    setPhotos(updatedPhotos);
    handleChange('photos', updatedPhotos);
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <header className="fixed top-0 inset-x-0 bg-white border-b border-gray-200 z-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex items-center justify-between">
          <div className="flex items-center gap-8">
            <button onClick={() => navigate('/become-host/stepTwoEquipments')}
                    className="text-gray-400 hover:text-gray-600 transition-colors">
              <ArrowLeft className="w-6 h-6" />
            </button>
            <h1 className="text-xl font-semibold text-gray-900">Photos de votre espace</h1>
          </div>
          <SaveQuitButton />
        </div>

        <div className="max-w-7xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center gap-3 text-sm text-gray-500">
            <ImageIcon className="w-4 h-4" />
            <span>{photos.length}/10 photos</span>
          </div>
          <div className="h-1 flex-1 mx-6 bg-gray-100 rounded-full overflow-hidden">
            <div
              className="h-full bg-blue-500 rounded-full transition-all duration-300"
              style={{ width: `${Math.min((photos.length / 10) * 100, 100)}%` }}
            />
          </div>
          <span className="text-sm text-gray-500">
            {photos.length < 5 ? `Ajoutez encore ${5 - photos.length} photos minimum` : 'Photos minimum atteintes'}
          </span>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 pt-32 pb-24">
        {photos.length === 0 ? (
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              Commencez à ajouter des photos
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Ajoutez au moins 5 photos de qualité pour donner aux télétravailleurs 
              un bon aperçu de votre espace.
            </p>
          </div>
        ) : null}

        {/* Photo Grid */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <UploadZone onUpload={handleFileUpload} photosCount={photos.length} />
          {photos.map((photo, index) => (
            <PhotoCard
              key={index}
              photo={photo}
              index={index}
              isCover={index === 0}
              onAction={handlePhotoAction}
              totalPhotos={photos.length}
            />
          ))}
        </div>
      </main>

      <StepProgress currentStep={7} totalSteps={14} />

      {/* Footer */}
      <footer className="fixed bottom-0 inset-x-0 bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center gap-3 text-gray-500">
            <HelpCircle className="w-5 h-5" />
            <span>Des questions ?</span>
          </div>

          <button
            onClick={() => navigate('/become-host/stepTwoTitle')}
            disabled={photos.length < 5}
            className={`px-8 py-3 rounded-xl flex items-center gap-2 transition-all duration-300
                     ${photos.length >= 5
                       ? 'bg-blue-600 text-white hover:bg-blue-500 hover:shadow-lg hover:shadow-blue-500/20'
                       : 'bg-gray-100 text-gray-400 cursor-not-allowed'}`}
          >
            <span>Continuer</span>
            <ArrowRight className="w-5 h-5" />
          </button>
        </div>
      </footer>
    </div>
  );
}