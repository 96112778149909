import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, MapPin, Clock, Search, Plus, Filter } from 'lucide-react';

const Bookings = () => {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const apiUrl = process.env.REACT_APP_API_GATEWAY;

  const getStatusColor = (status) => {
    switch(status.toLowerCase()) {
      case 'confirmé':
        return 'bg-emerald-50 text-emerald-700 ring-1 ring-emerald-600/20';
      case 'en attente':
        return 'bg-amber-50 text-amber-700 ring-1 ring-amber-600/20';
      case 'annulé':
        return 'bg-rose-50 text-rose-700 ring-1 ring-rose-600/20';
      default:
        return 'bg-gray-50 text-gray-700 ring-1 ring-gray-600/20';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd MMM yyyy', { locale: fr });
  };

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/reservation/guest/mybookings`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        
        if (data.success) {
          setReservations(Array.isArray(data.data) ? data.data : []);
        } else {
          setError(data.message);
          setReservations([]);
        }
      } catch (error) {
        console.error('Erreur:', error);
        setError('Erreur lors de la récupération des réservations');
        setReservations([]);
      } finally {
        setLoading(false);
      }
    };
  
    fetchReservations();
  }, []);

  const filteredReservations = reservations
    .filter(reservation => 
      reservation.espace_titre.toLowerCase().includes(searchTerm.toLowerCase()) ||
      reservation.espace_adresse.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(reservation => 
      filter === 'all' ? true : reservation.statut === filter
    );

  // États pour les filtres mobiles
  const filterOptions = [
    { value: 'all', label: 'Tous les statuts' },
    { value: 'valide', label: 'Confirmé' },
    { value: 'attente', label: 'En attente' },
    { value: 'annule', label: 'Annulé' }
  ];

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50/50 flex items-center justify-center p-4">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex flex-col items-center gap-4"
        >
          <div className="w-16 h-16 relative">
            <div className="absolute inset-0 rounded-full border-4 border-blue-100 opacity-20"></div>
            <div className="absolute inset-0 rounded-full border-4 border-blue-600 border-t-transparent animate-spin"></div>
          </div>
          <p className="text-gray-600 font-medium">Chargement de vos réservations...</p>
        </motion.div>
      </div>
    );
  }

  if (reservations.length === 0) {
    return (
      <div className="min-h-screen bg-gray-50/50 flex items-center justify-center p-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-md w-full bg-white rounded-2xl p-8 shadow-sm text-center"
        >
          <div className="inline-flex items-center justify-center w-20 h-20 rounded-full bg-gradient-to-tr from-blue-50 to-blue-100 mb-6">
            <Calendar className="w-10 h-10 text-blue-600" />
          </div>
          <h2 className="text-2xl font-semibold text-gray-900 mb-3">
            {error || "Aucune réservation"}
          </h2>
          <p className="text-gray-600 mb-6">
            Vous n'avez pas encore de réservations
          </p>
          <Link
            to="/espaces"
            className="inline-flex items-center justify-center px-6 py-3 rounded-xl bg-gradient-to-r from-blue-600 to-blue-700 text-white font-medium hover:opacity-90 transition-all"
          >
            Découvrir des espaces
          </Link>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50/50 pt-20 pb-12">
      <div className="max-w-5xl mx-auto px-4">
        {/* Header avec recherche responsive */}
        <div className="space-y-6 mb-8">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            <h1 className="text-2xl sm:text-3xl font-semibold text-gray-900">
              Mes réservations
            </h1>
            <Link
              to="/espaces"
              className="inline-flex items-center justify-center px-4 py-2.5 rounded-xl bg-gradient-to-r from-blue-600 to-blue-700 text-white font-medium hover:opacity-90 transition-all gap-2"
            >
              <Plus className="w-5 h-5" />
              <span>Réserver un espace</span>
            </Link>
          </div>

          <div className="flex flex-col sm:flex-row gap-3">
            <div className="relative flex-grow">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="text"
                placeholder="Rechercher une réservation..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all"
              />
            </div>
            
            {/* Filtre mobile */}
            <div className="sm:hidden">
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="w-full flex items-center justify-between px-4 py-3 rounded-xl border border-gray-200 bg-white"
              >
                <span className="text-gray-700">Filtrer</span>
                <Filter className="w-5 h-5 text-gray-500" />
              </button>
            </div>

            {/* Filtre desktop */}
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="hidden sm:block px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all bg-white min-w-[160px]"
            >
              {filterOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Liste des réservations */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="grid gap-4"
        >
          {filteredReservations.map((reservation) => (
            <motion.div
              key={reservation.reservation_id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="group"
            >
              <Link
                to={`/reservation/${reservation.reservation_id}`}
                className="block w-full bg-white rounded-xl p-4 sm:p-6 shadow-sm hover:shadow-md transition-all duration-200"
              >
                <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
                  <div className="w-full sm:w-32 h-48 sm:h-32 rounded-lg overflow-hidden flex-shrink-0">
                    <img
                      src={`data:image/jpeg;base64,${reservation.photo_principale}`}
                      alt={reservation.espace_titre}
                      className="w-full h-full object-cover"
                    />
                  </div>

                  <div className="flex-grow">
                    <div className="flex flex-col sm:flex-row sm:items-start justify-between gap-4">
                      <div>
                        <h3 className="text-xl font-semibold text-gray-900 mb-2">
                          {reservation.espace_titre}
                        </h3>
                        <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(reservation.statut)}`}>
                          {reservation.statut === 'valide' ? 'Confirmé' : 
                           reservation.statut === 'attente' ? 'En attente' : 
                           reservation.statut === 'annule' ? 'Annulé' : 'Non Confirmé'}
                        </span>
                      </div>
                    </div>

                    <div className="mt-4 grid gap-2">
                      <div className="flex items-center text-sm text-gray-600">
                        <MapPin className="w-4 h-4 mr-2 flex-shrink-0" />
                        <span className="line-clamp-1">{reservation.espace_adresse}</span>
                      </div>
                      <div className="flex items-center text-sm text-gray-600">
                        <Calendar className="w-4 h-4 mr-2 flex-shrink-0" />
                        <span className="line-clamp-1">
                          Du {formatDate(reservation.date_debut)} au {formatDate(reservation.date_fin)}
                        </span>
                      </div>
                      <div className="flex items-center text-sm text-gray-500">
                        <Clock className="w-4 h-4 mr-2 flex-shrink-0" />
                        <span>Réservé le {formatDate(reservation.cree_a)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
        </motion.div>
      </div>

      {/* Modal de filtres pour mobile */}
      <AnimatePresence>
        {isFilterOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 z-50 flex items-end sm:hidden"
            onClick={() => setIsFilterOpen(false)}
          >
            <motion.div
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              className="w-full bg-white rounded-t-2xl p-6 space-y-4"
              onClick={e => e.stopPropagation()}
            >
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold">Filtrer par statut</h3>
                <button onClick={() => setIsFilterOpen(false)}>
                  ✕
                </button>
              </div>
              <div className="space-y-2">
                {filterOptions.map(option => (
                  <button
                    key={option.value}
                    className={`w-full text-left px-4 py-3 rounded-xl transition-colors ${
                      filter === option.value
                        ? 'bg-blue-50 text-blue-600'
                        : 'hover:bg-gray-50'
                    }`}
                    onClick={() => {
                      setFilter(option.value);
                      setIsFilterOpen(false);
                    }}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Bookings;