import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import DatePicker from 'react-datepicker';
import { format, addDays, differenceInDays } from 'date-fns';
import { fr } from 'date-fns/locale';
import {
  Calendar,
  Users,
  MapPin,
  ChevronLeft,
  AlertCircle,
  Euro,
  Info,
  Trash2 
} from 'lucide-react';
import 'react-datepicker/dist/react-datepicker.css';
import CancelBookingModal from './CancelBookingModal';

const EditBooking = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const bookingDetails = location.state?.bookingDetails;
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const apiUrl = process.env.REACT_APP_API_GATEWAY;
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  // États pour les champs modifiables avec validation des valeurs initiales
  const [startDate, setStartDate] = useState(() => {
    try {
      return new Date(bookingDetails?.startDate);
    } catch (e) {
      console.error('Date de début invalide:', e);
      return new Date();
    }
  });

  const [endDate, setEndDate] = useState(() => {
    try {
      return new Date(bookingDetails?.endDate);
    } catch (e) {
      console.error('Date de fin invalide:', e);
      return addDays(new Date(), 1);
    }
  });

  const [guests, setGuests] = useState(bookingDetails?.numberOfGuests || 1);
  const [prices, setPrices] = useState({
    basePrice: bookingDetails?.basePrice || 0,
    serviceFee: bookingDetails?.serviceFee || 0,
    taxFee: bookingDetails?.taxFee || 0,
    totalPrice: bookingDetails?.totalPrice || 0
  });

  // Fonction pour gérer l'annulation
  const handleCancelBooking = async () => {
    setIsCancelling(true);
    try {
      const response = await fetch(`${apiUrl}/api/reservation/guest/cancel/${bookingDetails.reservationId}`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      if (data.success) {
        setSuccessMessage("Réservation annulée avec succès");
        setTimeout(() => {
          navigate(`/booking/${bookingDetails.reservationId}`);
        }, 1500);
      } else {
        throw new Error(data.message || "Erreur lors de l'annulation");
      }
    } catch (error) {
      console.error('Erreur:', error);
      setError(error.message || "Une erreur est survenue lors de l'annulation");
    } finally {
      setIsCancelling(false);
      setShowCancelModal(false);
    }
  };

  // Rediriger si pas de détails de réservation
  useEffect(() => {
    if (!bookingDetails) {
      setError("Impossible de charger les détails de la réservation");
      navigate('/bookings', { replace: true });
    }
  }, [bookingDetails, navigate]);

  // Vérifier les changements
  useEffect(() => {
    const originalStartDate = new Date(bookingDetails?.startDate);
    const originalEndDate = new Date(bookingDetails?.endDate);
    
    const hasDateChange = 
      startDate.getTime() !== originalStartDate.getTime() ||
      endDate.getTime() !== originalEndDate.getTime();
    
    const hasGuestChange = guests !== bookingDetails?.numberOfGuests;
    
    setHasChanges(hasDateChange || hasGuestChange);
  }, [startDate, endDate, guests, bookingDetails]);

  // Récupération des dates indisponibles
  useEffect(() => {
    const fetchUnavailableDates = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/workspace/unavailable-dates/${bookingDetails.spaceId}`, {
          credentials: 'include'
        });
        
        if (!response.ok) throw new Error('Erreur réseau');
        
        const data = await response.json();
        
        if (data.success) {
          // Filtrer les dates de la réservation actuelle
          const filteredDates = data.dates.filter(
            date => date.reservation_id !== bookingDetails.reservationId
          );
          setUnavailableDates(filteredDates);
        } else {
          throw new Error(data.message || 'Erreur lors de la récupération des dates');
        }
      } catch (error) {
        console.error('Erreur:', error);
        setError("Impossible de vérifier les disponibilités");
      }
    };

    if (bookingDetails?.spaceId) {
      fetchUnavailableDates();
    }
  }, [bookingDetails?.spaceId, bookingDetails?.reservationId, apiUrl]);

  // Calcul des prix
  useEffect(() => {
    const updatePrices = async () => {
      if (!startDate || !endDate || !hasChanges) return;

      try {
        const response = await fetch(`${apiUrl}/api/workspace/calculate-price`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            espace_id: bookingDetails.spaceId,
            date_debut: format(startDate, 'yyyy-MM-dd'),
            date_fin: format(endDate, 'yyyy-MM-dd'),
            nombre_guests: guests
          })
        });

        if (!response.ok) throw new Error('Erreur réseau');

        const data = await response.json();
        if (data.success) {
          setPrices({
            basePrice: data.price.prix_base,
            serviceFee: data.price.frais_service,
            taxFee: data.price.frais_taxe,
            totalPrice: data.price.prix_total
          });
        } else {
          throw new Error(data.message || 'Erreur lors du calcul du prix');
        }
      } catch (error) {
        console.error('Erreur:', error);
        setError("Impossible de calculer le nouveau prix");
      }
    };

    updatePrices();
  }, [startDate, endDate, guests, hasChanges, apiUrl, bookingDetails?.spaceId]);

  const handleSave = async () => {
    setIsLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      // Validations
      if (!startDate || !endDate || !guests) {
        throw new Error("Veuillez remplir tous les champs");
      }

      if (startDate >= endDate) {
        throw new Error("La date de fin doit être après la date de début");
      }

      const response = await fetch(`${apiUrl}/api/reservation/host/update/${bookingDetails.reservationId}`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          date_debut: format(startDate, 'yyyy-MM-dd'),
          date_fin: format(endDate, 'yyyy-MM-dd'),
          nombre_guests: guests,
          prix_total: prices.totalPrice,
          prix_base: prices.basePrice,
          frais_service: prices.serviceFee,
          frais_taxe: prices.taxFee
        })
      });

      if (!response.ok) throw new Error('Erreur réseau');

      const data = await response.json();

      if (data.success) {
        setSuccessMessage("Modification enregistrée avec succès");
        setTimeout(() => {
          navigate(`/booking/${bookingDetails.reservationId}`);
        }, 1500);
      } else {
        throw new Error(data.message || 'Erreur lors de la modification');
      }
    } catch (error) {
      console.error('Erreur:', error);
      setError(error.message || 'Une erreur est survenue');
    } finally {
      setIsLoading(false);
    }
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    }).format(price);
  };

  // Calcul du nombre de jours entre les dates
  const numberOfDays = useMemo(() => {
    return differenceInDays(endDate, startDate) + 1;
  }, [startDate, endDate]);

  const isDateDisabled = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    if (date < today) return true;

    return unavailableDates.some(({ date_debut, date_fin }) => {
      const checkDate = new Date(date);
      const startDate = new Date(date_debut);
      const endDate = new Date(date_fin);
      return checkDate >= startDate && checkDate <= endDate;
    });
  };

  if (!bookingDetails) return null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-10">
      <div className="max-w-3xl mx-auto px-4">
        {/* En-tête */}
        <div className="flex items-center justify-between mb-8">
          <button
            onClick={() => {
              if (hasChanges) {
                if (window.confirm('Des modifications non sauvegardées seront perdues. Voulez-vous vraiment quitter ?')) {
                  navigate(-1);
                }
              } else {
                navigate(-1);
              }
            }}
            className="flex items-center gap-2 px-4 py-2 rounded-xl text-gray-600 hover:text-gray-900 hover:bg-white transition-all"
          >
            <ChevronLeft className="w-5 h-5" />
            <span>Retour</span>
          </button>
        </div>

        {/* Carte principale */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-3xl shadow-xl overflow-hidden"
        >
          {/* Image et titre */}
          <div className="relative aspect-video">
            <img
              src={`data:image/jpeg;base64,${bookingDetails.spaceImage}`}
              alt={bookingDetails.spaceTitle}
              className="w-full h-full object-cover"
            />
            {hasChanges && (
              <div className="absolute top-4 right-4 px-4 py-2 bg-yellow-400 text-yellow-900 rounded-full text-sm font-medium">
                Modifications non sauvegardées
              </div>
            )}
          </div>

          <div className="p-8">
            <h1 className="text-2xl font-bold text-gray-900 mb-4">
              Modifier la réservation
            </h1>

            <AnimatePresence>
              {error && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  className="p-4 bg-red-50 rounded-xl flex items-start gap-3 mb-6"
                >
                  <AlertCircle className="w-5 h-5 text-red-500 flex-shrink-0 mt-0.5" />
                  <p className="text-red-600">{error}</p>
                </motion.div>
              )}

              {successMessage && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  className="p-4 bg-green-50 rounded-xl flex items-start gap-3 mb-6"
                >
                  <Info className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
                  <p className="text-green-600">{successMessage}</p>
                </motion.div>
              )}
            </AnimatePresence>

            <div className="space-y-6">
              {/* Informations de l'espace */}
              <div className="p-4 rounded-xl bg-gray-50">
                <h2 className="font-semibold text-gray-900 mb-4">{bookingDetails.spaceTitle}</h2>
                <div className="flex items-center gap-2 text-gray-600">
                  <MapPin className="w-4 h-4" />
                  <span>{bookingDetails.spaceAddress}</span>
                </div>
              </div>

              {/* Dates */}
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <h3 className="font-medium text-gray-700">Dates de réservation</h3>
                  <span className="text-sm text-gray-500">
                    {numberOfDays} {numberOfDays > 1 ? 'jours' : 'jour'}
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="relative">
                    <DatePicker
                      selected={startDate}
                      onChange={setStartDate}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      locale={fr}
                      dateFormat="dd/MM/yyyy"
                      filterDate={isDateDisabled}
                      className="w-full p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500 pl-12"
                    />
                    <Calendar className="w-5 h-5 text-gray-400 absolute left-4 top-1/2 -translate-y-1/2" />
                  </div>
                  <div className="relative">
                    <DatePicker
                      selected={endDate}
                      onChange={setEndDate}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      locale={fr}
                      dateFormat="dd/MM/yyyy"
                      filterDate={isDateDisabled}
                      className="w-full p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500 pl-12"
                    />
                    <Calendar className="w-5 h-5 text-gray-400 absolute left-4 top-1/2 -translate-y-1/2" />
                  </div>
                </div>
              </div>

              {/* Nombre de personnes */}
              {/* <div className="space-y-4">
                <h3 className="font-medium text-gray-700">Nombre de personnes</h3>
                <div className="relative">
                  <Users className="w-6 h-6 flex-shrink-0 text-blue-600" />
                  <span className="text-gray-600 font-medium">{bookingDetails.guests} personnes</span>
                </div>
              </div> */}

              {/* Récapitulatif des prix */}
              <div className="p-6 rounded-xl bg-gradient-to-br from-gray-50 to-gray-100 space-y-4">
                <h3 className="font-semibold text-gray-900">Récapitulatif des prix</h3>
                
                {hasChanges && (
                  <div className="p-3 bg-yellow-50 rounded-lg">
                    <p className="text-sm text-yellow-700 flex items-center gap-2">
                      <Info className="w-4 h-4" />
                      Les prix ont été recalculés en fonction des nouvelles dates
                    </p>
                  </div>
                )}

                <div className="space-y-3">
                  <div className="flex justify-between items-center text-gray-600">
                    <div className="flex flex-col">
                      <span>Prix de base</span>
                      <span className="text-sm text-gray-500">
                        {numberOfDays} {numberOfDays > 1 ? 'jours' : 'jour'}
                      </span>
                    </div>
                    <span className="font-medium">{formatPrice(prices.basePrice)}</span>
                  </div>
                  <div className="flex justify-between items-center text-gray-600">
                    <div className="flex flex-col">
                      <span>Frais de service</span>
                      <span className="text-sm text-gray-500">15%</span>
                    </div>
                    <span className="font-medium">{formatPrice(prices.serviceFee)}</span>
                  </div>
                  <div className="flex justify-between items-center text-gray-600">
                    <div className="flex flex-col">
                      <span>Taxes</span>
                      <span className="text-sm text-gray-500">TVA</span>
                    </div>
                    <span className="font-medium">{formatPrice(prices.taxFee)}</span>
                  </div>
                  <div className="pt-4 border-t border-gray-200">
                    <div className="flex justify-between items-center">
                      <span className="font-semibold text-gray-900">Total</span>
                      <span className="text-lg font-bold text-gray-900">
                        <Euro className="w-5 h-5 inline-block mr-1" />
                        {formatPrice(prices.totalPrice)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Boutons d'action */}
            <div className="flex gap-4 mt-8">
              <button
                  onClick={() => setShowCancelModal(true)}
                  className="px-6 py-3 rounded-xl border border-red-200 text-red-600 font-medium hover:bg-red-50 transition-colors flex items-center justify-center gap-2"
                >
                <Trash2 className="w-5 h-5" />
                <span>Annuler la réservation</span>
              </button>
              <button
                onClick={() => {
                  if (hasChanges) {
                    if (window.confirm('Des modifications non sauvegardées seront perdues. Voulez-vous vraiment annuler ?')) {
                      navigate(-1);
                    }
                  } else {
                    navigate(-1);
                  }
                }}
                className="flex-1 px-6 py-3 rounded-xl border border-gray-200 text-gray-600 font-medium hover:bg-gray-50 transition-colors"
              >
                Annuler
              </button>
              <button
                onClick={handleSave}
                disabled={isLoading || !hasChanges}
                className="flex-1 px-6 py-3 rounded-xl bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:hover:bg-blue-600"
              >
                {isLoading ? (
                  <span className="flex items-center justify-center gap-2">
                    <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                    Enregistrement...
                  </span>
                ) : hasChanges ? (
                  'Enregistrer les modifications'
                ) : (
                  'Aucun changement à enregistrer'
                )}
              </button>
            </div>
          </div>

          {/* Modal d'annulation */}
          <CancelBookingModal 
            isOpen={showCancelModal}
            onClose={() => setShowCancelModal(false)}
            onConfirm={handleCancelBooking}
            isLoading={isCancelling}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default EditBooking;