import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  ArrowRight,
  HelpCircle,
  Rocket,
  Calendar,
  DollarSign,
  Globe,
  Users,
  CheckCircle2,
  Star
} from 'lucide-react';
import SaveQuitButton from '../../../components/SaveQuitButton';
import publicationImage from '../../../assets/publish-house-illustration.webp';

const Feature = ({ icon: Icon, title, description }) => (
  <div className="flex items-start gap-4 p-6 bg-white rounded-2xl border border-gray-100 
                  hover:border-blue-200 hover:shadow-lg transform transition-all duration-300 hover:-translate-y-1">
    <div className="p-3 bg-blue-50 rounded-xl">
      <Icon className="w-6 h-6 text-blue-500" />
    </div>
    <div>
      <h3 className="font-semibold text-gray-900 mb-1">{title}</h3>
      <p className="text-gray-600 text-sm leading-relaxed">{description}</p>
    </div>
  </div>
);

const StepProgress = ({ currentStep, totalSteps }) => (
  <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                  bg-white rounded-full shadow-lg p-2 border border-gray-200">
    <div className="flex items-center gap-2 px-3 py-1">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`rounded-full transition-all duration-300 
                    ${index < currentStep ? 'w-8 h-2 bg-blue-500' : 'w-2 h-2 bg-gray-200'}`}
        />
      ))}
    </div>
  </div>
);

export default function StepThreePublish() {
  const navigate = useNavigate();

  const features = [
    {
      icon: Calendar,
      title: "Mode de réservation",
      description: "Définissez vos conditions de réservation et votre calendrier de disponibilité"
    },
    {
      icon: DollarSign,
      title: "Tarification",
      description: "Fixez vos tarifs et gérez vos options de paiement en toute simplicité"
    },
    {
      icon: Globe,
      title: "Visibilité",
      description: "Votre espace sera visible par des milliers de télétravailleurs"
    },
    {
      icon: Users,
      title: "Communauté",
      description: "Rejoignez une communauté active de propriétaires d'espaces de travail"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
      {/* Header */}
      <header className="fixed top-0 inset-x-0 bg-white/80 backdrop-blur-sm border-b border-gray-200 z-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex items-center justify-between">
          <div className="flex items-center gap-8">
            <button onClick={() => navigate('/become-host/steptwodetaileddescription')}
                    className="text-gray-400 hover:text-gray-600 transition-colors">
              <ArrowLeft className="w-6 h-6" />
            </button>
            <h1 className="text-xl font-semibold text-gray-900">Publication de l'annonce</h1>
          </div>
          <SaveQuitButton />
        </div>

        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-blue-50 rounded-lg">
              <Rocket className="w-5 h-5 text-blue-500" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Dernière étape</p>
              <h2 className="font-medium text-gray-900">Configuration finale de votre annonce</h2>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 pt-40 pb-48">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Left Column - Text Content */}
          <div>
            <div className="mb-8">
              <div className="inline-flex items-center gap-2 px-4 py-2 bg-blue-50 rounded-full 
                            text-blue-600 text-sm font-medium mb-4">
                <Star className="w-4 h-4" />
                <span>Étape finale</span>
              </div>
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                Prêt à accueillir vos premiers télétravailleurs ?
              </h2>
              <p className="text-lg text-gray-600 leading-relaxed">
                Plus que quelques détails à configurer avant de publier votre espace 
                et commencer à recevoir des réservations.
              </p>
            </div>

            {/* Features Grid */}
            <div className="grid gap-4">
              {features.map((feature, index) => (
                <Feature key={index} {...feature} />
              ))}
            </div>
          </div>

          {/* Right Column - Image */}
          <div className="relative">
            <div className="relative rounded-3xl overflow-hidden aspect-[4/3] bg-gradient-to-br from-blue-500 to-blue-600">
              <img
                src={publicationImage}
                alt="Publication de l'espace"
                className="w-full h-full object-cover"
              />
              {/* Overlay Effects */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-transparent to-transparent" />
              
              {/* Success Indicators */}
              <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
                <div className="bg-white/20 backdrop-blur-sm rounded-2xl p-6 text-center">
                  <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center mx-auto mb-4">
                    <CheckCircle2 className="w-8 h-8 text-blue-500" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Presque terminé !</h3>
                  <p className="text-white/90">Votre espace sera bientôt en ligne</p>
                </div>
              </div>
            </div>

            {/* Decorative Elements */}
            <div className="absolute -z-10 inset-0 transform translate-x-4 translate-y-4">
              <div className="absolute inset-0 bg-blue-100/50 rounded-3xl" />
            </div>
          </div>
        </div>
      </main>

      <StepProgress currentStep={11} totalSteps={14} />

      {/* Footer */}
      <footer className="fixed bottom-0 inset-x-0 bg-white/80 backdrop-blur-sm border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center gap-3 text-gray-500">
            <HelpCircle className="w-5 h-5" />
            <span>Besoin d'aide ?</span>
          </div>

          <button
            onClick={() => navigate('/become-host/stepthreereservationmode')}
            className="group px-8 py-3 rounded-xl flex items-center gap-2 
                     bg-blue-600 text-white hover:bg-blue-500 
                     hover:shadow-xl hover:shadow-blue-500/20 
                     transition-all duration-300 hover:-translate-y-0.5"
          >
            <span>Configuration finale</span>
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </button>
        </div>
      </footer>
    </div>
  );
}