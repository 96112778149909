import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { 
  FaUser, FaLock, FaEnvelope, FaBell, FaShieldAlt, 
  FaCreditCard, FaTrash, FaEdit, FaCheck, FaTimes,
  FaPhone, FaLanguage, FaGlobe, FaKey, FaHistory,
  FaDownload, FaSignOutAlt, FaUserShield, FaToggleOn,
  FaToggleOff, FaCog, FaMapMarkerAlt, FaRegBuilding,
  FaExclamationCircle, FaCheckCircle
} from 'react-icons/fa';
import ToastNotification from '@/components/ToastNotification';

const FormField = ({ label, children, description, error }) => (
  <div className="mb-4 sm:mb-6">
    <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
    {children}
    {description && (
      <p className="mt-1 text-sm text-gray-500">{description}</p>
    )}
    {error && (
      <p className="mt-1 text-sm text-red-600 flex items-center">
        <FaExclamationCircle className="mr-1" />
        {error}
      </p>
    )}
  </div>
);

const BillingTab = ({ userData, onUpdate }) => {
  const [hostProfile, setHostProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddIbanForm, setShowAddIbanForm] = useState(false);
  const [newIban, setNewIban] = useState('');
  const [success, setSuccess] = useState('');
  const [reservations, setReservations] = useState([]);
  const apiUrl = process.env.REACT_APP_API_GATEWAY;
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  const showNotification = (message, type = 'success') => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);
    // Auto-hide after 3 seconds
    setTimeout(() => setShowToast(false), 3000);
  };

  const fetchHostProfile = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_GATEWAY}/api/auth/account/iban`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error('Erreur lors du chargement du profil');
      }

      const data = await response.json();
      if (data.success) {
        setHostProfile(data.data);
        setError(null);
      } else {
        throw new Error(data.message || 'Erreur lors du chargement du profil');
      }
    } catch (err) {
      console.error("Error fetching host profile:", err);
      setError("Impossible de charger les informations du profil");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHostProfile();
  }, []);

  const handleUpdateIban = async () => {
    const cleanedIban = newIban.replace(/\s/g, '').toUpperCase();
    
    const ibanRegex = /^FR\d{2}[A-Z0-9]{23}$/;
    if (!ibanRegex.test(cleanedIban)) {
      showNotification("Format d'IBAN invalide. Veuillez entrer un IBAN valide", 'error');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_GATEWAY}/api/auth/account/iban`,
        {
          method: 'PUT',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ iban: cleanedIban }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      if (data.success) {
        setHostProfile(data.data);
        setShowAddIbanForm(false);
        setNewIban('');
        setSuccess('IBAN mis à jour avec succès');
        setError(null);
        await fetchHostProfile();
        showNotification('IBAN mis à jour avec succès');
      }
    } catch (err) {
      showNotification(err.message || "Impossible de mettre à jour l'IBAN", 'error');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd MMMM yyyy', { locale: fr });
  };

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/reservation/guest/mybookings`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        
        if (data.success) {
          setReservations(Array.isArray(data.data) ? data.data : []);
        } else {
          setError(data.message);
          setReservations([]);
        }
      } catch (error) {
        console.error('Erreur:', error);
        setError('Erreur lors de la récupération des réservations');
        setReservations([]);
      } finally {
        setLoading(false);
      }
    };
  
    fetchReservations();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-4 sm:space-y-6">
      <h2 className="text-lg sm:text-xl font-semibold mb-4 sm:mb-6">Informations de paiement</h2>

      {success && (
        <div className="mb-4 p-3 sm:p-4 bg-green-50 text-green-700 rounded-xl flex items-center">
          <FaCheckCircle className="mr-2" />
          {success}
        </div>
      )}

      <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4 sm:mb-6">
          <h3 className="text-base sm:text-lg font-medium mb-4 sm:mb-0">Coordonnées bancaires</h3>
          {!hostProfile?.iban && !showAddIbanForm && (
            <button 
              onClick={() => setShowAddIbanForm(true)}
              className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
            >
              Ajouter un IBAN
            </button>
          )}
        </div>

        {error && (
          <div className="mb-4 p-3 sm:p-4 bg-red-50 text-red-600 rounded-lg flex items-center">
            <FaExclamationCircle className="mr-2" />
            {error}
          </div>
        )}

        {showAddIbanForm ? (
          <div className="space-y-4">
            <FormField 
              label="IBAN" 
              description="Entrez votre IBAN pour recevoir vos paiements"
            >
              <input
                type="text"
                value={newIban}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^A-Za-z0-9]/g, '');
                  value = value.toUpperCase();
                  value = value.slice(0, 27);
                  value = value.replace(/(.{4})/g, '$1 ').trim();
                  setNewIban(value);
                }}
                maxLength={34}
                placeholder="FR76 XXXX XXXX XXXX XXXX XXXX XXX"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
              />
            </FormField>
            <div className="flex flex-col sm:flex-row gap-2">
              <button
                onClick={handleUpdateIban}
                className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
              >
                Sauvegarder
              </button>
              <button
                onClick={() => {
                  setShowAddIbanForm(false);
                  setError(null);
                }}
                className="w-full sm:w-auto px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition"
              >
                Annuler
              </button>
            </div>
          </div>
        ) : hostProfile?.iban ? (
          <div className="p-3 sm:p-4 border rounded-lg flex flex-col sm:flex-row sm:items-center sm:justify-between">
            <div className="flex items-center gap-4 mb-4 sm:mb-0">
              <div className="p-2 bg-gray-100 rounded">
                <FaRegBuilding className="text-gray-600" />
              </div>
              <div>
                <p className="font-medium text-sm sm:text-base break-all">
                  IBAN: {hostProfile.iban.replace(/(\w{4})/g, '$1 ').trim()}
                </p>
                <p className="text-xs sm:text-sm text-gray-500">
                  Mis à jour le {new Date(hostProfile.updated_at).toLocaleDateString()}
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                setNewIban(hostProfile.iban);
                setShowAddIbanForm(true);
              }}
              className="w-full sm:w-auto px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg transition text-center"
            >
              Modifier
            </button>
          </div>
        ) : (
          <p className="text-gray-500">Aucun IBAN enregistré</p>
        )}
      </div>

      <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6">
        <h3 className="text-base sm:text-lg font-medium mb-4 sm:mb-6">Historique des paiements</h3>
        <div className="space-y-4">
          {reservations.map((reservation) => (
            <div key={reservation.reservation_id} className="flex flex-col sm:flex-row sm:items-center sm:justify-between p-3 sm:p-4 bg-gray-50 rounded-lg">
              <div className="mb-2 sm:mb-0">
                <p className="font-medium text-sm sm:text-base">{reservation.espace_titre}</p>
                <p className="text-xs sm:text-sm text-gray-500">{formatDate(reservation.date_debut)}</p>
              </div>
              <div className="text-right">
                <p className="font-medium text-sm sm:text-base">{reservation.prix_total} €</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showToast && (
        <div
          className={`fixed bottom-4 right-4 p-4 rounded-xl shadow-lg flex items-center space-x-2 transition-all transform animate-toast-enter
            ${toastType === 'success' ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'}`}
        >
          <div className="flex-shrink-0">
            {toastType === 'success' ? (
              <FaCheckCircle className="h-5 w-5" />
            ) : (
              <FaExclamationCircle className="h-5 w-5" />
            )}
          </div>
          <p className="text-sm font-medium">{toastMessage}</p>
          <button
            onClick={() => setShowToast(false)}
            className="ml-4 text-gray-400 hover:text-gray-600"
          >
            <FaTimes className="h-4 w-4" />
          </button>
        </div>
      )}
    </div>
  );
};

const DeleteAccountSection = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [hasDeletionRequest, setHasDeletionRequest] = useState(false);
  const [deletionDate, setDeletionDate] = useState(null);

  useEffect(() => {
    const checkDeletionRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_GATEWAY}/api/auth/account/delete/check`,
          {
            credentials: 'include'
          }
        );

        if (response.ok) {
          const data = await response.json();
          setHasDeletionRequest(data.hasDeletionRequest);
          setDeletionDate(data.deletionDate);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification de la demande:', error);
      }
    };

    checkDeletionRequest();
  }, []);

  const handleDeleteAccount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/api/auth/account/delete`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (response.ok) {
        setShowConfirmModal(false);
        setToastMessage('Demande de suppression envoyée avec succès. Votre compte sera supprimé sous peu.');
        setShowToast(true);
      } else {
        const data = await response.json();
        throw new Error(data.message || 'Erreur lors de la suppression du compte');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression du compte:', error);
      setToastMessage(error.message || 'Erreur lors de la suppression du compte');
      setShowToast(true);
    }
  };

  return (
    <>
      <div className="mt-8 sm:mt-12 border-t pt-6 sm:pt-8">
        <div className="bg-red-50 rounded-xl p-4 sm:p-6">
          <h3 className="text-base sm:text-lg font-medium text-red-800 mb-2">
            {hasDeletionRequest ? 'Demande de suppression en cours' : 'Demande de suppression du compte'}
          </h3>
          
          {hasDeletionRequest ? (
            <>
              <div className="mb-4 p-3 sm:p-4 bg-yellow-50 text-yellow-700 rounded-xl flex items-center">
                <FaExclamationCircle className="mr-2" />
                <div>
                  <p className="font-medium">Votre demande de suppression est en cours de traitement</p>
                  <p className="text-sm mt-1">
                    Demande effectuée le {new Date(deletionDate).toLocaleDateString()}. 
                    La suppression sera effective dans les 30 jours suivant votre demande.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="text-xs sm:text-sm text-red-600 mb-4">
                La suppression de votre compte est définitive et peut prendre jusqu'à 30 jours pour être prise en compte. 
                Toutes vos données seront effacées.
              </p>
              <button 
                onClick={() => setShowConfirmModal(true)}
                className="w-full sm:w-auto px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition"
              >
                Supprimer mon compte
              </button>
            </>
          )}
        </div>
      </div>

      {/* Modal de confirmation - seulement affiché si pas de demande en cours */}
      {!hasDeletionRequest && showConfirmModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Confirmation de suppression</h3>
            <p className="text-gray-600 mb-6">
              Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible.
            </p>
            {error && (
              <p className="text-red-600 text-sm mb-4">
                {error}
              </p>
            )}
            <div className="flex gap-4">
              <button
                onClick={() => {
                  setShowConfirmModal(false);
                  setError(null);
                }}
                className="flex-1 px-2 py-1 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition"
              >
                Annuler
              </button>
              <button
                onClick={handleDeleteAccount}
                className="flex-1 px-2 py-1 bg-red-600 text-white rounded-lg hover:bg-red-700 transition"
              >
                Confirmer la suppression
              </button>
            </div>
          </div>
        </div>
      )}

      {showToast && (
        <ToastNotification
          message={toastMessage}
          type={toastMessage.includes('Erreur') ? 'error' : 'success'}
          duration={3000}
          onClose={() => setShowToast(false)}
        />
      )}
    </>
  );
};

const Account = () => {
  const [activeTab, setActiveTab] = useState('security');
  const [hostProfile, setHostProfile] = useState(null);
  const [userData, setUserData] = useState(null);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const navigationItems = [
    { id: 'billing', label: 'Paiements', icon: FaCreditCard }
  ];

  const handleGetAccountData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_GATEWAY}/api/auth/account/iban`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données');
      }

      const data = await response.json();
      
      if (data.success) {
        setHostProfile(data.data.bankInfo);
        setPayments(data.data.payments || []);
        setUserData(data.data.userInfo);
        setError(null);
      } else {
        throw new Error('Aucune donnée reçue');
      }
    } catch (err) {
      console.error('Error fetching account data:', err);
      setError(err.message || "Impossible de récupérer les informations du compte");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUser = async (updateData) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_GATEWAY}/api/auth/account/iban`,
        {
          method: 'PUT',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updateData)
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      if (data.success) {
        await handleGetAccountData();
      }
    } catch (err) {
      setError(err.message || "Impossible de mettre à jour les informations");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAccountData();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-6 sm:py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {error && (
          <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-xl">
            <p className="text-red-600 text-sm">{error}</p>
          </div>
        )}

        <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
          {/* Header */}
          <div className="p-4 sm:p-6 bg-gradient-to-r from-blue-600 to-blue-700">
            <div className="flex items-center justify-between">
              <h1 className="text-xl sm:text-2xl font-bold text-white">Paramètres du compte</h1>
              <button 
                onClick={toggleSidebar}
                className="sm:hidden text-white p-2"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              </button>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row min-h-[600px]">
            {/* Sidebar Navigation - Mobile */}
            <div className={`
              fixed inset-0 bg-black bg-opacity-50 z-40 sm:hidden
              ${isSidebarOpen ? 'block' : 'hidden'}
            `} onClick={toggleSidebar}>
              <div 
                className="absolute right-0 inset-y-0 w-64 bg-white shadow-xl p-6"
                onClick={e => e.stopPropagation()}
              >
                <nav className="space-y-2">
                  {navigationItems.map(({ id, label, icon: Icon }) => (
                    <button
                      key={id}
                      onClick={() => {
                        setActiveTab(id);
                        setIsSidebarOpen(false);
                      }}
                      className="w-full flex items-center gap-3 px-4 py-3 rounded-xl transition-colors bg-blue-50 text-blue-600"
                    >
                      <Icon className="flex-shrink-0" />
                      <span className="font-medium">{label}</span>
                    </button>
                  ))}
                </nav>
              </div>
            </div>

            {/* Sidebar Navigation - Desktop */}
            <div className="hidden sm:block w-64 border-r border-gray-200 p-6">
              <nav className="space-y-2">
                {navigationItems.map(({ id, label, icon: Icon }) => (
                  <button
                    key={id}
                    onClick={() => setActiveTab(id)}
                    className="w-full flex items-center gap-3 px-4 py-3 rounded-xl transition-colors bg-blue-50 text-blue-600"
                  >
                    <Icon className="flex-shrink-0" />
                    <span className="font-medium">{label}</span>
                  </button>
                ))}
              </nav>
            </div>

            {/* Main Content */}
            <div className="flex-1 p-4 sm:p-8">
              <AnimatePresence mode="wait">
                <motion.div
                  key="billing"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                >
                  <BillingTab 
                    userData={userData} 
                    hostProfile={hostProfile}
                    payments={payments}
                    onUpdate={handleUpdateUser}
                  />
                </motion.div>
              </AnimatePresence>

              {/* Delete Account Section */}
              <DeleteAccountSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;