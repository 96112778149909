import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes, FaRegCalendarAlt, FaRegEnvelope } from 'react-icons/fa';
import { MdDashboard, MdAnnouncement } from 'react-icons/md';
import { BiBell, BiCalendar, BiHeart } from 'react-icons/bi';
import { motion, AnimatePresence } from 'framer-motion';
import ProfileMenu from './ProfileMenu';
import { useAuth } from '../context/AuthContext';
import { useWebSocket } from '../context/WebSocketContext';
import { useNotifications } from '../context/NotificationContext';

function Header({ isLoggedIn, setIsLoggedIn }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { loading, userMode, setMode, userId } = useAuth(); // Ensure userId is available
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const { socket, messages } = useWebSocket();
  const { notifications, privateMessages, markPrivateMessagesAsRead } = useNotifications(); // Destructure markPrivateMessagesAsRead

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);


  const unreadNotificationsCount = notifications.filter(notification => !notification.est_lu).length;
  const unreadMessagesCount = privateMessages.filter(message => !message.est_lu && message.receiverId === userId).length; // Updated filter

  const handleSelectConversation = (conversation) => {
    dispatch({ type: 'SET_SELECTED_MESSAGE', payload: conversation });
    dispatch({ type: 'MARK_MESSAGES_AS_READ', payload: conversation.id }); // Mark messages as read
    markPrivateMessagesAsRead(conversation.id); // Update privateMessages as read
  };

  const NavLink = ({ to, children, icon: Icon, badge }) => {
    const location = useLocation();
    const isActive = location.pathname === to;
    
    return (
      <Link
        to={to}
        className={`relative flex items-center gap-2 px-4 py-2.5 rounded-xl font-medium transition-all
          ${isActive 
            ? 'text-blue-600 bg-blue-50' 
            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
          }`}
      >
        {Icon && <Icon className={`text-lg ${isActive ? 'text-blue-600' : 'text-gray-400'}`} />}
        {children}
        {badge && (
          <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
            {badge}
          </span>
        )}
      </Link>
    );
  };

  return (
    <header className="w-full bg-white shadow-sm top-0 left-0 right-0 z-40">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          {isLoggedIn && userMode == 'host' ? (
            <Link to="/hosting" className="text-2xl font-bold text-blue-600">
              HomeOfficeShare
            </Link>
          ) : isLoggedIn && userMode == 'guest' ? (
            <Link to="/dashboard" className="text-2xl font-bold text-blue-600">
              HomeOfficeShare
            </Link>
          ) : (
            <Link to="/" className="text-2xl font-bold text-blue-600">
              HomeOfficeShare
            </Link>
          )

          }

          {/* Navigation Desktop */}
          <nav className="hidden md:flex items-center space-x-2">
            {isLoggedIn && userMode === 'host' ? (
              <>
                <NavLink to="/host-dashboard" icon={MdDashboard}>Aujourd'hui</NavLink>
                {/* <NavLink to="/calendar" icon={FaRegCalendarAlt}>Calendrier</NavLink> */}
                <NavLink to="/listings" icon={MdAnnouncement}>Mes Annonces</NavLink>
                <NavLink to="/messages" icon={FaRegEnvelope} badge={unreadMessagesCount > 0 ? unreadMessagesCount : null}>Messages</NavLink>
              </>
            ) : isLoggedIn && userMode === 'guest' ? (
              <>
              <NavLink to="/bookings" icon={BiCalendar}>
                Mes réservations
              </NavLink>
              </>
            ) : (
              <>
                <NavLink to="/">Accueil</NavLink>
                {/* <NavLink to="/decouvrir">Découvrir</NavLink> */}
                <NavLink to="/become-host">Devenir Hôte</NavLink>
                <NavLink to="/aide">Aide</NavLink>
                <NavLink to="/contact">Contact</NavLink>
              </>
            )}
          </nav>

          {/* Auth Buttons Desktop */}
          <div className="hidden md:flex items-center space-x-4">
            {isLoggedIn ? (
              <div className="flex items-center gap-4">
                <NavLink to="/notifications" icon={BiBell} badge={unreadNotificationsCount}>
                  <span className="sr-only">Notifications</span>
                </NavLink>
                <ProfileMenu isLoggedIn={isAuthenticated} setIsLoggedIn={setIsAuthenticated} />
              </div>
            ) : (
              <div className="flex items-center space-x-3">
                <Link 
                  to="/login" 
                  className="text-gray-600 hover:text-gray-900 font-medium px-4 py-2 hover:bg-gray-50 rounded-xl transition-all"
                >
                  Connexion
                </Link>
                <Link 
                  to="/signup" 
                  className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-xl font-medium transition-all hover:scale-105 shadow-md"
                >
                  Inscription
                </Link>
              </div>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="md:hidden p-2 rounded-xl hover:bg-gray-50 text-gray-600 transition-colors"
          >
            {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>

      {/* Menu Mobile avec z-index ajusté */}
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className=""
          >
            <div className="px-4 py-6 space-y-4 max-h-[calc(100vh-4rem)] overflow-y-auto">
              {isLoggedIn && userMode === 'host' ? (
                <div className="grid gap-3">
                  <MenuItem to="/host-dashboard" icon={MdDashboard}>Aujourd'hui</MenuItem>
                  {/* <MenuItem to="/calendar" icon={FaRegCalendarAlt}>Calendrier</MenuItem> */}
                  <MenuItem to="/listings" icon={MdAnnouncement}>Mes Annonces</MenuItem>
                  <MenuItem to="/messages" icon={FaRegEnvelope} badge={unreadMessagesCount > 0 ? unreadMessagesCount : null}>Messages</MenuItem>
                  <MenuItem to="/notifications" icon={BiBell} badge={unreadNotificationsCount}>Notifications</MenuItem>
                  <div className="pt-4">
                    <ProfileMenu isMobile />
                  </div>
                </div>
              ) : isLoggedIn && userMode === 'guest' ? (
                <div className="pt-4">
                  <ProfileMenu isMobile />
                </div>
              ) : (
                <>
                  <MenuItem to="/">Accueil</MenuItem>
                  {/* <MenuItem to="/decouvrir">Découvrir</MenuItem> */}
                  <MenuItem to="/become-host">Devenir Hôte</MenuItem>
                  <MenuItem to="/aide">Aide</MenuItem>
                  <div className="pt-4 space-y-3">
                    <Link 
                      to="/login" 
                      className="block w-full text-center py-3 border border-gray-200 rounded-xl text-gray-700 font-medium hover:bg-gray-50 transition-all"
                    >
                      Connexion
                    </Link>
                    <Link 
                      to="/signup" 
                      className="block w-full text-center py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-xl font-medium transition-all"
                    >
                      Inscription
                    </Link>
                  </div>
                </>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}

// Composant MenuItem pour le menu mobile
const MenuItem = ({ to, icon: Icon, children, badge }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={`relative flex items-center gap-3 p-3 rounded-xl font-medium transition-all ${
        isActive
          ? 'bg-blue-50 text-blue-600'
          : 'text-gray-600 hover:bg-gray-50'
      }`}
    >
      {Icon && <Icon className={`text-xl ${isActive ? 'text-blue-600' : 'text-gray-400'}`} />}
      {children}
      {badge && (
        <span className="absolute top-1 right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
          {badge}
        </span>
      )}
    </Link>
  );
};

export default Header;