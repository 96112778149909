import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  ArrowRight,
  HelpCircle,
  Pencil,
  Star,
  Info,
  CheckCircle2,
  MessageSquare
} from 'lucide-react';
import { useForm } from '../../../context/FormHostContext';
import SaveQuitButton from '../../../components/SaveQuitButton';

const WritingTip = ({ icon: Icon, title, description }) => (
  <div className="p-4 bg-white rounded-xl border border-gray-200 hover:border-blue-200 
                  transition-all duration-300 hover:-translate-y-1">
    <div className="flex items-start gap-3">
      <div className="p-2 bg-blue-50 rounded-lg text-blue-500">
        <Icon className="w-5 h-5" />
      </div>
      <div>
        <h3 className="font-medium text-gray-900 mb-1">{title}</h3>
        <p className="text-sm text-gray-600">{description}</p>
      </div>
    </div>
  </div>
);

const StepProgress = ({ currentStep, totalSteps }) => (
  <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                  bg-white rounded-full shadow-lg p-2 border border-gray-200">
    <div className="flex items-center gap-2 px-3 py-1">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`rounded-full transition-all duration-300 
                    ${index < currentStep ? 'w-8 h-2 bg-blue-500' : 'w-2 h-2 bg-gray-200'}`}
        />
      ))}
    </div>
  </div>
);

export default function StepTwoDetailedDescription() {
  const navigate = useNavigate();
  const [description, setDescription] = useState('');
  const maxLength = 500;
  const { handleChange } = useForm();

  const writingTips = [
    {
      icon: MessageSquare,
      title: "Détaillez l'ambiance",
      description: "Décrivez l'atmosphère et le style de votre espace"
    },
    {
      icon: Star,
      title: "Points forts",
      description: "Mettez en avant ce qui rend votre espace unique"
    },
    {
      icon: Info,
      title: "Informations pratiques",
      description: "Mentionnez l'accessibilité et les services à proximité"
    }
  ];

  const handleDescriptionChange = (value) => {
    setDescription(value);
    handleChange('description', value);
  };

  const getProgressStatus = () => {
    const length = description.trim().length;
    if (length === 0) return { color: 'gray', message: 'Commencez à écrire' };
    if (length < 100) return { color: 'yellow', message: 'Un peu court' };
    if (length < 200) return { color: 'green', message: 'Bonne longueur' };
    return { color: 'blue', message: 'Description détaillée' };
  };

  const status = getProgressStatus();

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
      {/* Header */}
      <header className="fixed top-0 inset-x-0 bg-white/80 backdrop-blur-sm border-b border-gray-200 z-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex items-center justify-between">
          <div className="flex items-center gap-8">
            <button onClick={() => navigate('/become-host/steptwodescription')}
                    className="text-gray-400 hover:text-gray-600 transition-colors">
              <ArrowLeft className="w-6 h-6" />
            </button>
            <h1 className="text-xl font-semibold text-gray-900">Description détaillée</h1>
          </div>
          <SaveQuitButton />
        </div>

        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-3">
              <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center">
                <Pencil className="w-4 h-4 text-blue-500" />
              </div>
              <span className="font-medium text-gray-900">
                {description.length}/{maxLength} caractères
              </span>
            </div>
            <div className={`flex items-center gap-2 text-sm
                          ${status.color === 'blue' ? 'text-blue-500' : 
                            status.color === 'green' ? 'text-green-500' :
                            status.color === 'yellow' ? 'text-amber-500' : 
                            'text-gray-400'}`}>
              <CheckCircle2 className="w-4 h-4" />
              <span>{status.message}</span>
            </div>
          </div>
          <div className="h-1.5 bg-gray-100 rounded-full overflow-hidden">
            <div
              className={`h-full rounded-full transition-all duration-500 ease-out
                       ${status.color === 'blue' ? 'bg-blue-500' : 
                         status.color === 'green' ? 'bg-green-500' :
                         status.color === 'yellow' ? 'bg-amber-500' : 
                         'bg-gray-200'}`}
              style={{ width: `${Math.min((description.length / 200) * 100, 100)}%` }}
            />
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 pt-40 pb-48">
        <div className="grid md:grid-cols-2 gap-8">
          {/* Left Column - Writing Area */}
          <div>
            <textarea
              value={description}
              onChange={(e) => handleDescriptionChange(e.target.value)}
              placeholder="Décrivez ce qui rend votre espace unique..."
              maxLength={maxLength}
              className="w-full h-80 p-6 text-lg rounded-2xl border-2 border-gray-200 
                       focus:border-blue-500 focus:ring-4 focus:ring-blue-500/10 
                       transition-all duration-300 resize-none
                       bg-white/80 backdrop-blur-sm"
            />
          </div>

          {/* Right Column - Writing Tips */}
          <div className="space-y-4">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">
              Conseils pour une description efficace
            </h2>
            {writingTips.map((tip, index) => (
              <WritingTip key={index} {...tip} />
            ))}
            
            {description.length > 0 && (
              <div className="mt-8 p-6 bg-blue-50/50 rounded-xl border border-blue-100">
                <div className="flex items-center gap-2 text-blue-600 mb-2">
                  <Star className="w-5 h-5" />
                  <h3 className="font-medium">Aperçu de votre description</h3>
                </div>
                <p className="text-gray-700 leading-relaxed">
                  {description}
                </p>
              </div>
            )}
          </div>
        </div>
      </main>

      <StepProgress currentStep={10} totalSteps={14} />

      {/* Footer */}
      <footer className="fixed bottom-0 inset-x-0 bg-white/80 backdrop-blur-sm border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center gap-3 text-gray-500">
            <HelpCircle className="w-5 h-5" />
            <span>Besoin d'aide ?</span>
          </div>

          <button
            onClick={() => navigate('/become-host/stepthreepublish')}
            disabled={!description.trim()}
            className={`group px-8 py-3 rounded-xl flex items-center gap-2 
                     transition-all duration-300 
                     ${description.trim()
                       ? 'bg-blue-600 text-white hover:bg-blue-500 hover:shadow-xl hover:shadow-blue-500/20 hover:-translate-y-0.5' 
                       : 'bg-gray-100 text-gray-400 cursor-not-allowed'}`}
          >
            <span>Continuer</span>
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </button>
        </div>
      </footer>
    </div>
  );
}