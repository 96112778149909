// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './context/AuthContext';
import { FormProvider } from './context/FormHostContext';
import { WebSocketProvider } from './context/WebSocketContext';
import { NotificationProvider } from './context/NotificationContext';
import './styles/Global.css';

// Composant Header et Footer - Pages Publiques
import Header from './components/Header';
import Footer from './components/Footer';

// Les pages publiques de HomeOfficeShare
import Home from './pages/Home';
import Discover from './pages/Discover';
import CitySpaces from './pages/CitySpaces';
import SpaceDetails from './pages/SpaceDetails';
import Help from './pages/Help';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import TermsOfService from './pages/Legal-Policies/TermsOfService';
import PrivacyPolicy from './pages/Legal-Policies/PrivacyPolicy';
import ContactSupport from './pages/ContactSupport';

import BecomeHost from './pages/BecomeHost/BecomeHost';
import Intro from './pages/BecomeHost/Intro';
import WelcomeStep from './pages/BecomeHost/WelcomeStep';

// BecomeHost - Step 1
import StepOneIntro from './pages/BecomeHost/StepOne/StepOneIntro';
import StepOneType from './pages/BecomeHost/StepOne/StepOneType';
import StepOneEmplacement from './pages/BecomeHost/StepOne/StepOneEmplacement';
import StepOneCapacity from './pages/BecomeHost/StepOne/StepOneCapacity';

// BecomeHost - Step 2
import StepTwoIntro from './pages/BecomeHost/StepTwo/StepTwoIntro';
import StepTwoEquipments from './pages/BecomeHost/StepTwo/StepTwoEquipments';
import StepTwoPhotos from './pages/BecomeHost/StepTwo/StepTwoPhotos';
import StepTwoTitle from './pages/BecomeHost/StepTwo/StepTwoTitle';
import StepTwoDescription from './pages/BecomeHost/StepTwo/StepTwoDescription';
import StepTwoDetailedDescription from './pages/BecomeHost/StepTwo/StepTwoDetailedDescription';

// BecomeHost - Step 3
import StepThreePublish from './pages/BecomeHost/StepThree/StepThreePublish';
import StepThreeReservationMode from './pages/BecomeHost/StepThree/StepThreeReservationMode';
import StepThreePricing from './pages/BecomeHost/StepThree/StepThreePricing';
import StepThreeReview from './pages/BecomeHost/StepThree/StrepThreeReview';
import FinalStepCongrats from './pages/BecomeHost/FinalStepCongrats';
// Hosts Menu
import Profile from './pages/Profile';
import Account from './pages/Account';
import HostDashboard from './pages/Host/HostDashboard';
import HostCalendar from './pages/Host/Calendar';
import Messages from './pages/Host/Messages';
import ListingsPage from './pages/Host/Listings';
import EditListingPage from './pages/Host/EditListingPage';
import NotificationPage from './pages/Notifications';
import Bookings from './pages/Bookings';
import BookingSuccess from './pages/BookingSuccess';
import BookingDetails from './pages/BookingDetails';
import Favorites from './pages/Favorites';
import EditBooking from './pages/EditBooking';
import BookingsList from './pages/Host/BookingsList';

// Page Avis
import Reviews from './pages/Reviews';


// Composant ScrollToTop
import ScrollToTop from './components/ScrollToTop';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Vérification de l'authentification de l'utilisateur
    fetch(process.env.REACT_APP_API_GATEWAY + '/api/auth/isLoggedIn', {
      method: 'GET',
      credentials: 'include', // Pour inclure les cookies de session
      headers: {
        'Cache-Control': 'no-store', 
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setIsAuthenticated(data.isAuthenticated);
      })
      .catch(() => setIsAuthenticated(false))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <div>Chargement...</div>; // Indicateur de chargement
  }

  const PrivateRoute = ({ children }) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  return (
    <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <NotificationProvider>
          <FormProvider>
            <Router>
              <ScrollToTop />
              <div className="app-container">
                <Header isLoggedIn={isAuthenticated} setIsLoggedIn={setIsAuthenticated} />
                <Routes>
                  {/* Pages publiques */}
                  <Route path="/" element={<Home />} />
                  <Route path="/decouvrir" element={<Discover />} />
                  <Route path="/become-host" element={<BecomeHost />} />
                  <Route path="/espaces/:city" element={<CitySpaces />} />
                  <Route path="/espace/:id" element={<SpaceDetails />} />
                  <Route path="/aide" element={<Help />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/terms" element={<TermsOfService />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/contact" element={<ContactSupport />} />
                {/* Pages protégées */}
                <Route path="/dashboard" 
                element={
                    <PrivateRoute>
                        <Dashboard />
                    </PrivateRoute>
                  }
                />
                {/* Parcours Devenir Hôte */}
                <Route path="/become-host/intro" 
                  element={
                    <PrivateRoute>
                        <Intro />
                    </PrivateRoute>
                  } />
                <Route path="/become-host/welcome" 
                  element={
                    <PrivateRoute>
                        <WelcomeStep />
                    </PrivateRoute>
                  } />
                <Route path="/become-host/steponeintro" 
                  element={
                    <PrivateRoute>
                        <StepOneIntro />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/steponetype" 
                  element={
                    <PrivateRoute>
                        <StepOneType />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/steponeemplacement" 
                  element={
                    <PrivateRoute>
                        <StepOneEmplacement />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/steponecapacity" 
                  element={
                    <PrivateRoute>
                        <StepOneCapacity />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/steptwointro" 
                  element={
                    <PrivateRoute>
                        <StepTwoIntro />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/steptwoequipments" 
                  element={
                    <PrivateRoute>
                        <StepTwoEquipments />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/steptwophotos" 
                  element={
                    <PrivateRoute>
                        <StepTwoPhotos />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/steptwotitle" 
                  element={
                    <PrivateRoute>
                        <StepTwoTitle />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/steptwodescription" 
                  element={
                    <PrivateRoute>
                        <StepTwoDescription />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/steptwodetaileddescription" 
                  element={
                    <PrivateRoute>
                        <StepTwoDetailedDescription />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/stepthreepublish" 
                  element={
                    <PrivateRoute>
                        <StepThreePublish />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/stepthreereservationmode" 
                  element={
                    <PrivateRoute>
                        <StepThreeReservationMode />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/stepthreepricing" 
                  element={
                    <PrivateRoute>
                        <StepThreePricing />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/stepthreereview" 
                  element={
                    <PrivateRoute>
                        <StepThreeReview />
                    </PrivateRoute>
                  } />
                  <Route path="/become-host/finalstepcongrats" 
                  element={
                    <PrivateRoute>
                        <FinalStepCongrats />
                    </PrivateRoute>
                  } />
              <Route path="/hosting" 
                element={
                  <PrivateRoute>
                      <HostDashboard />
                  </PrivateRoute>
                } />
              <Route path="/profile" 
                element={
                  <PrivateRoute>
                      <Profile />
                  </PrivateRoute>
                } />
                <Route path="/account" 
                element={
                  <PrivateRoute>
                      <Account />
                  </PrivateRoute>
                } />
              {/* <Route path="/calendar" 
                element={
                  <PrivateRoute>
                      <HostCalendar />
                  </PrivateRoute>
                } /> */}
              <Route path="/messages" 
                element={
                  <PrivateRoute>
                      <Messages />
                  </PrivateRoute>
                } />
              <Route path="/listings" 
                element={
                  <PrivateRoute>
                      <ListingsPage />
                  </PrivateRoute>
                } />
              <Route path="/listings/edit/:id" 
                element={
                  <PrivateRoute>
                      <EditListingPage />
                  </PrivateRoute>
                } />
              <Route path="/notifications" 
                element={
                  <PrivateRoute>
                      <NotificationPage />
                  </PrivateRoute>
                } />
              <Route path="/bookings" 
                element={
                  <PrivateRoute>
                      <Bookings />
                  </PrivateRoute>
                } />
              <Route path="/booking-success" 
                element={
                  <PrivateRoute>
                      <BookingSuccess />
                  </PrivateRoute>
                } />
              <Route path="/booking/edit/:id" 
                element={
                  <PrivateRoute>
                      <EditBooking />
                  </PrivateRoute>
                } />
          <Route path="/hosting" 
            element={
              <PrivateRoute>
                  <HostDashboard />
              </PrivateRoute>
            } />
          <Route path="/profile" 
            element={
              <PrivateRoute>
                  <Profile />
              </PrivateRoute>
            } />
            <Route path="/account" 
            element={
              <PrivateRoute>
                  <Account />
              </PrivateRoute>
            } />
          <Route path="/calendar" 
            element={
              <PrivateRoute>
                  <HostCalendar />
              </PrivateRoute>
            } />
          <Route path="/messages" 
            element={
              <PrivateRoute>
                  <Messages />
              </PrivateRoute>
            } />
          <Route path="/listings" 
            element={
              <PrivateRoute>
                  <ListingsPage />
              </PrivateRoute>
            } />
          <Route path="/listings/edit/:id" 
            element={
              <PrivateRoute>
                  <EditListingPage />
              </PrivateRoute>
            } />
          <Route path="/notifications" 
            element={
              <PrivateRoute>
                  <NotificationPage />
              </PrivateRoute>
            } />
          <Route path="/bookings" 
            element={
              <PrivateRoute>
                  <Bookings />
              </PrivateRoute>
            } />
          <Route path="/booking-success" 
            element={
              <PrivateRoute>
                  <BookingSuccess />
              </PrivateRoute>
            } />

          <Route path="/reservation/:id" 
            element={
              <PrivateRoute>
                  <BookingDetails />
              </PrivateRoute>
            } />

          <Route path="/reviews/:id" 
            element={
              <PrivateRoute>
                  <Reviews />
              </PrivateRoute>
            } />

          <Route path="/favorites" 
            element={
              <PrivateRoute>
                  <Favorites />
              </PrivateRoute>
            } />

          <Route path="/hosting/bookings" 
              element={
                <PrivateRoute>
                    <BookingsList />
                </PrivateRoute>
              } />

              {/* Redirections basées sur l'état de connexion */}
              <Route path="*" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} />
            </Routes>
          </div>
          <Footer />
        </Router>
        </FormProvider>
        </NotificationProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
