import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  ArrowRight,
  HelpCircle,
  ListPlus,
  Star,
  Plus,
  X,
  Check,
  Info
} from 'lucide-react';
import { useForm } from '../../../context/FormHostContext';
import SaveQuitButton from '../../../components/SaveQuitButton';

const TagCard = ({ icon, label, isSelected, onSelect, disabled }) => (
  <button
    onClick={onSelect}
    disabled={disabled}
    className={`group relative flex flex-col items-center gap-4 p-6 rounded-2xl 
                border-2 transition-all duration-300 hover:-translate-y-1
                ${isSelected 
                  ? 'bg-gradient-to-br from-blue-50 to-blue-100/50 border-blue-200 shadow-lg' 
                  : disabled 
                    ? 'bg-gray-50 border-gray-100 cursor-not-allowed opacity-60' 
                    : 'bg-white hover:border-blue-200 border-gray-200'}`}
  >
    {/* Selection indicator */}
    <div className={`absolute top-3 right-3 transition-all duration-300
                   ${isSelected ? 'scale-100' : 'scale-0'}`}>
      <div className="w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center">
        <Check className="w-4 h-4 text-white" />
      </div>
    </div>

    {/* Icon */}
    <div className={`text-4xl transform transition-all duration-300 group-hover:scale-110
                   ${isSelected ? 'scale-110' : ''}`}>
      {icon}
    </div>

    {/* Label */}
    <span className={`text-lg font-medium text-center transition-colors duration-300
                   ${isSelected ? 'text-blue-700' : 'text-gray-700'}`}>
      {label}
    </span>
  </button>
);

const SelectedTagsPreview = ({ tags, onRemove }) => (
  <div className="fixed bottom-24 inset-x-0 z-40">
    <div className="max-w-xl mx-auto">
      <div className="mx-4 p-4 bg-white rounded-2xl shadow-xl border border-blue-100">
        <div className="flex items-center gap-3 mb-3">
          <Info className="w-5 h-5 text-blue-500" />
          <span className="text-sm text-blue-600 font-medium">Points forts sélectionnés</span>
        </div>
        <div className="flex gap-2">
          {tags.map(tag => (
            <div key={tag} 
                 className="flex items-center gap-2 px-4 py-2 bg-blue-50 
                          rounded-xl border border-blue-200">
              <span>{tag}</span>
              <button onClick={() => onRemove(tag)}
                      className="p-1 hover:bg-blue-100 rounded-full transition-colors">
                <X className="w-4 h-4 text-blue-500" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const StepProgress = ({ currentStep, totalSteps }) => (
  <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                  bg-white rounded-full shadow-lg p-2 border border-gray-200">
    <div className="flex items-center gap-2 px-3 py-1">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`rounded-full transition-all duration-300 
                    ${index < currentStep ? 'w-8 h-2 bg-blue-500' : 'w-2 h-2 bg-gray-200'}`}
        />
      ))}
    </div>
  </div>
);

export default function StepTwoDescription() {
  const navigate = useNavigate();
  const [selectedTags, setSelectedTags] = useState([]);
  const maxTags = 2;
  const { handleChange } = useForm();

  const tags = [
    { id: 1, label: 'Calme', description: 'Parfait pour la concentration', icon: '😌' },
    { id: 2, label: 'Équipé', description: 'Tout le nécessaire est fourni', icon: '🖥️' },
    { id: 3, label: 'Confortable', description: 'Pour un travail agréable', icon: '💺' },
    { id: 4, label: 'Bien situé', description: 'Proche de tout', icon: '🏪' },
    { id: 5, label: 'Lumineux', description: 'Lumière naturelle abondante', icon: '🌞' },
    { id: 6, label: 'Flexible', description: 'S\'adapte à vos besoins', icon: '🔄' },
  ];

  const toggleTag = (label) => {
    if (selectedTags.includes(label)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== label));
    } else if (selectedTags.length < maxTags) {
      setSelectedTags([...selectedTags, label]);
      handleChange('strongPoints', [...selectedTags, label]);
    }


  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Vos modifications ne seront pas enregistrées.';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  const handleNext = () => {
    navigate('/become-host/steptwodetaileddescription');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
      {/* Header */}
      <header className="fixed top-0 inset-x-0 bg-white/80 backdrop-blur-sm border-b border-gray-200 z-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex items-center justify-between">
          <div className="flex items-center gap-8">
            <button onClick={() => navigate('/become-host/stepTwoTitle')}
                    className="text-gray-400 hover:text-gray-600 transition-colors">
              <ArrowLeft className="w-6 h-6" />
            </button>
            <h1 className="text-xl font-semibold text-gray-900">Les points forts</h1>
          </div>
          <SaveQuitButton />
        </div>

        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-3">
              <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center">
                <Star className="w-4 h-4 text-blue-500" />
              </div>
              <span className="font-medium text-gray-900">
                {selectedTags.length}/{maxTags} points forts
              </span>
            </div>
            <span className="text-sm text-gray-500">
              {selectedTags.length === maxTags 
                ? "Maximum atteint" 
                : `${maxTags - selectedTags.length} choix restant${maxTags - selectedTags.length > 1 ? 's' : ''}`}
            </span>
          </div>
          <div className="h-1.5 bg-gray-100 rounded-full overflow-hidden">
            <div
              className="h-full bg-blue-500 rounded-full transition-all duration-500 ease-out"
              style={{ width: `${(selectedTags.length / maxTags) * 100}%` }}
            />
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-5xl mx-auto px-4 pt-40 pb-48">
        {selectedTags.length === 0 && (
          <div className="text-center mb-12 animate-fade-in">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Qu'est-ce qui rend votre espace unique ?
            </h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Choisissez jusqu'à {maxTags} caractéristiques qui feront la différence 
              pour les télétravailleurs.
            </p>
          </div>
        )}

        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {tags.map((tag) => (
            <TagCard
              key={tag.id}
              icon={tag.icon}
              label={tag.label}
              description={tag.description}
              isSelected={selectedTags.includes(tag.label)}
              onSelect={() => toggleTag(tag.label)}
              disabled={!selectedTags.includes(tag.label) && selectedTags.length >= maxTags}
            />
          ))}
        </div>
      </main>

      {/* Selected Tags Preview */}
      {selectedTags.length > 0 && (
        <SelectedTagsPreview 
          tags={selectedTags}
          onRemove={(tag) => toggleTag(tag)}
        />
      )}

      <StepProgress currentStep={9} totalSteps={14} />

      {/* Footer */}
      <footer className="fixed bottom-0 inset-x-0 bg-white/80 backdrop-blur-sm border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center gap-3 text-gray-500">
            <HelpCircle className="w-5 h-5" />
            <span>Besoin d'aide ?</span>
          </div>

          <button
            onClick={() => navigate('/become-host/steptwodetaileddescription')}
            disabled={selectedTags.length === 0}
            className={`group px-8 py-3 rounded-xl flex items-center gap-2 
                     transition-all duration-300 
                     ${selectedTags.length > 0
                       ? 'bg-blue-600 text-white hover:bg-blue-500 hover:shadow-xl hover:shadow-blue-500/20 hover:-translate-y-0.5' 
                       : 'bg-gray-100 text-gray-400 cursor-not-allowed'}`}
          >
            <span>Continuer</span>
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </button>
        </div>
      </footer>
    </div>
  );
}