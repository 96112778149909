import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  ArrowRight,
  HelpCircle,
  Euro,
  Clock,
  CalendarDays,
  CalendarRange,
  Calendar,
  TrendingUp,
  MapPin,
  Sparkles,
  Info,
  Plus,
  Minus
} from 'lucide-react';
import { useForm } from '../../../context/FormHostContext';
import SaveQuitButton from '../../../components/SaveQuitButton';

const PriceInput = ({ 
  icon: Icon, 
  period, 
  price, 
  setPrice, 
  recommendation, 
  duration 
}) => {
  const formatCurrency = (value) => new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  }).format(value);

  return (
    <div className={`bg-white rounded-2xl p-6 border border-gray-200 transition-all duration-300
                    hover:border-blue-200 hover:shadow-lg group`}>
      <div className="flex items-start justify-between mb-4">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-blue-50 rounded-lg">
            <Icon className="w-5 h-5 text-blue-500" />
          </div>
          <div>
            <h3 className="font-medium text-gray-900">Prix par {period}</h3>
            <p className="text-sm text-gray-500">{duration}</p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <button 
            onClick={() => setPrice(Math.max(10, price - 5))}
            className="w-8 h-8 rounded-full bg-gray-100 hover:bg-blue-100 flex items-center justify-center
                     text-gray-500 hover:text-blue-500 transition-colors"
          >
            <Minus className="w-4 h-4" />
          </button>
          <button 
            onClick={() => setPrice(price + 5)}
            className="w-8 h-8 rounded-full bg-gray-100 hover:bg-blue-100 flex items-center justify-center
                     text-gray-500 hover:text-blue-500 transition-colors"
          >
            <Plus className="w-4 h-4" />
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="relative">
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
            min="10"
            className="w-full h-12 px-12 text-2xl font-medium text-gray-900 rounded-lg
                     border-2 border-gray-200 focus:border-blue-500 focus:ring-4 focus:ring-blue-500/10
                     transition-all duration-300"
          />
          <div className="absolute inset-y-0 left-4 flex items-center">
            <Euro className="w-5 h-5 text-gray-400" />
          </div>
        </div>

        <div className="flex items-center gap-2 text-sm">
          <MapPin className="w-4 h-4 text-blue-500" />
          <span className="text-gray-600">
            Prix recommandé : {formatCurrency(recommendation)}
          </span>
        </div>
      </div>
    </div>
  );
};

const StepProgress = ({ currentStep, totalSteps }) => (
  <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                  bg-white rounded-full shadow-lg p-2 border border-gray-200">
    <div className="flex items-center gap-2 px-3 py-1">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`rounded-full transition-all duration-300 
                    ${index < currentStep ? 'w-8 h-2 bg-blue-500' : 'w-2 h-2 bg-gray-200'}`}
        />
      ))}
    </div>
  </div>
);

export default function StepThreePricing() {
  const navigate = useNavigate();
  const [pricePerHour, setPricePerHour] = useState(15);
  const [pricePerDay, setPricePerDay] = useState(120);
  const [pricePerWeek, setPricePerWeek] = useState(600);
  const [pricePerMonth, setPricePerMonth] = useState(2400);
  const { handleChange } = useForm();

  const handlePriceUpdate = (type, value) => {
    const updateFunctions = {
      hour: [setPricePerHour, 'prixByHour'],
      day: [setPricePerDay, 'prixByDay'],
      week: [setPricePerWeek, 'prixByWeek'],
      month: [setPricePerMonth, 'prixByMonth']
    };

    const [setState, contextKey] = updateFunctions[type];
    setState(value);
    handleChange(contextKey, value);
  };

  const priceInputs = [
    {
      icon: Clock,
      period: 'heure',
      price: pricePerHour,
      setPrice: (value) => handlePriceUpdate('hour', value),
      recommendation: 15,
      duration: '1-8 heures'
    },
    {
      icon: CalendarDays,
      period: 'jour',
      price: pricePerDay,
      setPrice: (value) => handlePriceUpdate('day', value),
      recommendation: 120,
      duration: '9-24 heures'
    },
    {
      icon: CalendarRange,
      period: 'semaine',
      price: pricePerWeek,
      setPrice: (value) => handlePriceUpdate('week', value),
      recommendation: 600,
      duration: '7 jours'
    },
    {
      icon: Calendar,
      period: 'mois',
      price: pricePerMonth,
      setPrice: (value) => handlePriceUpdate('month', value),
      recommendation: 2400,
      duration: '30 jours'
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
      {/* Header */}
      <header className="fixed top-0 inset-x-0 bg-white/80 backdrop-blur-sm border-b border-gray-200 z-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex items-center justify-between">
          <div className="flex items-center gap-8">
            <button onClick={() => navigate('/become-host/stepthreereservationMode')}
                    className="text-gray-400 hover:text-gray-600 transition-colors">
              <ArrowLeft className="w-6 h-6" />
            </button>
            <h1 className="text-xl font-semibold text-gray-900">Tarification</h1>
          </div>
          <SaveQuitButton />
        </div>

        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-blue-50 rounded-lg">
              <TrendingUp className="w-5 h-5 text-blue-500" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Paramètres de tarification</p>
              <h2 className="font-medium text-gray-900">
                Définissez vos tarifs pour chaque durée
              </h2>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 pt-40 pb-48">
        <div className="max-w-3xl mx-auto">
          {/* Info Card */}
          <div className="bg-blue-50 rounded-xl p-4 flex items-start gap-3 mb-8">
            <Sparkles className="w-5 h-5 text-blue-500 mt-0.5" />
            <div>
              <h3 className="font-medium text-blue-900 mb-1">
                Conseils de tarification
              </h3>
              <p className="text-blue-700 text-sm">
                Les espaces similaires dans votre zone sont généralement proposés entre 80€ et 150€ par jour. 
                Ajustez vos prix en fonction des équipements et services que vous proposez.
              </p>
            </div>
          </div>

          {/* Price Inputs Grid */}
          <div className="grid sm:grid-cols-2 gap-4">
            {priceInputs.map((input, index) => (
              <PriceInput key={index} {...input} />
            ))}
          </div>
        </div>
      </main>

      <StepProgress currentStep={13} totalSteps={14} />

      {/* Footer */}
      <footer className="fixed bottom-0 inset-x-0 bg-white/80 backdrop-blur-sm border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center gap-3 text-gray-500">
            <HelpCircle className="w-5 h-5" />
            <span>Aide à la tarification</span>
          </div>

          <button
            onClick={() => navigate('/become-host/stepthreereview')}
            disabled={pricePerDay < 10}
            className={`group px-8 py-3 rounded-xl flex items-center gap-2 
                     transition-all duration-300 
                     ${pricePerDay >= 10
                       ? 'bg-blue-600 text-white hover:bg-blue-500 hover:shadow-xl hover:shadow-blue-500/20 hover:-translate-y-0.5' 
                       : 'bg-gray-100 text-gray-400 cursor-not-allowed'}`}
          >
            <span>Continuer</span>
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </button>
        </div>
      </footer>
    </div>
  );
}