import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  ArrowRight,
  Save,
  HelpCircle,
  Check
} from 'lucide-react';
import { useForm } from '../../../context/FormHostContext';
import SaveQuitButton from '../../../components/SaveQuitButton';

const TypeOption = ({ icon, name, selected, onClick }) => (
  <button
    onClick={onClick}
    className={`group relative p-6 rounded-2xl border-2 transition-all duration-300
               hover:shadow-lg hover:-translate-y-1 active:translate-y-0
               ${selected 
                 ? 'border-blue-500 bg-blue-50/50' 
                 : 'border-gray-200 hover:border-blue-200 bg-white'}`}
  >
    {/* Selection Indicator */}
    <div className={`absolute top-4 right-4 w-6 h-6 rounded-full flex items-center justify-center
                     transition-all duration-300
                     ${selected 
                       ? 'bg-blue-500 text-white scale-100' 
                       : 'bg-gray-100 text-transparent scale-90 group-hover:scale-100'}`}>
      <Check className="w-4 h-4" />
    </div>
    
    {/* Content */}
    <div className="flex flex-col items-center text-center gap-4">
      <div className={`text-4xl transition-colors duration-300
                    ${selected ? 'text-blue-500' : 'text-gray-600 group-hover:text-blue-400'}`}>
        {icon}
      </div>
      <span className={`font-medium transition-colors duration-300
                     ${selected ? 'text-blue-700' : 'text-gray-700'}`}>
        {name}
      </span>
    </div>
  </button>
);

const StepProgress = ({ currentStep, totalSteps }) => (
  <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                  bg-white rounded-full shadow-lg p-2 border border-gray-200">
    <div className="flex items-center gap-2 px-3 py-1">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`rounded-full transition-all duration-300 
                    ${index < currentStep ? 'w-8 h-2 bg-blue-500' : 'w-2 h-2 bg-gray-200'}`}
        />
      ))}
    </div>
  </div>
);

export default function StepOneType() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [spaceTypes, setSpaceTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const { handleChange } = useForm();
  const apiUrl = process.env.REACT_APP_API_GATEWAY;

  useEffect(() => {
    const fetchTypeEspaces = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/workspace/typeEspaces`);
        const spaceData = await response.json();
        setSpaceTypes(spaceData?.data.data);
      } catch (error) {
        console.error('Error fetching typeEspaces:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTypeEspaces();
    setIsVisible(true);

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Vos modifications ne seront pas enregistrées.';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [apiUrl]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    handleChange('typeEspace', option);
  };

  const handleNext = () => {
    if (selectedOption) {
      navigate('/become-host/steponeemplacement');
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white">
        <div className="relative">
          <div className="w-12 h-12 rounded-full border-2 border-blue-500 border-t-transparent animate-spin" />
          <div className="absolute inset-0 w-12 h-12 rounded-full border-2 border-blue-200" />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      {/* Fixed Header */}
      <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-lg border-b border-gray-100 z-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <button className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors">
            <HelpCircle className="w-5 h-5" />
            <span>Des questions ?</span>
          </button>
          <SaveQuitButton />
        </div>
      </header>

      {/* Main Content */}
      <main className="pt-20 pb-24 max-w-7xl mx-auto px-4">
        <div className={`transition-all duration-700 ease-out
                       ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
          <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-12 max-w-3xl">
            Parmi les propositions suivantes, laquelle décrit le mieux votre espace ?
          </h1>

          {/* Grid of Options */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
            {spaceTypes.map((option, index) => (
              <TypeOption
                key={index}
                icon={option.icone}
                name={option.nom}
                selected={selectedOption === option.espace_type_id}
                onClick={() => handleOptionClick(option.espace_type_id)}
              />
            ))}
          </div>
        </div>
      </main>

      <StepProgress currentStep={2} totalSteps={14} />

      {/* Fixed Footer */}
      <footer className="fixed bottom-0 left-0 right-0 bg-white/80 backdrop-blur-lg border-t border-gray-100">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <button
            onClick={() => navigate('/become-host/steponeintro')}
            className="flex items-center gap-2 px-6 py-3 rounded-full text-gray-700 
                     hover:bg-gray-100 transition-all duration-300"
          >
            <ArrowLeft className="w-5 h-5" />
            <span>Retour</span>
          </button>

          <button
            onClick={handleNext}
            disabled={!selectedOption}
            className={`flex items-center gap-2 px-8 py-3 rounded-full font-medium
                     transition-all duration-300
                     ${selectedOption
                       ? 'bg-gradient-to-r from-blue-600 to-blue-500 text-white hover:shadow-lg hover:shadow-blue-500/20 hover:-translate-y-0.5'
                       : 'bg-gray-100 text-gray-400 cursor-not-allowed'}`}
          >
            <span>Suivant</span>
            <ArrowRight className="w-5 h-5" />
          </button>
        </div>
      </footer>
    </div>
  );
}