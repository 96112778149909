import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { 
  Filter, Download, Printer, Search,
  ChevronDown, MoreHorizontal, Calendar,
  User, Clock, Building, CreditCard, X
} from 'lucide-react';

const BookingsList = () => {
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [sortConfig, setSortConfig] = useState({ field: 'departureDate', direction: 'asc' });
  const [showFilters, setShowFilters] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_GATEWAY;

  const tabs = [
    { id: 'all', label: 'Tout', icon: Building },
    { id: 'upcoming', label: 'À venir', icon: Calendar },
    { id: 'completed', label: 'Terminées', icon: Clock },
    { id: 'cancelled', label: 'Annulées', icon: CreditCard }
  ];

//   Récupérer les réservations de l'hôte
useEffect(() => {
  const fetchReservations = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${apiUrl}/api/reservation/host/mybookings`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      if (data.success) {
        // S'assurer que data.data est un tableau
        setBookings(Array.isArray(data.data) ? data.data : []);
      } else {
        setError(data.message);
        setBookings([]); // Réinitialiser avec un tableau vide en cas d'erreur
      }
      
    } catch (err) {
      console.error('Erreur:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  fetchReservations();
}, [apiUrl]);

  const sortBookings = (a, b) => {
    const { field, direction } = sortConfig;
    const aValue = a[field];
    const bValue = b[field];

    if (direction === 'asc') {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  };
  // Ajouter ces états après les autres useState
  const [filterPeriod, setFilterPeriod] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterAmount, setFilterAmount] = useState('all');
  
  // Modifier la fonction filteredBookings
  const filteredBookings = bookings
    .filter(booking => {
      // Filtre par onglet
      if (activeTab !== 'all') {
        if (activeTab === 'upcoming' && new Date(booking.date_fin) < new Date()) return false;
        if (activeTab === 'completed' && new Date(booking.date_fin) > new Date()) return false;
        if (activeTab === 'cancelled' && booking.statut !== 'annule') return false;
      }
  
      // Filtre par période
      if (filterPeriod !== 'all') {
        const today = new Date();
        const bookingDate = new Date(booking.cree_a);
        if (filterPeriod === '7days' && (today - bookingDate) > 7 * 24 * 60 * 60 * 1000) return false;
        if (filterPeriod === '30days' && (today - bookingDate) > 30 * 24 * 60 * 60 * 1000) return false;
      }
  
      // Filtre par statut
      if (filterStatus !== 'all') {
        if (filterStatus === 'confirmed' && booking.statut !== 'valide') return false;
        if (filterStatus === 'pending' && booking.statut !== 'en_attente') return false;
        if (filterStatus === 'cancelled' && booking.statut !== 'annule') return false;
      }
  
      // Filtre par montant
      if (filterAmount !== 'all') {
        const amount = booking.prix_total;
        if (filterAmount === 'less100' && amount >= 100) return false;
        if (filterAmount === '100to500' && (amount < 100 || amount > 500)) return false;
        if (filterAmount === 'more500' && amount <= 500) return false;
      }
  
      // Filtre par recherche
      if (searchQuery) {
        const searchLower = searchQuery.toLowerCase();
        return (
          booking.guest_nom.toLowerCase().includes(searchLower) ||
          booking.espace_titre.toLowerCase().includes(searchLower)
        );
      }
  
      return true;
    })
    .sort(sortBookings);
  const handleSort = (field) => {
    setSortConfig(prev => ({
      field,
      direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };
  // Animation variants
  const tableRowVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };
  
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Header */}
        <div className="bg-white rounded-2xl shadow-sm p-6 mb-6">
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-6">
            <h1 className="text-2xl font-bold text-gray-900">
              Réservations
            </h1>
  
            <div className="flex flex-wrap items-center gap-3">
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="flex items-center gap-2 px-4 py-2 bg-white border rounded-xl hover:bg-gray-50 
                          transition-colors duration-200"
              >
                <Filter className="w-5 h-5" />
                <span>Filtrer</span>
              </button>
  
              <button className="flex items-center gap-2 px-4 py-2 bg-white border rounded-xl hover:bg-gray-50 
                                transition-colors duration-200">
                <Download className="w-5 h-5" />
                <span>Exporter</span>
              </button>
  
              <button className="flex items-center gap-2 px-4 py-2 bg-white border rounded-xl hover:bg-gray-50 
                                transition-colors duration-200">
                <Printer className="w-5 h-5" />
                <span>Imprimer</span>
              </button>
            </div>
          </div>
  
          {/* Search and Filters */}
          <div className="space-y-4">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="text"
                placeholder="Rechercher par nom, annonce..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border rounded-xl focus:ring-2 focus:ring-blue-500 
                         focus:border-transparent transition-all duration-200"
              />
            </div>
  
            <AnimatePresence>
              {showFilters && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="overflow-hidden"
                >
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4 bg-gray-50 rounded-xl">
                    {/* Filtre date */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Période
                      </label>
                      <select 
                        className="w-full p-2 border rounded-lg"
                        value={filterPeriod}
                        onChange={(e) => setFilterPeriod(e.target.value)}
                      >
                        <option value="all">Tous</option>
                        <option value="7days">7 derniers jours</option>
                        <option value="30days">30 derniers jours</option>
                      </select>
                    </div>
  
                    {/* Filtre statut */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Statut
                      </label>
                      <select className="w-full p-2 border rounded-lg">
                        <option>Tous</option>
                        <option>Confirmé</option>
                        <option>En attente</option>
                        <option>Annulé</option>
                      </select>
                    </div>
  
                    {/* Filtre montant */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Montant
                      </label>
                      <select className="w-full p-2 border rounded-lg">
                        <option>Tous</option>
                        <option>{'< 100€'}</option>
                        <option>100€ - 500€</option>
                        <option>{'> 500€'}</option>
                      </select>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
  
        {/* Tabs */}
        <div className="flex gap-4 mb-6 overflow-x-auto pb-2">
          {tabs.map((tab) => {
            const Icon = tab.icon;
            return (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`flex items-center gap-2 px-4 py-2 rounded-xl text-sm font-medium 
                          transition-all duration-200 whitespace-nowrap ${
                  activeTab === tab.id
                    ? 'bg-blue-600 text-white shadow-sm'
                    : 'bg-white text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Icon className="w-4 h-4" />
                {tab.label}
              </button>
            );
          })}
        </div>
  
        {/* Table */}
        {isLoading ? (
          <div className="flex items-center justify-center h-64">
            <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
          </div>
        ) : filteredBookings.length > 0 ? (
          <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      { label: 'Statut', field: 'status' },
                      { label: 'Guests', field: 'guest' },
                      { label: 'Arrivée', field: 'arrivalDate' },
                      { label: 'Départ', field: 'departureDate' },
                      { label: 'Réservée le', field: 'bookingDate' },
                      { label: 'Annonce', field: 'listing' },
                      { label: 'Total', field: 'totalAmount' }
                    ].map((column) => (
                      <th
                        key={column.field}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        <button
                          onClick={() => handleSort(column.field)}
                          className="flex items-center gap-1 hover:text-gray-700"
                        >
                          {column.label}
                          {sortConfig.field === column.field && (
                            <ChevronDown
                              className={`w-4 h-4 transform transition-transform ${
                                sortConfig.direction === 'desc' ? 'rotate-180' : ''
                              }`}
                            />
                          )}
                        </button>
                      </th>
                    ))}
                    <th className="relative px-6 py-3">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <AnimatePresence>
                    {filteredBookings.map((booking, index) => (
                      <motion.tr
                        key={booking.id}
                        variants={tableRowVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        transition={{ duration: 0.2, delay: index * 0.05 }}
                        className="hover:bg-gray-50"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                            booking.statut === 'valide'
                              ? 'bg-green-100 text-green-800'
                              : booking.statut === 'annule'
                              ? 'bg-red-100 text-red-800'
                              : 'bg-yellow-100 text-yellow-800'
                          }`}>
                          {booking.statut === 'valide' ? 'Confirmé' 
                            : booking.statut === 'annule' ? 'Annulé'
                            : booking.statut === 'refuse' ? 'Refusé'
                            : 'En attente'}
                          </span>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">
                                {booking.guest_prenom} {booking.guest_nom}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {format(new Date(booking.date_debut), 'dd MMM yyyy', { locale: fr })}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {format(new Date(booking.date_fin), 'dd MMM yyyy', { locale: fr })}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {format(new Date(booking.cree_a), "dd MMM yyyy 'à' HH:mm", { locale: fr })}
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-gray-900">{booking.espace_titre}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(booking.prix_total)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button 
                          onClick={() => setSelectedBooking(booking)}
                          className="text-blue-600 hover:text-blue-900">Détails</button>
                        </td>
                      </motion.tr>
                    ))}
                  </AnimatePresence>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-2xl shadow-sm p-12 text-center">
            <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
              <Calendar className="w-8 h-8 text-gray-400" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              Aucune réservation trouvée
            </h3>
            <p className="text-gray-500">
              {searchQuery 
                ? "Aucune réservation ne correspond à votre recherche" 
                : "Vous n'avez pas encore de réservations pour cette période"}
            </p>
          </div>
        )}
  
        {/* Pagination */}
        <div className="mt-6 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-700">
              Affichage de {filteredBookings.length} réservations
            </span>
          </div>
          
          <div className="flex items-center gap-2">
            <select 
              className="p-2 border rounded-lg text-sm"
              onChange={(e) => console.log('Changed rows per page:', e.target.value)}
            >
              <option value="10">10 par page</option>
              <option value="25">25 par page</option>
              <option value="50">50 par page</option>
            </select>
  
            <div className="flex items-center gap-1">
              <button className="p-2 border rounded-lg hover:bg-gray-50">
                Précédent
              </button>
              <button className="p-2 border rounded-lg hover:bg-gray-50">
                Suivant
              </button>
            </div>
          </div>
        </div>
      </div>
  
      {/* Detailed View Modal */}
      <AnimatePresence>
        {selectedBooking && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              className="bg-white rounded-2xl p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto m-4"
            >
              <div className="flex justify-between items-start mb-6">
                <h2 className="text-xl font-semibold">
                  Détails de la réservation
                </h2>
                <button onClick={() => setSelectedBooking(null)}>
                  <X className="w-6 h-6" />
                </button>
              </div>
  
              <div className="space-y-6">
                {/* Guest Information */}
                <div>
                  <h3 className="text-lg font-medium mb-4">Informations guest</h3>
                  <div className="flex items-start gap-6">
                    {/* Guest Avatar */}
                    <div className="flex-shrink-0">
                      {selectedBooking.guest_photo ? (
                        <img
                          src={selectedBooking.guest_photo}
                          alt={`${selectedBooking.guest_prenom} ${selectedBooking.guest_nom}`}
                          className="w-20 h-20 rounded-full object-cover"
                        />
                      ) : (
                        <div className="w-20 h-20 rounded-full bg-gray-200 flex items-center justify-center">
                          <User className="w-8 h-8 text-gray-400" />
                        </div>
                      )}
                    </div>
                    
                    {/* Guest Details */}
                    <div className="flex-1 grid grid-cols-2 gap-4">
                      <div>
                        <p className="text-sm text-gray-500">Nom</p>
                        <p className="font-medium">{selectedBooking.guest_nom}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Prénom</p>
                        <p className="font-medium">{selectedBooking.guest_prenom}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Participants</p>
                        <p className="font-medium">{selectedBooking.nombre_guests} personnes</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Email</p>
                        <p className="font-medium">{selectedBooking.guest_email}</p>
                      </div>
                      {/* <div>
                        <p className="text-sm text-gray-500">Téléphone</p>
                        <p className="font-medium">{selectedBooking.guest.phone}</p>
                      </div> */}
                    </div>
                  </div>
                </div>
  
                {/* Booking Details */}
                <div>
                  <h3 className="text-lg font-medium mb-4">Détails de la réservation</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500">Arrivée</p>
                      <p className="font-medium">
                        {format(new Date(selectedBooking.date_debut), 'dd MMMM yyyy', { locale: fr })}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Départ</p>
                      <p className="font-medium">
                        {format(new Date(selectedBooking.date_fin), 'dd MMMM yyyy', { locale: fr })}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Statut</p>
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        selectedBooking.statut === 'valide'
                          ? 'bg-green-100 text-green-800'
                          : selectedBooking.statut === 'annule'
                          ? 'bg-red-100 text-red-800'
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                      {selectedBooking.statut === 'valide' ? 'Confirmé' 
                        : selectedBooking.statut === 'annule' ? 'Annulé'
                        : selectedBooking.statut === 'refuse' ? 'Refusé'
                        : 'En attente'}
                      </span>
                    </div>
                  </div>
                </div>
  
                {/* Payment Information */}
                <div>
                  <h3 className="text-lg font-medium mb-4">Informations de paiement</h3>
                  <div className="bg-gray-50 rounded-xl p-4 space-y-4">
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-gray-500">Prix de base</span>
                        <span className="font-medium">
                          {new Intl.NumberFormat('fr-FR', { 
                            style: 'currency', 
                            currency: 'EUR' 
                          }).format(selectedBooking.prix_base)}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500">Frais de service</span>
                        <span className="font-medium">
                          {new Intl.NumberFormat('fr-FR', { 
                            style: 'currency', 
                            currency: 'EUR' 
                          }).format(selectedBooking.frais_service)}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500">Taxes</span>
                        <span className="font-medium">
                          {new Intl.NumberFormat('fr-FR', { 
                            style: 'currency', 
                            currency: 'EUR' 
                          }).format(selectedBooking.frais_taxe)}
                        </span>
                      </div>
                      <div className="pt-2 mt-2 border-t border-gray-200 flex justify-between">
                        <span className="font-medium">Total</span>
                        <span className="font-semibold">
                          {new Intl.NumberFormat('fr-FR', { 
                            style: 'currency', 
                            currency: 'EUR' 
                          }).format(selectedBooking.prix_total)}
                        </span>
                      </div>
                    </div>
  
                    {/* Informations de transaction */}
                    <div className="pt-4 border-t border-gray-200">
                      <h4 className="text-sm font-medium text-gray-700 mb-2">Détails de la transaction</h4>
                      <div className="grid grid-cols-2 gap-3 text-sm">
                        <div>
                          <p className="text-gray-500">Statut de la transaction</p>
                          <p className="font-medium">{selectedBooking.statut_transaction || 'N/A'}</p>
                        </div>
                        <div>
                          <p className="text-gray-500">Type de transaction</p>
                          <p className="font-medium">{selectedBooking.type_transaction || 'N/A'}</p>
                        </div>
                        <div>
                          <p className="text-gray-500">Montant</p>
                          <p className="font-medium">
                            {new Intl.NumberFormat('fr-FR', { 
                              style: 'currency', 
                              currency: 'EUR' 
                            }).format(selectedBooking.montant)}
                          </p>
                        </div>
                        <div>
                          <p className="text-gray-500">ID de la transaction</p>
                          <p className="font-medium">{selectedBooking.transaction_id || 'N/A'}</p>
                        </div>
                        <div>
                          <p className="text-gray-500">Prix de base</p>
                          <p className="font-medium">
                            {new Intl.NumberFormat('fr-FR', { 
                              style: 'currency', 
                              currency: 'EUR' 
                            }).format(selectedBooking.prix_base)}
                          </p>
                        </div>
                        <div>
                          <p className="text-gray-500">Frais de service</p>
                          <p className="font-medium">
                            {new Intl.NumberFormat('fr-FR', { 
                              style: 'currency', 
                              currency: 'EUR' 
                            }).format(selectedBooking.frais_service)}
                          </p>
                        </div>
                        <div>
                          <p className="text-gray-500">Frais de taxe</p>
                          <p className="font-medium">
                            {new Intl.NumberFormat('fr-FR', { 
                              style: 'currency', 
                              currency: 'EUR' 
                            }).format(selectedBooking.frais_taxe)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                {/* Actions */}
                <div className="flex justify-end gap-3 mt-6 pt-6 border-t">
                  <button
                    onClick={() => setSelectedBooking(null)}
                    className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 
                             transition-colors duration-200"
                  >
                    Fermer
                  </button>
                  <button
                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                             transition-colors duration-200"
                  >
                    Télécharger le récapitulatif
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BookingsList;