// @/components/ui/numeric-input.js
import React from 'react';

const NumericInput = ({ value, onChange, min, className, ...props }) => {
  const handleChange = (e) => {
    const newValue = e.target.value.replace(/\D/g, '');
    onChange(parseInt(newValue) || 0);
  };

  return (
    <input
      type="number"
      value={value}
      onChange={handleChange}
      min={min}
      className={`w-full p-2 border rounded-xl focus:ring-2 focus:ring-blue-500/20 ${className}`}
      {...props}
    />
  );
};

export {NumericInput};