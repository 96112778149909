import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft,
  ArrowRight,
  HelpCircle,
  MapPin,
  Users,
  Wifi,
  Shield,
  Clock,
  Home,
  CircleDollarSign,
  Image as ImageIcon,
  ClipboardCheck,
  CheckCircle2,
  Building,
  BedSingle,
  DoorOpen,
  Star
} from 'lucide-react';
import { useForm } from '../../../context/FormHostContext';
import { useAuth } from '../../../context/AuthContext';
import SaveQuitButton from '../../../components/SaveQuitButton';

const Section = ({ title, icon: Icon, children }) => (
  <div className="bg-white rounded-2xl border border-gray-200 overflow-hidden">
    <div className="border-b border-gray-100 p-6">
      <div className="flex items-center gap-3">
        <div className="p-2 bg-blue-50 rounded-lg">
          <Icon className="w-5 h-5 text-blue-500" />
        </div>
        <h3 className="font-semibold text-gray-900">{title}</h3>
      </div>
    </div>
    <div className="p-6">{children}</div>
  </div>
);

const PhotoPreview = ({ photos, mainPhoto }) => (
  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
    {photos.map((photo, index) => (
      <div 
        key={index}
        className={`relative rounded-xl overflow-hidden aspect-square
                  ${photo === mainPhoto ? 'col-span-2 row-span-2' : ''}`}
      >
        <img 
          src={photo} 
          alt={`Espace ${index + 1}`} 
          className="w-full h-full object-cover"
        />
        {photo === mainPhoto && (
          <div className="absolute bottom-3 left-3 px-3 py-1.5 bg-blue-500 rounded-full
                         text-white text-sm font-medium">
            Photo principale
          </div>
        )}
      </div>
    ))}
  </div>
);

const PriceDisplay = ({ period, price }) => (
  <div className="flex items-center justify-between py-3">
    <span className="text-gray-600">{period}</span>
    <span className="font-semibold text-gray-900">
      {new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR'
      }).format(price)}
    </span>
  </div>
);

export default function StepThreeReview() {
  const navigate = useNavigate();
  const { formData } = useForm();
  const { userId } = useAuth();
  const apiURL = process.env.REACT_APP_API_GATEWAY;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendFiles = async (formData) => {
    try {
      const response = await fetch(`${apiURL}/api/workspace/createWorkspace`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });
      const imageData = await response.json();
      console.log('Images uploaded successfully:', imageData);
    } catch (error) {
      console.error('Error uploading images:', error);
      throw error;
    }
  };

  const createSpace = async () => {
    const formDataImage = new FormData();
    
    // Données de base
    const baseData = {
      hote_id: userId,
      status: "actif",
      typeEspace: formData.typeEspace,
      latitude: formData.latitude,
      longitude: formData.longitude,
      adresse: formData.adresse,
      ville: formData.ville,
      pays: formData.pays,
      guests: formData.guests,
      spaces: formData.spaces,
      toilets: formData.toilets,
      desks: formData.desks,
      equipements: JSON.stringify(formData.equipements),
      titre: formData.titre,
      strongPoints: JSON.stringify(formData.strongPoints),
      description: formData.description,
      modeReservation: formData.modeReservation,
      prixByHour: formData.prixByHour,
      prixByDay: formData.prixByDay,
      prixByWeek: formData.prixByWeek,
      prixByMonth: formData.prixByMonth
    };

    // Ajouter les données de base au FormData
    Object.entries(baseData).forEach(([key, value]) => {
      formDataImage.append(key, value);
    });

    // Traiter les photos
    for (let i = 0; i < formData.photos.length; i++) {
      const blobUrl = formData.photos[i];
      try {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        const name_file = formData.photoPrincipal === blobUrl ? 
          'photoPrincipal.jpg' : `photo_${i}.jpg`;
        const file = new File([blob], name_file, { type: blob.type });
        formDataImage.append('photos', file);
      } catch (error) {
        console.error('Erreur lors de la conversion du Blob:', error);
        throw error;
      }
    }

    await sendFiles(formDataImage);
  };

  const handleNext = async () => {
    try {
      setIsSubmitting(true);
      await createSpace();
      navigate('/become-host/finalstepcongrats');
    } catch (error) {
      console.error('Error creating space:', error);
      // Gérer l'erreur (afficher un message, etc.)
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="fixed top-0 inset-x-0 bg-white border-b border-gray-200 z-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex items-center justify-between">
          <div className="flex items-center gap-8">
            <button onClick={() => navigate('/become-host/stepthreepricing')}
                    className="text-gray-400 hover:text-gray-600 transition-colors">
              <ArrowLeft className="w-6 h-6" />
            </button>
            <h1 className="text-xl font-semibold text-gray-900">Récapitulatif de l'annonce</h1>
          </div>
          <SaveQuitButton />
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 pt-24 pb-32">
        <div className="grid md:grid-cols-2 gap-6">
          {/* Left Column */}
          <div className="space-y-6">
            <Section title="Informations principales" icon={Building}>
              <div className="space-y-4">
                <div>
                  <h3 className="font-medium text-gray-900 mb-2">{formData.titre}</h3>
                  <p className="text-gray-600">{formData.description}</p>
                </div>
                <div className="flex items-center gap-2 text-gray-600">
                  <MapPin className="w-4 h-4" />
                  <span>{formData.adresse}</span>
                </div>
              </div>
            </Section>

            <Section title="Capacité et aménagements" icon={Users}>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center gap-3">
                  <Users className="w-5 h-5 text-gray-400" />
                  <div>
                    <p className="text-sm text-gray-500">Capacité</p>
                    <p className="font-medium text-gray-900">{formData.guests} personnes</p>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <DoorOpen className="w-5 h-5 text-gray-400" />
                  <div>
                    <p className="text-sm text-gray-500">Espaces</p>
                    <p className="font-medium text-gray-900">{formData.spaces}</p>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <BedSingle className="w-5 h-5 text-gray-400" />
                  <div>
                    <p className="text-sm text-gray-500">Bureaux</p>
                    <p className="font-medium text-gray-900">{formData.desks}</p>
                  </div>
                </div>
              </div>
            </Section>

            <Section title="Photos" icon={ImageIcon}>
              <PhotoPreview 
                photos={formData.photos}
                mainPhoto={formData.photoPrincipal}
              />
            </Section>
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            <Section title="Équipements" icon={Wifi}>
              <div className="grid grid-cols-2 gap-4">
                {formData.equipements?.map((equipement, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <CheckCircle2 className="w-4 h-4 text-green-500" />
                    <span className="text-gray-600">{equipement}</span>
                  </div>
                ))}
              </div>
            </Section>

            <Section title="Points forts" icon={Shield}>
              <div className="space-y-3">
                {formData.strongPoints?.map((point, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <Star className="w-4 h-4 text-yellow-500" />
                    <span className="text-gray-600">{point}</span>
                  </div>
                ))}
              </div>
            </Section>

            <Section title="Mode de réservation" icon={Clock}>
              <div className="flex items-center gap-3 px-4 py-3 bg-blue-50 rounded-xl">
                <Clock className="w-5 h-5 text-blue-500" />
                <div>
                  <p className="font-medium text-gray-900">
                    {formData.modeReservation === 'AUTO' ? 
                      'Réservation immédiate' : 
                      'Validation manuelle'}
                  </p>
                  <p className="text-sm text-gray-600">
                    {formData.modeReservation === 'AUTO' ? 
                      'Les utilisateurs peuvent réserver instantanément' : 
                      'Vous devrez valider chaque demande'}
                  </p>
                </div>
              </div>
            </Section>

            <Section title="Tarification" icon={CircleDollarSign}>
              <div className="space-y-2 divide-y divide-gray-100">
                <PriceDisplay period="Prix par heure" price={formData.prixByHour} />
                <PriceDisplay period="Prix par jour" price={formData.prixByDay} />
                <PriceDisplay period="Prix par semaine" price={formData.prixByWeek} />
                <PriceDisplay period="Prix par mois" price={formData.prixByMonth} />
              </div>
            </Section>
          </div>
        </div>
      </main>

      {/* Steps */}
      <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                    bg-white rounded-full shadow-lg p-2 border border-gray-200">
        <div className="flex items-center gap-2 px-3 py-1">
          {Array.from({ length: 14 }).map((_, index) => (
            <div
              key={index}
              className={`rounded-full transition-all duration-300 
                       ${index < 14 ? 'w-8 h-2 bg-blue-500' : 'w-2 h-2 bg-gray-200'}`}
            />
          ))}
        </div>
      </div>

      {/* Footer */}
      <footer className="fixed bottom-0 inset-x-0 bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <button className="flex items-center gap-2 text-gray-600 hover:text-gray-900">
            <HelpCircle className="w-5 h-5" />
            <span>Besoin d'aide ?</span>
          </button>

          <button
            onClick={handleNext}
            disabled={isSubmitting}
            className="group px-8 py-3 rounded-xl flex items-center gap-2 
                     bg-blue-600 text-white hover:bg-blue-500 
                     hover:shadow-xl hover:shadow-blue-500/20 
                     transition-all duration-300 hover:-translate-y-0.5
                     disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <span>{isSubmitting ? 'Publication en cours...' : 'Publier l\'annonce'}</span>
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </button>
        </div>
      </footer>
    </div>
  );
}