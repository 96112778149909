import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowRight, 
  Home,
  ImagePlus,
  Rocket,
  X
} from 'lucide-react';

const StepCard = ({ number, title, description, icon: Icon, isVisible }) => {
  return (
    <div 
      className={`transform transition-all duration-700 ease-out
                ${isVisible ? 'translate-x-0 opacity-100' : 'translate-x-20 opacity-0'}
                flex items-start gap-6 p-6 bg-white/80 backdrop-blur-lg rounded-2xl
                hover:shadow-lg hover:shadow-blue-100 hover:-translate-y-1
                border border-blue-50 group cursor-default`}
      style={{ transitionDelay: `${number * 150}ms` }}
    >
      {/* Numéro avec effet de gradient */}
      <div className="relative flex-shrink-0">
        <div className="w-12 h-12 rounded-full bg-gradient-to-br from-blue-500 to-blue-600 
                      flex items-center justify-center text-white font-bold text-lg
                      shadow-lg shadow-blue-200 group-hover:shadow-blue-300
                      transition-shadow duration-300">
          {number}
        </div>
      </div>

      {/* Contenu */}
      <div className="flex-1 relative">
        <div className="flex items-center gap-3 mb-2">
          <Icon className="w-5 h-5 text-blue-500" />
          <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
        </div>
        <p className="text-gray-600 leading-relaxed">{description}</p>

        {/* Ligne décorative animée */}
        <div className="absolute -left-9 top-0 bottom-0 w-0.5 bg-gradient-to-b from-blue-500/0 via-blue-500/20 to-blue-500/0
                      transform scale-y-0 group-hover:scale-y-100 transition-transform duration-300 origin-top" />
      </div>
    </div>
  );
};

export default function WelcomeStep() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleStart = () => {
    navigate('/become-host/steponeintro');
  };

  const handleQuit = () => {
    navigate('/stepone');
  };

  const steps = [
    {
      number: 1,
      title: "Parlez-nous de votre espace",
      description: "Indiquez l'emplacement de votre espace et quelques informations de base pour les télétravailleurs.",
      icon: Home
    },
    {
      number: 2,
      title: "Mettez votre espace en valeur",
      description: "Ajoutez des photos de qualité et une description engageante pour attirer des réservations.",
      icon: ImagePlus
    },
    {
      number: 3,
      title: "Publiez votre annonce",
      description: "Validez les détails et mettez votre espace en ligne pour accueillir les premiers invités.",
      icon: Rocket
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50 relative overflow-hidden">
      {/* Formes décoratives d'arrière-plan */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 right-0 w-2/3 h-2/3 bg-blue-100/30 rounded-full blur-3xl transform translate-x-1/2 -translate-y-1/2" />
        <div className="absolute bottom-0 left-0 w-2/3 h-2/3 bg-blue-100/30 rounded-full blur-3xl transform -translate-x-1/2 translate-y-1/2" />
      </div>

      {/* Contenu principal */}
      <div className="relative w-full max-w-6xl mx-auto px-4 py-12 lg:py-20">
        {/* Bouton Quitter */}
        <button 
          onClick={handleQuit}
          className="absolute top-8 right-8 flex items-center gap-2 px-4 py-2 
                   text-gray-600 hover:text-gray-900 transition-colors duration-300
                   group"
        >
          <X className="w-5 h-5 transform group-hover:rotate-90 transition-transform duration-300" />
          <span>Quitter</span>
        </button>

        {/* En-tête */}
        <div className={`max-w-2xl mb-16 transition-all duration-700 ease-out
                      ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6 leading-tight">
            Commencer sur{' '}
            <span className="text-blue-600">HomeOfficeShare</span>,
            <br />c'est facile
          </h1>
          <p className="text-xl text-gray-600 leading-relaxed">
            Suivez ces étapes simples pour mettre votre espace en ligne 
            et commencer à accueillir des télétravailleurs.
          </p>
        </div>

        {/* Liste des étapes */}
        <div className="space-y-4 mb-16">
          {steps.map((step) => (
            <StepCard 
              key={step.number}
              {...step}
              isVisible={isVisible}
            />
          ))}
        </div>

        {/* Bouton Commencer */}
        <div className={`flex justify-center transition-all duration-700 delay-700
                      ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
          <button
            onClick={handleStart}
            className="group relative px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-500
                     text-white rounded-full text-lg font-medium
                     hover:shadow-xl hover:shadow-blue-500/20
                     transform transition-all duration-300
                     hover:-translate-y-1 active:translate-y-0
                     focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:ring-offset-2"
          >
            <span className="flex items-center gap-3">
              <span>Commencer</span>
              <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform duration-300" />
            </span>

            {/* Effet de brillance au survol */}
            <div className="absolute inset-0 rounded-full overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent
                           translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}