import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  HelpCircle, 
  ArrowLeft, 
  ArrowRight,
  Settings,
  Sparkles,
  Shield,
  Coffee,
  Video,
  LucidePenTool,
  Plus
} from 'lucide-react';
import { useForm } from '../../../context/FormHostContext';
import SaveQuitButton from '../../../components/SaveQuitButton';

const CategoryTab = ({ active, icon: Icon, label, count, onClick }) => (
  <button
    onClick={onClick}
    className={`flex items-center gap-3 px-6 py-4 rounded-xl transition-all duration-300
              ${active 
                ? 'bg-blue-600 text-white shadow-lg shadow-blue-500/20' 
                : 'bg-white hover:bg-gray-50 text-gray-600'}`}
  >
    <Icon className="w-5 h-5" />
    <span className="font-medium">{label}</span>
    {count > 0 && (
      <span className={`ml-2 px-2 py-0.5 text-sm rounded-full
                     ${active ? 'bg-white/20' : 'bg-gray-100'}`}>
        {count}
      </span>
    )}
  </button>
);

const EquipmentCard = ({ icon, label, selected, onClick }) => (
  <button
    onClick={onClick}
    className={`group relative p-6 rounded-2xl transition-all duration-300 
              border-2 ${selected 
                ? 'border-blue-500 bg-blue-50' 
                : 'border-gray-200 hover:border-blue-200 bg-white'}`}
  >
    {/* Selection Indicator */}
    <div className={`absolute top-3 right-3 w-6 h-6 rounded-full 
                   flex items-center justify-center transition-all duration-300
                   ${selected 
                     ? 'bg-blue-500 scale-100' 
                     : 'bg-gray-100 scale-90 group-hover:scale-100'}`}>
      <Plus className={`w-4 h-4 transition-all duration-300 
                     ${selected 
                       ? 'text-white rotate-45' 
                       : 'text-gray-400 rotate-0'}`} />
    </div>

    {/* Content */}
    <div className="flex flex-col items-center text-center gap-4">
      <div className={`text-4xl transition-all duration-300 transform
                    ${selected 
                      ? 'text-blue-500 scale-110' 
                      : 'text-gray-600 group-hover:text-blue-400 group-hover:scale-105'}`}>
        {icon}
      </div>
      <span className={`font-medium transition-colors duration-300
                     ${selected ? 'text-blue-700' : 'text-gray-700'}`}>
        {label}
      </span>
    </div>

    {/* Hover Effect */}
    <div className={`absolute inset-0 rounded-2xl bg-blue-50 transition-opacity duration-300
                   ${selected ? 'opacity-0' : 'opacity-0 group-hover:opacity-5'}`} />
  </button>
);

const categoryIcons = {
  basic_equipment: Settings,
  productivity_equipment: Sparkles,
  safety_equipment: Shield,
  comfort_equipment: Coffee,
  video_conferencing_equipment: Video,
  specialized_equipment: LucidePenTool,
  additional_equipment: Plus
};

const categoryLabels = {
  basic_equipment: "Équipements de base",
  productivity_equipment: "Productivité",
  safety_equipment: "Sécurité",
  comfort_equipment: "Confort",
  video_conferencing_equipment: "Communication",
  specialized_equipment: "Spécialisés",
  additional_equipment: "Supplémentaires"
};

const StepProgress = ({ currentStep, totalSteps }) => (
  <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                  bg-white/90 backdrop-blur-sm rounded-full shadow-lg p-2 border border-blue-50">
    <div className="flex items-center gap-2 px-3 py-1">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`rounded-full transition-all duration-300 
                    ${index < currentStep 
                      ? 'w-8 h-2 bg-blue-500' 
                      : 'w-2 h-2 bg-gray-200'}`}
        />
      ))}
    </div>
  </div>
);

export default function StepTwoEquipments() {
  const navigate = useNavigate();
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [activeCategory, setActiveCategory] = useState('basic_equipment');
  const { handleChange } = useForm();
  const apiUrl = process.env.REACT_APP_API_GATEWAY;

  useEffect(() => {
    const fetchEquipements = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/workspace/equipement`);
        const Equipementsdata = await response.json();
        
        setEquipments(Equipementsdata.data.data);
      } catch (error) {
        console.error('Error fetching equipement:', error);
      }
    };

    fetchEquipements();
  }, [apiUrl]);

  const toggleEquipment = (equipment) => {
    const equipmentId = equipment.type_equipement + '-' + equipment.nom_equipement;
    setSelectedEquipments(prev => {
      const newSelection = prev.includes(equipmentId)
        ? prev.filter(item => item !== equipmentId)
        : [...prev, equipmentId];
      handleChange('equipements', newSelection);
      return newSelection;
    });
  };

  const getSelectedCountForCategory = (category) => {
    return selectedEquipments.filter(eq => eq.startsWith(category)).length;
  };

  return (
    <div className="min-h-screen bg-gray-100/80">
      {/* Header */}
      <div className="fixed top-0 inset-x-0 z-50">
        <header className="bg-white border-b border-gray-200">
          <div className="max-w-7xl mx-auto px-4 py-4 flex items-center justify-between">
            <div className="flex items-center gap-8">
              <button onClick={() => navigate('/become-host/steptwointro')}
                      className="text-gray-400 hover:text-gray-600 transition-colors">
                <ArrowLeft className="w-6 h-6" />
              </button>
              <h1 className="text-xl font-semibold text-gray-900">Équipements disponibles</h1>
            </div>
            <SaveQuitButton />
          </div>
        </header>

        {/* Category Navigation */}
        <div className="bg-gray-50 border-b border-gray-200">
          <div className="max-w-7xl mx-auto px-4 py-3">
            <div className="flex gap-3 overflow-x-auto pb-3 hide-scrollbar">
              {Object.keys(categoryLabels).map(category => (
                <CategoryTab
                  key={category}
                  icon={categoryIcons[category]}
                  label={categoryLabels[category]}
                  count={getSelectedCountForCategory(category)}
                  active={activeCategory === category}
                  onClick={() => setActiveCategory(category)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 pt-40 pb-24">
        {/* Equipment Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {equipments
            .filter(eq => eq.type_equipement === activeCategory)
            .map(equipment => (
              <EquipmentCard
                key={equipment.equipement_id}
                icon={equipment.icone_equipement}
                label={equipment.nom_equipement}
                selected={selectedEquipments.includes(
                  equipment.type_equipement + '-' + equipment.nom_equipement
                )}
                onClick={() => toggleEquipment(equipment)}
              />
            ))}
        </div>
      </main>

      <StepProgress currentStep={6} totalSteps={14} />

      {/* Footer */}
      <footer className="fixed bottom-0 inset-x-0 bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <span className="text-gray-500">
              {selectedEquipments.length} équipement{selectedEquipments.length !== 1 ? 's' : ''} sélectionné{selectedEquipments.length !== 1 ? 's' : ''}
            </span>
          </div>
          <button
            onClick={() => navigate('/become-host/steptwophotos')}
            className="px-8 py-3 bg-blue-600 text-white rounded-xl
                     hover:bg-blue-500 hover:shadow-lg hover:shadow-blue-500/20
                     transition-all duration-300 flex items-center gap-2"
          >
            <span>Continuer</span>
            <ArrowRight className="w-5 h-5" />
          </button>
        </div>
      </footer>

      {/* Custom Styles */}
      <style>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      `}</style>
    </div>
  );
}