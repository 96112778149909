import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { format, parseISO, differenceInHours, differenceInDays } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useAuth } from '@/context/AuthContext';
import {
  FaCalendarCheck,
  FaMapMarkerAlt,
  FaClock,
  FaUserFriends,
  FaCheckCircle,
  FaDownload,
  FaShare,
  FaWhatsapp,
  FaEnvelope,
  FaArrowLeft,
  FaExclamationCircle
} from 'react-icons/fa';

const BookingSuccess = () => {
  const { id } = useParams();
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userName } = useAuth();
  const apiUrl = process.env.REACT_APP_API_GATEWAY;

  // Récupérer la valeur de session_id
  const session_id = new URLSearchParams(window.location.search).get('session_id');

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/reservation/guest/success/${session_id}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();

        if (data.success) {
          setBooking(data.data);
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError('Erreur lors de la récupération des détails de la réservation');
      } finally {
        setLoading(false);
      }
    };

    fetchBookingDetails();
  }, [session_id]);
  
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 flex items-center justify-center">
        <div className="relative">
          <div className="w-16 h-16 border-4 border-blue-400 border-t-transparent rounded-full animate-spin" />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-8 h-8 border-4 border-indigo-400 border-t-transparent rounded-full animate-spin" />
          </div>
        </div>
      </div>
    );
  }

  if (error || !booking) {


    return (
      <div className="min-h-screen bg-gradient-to-br from-rose-50 via-red-50 to-orange-50 flex items-center justify-center p-4">
        <div className="relative bg-white/80 backdrop-blur-lg p-8 rounded-3xl shadow-xl max-w-md w-full">
          <div className="absolute -top-12 left-1/2 -translate-x-1/2">
            <div className="w-24 h-24 bg-red-100 rounded-3xl flex items-center justify-center rotate-12">
              <FaExclamationCircle className="text-red-500 text-4xl -rotate-12" />
            </div>
          </div>
          <div className="mt-12 text-center">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Une erreur est survenue</h2>
            <p className="text-gray-600 mb-8">{error || "Impossible de charger les détails de la réservation"}</p>
            <Link
              to="/"
              className="inline-flex items-center justify-center px-8 py-4 bg-gradient-to-r from-red-500 to-orange-500 text-white font-medium rounded-2xl hover:from-red-600 hover:to-orange-600 transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              Retour à l'accueil
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const handleShare = async () => {
    const shareData = {
      title: 'Réservation confirmée',
      text: `J'ai réservé ${booking.titre} pour le ${format(booking.date_debut, 'dd MMMM yyyy', { locale: fr })}`,
      url: window.location.href
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      }
    } catch (err) {
      console.log('Erreur de partage:', err);
    }
  };

  const handleDownloadReceipt = () => {
    // TODO: Implémenter la génération et le téléchargement du reçu
    console.log('Téléchargement du reçu...');
  };

  const formatDuration = (booking) => {
    const startDate = parseISO(booking.date_debut);
    const endDate = parseISO(booking.date_fin);
  
    switch (booking.type_reservation) {
      case 'hour':
        const hours = differenceInHours(endDate, startDate);
        return `${hours} ${hours > 1 ? 'heures' : 'heure'}`;
        
      case 'day':
        const days = differenceInDays(endDate, startDate);
        return `${days} ${days > 1 ? 'jours' : 'jour'}`;
        
      default:
        return `${format(startDate, 'HH:mm', { locale: fr })} - ${format(endDate, 'HH:mm', { locale: fr })}`;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 py-12 px-4">
      <div className="max-w-5xl mx-auto">
        {/* En-tête */}
        <motion.div 
          variants={container}
          initial="hidden"
          animate="show"
          className="text-center relative z-10 mb-16"
        >
          <motion.div
            initial={{ scale: 0, rotate: -180 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ type: "spring", duration: 1.5 }}
            className="w-32 h-32 bg-gradient-to-br from-green-400 to-emerald-500 rounded-3xl flex items-center justify-center mx-auto mb-8 shadow-lg transform hover:rotate-12 transition-transform duration-300"
          >
            <FaCheckCircle className="text-white text-6xl" />
          </motion.div>

          <motion.h1 
            variants={item}
            className="text-5xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent mb-4"
          >
            Merci {userName} ! 🎉
          </motion.h1>
          <motion.div
            variants={item}
            className="text-lg text-gray-600 max-w-2xl mx-auto space-y-2"
          >
            <p className="text-2xl mb-2">Votre réservation est confirmée ✨</p>
            <p>Un email de confirmation vous a été envoyé 📧</p>
          </motion.div>

          {/* Informations utilisateur */}
          <motion.div 
            variants={item}
            className="bg-white/80 backdrop-blur-xl p-6 rounded-2xl shadow-lg mb-8 mt-8 mx-auto max-w-2xl flex items-center space-x-4"
          >
            <div className="w-16 h-16 bg-gradient-to-br from-blue-400 to-indigo-500 rounded-full flex items-center justify-center text-white text-2xl font-bold">
              {booking.user?.name ? booking.user.name.charAt(0).toUpperCase() : 'G'}
            </div>
            <div>
              <p className="text-sm text-gray-500">Réservé par</p>
              <p className="text-xl font-semibold text-gray-900">
                {userName} ✨
              </p>
              <p className="text-sm text-gray-600">{booking.user?.email}</p>
            </div>
          </motion.div>
        </motion.div>

        {/* Carte principale */}
        <motion.div 
          variants={container}
          initial="hidden"
          animate="show"
          className="relative bg-white/80 backdrop-blur-xl rounded-3xl shadow-xl overflow-hidden mb-12"
        >
          {/* Image de l'espace */}
          <motion.div 
            variants={item}
            className="relative h-72 bg-gray-200"
          >
            <img
              src={`data:image/jpeg;base64,${booking.photo_base64}`}
              alt={booking.titre}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
            <div className="absolute bottom-8 left-8 max-w-xl">
              <h2 className="text-3xl font-bold text-white mb-2">{booking.titre}</h2>
              <p className="flex items-center text-white/90 text-lg">
                <FaMapMarkerAlt className="mr-2" />
                {booking.adresse}
              </p>
            </div>
          </motion.div>

          {/* Détails de la réservation */}
          <div className="p-8">
            <motion.div 
              variants={item}
              className="grid grid-cols-1 md:grid-cols-2 gap-8"
            >
              {/* Date et heure */}
              <div className="space-y-6">
                <div className="group bg-gradient-to-br from-blue-50 to-indigo-50 p-6 rounded-2xl hover:shadow-lg transition-all duration-300">
                  <div className="flex items-start space-x-4">
                    <div className="p-4 bg-blue-500 rounded-2xl group-hover:scale-110 transition-transform duration-300">
                      <FaCalendarCheck className="text-white text-2xl" />
                    </div>
                    <div>
                      <p className="text-sm text-blue-600 font-medium">Date 📅</p>
                      <p className="text-xl font-semibold text-gray-900">
                        {format(booking.date_debut, 'dd MMMM yyyy', { locale: fr })}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="group bg-gradient-to-br from-purple-50 to-pink-50 p-6 rounded-2xl hover:shadow-lg transition-all duration-300">
                  <div className="flex items-start space-x-4">
                    <div className="p-4 bg-purple-500 rounded-2xl group-hover:scale-110 transition-transform duration-300">
                      <FaClock className="text-white text-2xl" />
                    </div>
                    <div>
                      <p className="text-sm text-purple-600 font-medium">
                        {booking.type_reservation === 'hour' ? 'Horaires ⏰' : 'Durée ⏱️'}
                      </p>
                      <p className="text-xl font-semibold text-gray-900">
                        {formatDuration(booking)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Invités et montant */}
              <div className="space-y-6">
                <div className="group bg-gradient-to-br from-green-50 to-emerald-50 p-6 rounded-2xl hover:shadow-lg transition-all duration-300">
                  <div className="flex items-start space-x-4">
                    <div className="p-4 bg-green-500 rounded-2xl group-hover:scale-110 transition-transform duration-300">
                      <FaUserFriends className="text-white text-2xl" />
                    </div>
                    <div>
                      <p className="text-sm text-green-600 font-medium">Invités 👥</p>
                      <p className="text-xl font-semibold text-gray-900">
                        {booking.capacite_max} {booking.capacite_max > 1 ? 'personnes' : 'personne'}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="group bg-gradient-to-br from-amber-50 to-yellow-50 p-6 rounded-2xl hover:shadow-lg transition-all duration-300">
                  <div className="flex items-start space-x-4">
                    <div className="p-4 bg-amber-500 rounded-2xl group-hover:scale-110 transition-transform duration-300">
                      <span className="text-white text-2xl font-bold">€</span>
                    </div>
                    <div>
                      <p className="text-sm text-amber-600 font-medium">Montant total 💰</p>
                      <p className="text-xl font-semibold text-gray-900">{booking.prix_total.toFixed(2)} €</p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Actions */}
            <motion.div 
              variants={item}
              className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4"
            >
              <button 
                onClick={handleDownloadReceipt}
                className="group relative flex items-center justify-center gap-3 px-8 py-4 bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-medium rounded-2xl overflow-hidden transition-all duration-300 hover:shadow-lg"
              >
                <div className="absolute inset-0 bg-white/20 transform -skew-x-12 -translate-x-full group-hover:translate-x-full transition-transform duration-700" />
                <FaDownload className="text-xl group-hover:scale-110 transition-transform duration-300" />
                <span>Télécharger le reçu</span>
              </button>
              <button 
                onClick={handleShare}
                className="group relative flex items-center justify-center gap-3 px-8 py-4 bg-gradient-to-r from-purple-500 to-pink-600 text-white font-medium rounded-2xl overflow-hidden transition-all duration-300 hover:shadow-lg"
              >
                <div className="absolute inset-0 bg-white/20 transform -skew-x-12 -translate-x-full group-hover:translate-x-full transition-transform duration-700" />
                <FaShare className="text-xl group-hover:scale-110 transition-transform duration-300" />
                <span>Partager</span>
              </button>
            </motion.div>
          </div>
        </motion.div>

        {/* Actions secondaires */}
        <motion.div 
          variants={container}
          initial="hidden"
          animate="show"
          className="flex flex-col items-center space-y-8"
        >
          {/* Boutons de partage */}
          <motion.div 
            variants={item}
            className="flex space-x-6"
          >
            <button 
              onClick={() => window.open(`https://wa.me/?text=${encodeURIComponent(`J'ai réservé ${booking.titre} pour le ${format(booking.date_debut, 'dd MMMM yyyy', { locale: fr })}`)}`)}
              className="group p-4 bg-gradient-to-br from-green-400 to-green-600 rounded-2xl transform hover:scale-110 transition-all duration-300 shadow-lg hover:shadow-xl hover:-rotate-6"
            >
              <FaWhatsapp className="text-white text-2xl" />
            </button>
            <button 
              onClick={() => window.location.href = `mailto:?subject=Réservation confirmée&body=J'ai réservé ${booking.titre} pour le ${format(booking.date_debut, 'dd MMMM yyyy', { locale: fr })}`}
              className="group p-4 bg-gradient-to-br from-blue-400 to-blue-600 rounded-2xl transform hover:scale-110 transition-all duration-300 shadow-lg hover:shadow-xl hover:rotate-6"
            >
              <FaEnvelope className="text-white text-2xl" />
            </button>
          </motion.div>

          {/* Lien de retour */}
          <Link
            to="/"
            className="group inline-flex items-center space-x-2 text-gray-600 hover:text-gray-900 transition-colors duration-300"
          >
            <FaArrowLeft className="group-hover:-translate-x-1 transition-transform duration-300" />
            <span>Retour à l'accueil</span>
          </Link>
        </motion.div>

        {/* Code de réservation */}
        <motion.div
          variants={item}
          className="mt-12 text-center"
        >
          <div className="inline-block bg-gradient-to-r from-gray-50 to-gray-100 px-8 py-4 rounded-2xl shadow-lg">
            <p className="text-sm text-gray-500 font-medium mb-1">Code de réservation</p>
            <p className="font-mono text-2xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
              RSV-{booking.reservation_id}
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default BookingSuccess;