import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft,
  ArrowRight,
  X,
  Save
} from 'lucide-react';
import spacePreview from '../../../assets/images/space-preview.png'

const NavigationButton = ({ children, variant = "default", onClick, className = "" }) => {
  const baseStyles = "px-6 py-3 rounded-full font-medium transition-all duration-300 flex items-center gap-2";
  const variants = {
    default: "bg-gray-100 text-gray-700 hover:bg-gray-200",
    primary: "bg-gradient-to-r from-blue-600 to-blue-500 text-white hover:shadow-lg hover:shadow-blue-500/20 hover:-translate-y-0.5"
  };

  return (
    <button 
      onClick={onClick}
      className={`${baseStyles} ${variants[variant]} ${className}`}
    >
      {children}
    </button>
  );
};

const StepProgress = ({ currentStep, totalSteps }) => (
  <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                  bg-white rounded-full shadow-lg p-2 border border-gray-200">
    <div className="flex items-center gap-2 px-3 py-1">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`rounded-full transition-all duration-300 
                    ${index < currentStep ? 'w-8 h-2 bg-blue-500' : 'w-2 h-2 bg-gray-200'}`}
        />
      ))}
    </div>
  </div>
);

export default function StepOneIntro() {
  const navigate = useNavigate();
  const [currentStep] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const totalSteps = 3;

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleNext = () => {
    if (currentStep < totalSteps) {
      navigate('/become-host/steponetype');
    }
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Header avec Save/Quit */}
      <header className="fixed top-0 right-0 right-0 bg-white/80 backdrop-blur-lg border-b border-gray-100 z-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
        </div>
      </header>

      {/* Main Content */}
      <main className="pt-20 pb-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid lg:grid-cols-2 gap-12 lg:gap-20 items-center">
            {/* Left Content */}
            <div className={`transition-all duration-700 ease-out
                          ${isVisible ? 'translate-x-0 opacity-100' : '-translate-x-10 opacity-0'}`}>
              <div className="max-w-xl">
                {/* Step Indicator */}
                <div className="inline-flex items-center px-4 py-2 bg-blue-50 rounded-full text-blue-600 text-sm font-medium mb-6">
                  Étape {currentStep}
                </div>

                <h1 className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6">
                  Parlez-nous de votre espace
                </h1>

                <p className="text-xl text-gray-600 leading-relaxed mb-8">
                  Au cours de cette étape, nous allons vous demander quel type d'espace
                  vous proposez et si les utilisateurs pourront le réserver dans son intégralité
                  ou seulement une partie. Nous vous demanderons ensuite d'indiquer son emplacement
                  et sa capacité d'accueil.
                </p>

              </div>
            </div>

            {/* Right Image */}
            <div className={`relative transition-all duration-700 delay-300 ease-out
                          ${isVisible ? 'translate-x-0 opacity-100' : 'translate-x-10 opacity-0'}`}>
              <div className="relative rounded-3xl overflow-hidden bg-gradient-to-br from-blue-100 to-blue-50 aspect-[4/3]">
                <img
                  src={spacePreview}
                  alt="Aperçu de l'espace"
                  className="w-full h-full object-cover"
                />
                {/* Overlay gradient */}
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-transparent" />
              </div>

              {/* Decorative elements */}
              <div className="absolute -z-10 inset-0 transform translate-x-4 translate-y-4">
                <div className="absolute inset-0 bg-blue-100/50 rounded-3xl" />
              </div>
            </div>
          </div>
        </div>
      </main>

      <StepProgress currentStep={1} totalSteps={14} />

      {/* Footer Navigation */}
      <footer className="fixed bottom-0 left-0 right-0 bg-white/80 backdrop-blur-lg border-t border-gray-100">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <NavigationButton onClick={() => navigate('/dashboard')}>
            <ArrowLeft className="w-5 h-5" />
            <span>Retour</span>
          </NavigationButton>

          <NavigationButton variant="primary" onClick={handleNext}>
            <span>Suivant</span>
            <ArrowRight className="w-5 h-5" />
          </NavigationButton>
        </div>
      </footer>
    </div>
  );
}