import React, { createContext, useContext, useEffect, useState } from 'react';
import { WebSocketProvider } from './WebSocketContext';
import { useAuth } from './AuthContext';

export const NotificationContext = createContext(null);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [privateMessages, setPrivateMessages] = useState([]);
  const { userId } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch notifications
        const notifRes = await fetch(`${process.env.REACT_APP_API_GATEWAY}/api/auth/notification`, {
          method: 'GET',
          credentials: 'include'
        });
        const notifData = await notifRes.json();
        notifData.sort((a, b) => new Date(b.cree_a) - new Date(a.cree_a));
        setNotifications(notifData);

        // Fetch unread private messages
        const messagesRes = await fetch(`${process.env.REACT_APP_API_GATEWAY}/api/message/unread`, {
          method: 'GET',
          credentials: 'include'
        });
        const messagesData = await messagesRes.json();
        messagesData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setPrivateMessages(messagesData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleNewMessage = (message) => {
    if (
      message.type === 'privateMessage' || 
      (['image', 'file'].includes(message.type) && message.receiverId === userId)
    ) {
      setPrivateMessages((prev) => [message, ...prev]);
    } else {
      setNotifications((prev) => [message, ...prev]);
    }
  };

  const markPrivateMessagesAsRead = async (receiverId) => {
    try {
      await fetch(`${process.env.REACT_APP_API_GATEWAY}/api/message/read/${receiverId}`, {
        method: 'GET',
        credentials: 'include'
      });
      
      setPrivateMessages((prev) =>
        prev.filter(msg => msg.senderId !== receiverId)
      );
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  return (
    <WebSocketProvider onMessage={handleNewMessage}>
      <NotificationContext.Provider value={{ notifications, setNotifications, privateMessages, setPrivateMessages, markPrivateMessagesAsRead }}>
        {children}
      </NotificationContext.Provider>
    </WebSocketProvider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};