import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Clock, Mail, Shield, Info, Check, ExternalLink } from 'lucide-react';

const SectionTitle = ({ children, id }) => (
  <h2 
    id={id} 
    className="text-xl font-semibold text-gray-900 mt-8 mb-4 scroll-mt-20 flex items-center"
  >
    {children}
  </h2>
);

const SubSectionTitle = ({ children }) => (
  <h3 className="text-lg font-medium text-gray-900 mt-6 mb-3">
    {children}
  </h3>
);

const TableOfContents = ({ sections }) => (
  <div className="hidden lg:block sticky top-8 bg-white rounded-xl p-6 shadow-sm border border-gray-100">
    <h3 className="font-medium text-gray-900 mb-3">Sommaire</h3>
    <nav className="space-y-1">
      {sections.map((section, index) => (
        <a
          key={index}
          href={`#${section.id}`}
          className="block text-sm text-gray-600 hover:text-blue-600 py-1 transition-colors duration-200"
        >
          {section.title}
        </a>
      ))}
    </nav>
  </div>
);

const TermsOfService = () => {
  const sections = [
    { id: 'objet', title: '1. Objet du Service' },
    { id: 'inscription', title: '2. Inscription et Compte' },
    { id: 'services', title: '3. Services et Utilisation' },
    { id: 'reservation', title: '4. Réservation et Paiement' },
    { id: 'evaluations', title: '5. Évaluations et Avis' },
    { id: 'confidentialite', title: '6. Confidentialité' },
    { id: 'propriete', title: '7. Propriété Intellectuelle' },
    { id: 'responsabilite', title: '8. Responsabilité' },
    { id: 'resiliation', title: '9. Résiliation' },
    { id: 'modification', title: '10. Modifications des CGU' },
    { id: 'juridiction', title: '11. Droit Applicable' },
    { id: 'contact', title: '12. Contact' }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50/50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* En-tête */}
        <div className="mb-8">
          <Link 
            to="/" 
            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 mb-6 group"
          >
            <ArrowLeft className="w-4 h-4 mr-2 transform group-hover:-translate-x-1 transition-transform duration-200" />
            Retour à l'accueil
          </Link>

          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <h1 className="text-3xl font-bold text-gray-900">
              Conditions Générales d'Utilisation
            </h1>
            <div className="flex items-center text-sm text-gray-500 bg-white px-4 py-2 rounded-lg shadow-sm border border-gray-100">
              <Clock className="w-4 h-4 mr-2 text-blue-500" />
              Mise à jour le 12/11/2024
            </div>
          </div>
        </div>

        {/* Contenu principal avec navigation latérale */}
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Contenu principal */}
          <div className="lg:col-span-3">
            <div className="bg-white rounded-xl shadow-sm border border-gray-100">
              {/* Bannière d'introduction */}
              <div className="p-8 border-b border-gray-100 bg-gradient-to-br from-blue-50 to-white">
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    <div className="p-3 bg-white rounded-lg shadow-sm">
                      <Shield className="w-6 h-6 text-blue-600" />
                    </div>
                  </div>
                  <div>
                    <h2 className="text-lg font-medium text-gray-900 mb-2">
                      Protection des utilisateurs
                    </h2>
                    <p className="text-gray-600 leading-relaxed">
                      Les présentes Conditions Générales d'Utilisation régissent l'accès et l'utilisation de la plateforme HomeOfficeShare. En accédant à nos services, vous acceptez ces conditions sans réserve.
                    </p>
                  </div>
                </div>
              </div>

              <div className="p-8">
                <SectionTitle id="objet">1. Objet du Service</SectionTitle>
                <p className="text-gray-600 leading-relaxed">
                  HomeOfficeShare est une plateforme en ligne qui met en relation des hôtes proposant des espaces de travail privés à domicile et des utilisateurs (télétravailleurs, freelances, étudiants, etc.) à la recherche d'un espace pour travailler.
                </p>

                <SectionTitle id="inscription">2. Inscription et Compte Utilisateur</SectionTitle>
                <SubSectionTitle>2.1 Eligibilité</SubSectionTitle>
                <p className="text-gray-600 leading-relaxed">
                  Pour utiliser HomeOfficeShare, vous devez être âgé de 18 ans ou plus et disposer de la capacité légale pour contracter.
                </p>

                <SubSectionTitle>2.2 Création de Compte</SubSectionTitle>
                <p className="text-gray-600 leading-relaxed">
                  Pour accéder à certains services, vous devez créer un compte en fournissant des informations personnelles exactes, complètes et à jour.
                </p>

                <SectionTitle id="services">3. Services et Utilisation de la Plateforme</SectionTitle>
                <div className="bg-gray-50 rounded-lg p-6 my-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-3">Ce que nous garantissons :</h4>
                  <ul className="space-y-3">
                    {[
                      'Espaces de travail vérifiés et sécurisés',
                      'Paiements sécurisés et transparents',
                      'Support client réactif 7j/7',
                      'Confidentialité des données'
                    ].map((item, index) => (
                      <li key={index} className="flex items-center text-gray-600">
                        <Check className="w-5 h-5 text-green-500 mr-3 flex-shrink-0" />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>

                <SectionTitle id="reservation">4. Politique de Réservation et de Paiement</SectionTitle>
                <div className="space-y-4">
                  <p className="text-gray-600 leading-relaxed">
                    Les réservations se font exclusivement via la Plateforme. Le paiement est sécurisé et traité par nos partenaires de confiance.
                  </p>
                  <div className="pl-4 border-l-4 border-blue-100">
                    <h4 className="text-sm font-medium text-gray-900 mb-2">Conditions d'annulation :</h4>
                    <ul className="space-y-2 text-gray-600">
                      <li>• Annulation gratuite jusqu'à 48h avant la réservation</li>
                      <li>• Remboursement partiel entre 48h et 24h avant la réservation</li>
                      <li>• Conditions spéciales pour les réservations longue durée</li>
                    </ul>
                  </div>
                </div>

                <SectionTitle id="evaluations">5. Évaluations et Avis</SectionTitle>
                <p className="text-gray-600 leading-relaxed">
                  Après chaque réservation, les Utilisateurs et les Hôtes peuvent laisser un avis réciproque basé sur leur expérience. Ces avis doivent être objectifs et respectueux.
                </p>

                <SectionTitle id="confidentialite">6. Confidentialité et Protection des Données</SectionTitle>
                <p className="text-gray-600 leading-relaxed">
                  HomeOfficeShare collecte et traite vos données personnelles conformément au RGPD. Pour plus de détails, consultez notre{' '}
                  <Link to="/privacy" className="text-blue-600 hover:text-blue-700 transition-colors duration-200">
                    Politique de Confidentialité
                  </Link>.
                </p>

                <SectionTitle id="propriete">7. Propriété Intellectuelle</SectionTitle>
                <p className="text-gray-600 leading-relaxed">
                  Le contenu de la Plateforme (textes, images, logos, code source, etc.) est la propriété exclusive de HomeOfficeShare. Toute reproduction ou utilisation non autorisée est strictement interdite.
                </p>

                <SectionTitle id="responsabilite">8. Responsabilité</SectionTitle>
                <div className="space-y-4">
                  <p className="text-gray-600 leading-relaxed">
                    HomeOfficeShare agit comme une plateforme de mise en relation et décline toute responsabilité en cas de :
                  </p>
                  <ul className="space-y-2 text-gray-600 pl-4">
                    <li>• Dommages liés à l'utilisation des espaces</li>
                    <li>• Problèmes techniques indépendants de notre volonté</li>
                    <li>• Conflits entre utilisateurs et hôtes</li>
                  </ul>
                </div>

                <SectionTitle id="resiliation">9. Résiliation</SectionTitle>
                <p className="text-gray-600 leading-relaxed">
                  HomeOfficeShare se réserve le droit de suspendre ou de résilier un compte utilisateur en cas de non-respect de ces Conditions, avec ou sans préavis selon la gravité de l'infraction.
                </p>

                <SectionTitle id="modification">10. Modifications des CGU</SectionTitle>
                <p className="text-gray-600 leading-relaxed">
                  HomeOfficeShare se réserve le droit de modifier ces Conditions à tout moment. Les utilisateurs seront informés par email ou par notification sur la Plateforme.
                </p>

                <SectionTitle id="juridiction">11. Droit Applicable et Juridiction</SectionTitle>
                <p className="text-gray-600 leading-relaxed">
                  Les présentes Conditions sont régies par le droit français. En cas de litige, les tribunaux français seront seuls compétents.
                </p>

                <SectionTitle id="contact">12. Contact</SectionTitle>
                <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-6 mt-4">
                  <div className="flex items-start space-x-4">
                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <Mail className="w-6 h-6 text-blue-600" />
                    </div>
                    <div>
                      <h4 className="text-lg font-medium text-gray-900">Besoin d'assistance ?</h4>
                      <p className="mt-2 text-gray-600">
                        Notre équipe est à votre disposition à{' '}
                        <a 
                          href="mailto:support@homeofficeshare.com"
                          className="text-blue-600 hover:text-blue-700 transition-colors duration-200"
                        >
                          support@homeofficeshare.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                {/* Note de bas de page */}
                <div className="mt-8 p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-start space-x-3">
                    <Info className="w-5 h-5 text-blue-600 mt-0.5 flex-shrink-0" />
                    <p className="text-sm text-gray-600">
                      Nous vous recommandons de consulter régulièrement ces CGU car elles peuvent être mises à jour. La date de dernière mise à jour est indiquée en haut de cette page.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Navigation latérale */}
          <aside>
            <TableOfContents sections={sections} />
          </aside>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;