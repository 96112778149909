import React, { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function Signup() {
  const API_URL = process.env.REACT_APP_API_GATEWAY;
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      const redirectTo = location.state?.redirectTo || '/';
      navigate(redirectTo);
    }
  }, [isAuthenticated, navigate, location.state]);

  const handleGoogleSignup = () => {
    window.location.href = API_URL + '/api/auth/google';
  };

  const features = [
    {
      title: "Espaces vérifiés",
      description: "Tous nos espaces sont vérifiés et approuvés"
    },
    {
      title: "Réservation flexible",
      description: "Annulation gratuite jusqu'à 48h avant"
    },
    {
      title: "Support 24/7",
      description: "Une équipe à votre écoute à tout moment"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-indigo-50 flex items-center justify-center p-4 sm:p-6 lg:p-8">
      <div className="w-full max-w-6xl grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
        {/* Colonne de gauche - Formulaire */}
        <div className="w-full max-w-md mx-auto">
          <div className="text-center mb-8">
            <div className="mx-auto w-16 h-16 bg-gradient-to-tr from-blue-600 to-indigo-600 rounded-2xl transform rotate-12 transition-transform hover:rotate-0 duration-300" />
            <h2 className="mt-6 text-3xl font-bold text-gray-900">
              Rejoignez-nous
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Commencez à explorer des espaces de travail uniques
            </p>
          </div>

          {/* Card principale */}
          <div className="bg-white/80 backdrop-blur-lg rounded-2xl shadow-xl p-8 space-y-6 transform hover:scale-[1.01] transition-all duration-300">
            {/* Bouton Google */}
            <button
              onClick={handleGoogleSignup}
              className="w-full group relative flex items-center justify-center px-6 py-3 border-2 border-gray-300 rounded-xl hover:border-gray-400 transition-all duration-200 bg-white hover:bg-gray-50"
            >
              <span className="absolute left-4 flex items-center justify-center">
                <svg className="w-6 h-6" viewBox="0 0 24 24">
                  <path
                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    fill="#4285F4"
                  />
                  <path
                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    fill="#34A853"
                  />
                  <path
                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    fill="#EA4335"
                  />
                </svg>
              </span>
              <span className="text-gray-700 font-medium ml-4">
                Continuer avec Google
              </span>
            </button>

            {/* Séparateur */}
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-4 bg-white text-gray-500">ou</span>
              </div>
            </div>

            {/* Login Link */}
            <div className="text-center">
              <div className="text-sm">
                <span className="text-gray-500">Déjà membre ?</span>{' '}
                <Link
                  to="/login"
                  className="font-medium text-blue-600 hover:text-blue-500 transition-colors duration-200"
                >
                  Se connecter
                </Link>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="mt-8 text-center">
            <p className="text-xs text-gray-500">
              En vous inscrivant, vous acceptez nos{' '}
              <Link to="/terms" className="text-blue-600 hover:text-blue-500">
                conditions d'utilisation
              </Link>{' '}
              et notre{' '}
              <Link to="/privacy" className="text-blue-600 hover:text-blue-500">
                politique de confidentialité
              </Link>
            </p>
          </div>
        </div>

        {/* Colonne de droite - Features */}
        <div className="hidden lg:block">
          <div className="bg-white/40 backdrop-blur-lg rounded-2xl p-8 transform hover:scale-[1.01] transition-all duration-300">
            <h3 className="text-2xl font-bold text-gray-900 mb-8">
              Pourquoi nous choisir ?
            </h3>
            <div className="space-y-8">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className="flex items-start space-x-4 group"
                >
                  <div className="flex-shrink-0 w-12 h-12 rounded-xl bg-blue-600/10 group-hover:bg-blue-600/20 flex items-center justify-center transition-colors duration-200">
                    <div className="w-6 h-6 rounded-lg bg-blue-600/20 group-hover:bg-blue-600/30 transition-colors duration-200" />
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-900 mb-1">
                      {feature.title}
                    </h4>
                    <p className="text-gray-600">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            {/* Statistiques */}
            <div className="mt-12 grid grid-cols-3 gap-4 pt-8 border-t border-gray-200">
              <div className="text-center">
                <div className="text-2xl font-bold text-blue-600">500+</div>
                <div className="text-sm text-gray-600">Espaces attendus</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-blue-600">10k+</div>
                <div className="text-sm text-gray-600">potentiels clients</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-blue-600">98%</div>
                <div className="text-sm text-gray-600">Satisfaction</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;