import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from '@/context/FormHostContext';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaTimes, FaCamera, FaImage, FaTrash, 
  FaBuilding, FaMapMarkerAlt, FaUsers, 
  FaToilet, FaDoorOpen, FaCheck,
  FaMoneyBill, FaCogs, FaStar, FaWifi,
  FaParking, FaUniversalAccess, FaThermometerHalf,
  FaEuroSign, FaClock, FaCalendarDay, FaCalendarWeek,
  FaCalendarAlt, FaPlus, FaMinus, FaEye
} from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import ToastNotification from '@/components/ToastNotification';
import { useAuth } from '@/context/AuthContext';
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN; // Remplacez par votre jeton Mapbox

const EditListingPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [space, setSpace] = useState(null);
  const [value, setValue] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('general');
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const markerRef = useRef(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  // État pour les équipements et points forts
  const [equipements, setEquipements] = useState({});
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [strongPoints, setStrongPoints] = useState([]);
  const [newStrongPoint, setNewStrongPoint] = useState('');
  const { formData,handleChange } = useForm();  
  const apiUrl = process.env.REACT_APP_API_GATEWAY;
  const { userId } = useAuth();
  const [spaceTypes, setSpaceTypes] = useState([]);

  // Fetch space types
  const fetchSpaceTypes = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/workspace/typeEspaces`);
      const spacesData = await response.json();

      setSpaceTypes(spacesData.data.data);
    } catch (error) {
      console.error('Error fetching space types:', error);
    }
  };

  useEffect(() => {
    fetchSpaceTypes();
  }, []);

  // Etat pour la séléction des équipements
  const toggleEquipment = (equipement, type, event) => {
    event.preventDefault(); // Prevent form submission
    setSelectedEquipments((prev) =>
      prev.includes(equipement.type_equipement + '-'+equipement.nom_equipement) ? prev.filter((item) => item !== equipement.type_equipement + '-'+equipement.nom_equipement) : [...prev, equipement.type_equipement + '-'+equipement.nom_equipement]
    );

    

    handleChange('equipements', selectedEquipments);

    console.log('Equipements sélectionnés:', selectedEquipments);
    
  };

  // Utiliser pour charger les équipements de la BDD
  const fetchEquipements = async () => {
    try {
      const response = await fetch(apiUrl+'/api/workspace/equipement');
      const equipementData = await response.json();
      setValue(equipementData.data.data);
      console.log('Equipements:', data);
    } catch (error) {
      console.error('Error fetching equipement:', error);
    }
  }
  useEffect(() => {
    fetchEquipements();
  }, []);


  // État pour les tarifs
  const [pricing, setPricing] = useState({
    hour: '',
    day: '',
    week: '',
    month: ''
  });

  const tabs = {
    general: {
      icon: FaBuilding,
      label: "Informations générales",
    },
    location: {
      icon: FaMapMarkerAlt,
      label: "Localisation",
    },
    capacity: {
      icon: FaUsers,
      label: "Capacités",
    },
    photos: {
      icon: FaCamera,
      label: "Photos",
    },
    equipements: {
      icon: FaCogs,
      label: "Équipements",
    },
    features: {
      icon: FaStar,
      label: "Points forts",
    },
    pricing: {
      icon: FaMoneyBill,
      label: "Tarification",
    },
    visibility: {
      icon: FaEye,
      label: "Visibilité",
    },
  };
  
  

  // Utilisez useEffect pour charger les données initiales
  useEffect(() => {
    const fetchSpaceDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/api/workspace/details/${id}`, {
          credentials: 'include'
        });
        
        if (!response.ok) {
          throw new Error('Erreur lors du chargement des données');
        }

        const data = await response.json();
        console.log('Données reçues:', data);
        
        setSpace(data[0]);
        setPhotos((data[0].photos || []).sort((a, b) => b.is_main - a.is_main));
        setEquipements(data[0].equipements || {});
        setStrongPoints(data[0].strong_points || []);
        
        handleChange('titre', data[0].titre);
        handleChange('description', data[0].description);
        handleChange('guests', data[0].capacite_max);
        handleChange('spaces', data[0].nb_salles);
        handleChange('toilets', data[0].nb_toilette);
        handleChange('desks', data[0].nb_bureau);
        handleChange('equipements', data[0].equipements);
        handleChange('strongPoints', data[0].strong_points);
        handleChange('modeReservation', data[0].mode || 'AUTO');
        handleChange('status', data[0].status);
        handleChange('typeEspace', data[0].espace_type_id);
        handleChange('adresse', data[0].adresse);
        handleChange('ville', data[0].ville);
        handleChange('pays', data[0].pays);
        handleChange('steps', 1);

        // Convert selected equipment to an array
        const selectedEquipmentsArray = Object.values(data[0].equipements || {});
        setSelectedEquipments(selectedEquipmentsArray);


        // Initialiser les tarifications
        if (data[0].tarifications) {
          const tarifsByType = data[0].tarifications.reduce((acc, tarif) => {
            acc[tarif.type] = tarif.prix;
            return acc;
          }, {});
          setPricing(tarifsByType);


          handleChange('prixByHour', tarifsByType.hour);
          handleChange('prixByDay', tarifsByType.day);
          handleChange('prixByWeek', tarifsByType.week);
          handleChange('prixByMonth', tarifsByType.month);
        }

        // Set map coordinates
        const coordinates = {
          longitude: data[0].longitude || 2.3522,
          latitude: data[0].latitude || 48.8566
        };
        handleChange('longitude', coordinates.longitude);
        handleChange('latitude', coordinates.latitude);
        
      } catch (error) {
        console.error('Erreur:', error);
        toast.error("Erreur lors du chargement des données");
      } finally {
        setLoading(false);
      }
    };

    fetchSpaceDetails();
  }, [id]);


  // Initialisation de la carte
  useEffect(() => {
    if (activeTab !== 'location') return; // S'exécute uniquement quand l'onglet "location" est actif
  
    const initializeMap = () => {
      if (map.current || !mapContainer.current) return; // Empêche l'initialisation multiple
  
      console.log('Initialisation de la carte...');
  
      // Initialisation de Mapbox
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [formData.longitude || 2.3522, formData.latitude || 48.8566],
        zoom: 12,
      });

      // Style Dynamique
      map.current.on('load', () => {
        // Caché le logo Mapbox
        document.querySelector('.mapboxgl-ctrl-attrib-inner').style.display = 'none';
      })
  
      // Ajout des contrôles de navigation
      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
  
      // Initialisation du Geocoder
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        placeholder: 'Saisissez votre adresse',
        marker: false,
        proximity: { longitude: 2.3522, latitude: 48.8566 } // Précision par rapport à Paris
      });
  
      geocoder.addTo('#geocoder-container');

      // Centrer la carte sur le résultat de recherche
      
      
      geocoder.on('result', (e) => {
        const coordinates = e.result.geometry.coordinates;
        
        
        // Centrer la carte sur l'adresse sélectionnée
        map.current.flyTo({ center: e.result.geometry.coordinates });

        const placeType = e.result.place_type[0];
        if (placeType === 'address') {
          // Mettre à jour les états
          handleChange('longitude', coordinates[0]);
          handleChange('latitude', coordinates[1]);
          handleChange('adresse', e.result.place_name);
          handleChange(
            'ville',
            e.result?.context.find((c) => c.id.includes('place'))?.text
          );
          handleChange(
            'pays',
            e.result?.context.find((c) => c.id.includes('country'))?.text
          );
        }else{
          setToastMessage('Veuillez sélectionner une adresse complète.');
          setShowToast(true);
        }
        
      });
    };
  
    // Observer pour attendre que le DOM soit prêt
    const observer = new MutationObserver(() => {
      if (mapContainer.current) {
        initializeMap();
        observer.disconnect(); // Arrête l'observation
      }
    });
  
    observer.observe(document.body, { childList: true, subtree: true });
  
    return () => {
      observer.disconnect(); // Nettoie l'observateur
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [activeTab]);
  

  const addStrongPoint = () => {
    if (newStrongPoint.trim()) {
      setStrongPoints(prev => [...prev, newStrongPoint.trim()]);
      setNewStrongPoint('');
    }
  };

  const removeStrongPoint = (index) => {
    setStrongPoints(prev => prev.filter((_, i) => i !== index));
  };

  const handlePricingChange = (type, value) => {

    handleChange('prixByHour', pricing.hour);
    handleChange('prixByDay', pricing.day);
    handleChange('prixByWeek', pricing.week);
    handleChange('prixByMonth', pricing.month);
    
    setPricing(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const handleNotification = (message, type) => {
    setToastMessage(message);
    setShowToast(true);
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    if (file.size > 5 * 1024 * 1024) {
      handleNotification('La taille du fichier doit être inférieure à 5MB', 'error');
      return;
    }
  
    // Rename the file to handle special characters
    const renamedFile = new File([file], 'photo' + Date.now() + file.type, { type: file.type });
  
    setUploading(true);
    const formData = new FormData();
    formData.append('photo', renamedFile);
    formData.append('espace_id', id);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/api/workspace/photos/upload`, {
        method: 'POST',
        credentials: 'include',
        body: formData
      });
  
      if (!response.ok) {
        throw new Error('Erreur lors de l\'upload');
      }
  
      const uploadPhoto = await response.json();

      setPhotos(prev => {
        const updatedPhotos = [...prev, uploadPhoto.data.data];
        return updatedPhotos.sort((a, b) => b.is_main - a.is_main);
      });
      handleNotification('Photo ajoutée', 'success');
    } catch (error) {
      console.error('Erreur upload:', error);
      handleNotification('Erreur lors de l\'upload de la photo', 'error');
    } finally {
      setUploading(false);
    }
  };
  

  const handleDeletePhoto = async (photoId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/api/workspace/photos/delete/${photoId}`, {
        method: 'DELETE',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la suppression');
      }

      setPhotos(prev => prev.filter(photo => photo.espace_photos_id !== photoId));
      handleNotification('Photo supprimée', 'success');
    } catch (error) {
      console.error('Erreur suppression:', error);
      handleNotification('Erreur lors de la suppression de la photo', 'error');
    }
  };

  const handleSetCoverPhoto = async (photoId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/api/workspace/photos/setcover/${photoId}`, {
        method: 'PUT',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour de la photo de couverture');
      }

      setPhotos(prev => {
        const updatedPhotos = prev.map(photo => ({
          ...photo,
          is_main: photo.espace_photos_id === photoId
        }));
        return updatedPhotos.sort((a, b) => b.is_main - a.is_main);
      });
      handleNotification('Photo de couverture mise à jour', 'success');
    } catch (error) {
      console.error('Erreur:', error);
      handleNotification('Erreur lors de la mise à jour de la photo de couverture', 'error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    
   

    // Déterminer le stepForm en fonction de l'onglet actif

    let steps = 1;
    switch(activeTab) {
      case 'general': steps = 1; break;
      case 'location': steps = 2; break;
      case 'capacity': steps = 3; break;
      case 'photos': steps = 4; break;
      case 'equipements': steps = 5; break;
      case 'features': steps = 5; break;
      case 'pricing': steps = 6; break;
      case 'visibility': steps = 7; break;
      default: steps = 1;
    }



    // Construire les données à envoyer
    const formDataImage = new FormData();
    formDataImage.append('steps', steps);
    formDataImage.append('hote_id', userId);
    formDataImage.append('typeEspace', formData.typeEspace);
    formDataImage.append('latitude', formData.latitude);
    formDataImage.append('longitude', formData.longitude);
    formDataImage.append('adresse', formData.adresse);
    formDataImage.append('ville', formData.ville);
    formDataImage.append('pays', formData.pays);
    formDataImage.append('guests', formData.guests);
    formDataImage.append('spaces', formData.spaces);
    formDataImage.append('toilets', formData.toilets);
    formDataImage.append('desks', formData.desks);
    formDataImage.append('equipements', JSON.stringify(formData.equipements));
    formDataImage.append('titre', formData.titre);
    formDataImage.append('strongPoints', JSON.stringify(formData.strongPoints));
    formDataImage.append('description', formData.description);
    formDataImage.append('modeReservation', formData.modeReservation);
    formDataImage.append('prixByHour', formData.prixByHour);
    formDataImage.append('prixByDay', formData.prixByDay);
    formDataImage.append('prixByWeek', formData.prixByWeek);
    formDataImage.append('prixByMonth', formData.prixByMonth);
    formDataImage.append('status', formData.status);


   
    console.log("Test", formData);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/api/workspace/updateworkspace/${id}`, {
        method: 'PUT',
        credentials: 'include',
        body: formDataImage
      });

      const data = await response.json();

      if (!response.ok || !data.success) {
        throw new Error(data.message || 'Erreur lors de la mise à jour');
      }

      setToastMessage('Mise à jour effectuée');
      setShowToast(true);      
      // Passer à l'onglet suivant ou retourner à la liste
      const tabKeys = Object.keys(tabs);
      const currentIndex = tabKeys.indexOf(activeTab);
      if (currentIndex < tabKeys.length - 1) {
        setActiveTab(tabKeys[currentIndex + 1]);
      } else {
        navigate('/listings');
      }

    } catch (error) {
      console.error('Erreur:', error);
      setToastMessage('Erreur:' + error.message || 'Erreur lors de la sauvegarde');
      setShowToast(true);
    } finally {
      setSaving(false);
    }
  };

const strongPointsOptions = [
  { id: 1, label: 'Calme', icon: '😌' },
  { id: 2, label: 'Équipé', icon: '🖥️' },
  { id: 3, label: 'Confortable', icon: '💺' },
  { id: 4, label: 'Proche des commodités', icon: '🏪' },
  { id: 5, label: 'Luminosité naturelle', icon: '🌞' },
  { id: 6, label: 'Flexible', icon: '🔄' },
];

const toggleStrongPoint = (label, event) => {
  event.preventDefault(); // Prevent form submission
  if (strongPoints.includes(label)) {
    setStrongPoints(strongPoints.filter((point) => point !== label));
  } else {
    setStrongPoints([...strongPoints, label]);
    handleChange('strongPoints', [...strongPoints, label]);
  }
};

  const renderTabContent = () => {
    switch(activeTab) {
      case 'general':
        return (
          <div className="space-y-6">
            <div>
              <label className="block text-gray-700 font-medium mb-2">Titre</label>
              <input
                type="text"
                name="titre"
                defaultValue={formData.titre}
                onChange={ (e) => handleChange('titre', e.target.value)}
                className="w-full p-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">Description</label>
              <textarea
                name="description"
                defaultValue={formData.description}
                onChange={ (e) => handleChange('description', e.target.value)}
                className="w-full p-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent h-32"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">Type d'espace</label>
              <select
                name="typeEspace"
                defaultValue={formData.typeEspace}
                className="w-full p-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
                onChange={ (e) => handleChange('typeEspace', e.target.value)}

              >
                <option value="">Sélectionner un type d'espace</option>
                {spaceTypes.map((type) => (
                  <option key={type.espace_type_id} value={type.espace_type_id}
                  >
                    {type.nom}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">Mode de réservation</label>
              <select
                name="modeReservation"
                defaultValue={formData.modeReservation}
                onChange={ (e) => handleChange('modeReservation', e.target.value)}
                className="w-full p-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="AUTO">Instantané</option>
                <option value="MANUAL">Manuel</option>
              </select>
            </div>
          </div>
        );

      case 'location':
        return (
          <div className="space-y-6">
            <h1 className="step-title">Où est situé votre logement ?</h1>
            <p className="step-description">
              Votre adresse est uniquement communiquée aux voyageurs une fois leur réservation effectuée.
            </p>

            <div className="map-container">
              {/* Input box pour l'autocomplétion d'adresse */}
              <div id="geocoder-container" className="geocoder-container"></div>
              <div ref={mapContainer} className="mapbox-container" style={{ height: '400px' }}></div>
            </div>
          </div>
        );

      case 'capacity':
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-700 font-medium mb-2">Capacité maximale</label>
                <input
                  type="number"
                  name="capacite_max"
                  defaultValue={formData.guests}
                  onChange={ (e) => handleChange('guests', e.target.value)}
                  min="1"
                  className="w-full p-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium mb-2">Nombre de bureaux</label>
                <input
                  type="number"
                  name="nb_bureau"
                  defaultValue={formData.desks}
                  onChange={ (e) => handleChange('desks', e.target.value)}
                  min="0"
                  className="w-full p-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-700 font-medium mb-2">Nombre de toilettes</label>
                <input
                  type="number"
                  name="nb_toilette"
                  defaultValue={formData.toilets}
                  onChange={ (e) => handleChange('toilets', e.target.value)}
                  min="0"
                  className="w-full p-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium mb-2">Nombre de salles</label>
                <input
                  type="number"
                  name="nb_salles"
                  defaultValue={formData.spaces}
                  onChange={ (e) => handleChange('spaces', e.target.value)}
                  min="0"
                  className="w-full p-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>
            </div>
          </div>
        );

      case 'photos':
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {photos.map((photo, index) => (
                <div key={index} className="relative group">
                  <img
                    src={`data:image/jpeg;base64,${photo.photo_base64}`}
                    alt={`Photo ${index + 1}`}
                    className="w-full h-48 object-cover rounded-lg"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg flex items-center justify-center gap-2">
                    <button
                      type="button"
                      onClick={() => handleDeletePhoto(photo.espace_photos_id)}
                      className="p-2 bg-red-600 text-white rounded-full hover:bg-red-700 transition-colors"
                    >
                      <FaTrash />
                    </button>
                    <button
                      type="button"
                      onClick={() => handleSetCoverPhoto(photo.espace_photos_id)}
                      className={`p-2 ${photo.is_main ? 'bg-green-600' : 'bg-blue-600'} text-white rounded-full hover:bg-blue-700 transition-colors`}
                    >
                      {photo.is_main ? 'Couverture' : 'Définir comme couverture'}
                    </button>
                  </div>
                </div>
              ))}
              
              <label className="relative flex items-center justify-center w-full h-48 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 cursor-pointer transition-colors">
                <div className="text-center">
                  {uploading ? (
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                  ) : (
                    <>
                      <FaImage className="mx-auto h-12 w-12 text-gray-400" />
                      <span className="mt-2 block text-sm text-gray-600">
                        Ajouter une photo
                      </span>
                    </>
                  )}
                </div>
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handlePhotoUpload}
                  disabled={uploading}
                />
              </label>
            </div>
          </div>
        );

      case 'equipements':
        return (
          <div className="space-y-8">
            <div>
              {/* Équipements de base */}
              <h2 className="text-2xl font-semibold mb-4">Équipements de base</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {value
                  .filter((eq) => eq.type_equipement === 'basic_equipment')
                  .map((equipment) => (
                    <button
                      key={equipment.equipement_id}
                      className={`p-4 border rounded-xl shadow-sm transition-all hover:shadow-lg ${
                        selectedEquipments.includes(equipment.type_equipement + '-' + equipment.nom_equipement)
                          ? 'bg-blue-100 border-blue-500 selected'
                          : 'bg-white border-gray-300'
                      }`}
                      onClick={(e) => toggleEquipment(equipment, 'basic_equipment', e)}
                    >
                      <span className="text-3xl">{equipment.icone_equipement}</span>
                      <span className="block mt-2 text-sm font-medium text-center">{equipment.nom_equipement}</span>
                    </button>
                  ))}
              </div>

              {/* Équipements hors du commun */}
              <h2 className="text-2xl font-semibold mt-8 mb-4">Équipements hors du commun</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {value
                  .filter((eq) => eq.type_equipement === 'productivity_equipment')
                  .map((equipment) => (
                    <button
                      key={equipment.equipement_id}
                      className={`p-4 border rounded-xl shadow-sm transition-all hover:shadow-lg ${
                        selectedEquipments.includes(equipment.type_equipement + '-' + equipment.nom_equipement)
                          ? 'bg-blue-100 border-blue-500'
                          : 'bg-white border-gray-300'
                      }`}
                      onClick={(e) => toggleEquipment(equipment, 'productivity_equipment', e)}
                    >
                      <span className="text-3xl">{equipment.icone_equipement}</span>
                      <span className="block mt-2 text-sm font-medium text-center">{equipment.nom_equipement}</span>
                    </button>
                  ))}
              </div>

              {/* Équipements de sécurité */}
              <h2 className="text-2xl font-semibold mt-8 mb-4">Équipements de sécurité</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {value
                  .filter((eq) => eq.type_equipement === 'safety_equipment')
                  .map((equipment) => (
                    <button
                      key={equipment.equipement_id}
                      className={`p-4 border rounded-xl shadow-sm transition-all hover:shadow-lg ${
                        selectedEquipments.includes(equipment.type_equipement + '-' + equipment.nom_equipement)
                          ? 'bg-blue-100 border-blue-500'
                          : 'bg-white border-gray-300'
                      }`}
                      onClick={(e) => toggleEquipment(equipment, 'safety_equipment', e)}
                    >
                      <span className="text-3xl">{equipment.icone_equipement}</span>
                      <span className="block mt-2 text-sm font-medium text-center">{equipment.nom_equipement}</span>
                    </button>
                  ))}
              </div>

              {/* Équipements de confort */}
              <h2 className="text-2xl font-semibold mt-8 mb-4">Équipements de confort</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {value
                  .filter((eq) => eq.type_equipement === 'comfort_equipment')
                  .map((equipment) => (
                    <button
                      key={equipment.equipement_id}
                      className={`p-4 border rounded-xl shadow-sm transition-all hover:shadow-lg ${
                        selectedEquipments.includes(equipment.type_equipement + '-' + equipment.nom_equipement)
                          ? 'bg-blue-100 border-blue-500'
                          : 'bg-white border-gray-300'
                      }`}
                      onClick={(e) => toggleEquipment(equipment, 'comfort_equipment', e)}
                    >
                      <span className="text-3xl">{equipment.icone_equipement}</span>
                      <span className="block mt-2 text-sm font-medium text-center">{equipment.nom_equipement}</span>
                    </button>
                  ))}
              </div>

              {/* Équipements de communication */}
              <h2 className="text-2xl font-semibold mt-8 mb-4">Équipements de communication</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {value
                  .filter((eq) => eq.type_equipement === 'video_conferencing_equipment')
                  .map((equipment) => (
                    <button
                      key={equipment.equipement_id}
                      className={`p-4 border rounded-xl shadow-sm transition-all hover:shadow-lg ${
                        selectedEquipments.includes(equipment.type_equipement + '-' + equipment.nom_equipement)
                          ? 'bg-blue-100 border-blue-500'
                          : 'bg-white border-gray-300'
                      }`}
                      onClick={(e) => toggleEquipment(equipment, 'video_conferencing_equipment', e)}
                    >
                      <span className="text-3xl">{equipment.icone_equipement}</span>
                      <span className="block mt-2 text-sm font-medium text-center">{equipment.nom_equipement}</span>
                    </button>
                  ))}
              </div>

              {/* Équipements spécialisés */}
              <h2 className="text-2xl font-semibold mt-8 mb-4">Équipements spécialisés</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {value
                  .filter((eq) => eq.type_equipement === 'specialized_equipment')
                  .map((equipment) => (
                    <button
                      key={equipment.equipement_id}
                      className={`p-4 border rounded-xl shadow-sm transition-all hover:shadow-lg ${
                        selectedEquipments.includes(equipment.type_equipement + '-' + equipment.nom_equipement)
                          ? 'bg-blue-100 border-blue-500'
                          : 'bg-white border-gray-300'
                      }`}
                      onClick={(e) => toggleEquipment(equipment, 'specialized_equipment', e)}
                    >
                      <span className="text-3xl">{equipment.icone_equipement}</span>
                      <span className="block mt-2 text-sm font-medium text-center">{equipment.nom_equipement}</span>
                    </button>
                  ))}
              </div>

              {/* Équipements supplémentaires */}
              <h2 className="text-2xl font-semibold mt-8 mb-4">Équipements supplémentaires</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {value
                  .filter((eq) => eq.type_equipement === 'additional_equipment')
                  .map((equipment) => (
                    <button
                      key={equipment.equipement_id}
                      className={`p-4 border rounded-xl shadow-sm transition-all hover:shadow-lg ${
                        selectedEquipments.includes(equipment.type_equipement + '-' + equipment.nom_equipement)
                          ? 'bg-blue-100 border-blue-500'
                          : 'bg-white border-gray-300'
                      }`}
                      onClick={(e) => toggleEquipment(equipment, 'additional_equipment', e)}
                    >
                      <span className="text-3xl">{equipment.icone_equipement}</span>
                      <span className="block mt-2 text-sm font-medium text-center">{equipment.nom_equipement}</span>
                    </button>
                  ))}
              </div>
            </div>
          </div>
        );

      case 'features':
        return (
          <div className="space-y-6">
            <h1 className="step-title">Choisissez les points forts de votre espace</h1>
            <p className="step-description">
              Sélectionnez jusqu'à <strong>2</strong> points forts pour décrire votre espace. Ces éléments aideront à attirer des utilisateurs.
            </p>

            <div className="tags-container">
              {strongPointsOptions.map((option) => (
                <button
                  key={option.id}
                  className={`tag ${strongPoints.includes(option.label) ? 'selected' : ''}`}
                  onClick={(e) => toggleStrongPoint(option.label, e)}
                  disabled={!strongPoints.includes(option.label) && strongPoints.length >= 2}
                >
                  <span className="tag-icon">{option.icon}</span>
                  <span className="tag-label">{option.label}</span>
                </button>
              ))}
            </div>

            <div className="space-y-2">
              {strongPoints.map((point, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-3 bg-gray-50 rounded-xl"
                >
                  <span>{point}</span>
                  <button
                    type="button"
                    onClick={() => removeStrongPoint(index)}
                    className="text-red-500 hover:text-red-600"
                  >
                    <FaTrash />
                  </button>
                </div>
              ))}
            </div>
          </div>
        );

      case 'pricing':
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <label className="block text-gray-700 font-medium">Tarif horaire</label>
                <div className="relative">
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={pricing.hour}
                    onChange={(e) => handlePricingChange('hour', e.target.value)}
                    className="w-full pl-12 pr-4 py-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                  <FaEuroSign className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
              </div>

              <div className="space-y-4">
                <label className="block text-gray-700 font-medium">Tarif journalier</label>
                <div className="relative">
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={pricing.day}
                    onChange={(e) => handlePricingChange('day', e.target.value)}
                    className="w-full pl-12 pr-4 py-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
<FaEuroSign className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
              </div>

              <div className="space-y-4">
                <label className="block text-gray-700 font-medium">Tarif hebdomadaire</label>
                <div className="relative">
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={pricing.week}
                    onChange={(e) => handlePricingChange('week', e.target.value)}
                    className="w-full pl-12 pr-4 py-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                  <FaEuroSign className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
              </div>

              <div className="space-y-4">
                <label className="block text-gray-700 font-medium">Tarif mensuel</label>
                <div className="relative">
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={pricing.month}
                    onChange={(e) => handlePricingChange('month', e.target.value)}
                    className="w-full pl-12 pr-4 py-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                  <FaEuroSign className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
              </div>
            </div>

            <div className="bg-blue-50 p-4 rounded-xl mt-6">
              <h4 className="font-medium text-blue-700 mb-2">Conseils de tarification</h4>
              <ul className="text-sm text-blue-600 space-y-1">
                <li>• Le tarif horaire moyen dans votre zone est de 15€/heure</li>
                <li>• Proposez des réductions pour les réservations longue durée</li>
                <li>• Pensez à ajuster vos tarifs selon les équipements proposés</li>
              </ul>
            </div>
          </div>
        );

      case 'visibility':
        return (
          <div className="space-y-6">
            <div>
              <label className="block text-gray-700 font-medium mb-2">Statut</label>
              <select
                name="status"
                defaultValue={formData.status}
                onChange={ (e) => handleChange('status', e.target.value)}
                className="w-full p-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="En cours">En cours</option>
                <option value="actif">Actif</option>
                <option value="inactif">Inactif</option>
              </select>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  // Affichage du loader pendant le chargement
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  // Rendu principal
  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
          {/* En-tête */}
          <div className="p-6 bg-gradient-to-r from-blue-600 to-blue-700">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-bold text-white">
                {space?.titre || 'Modifier l\'espace'}
              </h1>
              <button 
                onClick={() => navigate('/listings')}
                className="p-2 hover:bg-white/10 rounded-lg transition-colors"
              >
                <FaTimes className="text-white" size={24} />
              </button>
            </div>
          </div>

          <div className="flex flex-col md:flex-row">
            {/* Sidebar avec tabs */}
            <div className="w-full md:w-64 bg-gray-50 p-6 space-y-2">
              {Object.entries(tabs).map(([key, { icon: Icon, label }]) => (
                <button
                  key={key}
                  onClick={() => setActiveTab(key)}
                  className={`w-full flex items-center p-3 rounded-xl transition-colors ${
                    activeTab === key
                      ? 'bg-blue-50 text-blue-600'
                      : 'hover:bg-gray-100 text-gray-600'
                  }`}
                >
                  <Icon className="mr-3" />
                  <span>{label}</span>
                </button>
              ))}
            </div>

            {/* Contenu principal */}
            <div className="flex-1 p-8">
              <form onSubmit={handleSubmit} className="space-y-6">
                <AnimatePresence mode="wait">
                  <motion.div
                    key={activeTab}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.2 }}
                  >
                    {renderTabContent()}
                  </motion.div>
                </AnimatePresence>

                {/* Boutons de navigation */}
                <div className="flex gap-4 pt-8 mt-8 border-t">
                  <button
                    type="button"
                    onClick={() => navigate('/listings')}
                    className="flex-1 py-3 px-4 border border-gray-300 rounded-xl hover:bg-gray-50 transition-colors"
                    disabled={saving}
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    className={`flex-1 bg-blue-600 text-white py-3 px-4 rounded-xl hover:bg-blue-700 transition-colors flex items-center justify-center gap-2 ${
                      saving ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={saving}
                  >
                    {saving ? (
                      <>
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                        <span>Enregistrement...</span>
                      </>
                    ) : (
                      <>
                        <FaCheck />
                        <span>Sauvegarder et continuer</span>
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>

          {showToast && (
              <ToastNotification
                message={toastMessage}
                type={toastMessage.includes('Erreur') ? 'error' : 'success'}
                duration={3000}
                onClose={() => setShowToast(false)}
              />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditListingPage;