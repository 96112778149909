import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  Banknote, 
  Users, 
  CalendarCheck, 
  Shield, 
  HandHeart, 
  Smile,
  X,
  ChevronDown,
  ArrowRight
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import heroImage from '../../assets/images/become-host-hero.webp';

const BenefitCard = ({ icon: Icon, title, description }) => (
  <motion.div
    whileHover={{ y: -8, boxShadow: '0 10px 30px -10px rgba(0,0,0,0.1)' }}
    className="bg-white rounded-2xl p-8 shadow-sm hover:shadow-xl 
               transition-all duration-300 border border-gray-100"
  >
    <div className="w-16 h-16 bg-primary/10 rounded-xl flex items-center 
                   justify-center mb-6 group-hover:scale-110 transition-all duration-300">
      <Icon className="w-8 h-8 text-primary" />
    </div>
    <h3 className="text-xl font-semibold mb-3 text-gray-900">{title}</h3>
    <p className="text-gray-600 leading-relaxed">{description}</p>
  </motion.div>
);

const Step = ({ icon: Icon, title, description, number }) => (
  <div className="relative bg-white rounded-2xl p-8 shadow-sm border border-gray-100">
    <div className="absolute -top-4 -left-4 w-12 h-12 bg-primary text-white rounded-xl 
                   flex items-center justify-center font-bold text-lg">
      {number}
    </div>
    <div className="pt-4">
      <div className="w-16 h-16 bg-primary/10 rounded-full flex items-center 
                     justify-center mb-6 mx-auto">
        <Icon className="w-8 h-8 text-primary" />
      </div>
      <h3 className="text-xl font-semibold mb-3 text-center text-gray-900">{title}</h3>
      <p className="text-gray-600 text-center leading-relaxed">{description}</p>
    </div>
  </div>
);

const LoginModal = ({ onClose, onLogin }) => (
  <div
    className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 
                 flex items-center justify-center p-4"
  >
    <div className="bg-white rounded-2xl p-8 max-w-sm w-full relative shadow-xl">
      <button
        onClick={onClose}
        className="absolute top-4 right-4 p-2 text-gray-500 hover:bg-gray-100 
                   rounded-full transition-colors"
      >
        <X className="w-5 h-5" />
      </button>
      <h2 className="text-2xl font-bold mb-4">Connexion requise</h2>
      <p className="text-gray-600 mb-6">
        Veuillez vous connecter pour commencer votre aventure d'hôte.
      </p>
      <div className="flex flex-col-reverse sm:flex-row gap-4 justify-end">
        <button
          onClick={onClose}
          className="px-6 py-3 font-semibold text-gray-700 border-2 border-gray-300 
              hover:border-gray-400 hover:bg-gray-50 hover:scale-105
              rounded-xl transition-all duration-300"
        >
          Annuler
        </button>
        <button
          onClick={onLogin}
          className="px-6 py-3 font-semibold text-white rounded-xl
              bg-gradient-to-r from-blue-500 to-purple-600 
              hover:from-blue-600 hover:to-purple-700
              shadow-lg hover:shadow-purple-500/25 
              hover:scale-110 transition-all duration-300"
        >
          Se connecter
        </button>
      </div>
    </div>
  </div>
);

const BecomeHost = () => {
  const { loading, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  const handleStartJourney = () => {
    if (isAuthenticated) {
      navigate('/become-host/intro');
    } else {
      setShowLoginPrompt(true);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-primary/20 border-t-primary 
                      rounded-full animate-spin"/>
      </div>
    );
  }

  const benefits = [
    {
      icon: Banknote,
      title: "Revenus Flexibles",
      description: "Gagnez un revenu supplémentaire en louant vos espaces de manière flexible."
    },
    {
      icon: Users,
      title: "Rejoignez une Communauté",
      description: "Faites partie d'une communauté de professionnels inspirés."
    },
    {
      icon: CalendarCheck,
      title: "Gestion Simplifiée",
      description: "Gérez vos réservations en toute simplicité avec notre plateforme."
    },
    {
      icon: Shield,
      title: "Sécurité & Garantie",
      description: "Protégez votre espace avec nos garanties d'assurance."
    }
  ];

  const steps = [
    {
      icon: HandHeart,
      title: "Inscription Rapide",
      description: "Créez votre compte en quelques clics.",
      number: "1"
    },
    {
      icon: Smile,
      title: "Préparez votre Espace",
      description: "Présentez votre espace aux télétravailleurs.",
      number: "2"
    },
    {
      icon: CalendarCheck,
      title: "Commencez à Accueillir",
      description: "Gérez vos réservations et accueillez vos invités.",
      number: "3"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <section className="relative min-h-[85vh]">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-black/70 
                         via-black/50 to-black/70 z-10" />
          <img
            src={heroImage}
            alt="Espace de travail moderne"
            className="w-full h-full object-cover"
          />
        </div>
        
        <div className="relative z-20 h-full flex items-center justify-center py-20
                      px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-4xl">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-white 
                           mb-6 leading-tight">
                Ouvrez les portes de votre espace
                <span className="block mt-2 text-primary">
                  au monde du télétravail
                </span>
              </h1>
            </motion.div>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="text-xl sm:text-2xl text-white/90 mb-12 max-w-3xl mx-auto"
            >
              Transformez votre espace en une destination flexible pour
              des professionnels en quête d'inspiration.
            </motion.p>

            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="relative inline-block group"
            >
              <div className="absolute -inset-1 bg-gradient-to-r from-primary to-primary/60 
                           rounded-full blur opacity-30 group-hover:opacity-100 
                           transition duration-500"/>
              <button
                onClick={handleStartJourney}
                className="relative px-8 py-4 bg-primary text-white text-lg 
                         rounded-full font-medium hover:shadow-xl 
                         hover:bg-primary/90 flex items-center gap-2"
              >
                Commencez l'aventure
                <ArrowRight className="w-5 h-5 animate-pulse" />
              </button>
            </motion.div>
          </div>
        </div>

        {/* Scroll Indicator */}
        <div className="absolute bottom-8 left-1/2 -translate-x-1/2 z-20">
          <motion.div
            animate={{ y: [0, 8, 0] }}
            transition={{ duration: 1.5, repeat: Infinity }}
            className="flex flex-col items-center gap-2"
          >
            <span className="text-white/80 text-sm">Découvrir plus</span>
            <ChevronDown className="w-6 h-6 text-white/80" />
          </motion.div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl sm:text-4xl font-bold mb-4">
              Pourquoi devenir hôte ?
            </h2>
            <p className="text-xl text-gray-600">
              Découvrez les avantages de rejoindre notre communauté
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <BenefitCard {...benefit} />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Steps Section */}
      <section className="py-24 px-4 sm:px-6 lg:px-8 bg-white">
        <div className="max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl sm:text-4xl font-bold mb-4">
              Comment ça marche ?
            </h2>
            <p className="text-xl text-gray-600">
              Trois étapes simples pour commencer
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {steps.map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Step {...step} />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Sécurité Section */}
      <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-50">
        <div className="max-w-4xl mx-auto text-center">
          <Shield className="w-16 h-16 text-primary mx-auto mb-6" />
          <h2 className="text-3xl sm:text-4xl font-bold mb-6">
            Hébergement en toute sécurité
          </h2>
          <p className="text-xl text-gray-600">
            HomeOfficeShare s'engage à garantir la sécurité de votre espace
            avec des protections et des garanties pour chaque réservation.
          </p>
        </div>
      </section>

      {/* Modal de connexion */}
      {showLoginPrompt && (
        <LoginModal
          onClose={() => setShowLoginPrompt(false)}
          onLogin={() => navigate('/login', { 
            state: { redirectTo: '/become-host/intro' } 
          })}
        />
      )}
    </div>
  );
};

export default BecomeHost;