import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { 
  ArrowLeft,
  ArrowRight,
  MapPin,
  HelpCircle,
  Shield,
  X
} from 'lucide-react';
import { useForm } from '../../../context/FormHostContext';
import SaveQuitButton from '../../../components/SaveQuitButton';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const ProgressIndicator = ({ currentStep, totalMainSteps, currentSubStep, totalSubSteps }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-3">
        <div className="flex-1 h-2 bg-gray-100 rounded-full overflow-hidden">
          <div 
            className="h-full bg-gradient-to-r from-blue-500 to-blue-400 rounded-full transition-all duration-500 ease-out"
            style={{ width: `${(currentStep / totalMainSteps) * 100}%` }}
          />
        </div>
        <span className="text-sm font-medium text-gray-500">
          Étape {currentStep}/{totalMainSteps}
        </span>
      </div>
      
      <div className="flex gap-2">
        {Array.from({ length: totalSubSteps }).map((_, index) => (
          <div 
            key={index}
            className={`h-1 flex-1 rounded-full transition-all duration-300
                      ${index < currentSubStep ? 'bg-blue-500' : 'bg-gray-200'}`}
          />
        ))}
      </div>
    </div>
  );
};

const Toast = ({ message, onClose }) => (
  <div className="fixed top-4 right-4 z-50 animate-slide-in">
    <div className="bg-red-500 text-white px-6 py-3 rounded-lg shadow-lg flex items-center gap-3">
      <span>{message}</span>
      <button onClick={onClose} className="text-white hover:text-red-100">
        <X className="w-4 h-4" />
      </button>
    </div>
  </div>
);

const StepProgress = ({ currentStep, totalSteps }) => (
  <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                  bg-white rounded-full shadow-lg p-2 border border-gray-200">
    <div className="flex items-center gap-2 px-3 py-1">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`rounded-full transition-all duration-300 
                    ${index < currentStep ? 'w-8 h-2 bg-blue-500' : 'w-2 h-2 bg-gray-200'}`}
        />
      ))}
    </div>
  </div>
);

export default function StepOneEmplacement() {
  const navigate = useNavigate();
  const [address, setAddress] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const { formData, handleChange } = useForm();

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v11',
      center: [2.3522, 48.8566],
      zoom: 12
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      placeholder: 'Saisissez votre adresse',
      marker: {
        color: '#2563eb'
      },
      proximity: { longitude: 2.3522, latitude: 48.8566 }
    });

    geocoder.addTo('#geocoder-container');

    geocoder.on('result', (e) => {
      const placeType = e.result.place_type[0];
      if (placeType === 'address') {
        setAddress(e.result.place_name);
        handleChange('longitude', e.result.geometry.coordinates[0]);
        handleChange('latitude', e.result.geometry.coordinates[1]);
        handleChange('adresse', e.result.place_name);
        handleChange('ville', e.result?.context.find(c => c.id.includes('place'))?.text);
        handleChange('pays', e.result?.context.find(c => c.id.includes('country'))?.text);

        map.current.flyTo({
          center: e.result.geometry.coordinates,
          zoom: 15,
          essential: true,
          duration: 1000
        });
      } else {
        setToastMessage('Veuillez sélectionner une adresse complète.');
        setShowToast(true);
      }
    });

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Vos modifications ne seront pas enregistrées.';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    setIsVisible(true);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleNext = () => {
    if (address && formData.adresse) {
      navigate('/become-host/steponecapacity');
    } else {
      setToastMessage('Veuillez sélectionner une adresse complète.');
      setShowToast(true);
    }
  };

  return (
    <div className="min-h-screen bg-white">
      {showToast && (
        <Toast 
          message={toastMessage} 
          onClose={() => setShowToast(false)} 
        />
      )}

      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-lg border-b border-gray-100 z-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <button className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors">
            <HelpCircle className="w-5 h-5" />
            <span>Des questions ?</span>
          </button>
          <SaveQuitButton />
        </div>
      </header>

      {/* Main Content */}
      <main className="pt-20 pb-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className={`transition-all duration-700 ease-out
                        ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
            {/* Title Section */}
            <div className="max-w-2xl mb-8">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 bg-blue-50 rounded-full">
                  <MapPin className="w-6 h-6 text-blue-500" />
                </div>
                <h1 className="text-3xl font-bold text-gray-900">
                  Où est situé votre espace ?
                </h1>
              </div>
              <div className="flex items-start gap-3 p-4 bg-blue-50 rounded-xl">
                <Shield className="w-5 h-5 text-blue-500 flex-shrink-0 mt-1" />
                <p className="text-blue-700">
                  Votre adresse est uniquement communiquée aux voyageurs une fois leur réservation effectuée.
                </p>
              </div>
            </div>

            {/* Map Container */}
            <div className="bg-white rounded-2xl overflow-hidden shadow-xl mb-12">
              <div id="geocoder-container" className="p-4 border-b border-gray-100" />
              <div 
                ref={mapContainer} 
                className="h-[400px] w-full transition-all duration-300"
              />
            </div>
          </div>
        </div>
      </main>

      <StepProgress currentStep={3} totalSteps={14} />

      {/* Footer */}
      <footer className="fixed bottom-0 left-0 right-0 bg-white/80 backdrop-blur-lg border-t border-gray-100">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <button
            onClick={() => navigate('/become-host/steponetype')}
            className="flex items-center gap-2 px-6 py-3 rounded-full text-gray-700 
                     hover:bg-gray-100 transition-all duration-300"
          >
            <ArrowLeft className="w-5 h-5" />
            <span>Retour</span>
          </button>

          <button
            onClick={handleNext}
            disabled={!address}
            className={`flex items-center gap-2 px-8 py-3 rounded-full font-medium
                     transition-all duration-300
                     ${address
                       ? 'bg-gradient-to-r from-blue-600 to-blue-500 text-white hover:shadow-lg hover:shadow-blue-500/20 hover:-translate-y-0.5'
                       : 'bg-gray-100 text-gray-400 cursor-not-allowed'}`}
          >
            <span>Suivant</span>
            <ArrowRight className="w-5 h-5" />
          </button>
        </div>
      </footer>

      <style>{`
        .mapboxgl-ctrl-geocoder {
          width: 100% !important;
          max-width: 100% !important;
          box-shadow: none !important;
          border: 1px solid #e5e7eb;
          border-radius: 0.75rem;
        }

        .mapboxgl-ctrl-geocoder--input {
          height: 48px !important;
          padding: 6px 45px !important;
        }

        .mapboxgl-ctrl-geocoder--icon {
          top: 12px !important;
        }

        .mapboxgl-ctrl-geocoder--button {
          top: 0 !important;
        }

        @keyframes slide-in {
          from {
            transform: translateX(100%);
            opacity: 0;
          }
          to {
            transform: translateX(0);
            opacity: 1;
          }
        }

        .animate-slide-in {
          animation: slide-in 0.3s ease-out;
        }
      `}</style>
    </div>
  );
}