import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  Search, Plus, Grid, List, Trash2, Edit2, 
  MapPin, Clock, AlertCircle, Check, X, Filter
} from 'lucide-react';

const ListingsPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [value, setValue] = useState([]);
  const [viewType, setViewType] = useState('grid');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState('all');
  const [listings, setListings] = useState([]);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [listingToDelete, setListingToDelete] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const [isLoading, setIsLoading] = useState(true);
  
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_GATEWAY;

  const filteredListings = listings.filter(listing => 
    (activeFilter === 'all' || 
    (activeFilter === 'Disponible' && listing.status === 'Disponible') || 
    (activeFilter === 'Indisponible' && listing.status !== 'Indisponible')) &&
    (searchQuery === '' ||  // Affiche si la recherche est vide
     (listing.titre?.toLowerCase().includes(searchQuery.toLowerCase())) ||
     (listing.adresse?.toLowerCase().includes(searchQuery.toLowerCase())))
  ).sort((a, b) => {
      if (a.status === 'Indisponible' && b.status !== 'Indisponible') return -1;
      if (a.status !== 'Disponible' && b.status === 'Disponible') return 1;
      return 0;
  });

  useEffect(() => {
    const fetchListings = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${apiUrl}/api/workspace/myWorkspace`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });

        const spacesData = await response.json();
        setListings(Array.isArray(spacesData.data) ? spacesData.data : []);

      } catch (error) {
        console.error('Erreur:', error);
        setListings([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchListings();
  }, [apiUrl]);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${apiUrl}/api/workspace/deleteWorkspace/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });

      const data = await response.json();
      if (response.ok) {
        setListings(prev => prev.filter(listing => listing.espace_id !== id));
        showNotification('Espace supprimé avec succès', 'success');
      } else {
        showNotification(data.error.message || 'Erreur lors de la suppression', 'error');
      }
    } catch (error) {
      showNotification('Erreur serveur', 'error');
    }
  };

  const showNotification = (message, type = 'success') => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const ConfirmationModal = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-2xl p-6 max-w-sm w-full shadow-xl"
      >
        <div className="flex items-center gap-3 mb-4">
          <div className="p-2 bg-red-100 rounded-full">
            <AlertCircle className="w-6 h-6 text-red-600" />
          </div>
          <h3 className="text-xl font-semibold text-gray-900">Confirmer la suppression</h3>
        </div>
        
        <p className="text-gray-600 mb-6">
          Êtes-vous sûr de vouloir supprimer cette annonce ? Cette action est irréversible.
        </p>
        
        <div className="flex gap-3">
          <button
            onClick={() => {
              handleDelete(listingToDelete.espace_id);
              setIsDeleteConfirmOpen(false);
            }}
            className="flex-1 px-4 py-2 bg-red-600 text-white rounded-xl hover:bg-red-700 
                       transition-colors duration-200"
          >
            Supprimer
          </button>
          <button
            onClick={() => setIsDeleteConfirmOpen(false)}
            className="flex-1 px-4 py-2 bg-gray-100 text-gray-700 rounded-xl hover:bg-gray-200 
                       transition-colors duration-200"
          >
            Annuler
          </button>
        </div>
      </motion.div>
    </motion.div>
  );

  const ListingCard = ({ listing }) => (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="bg-white rounded-2xl shadow-sm hover:shadow-lg transition-all duration-300 overflow-hidden"
    >
      <div className="relative group">
        <img
          src={`data:image/jpeg;base64,${listing.photo_base64}`}
          alt={listing.titre}
          className="w-full h-56 object-cover transition-transform duration-300 group-hover:scale-105"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 
                        group-hover:opacity-100 transition-opacity duration-300" />
        <div className={`absolute top-4 left-4 px-3 py-1 rounded-full text-sm font-medium 
                        ${listing.status === "Masquée" 
                          ? "bg-red-500" 
                          : listing.status === "En cours" 
                          ? "bg-amber-500" 
                          : "bg-emerald-500"} text-white`}>
          {listing.status}
        </div>
      </div>

      <div className="p-6 space-y-4">
        <h3 className="text-xl font-semibold text-gray-900 leading-tight">{listing.titre}</h3>
        
        <div className="flex items-center gap-2 text-gray-500">
          <MapPin className="w-4 h-4" />
          <p className="text-sm truncate">{listing.adresse}</p>
        </div>

        <div className="flex gap-2">
          <button
            onClick={() => navigate(`/listings/edit/${listing.espace_id}`)}
            className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-blue-50 text-blue-600 
                       rounded-xl hover:bg-blue-100 transition-colors duration-200"
          >
            <Edit2 className="w-4 h-4" />
            <span>Modifier</span>
          </button>
          <button
            onClick={() => {
              setListingToDelete(listing);
              setIsDeleteConfirmOpen(true);
            }}
            className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-red-50 text-red-600 
                       rounded-xl hover:bg-red-100 transition-colors duration-200"
          >
            <Trash2 className="w-4 h-4" />
            <span>Supprimer</span>
          </button>
        </div>
      </div>
    </motion.div>
  );

  const ListingRow = ({ listing }) => (
    <motion.div
      layout
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      className="flex items-center gap-4 p-4 bg-white rounded-xl hover:shadow-md transition-all duration-300"
    >
      <img
        src={`data:image/jpeg;base64,${listing.photo_base64}`}
        alt={listing.titre}
        className="w-20 h-20 object-cover rounded-lg"
      />
      
      <div className="flex-1 min-w-0">
        <h3 className="text-lg font-semibold text-gray-900 truncate">{listing.titre}</h3>
        <div className="flex items-center gap-2 text-gray-500 mt-1">
          <MapPin className="w-4 h-4" />
          <p className="text-sm truncate">{listing.adresse}</p>
        </div>
      </div>

      <div className={`px-3 py-1 rounded-full text-sm font-medium 
                      ${listing.status === "Masquée" 
                        ? "bg-red-100 text-red-700" 
                        : listing.status === "En cours" 
                        ? "bg-amber-100 text-amber-700" 
                        : "bg-emerald-100 text-emerald-700"}`}>
        {listing.status}
      </div>

      <div className="flex gap-2">
        <button
          onClick={() => navigate(`/listings/edit/${listing.espace_id}`)}
          className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors duration-200"
        >
          <Edit2 className="w-5 h-5" />
        </button>
        <button
          onClick={() => {
            setListingToDelete(listing);
            setIsDeleteConfirmOpen(true);
          }}
          className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors duration-200"
        >
          <Trash2 className="w-5 h-5" />
        </button>
      </div>
    </motion.div>
  );

  const Toast = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className={`fixed bottom-4 right-4 max-w-sm w-full shadow-lg rounded-xl overflow-hidden
                 ${toastType === 'success' ? 'bg-emerald-600' : 'bg-red-600'}`}
    >
      <div className="p-4 flex items-center gap-3">
        <div className="flex-shrink-0">
          {toastType === 'success' ? (
            <Check className="w-5 h-5 text-white" />
          ) : (
            <AlertCircle className="w-5 h-5 text-white" />
          )}
        </div>
        <p className="flex-1 text-white font-medium">{toastMessage}</p>
        <button 
          onClick={() => setShowToast(false)}
          className="flex-shrink-0 text-white/80 hover:text-white transition-colors duration-200"
        >
          <X className="w-5 h-5" />
        </button>
      </div>
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-gray-50/50 p-6 md:p-8 space-y-8">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-8">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-900">
            Mes Annonces
          </h1>

          <div className="flex items-center gap-3">
            {/* <button
              onClick={() => setViewType(viewType === 'grid' ? 'list' : 'grid')}
              className="p-2 text-gray-600 hover:bg-white rounded-xl transition-colors duration-200"
            >
              {viewType === 'grid' ? <List className="w-5 h-5" /> : <Grid className="w-5 h-5" />}
            </button> */}

            <button
              onClick={() => setIsSearchOpen(!isSearchOpen)}
              className="p-2 text-gray-600 hover:bg-white rounded-xl transition-colors duration-200"
            >
              <Search className="w-5 h-5" />
            </button>

            <button
              onClick={() => navigate('/become-host')}
              className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-xl 
                         hover:bg-blue-700 transition-colors duration-200"
            >
              <Plus className="w-5 h-5" />
              <span className="hidden md:inline">Nouvelle annonce</span>
            </button>
          </div>
        </div>

        {/* Search and Filters */}
        <div className="space-y-4 mb-8">
          <AnimatePresence>
            {isSearchOpen && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                className="overflow-hidden"
              >
                <div className="flex gap-2 p-2 bg-white rounded-xl shadow-sm">
                  <Search className="w-5 h-5 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Rechercher par titre ou adresse..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="flex-1 bg-transparent outline-none text-gray-900 placeholder-gray-400"
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          <div className="flex flex-wrap gap-2">
            {['all', 'Disponible', 'Indisponible'].map((filter) => (
              <button
                key={filter}
                onClick={() => setActiveFilter(filter)}
                className={`px-4 py-2 rounded-xl transition-colors duration-200 ${
                  activeFilter === filter
                    ? 'bg-blue-600 text-white'
                    : 'bg-white text-gray-600 hover:bg-gray-50'
                }`}
              >
                {filter === 'all' ? 'Tous' : filter}
              </button>
            ))}
          </div>
        </div>

        {/* Listings Grid/List */}
        {isLoading ? (
          <div className="flex items-center justify-center min-h-[400px]">
            <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
          </div>
        ) : filteredListings.length > 0 ? (
          <motion.div layout>
            <AnimatePresence mode="wait">
              {viewType === 'grid' ? (
                <motion.div
                  key="grid"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
                >
                  {filteredListings.map((listing) => (
                    <ListingCard key={listing.espace_id} listing={listing} />
                  ))}
                </motion.div>
              ) : (
                <motion.div
                  key="list"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="space-y-4"
                >
                  {filteredListings.map((listing) => (
                    <ListingRow key={listing.espace_id} listing={listing} />
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex flex-col items-center justify-center min-h-[400px] bg-white rounded-2xl p-8"
          >
            <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mb-4">
              <Search className="w-8 h-8 text-gray-400" />
            </div>
            <h3 className="text-xl font-semibold text-gray-900 mb-2">
              Aucune annonce trouvée
            </h3>
            <p className="text-gray-500">
              {searchQuery 
                ? "Aucun résultat ne correspond à votre recherche" 
                : "Vous n'avez pas encore d'annonces. Commencez par en créer une !"}
            </p>
            {!searchQuery && (
              <button
                onClick={() => navigate('/become-host')}
                className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-xl hover:bg-blue-700 
                         transition-colors duration-200 flex items-center gap-2"
              >
                <Plus className="w-5 h-5" />
                <span>Créer une annonce</span>
              </button>
            )}
          </motion.div>
        )}

        {/* Confirmation Modal */}
        <AnimatePresence>
          {isDeleteConfirmOpen && <ConfirmationModal />}
        </AnimatePresence>

        {/* Toast Notification */}
        <AnimatePresence>
          {showToast && <Toast />}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default ListingsPage;