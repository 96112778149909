import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  PencilRuler,
  Camera,
  Sparkles,
  HelpCircle,
  ArrowLeft,
  ArrowRight,
  Lightbulb,
  PenTool,
  BookOpen
} from 'lucide-react';
import SaveQuitButton from '../../../components/SaveQuitButton';
import steptwoimage from "../../../assets/images/ny1.webp";

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white/80 backdrop-blur-sm rounded-xl p-6 shadow-lg border border-blue-50
                  hover:shadow-xl hover:-translate-y-1 transform transition-all duration-300">
    <div className="flex items-start gap-4">
      <div className="p-2 bg-blue-50 rounded-lg text-blue-500">
        <Icon className="w-6 h-6" />
      </div>
      <div>
        <h3 className="font-semibold text-gray-900 mb-1">{title}</h3>
        <p className="text-gray-600 text-sm leading-relaxed">{description}</p>
      </div>
    </div>
  </div>
);

const StepProgress = ({ currentStep, totalSteps }) => (
  <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 z-40
                  bg-white/90 backdrop-blur-sm rounded-full shadow-lg p-2 border border-blue-50">
    <div className="flex items-center gap-2 px-3 py-1">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`rounded-full transition-all duration-300 
                    ${index < currentStep 
                      ? 'w-8 h-2 bg-blue-500' 
                      : 'w-2 h-2 bg-gray-200'}`}
        />
      ))}
    </div>
  </div>
);

export default function StepTwoIntro() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Vos modifications ne seront pas enregistrées.';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  const features = [
    {
      icon: PenTool,
      title: "Équipements",
      description: "Détaillez les équipements disponibles pour offrir une expérience de travail optimale"
    },
    {
      icon: Camera,
      title: "Photos",
      description: "Mettez en valeur votre espace avec des photos de qualité qui inspirent confiance"
    },
    {
      icon: BookOpen,
      title: "Description",
      description: "Racontez l'histoire de votre espace et ce qui le rend unique pour les télétravailleurs"
    }
  ];

  return (
    <div className="min-h-screen relative bg-gradient-to-br from-gray-50 to-blue-50">
      {/* Decorative Background */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 -right-1/4 w-1/2 h-1/2 bg-blue-100/30 
                      rounded-full blur-3xl transform rotate-12 animate-float" />
        <div className="absolute -bottom-1/4 -left-1/4 w-1/2 h-1/2 bg-blue-100/30 
                      rounded-full blur-3xl transform -rotate-12 animate-float-delayed" />
      </div>

      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-lg border-b border-blue-50 z-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <button className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors">
            <HelpCircle className="w-5 h-5" />
            <span>Des questions ?</span>
          </button>
          <SaveQuitButton />
        </div>
      </header>

      {/* Main Content */}
      <main className="relative pt-20 pb-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid lg:grid-cols-2 gap-12 lg:gap-20 items-center">
            {/* Left Content */}
            <div className={`transition-all duration-700 ease-out
                          ${isVisible ? 'translate-x-0 opacity-100' : '-translate-x-10 opacity-0'}`}>
              <div className="max-w-xl">
                {/* Step Indicator */}
                <div className="inline-flex items-center gap-2 px-4 py-2 bg-blue-50 
                              rounded-full text-blue-600 text-sm font-medium mb-6">
                  <PencilRuler className="w-4 h-4" />
                  <span>Étape 2</span>
                </div>

                <h1 className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6 leading-tight">
                  Ajoutez une touche unique 
                  <span className="text-blue-600"> à votre espace</span>
                </h1>

                <p className="text-xl text-gray-600 leading-relaxed mb-12">
                  Personnalisez votre annonce en ajoutant tous les éléments qui rendront
                  votre espace irrésistible pour les télétravailleurs.
                </p>

                {/* Features Grid */}
                <div className="grid gap-6">
                  {features.map((feature, index) => (
                    <FeatureCard key={index} {...feature} />
                  ))}
                </div>
              </div>
            </div>

            {/* Right Image */}
            <div className={`relative transition-all duration-700 delay-300 ease-out
                          ${isVisible ? 'translate-x-0 opacity-100' : 'translate-x-10 opacity-0'}`}>
              <div className="relative rounded-3xl overflow-hidden bg-gradient-to-br from-blue-100 to-blue-50 
                            aspect-[4/3] shadow-2xl">
                <img
                  src={steptwoimage}
                  alt="Aperçu de l'espace"
                  className="w-full h-full object-cover"
                />
                {/* Overlay gradient */}
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-transparent" />

                {/* Decorative Elements */}
                <div className="absolute top-4 right-4 transform rotate-12">
                  <Sparkles className="w-6 h-6 text-blue-500" />
                </div>
                <div className="absolute bottom-4 left-4">
                  <Lightbulb className="w-6 h-6 text-blue-500" />
                </div>
              </div>

              {/* Shadow Effect */}
              <div className="absolute -z-10 inset-0 transform translate-x-4 translate-y-4">
                <div className="absolute inset-0 bg-blue-100/50 rounded-3xl" />
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Progress Steps */}
      <StepProgress currentStep={5} totalSteps={14} />

      {/* Footer Navigation */}
      <footer className="fixed bottom-0 left-0 right-0 bg-white/80 backdrop-blur-lg border-t border-blue-50">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <button
            onClick={() => navigate('/become-host/steponecapacity')}
            className="flex items-center gap-2 px-6 py-3 rounded-full text-gray-700 
                     hover:bg-gray-100 transition-all duration-300"
          >
            <ArrowLeft className="w-5 h-5" />
            <span>Retour</span>
          </button>

          <button
            onClick={() => navigate('/become-host/steptwoequipments')}
            className="flex items-center gap-2 px-8 py-3 rounded-full text-white
                     bg-gradient-to-r from-blue-600 to-blue-500
                     hover:shadow-lg hover:shadow-blue-500/20 hover:-translate-y-0.5
                     transform transition-all duration-300"
          >
            <span>Continuer</span>
            <ArrowRight className="w-5 h-5" />
          </button>
        </div>
      </footer>

      {/* Animations Keyframes */}
      <style>{`
        @keyframes float {
          0% { transform: translate(0, 0) scale(1); }
          50% { transform: translate(2%, 2%) scale(1.05); }
          100% { transform: translate(0, 0) scale(1); }
        }
        
        @keyframes float-delayed {
          0% { transform: translate(0, 0) scale(1.05); }
          50% { transform: translate(-2%, -2%) scale(1); }
          100% { transform: translate(0, 0) scale(1.05); }
        }
        
        .animate-float {
          animation: float 20s ease-in-out infinite;
        }
        
        .animate-float-delayed {
          animation: float-delayed 20s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
}