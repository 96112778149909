import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';

function ProfileMenu({ isLoggedIn, setIsLoggedIn, isMobile }) {
  const { isAuthenticated, logout, userPhoto, userMode, setMode } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  // Gérer la fermeture du menu au clic à l'extérieur
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleModeSwitch = () => {
    const newMode = userMode === 'guest' ? 'host' : 'guest';
    setMode(newMode);
    localStorage.setItem('userMode', newMode);
    setDropdownOpen(false);
    if (newMode === 'guest') {
      navigate('/dashboard');
    } else {
      navigate('/hosting');
    }
  };

  const handleLinkClick = () => {
    setDropdownOpen(false);
  };

  // Style conditionnel pour le menu déroulant en mode mobile
  const dropdownStyles = isMobile
    ? "w-full bg-white py-2"
    : "absolute right-0 mt-2 bg-white shadow-lg rounded-lg py-2 w-[230px] z-50";

  return (
    <div ref={menuRef} className={`relative ${isMobile ? 'w-full' : ''}`}>
      {/* Profile Icon/Button */}
      {!isMobile && (
        <div 
          className="profile-icon cursor-pointer flex items-center gap-2 p-1 rounded-full hover:bg-gray-50 transition-colors"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          {userPhoto ? (
            <img
              src={userPhoto}
              alt="Profil"
              className="rounded-full w-8 h-8 object-cover border-2 border-white shadow-sm"
            />
          ) : (
            <FaUserCircle className="text-2xl text-gray-600" />
          )}
        </div>
      )}

      {/* Menu Content */}
      {(dropdownOpen || isMobile) && (
        <div className={dropdownStyles}>
          <ul className="space-y-1 px-4">
            {userMode === 'host' ? (
              <>
                <li>
                  <Link 
                    to="/profile" 
                    className="block px-3 py-2 rounded-lg text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors" 
                    onClick={handleLinkClick}
                  >
                    Profil
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/account" 
                    className="block px-3 py-2 rounded-lg text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors" 
                    onClick={handleLinkClick}
                  >
                    Compte
                  </Link>
                </li>
                <li>
                  <Link to="/favorites" 
                      className="block px-3 py-2 rounded-lg text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors" 
                      onClick={handleLinkClick}
                    >
                      Mes favoris
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/become-host" 
                    className="block px-3 py-2 rounded-lg text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors" 
                    onClick={handleLinkClick}
                  >
                    Créer une annonce
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link 
                    to="/account" 
                    className="block px-3 py-2 rounded-lg text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors" 
                    onClick={handleLinkClick}
                  >
                    Compte
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/messages" 
                    className="block px-3 py-2 rounded-lg text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors" 
                    onClick={handleLinkClick}
                    >
                    Messages
                  </Link>
                </li>
                {isMobile && (
                  <li>
                    <Link 
                      to="/bookings" 
                      className="block px-3 py-2 rounded-lg text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors" 
                      onClick={handleLinkClick}
                    >
                      Mes Réservations
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/favorites" 
                      className="block px-3 py-2 rounded-lg text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors" 
                      onClick={handleLinkClick}
                    >
                      Mes favoris
                  </Link>
                </li>
                <li>
                  <Link to="/notifications" 
                      className="block px-3 py-2 rounded-lg text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors" 
                      onClick={handleLinkClick}
                    >
                      Mes notifications
                  </Link>
                </li>
            </>
            )}
            <li>
              <button
                onClick={handleModeSwitch}
                className="w-full text-left px-3 py-2 rounded-lg text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors"
              >
                {userMode === 'guest' ? 'Mode Hôte 🏠' : 'Mode Guest 🌍'}
              </button>
            </li>
            <li className="pt-2">
              <button
                onClick={() => { logout(); setDropdownOpen(false); }}
                className="w-full text-white bg-red-600 hover:bg-red-700 py-2 px-4 rounded-lg transition-colors"
              >
                Se déconnecter
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default ProfileMenu;